import { BlankImg } from "assets/images";
import CustomModal from "components/Modal/CustomModal";
import { useEffectOnce } from "hooks/useEffectOnce";
import React, { useState } from "react";
import HostUserMoreDetail from "./HostUserMoreDetail";

const HostAcceptedList = (props) => {
  const [hostProfile, setHostProfile] = useState();
  const [showUser, setShowUser] = useState(false);
  const handleCloseUser = () => setShowUser(false);
  const handleShowUser = () => setShowUser(true);

  const profilePicture = () => {
    let response = fetch(
      props?.hostDetails?.user?.profile_picture?.cloudinary_file
    );
    response
      .then(function (response) {
        if (response && response.status === 200) {
          setHostProfile(response?.url);
        } else {
          setHostProfile(props?.hostDetails?.user?.profile_picture?.file);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffectOnce(() => {
    profilePicture();
  }, []);

  return (
    <tr>
      <td className="image-title-wrapper">
        <img
          className="host-pp-small"
          onClick={handleShowUser}
          src={
            props?.hostDetails?.user?.profile_picture?.file !== null
              ? hostProfile
              : BlankImg
          }
          alt="user"
        />{" "}
        <CustomModal
          showStatus={showUser}
          modalClass="profile_detail"
          clickStatus={handleCloseUser}
          buttonMessage="Close"
          closeStatus={handleCloseUser}
          title=" "
          message={
            <HostUserMoreDetail details={props?.hostDetails?.user?.id} />
          }
          secondMessage="none"
          secondButton={false}
        />
        <span>
          {props?.hostDetails?.user.first_name}{" "}
          {(props?.hostDetails?.user.last_name).charAt(0)}.
        </span>
      </td>
      <td>
        ${" "}
        {props?.hostDetails?.revenue !== null ? props?.hostDetails?.revenue : 0}
      </td>
      <td>
        ${" "}
        {props?.hostDetails?.earning != null ? props?.hostDetails?.earning : 0}
      </td>
      <td>{props?.hostDetails?.top_product}</td>
    </tr>
  );
};

export default React.memo(HostAcceptedList);
