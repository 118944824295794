import DataContext from "context/DataContext";
import React, { useContext, useState } from "react";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import { formatDate } from "utils/dateFormatter";
import "react-datepicker/dist/react-datepicker.css";
import "./csv.css";

const CsvView = ({ type }) => {
  const { csvDataArray, CSVArrayData } = useContext(DataContext);
  const [startDate, setStartDate] = useState(null);
  const [startDateA, setStartDateA] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [endDateA, setEndDateA] = useState(new Date());
  const [message, setMessage] = useState("No data yet for the CSV.");
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setStartDateA(start);
    setEndDate(end);
    setEndDateA(end);
  };
  const getCSVData = () => {
    if (type === "host-detail") {
      const data = [];
      CSVArrayData?.map((ddData) =>
        data.push({
          fullname: ddData.host.first_name + " " + ddData.host.last_name,
          revenue: "$" + ddData.revenue,
          earnings: "$" + ddData.earning,
          totalShowings: ddData.shows,
          topProduct: ddData.top_product,
        })
      );
      return data;
    } else if (type === "company-detail") {
      const data = [];
      CSVArrayData?.map((ddData) =>
        data.push({
          company: ddData.company.store.name,
          host: "$" + ddData.hosts,
          totalShowings: "$" + ddData.shows,
          revenue: ddData.revenue,
          topProduct: ddData.top_product,
        })
      );
      return data;
    } else if (type === "store-host-detail") {
      const data = [];
      CSVArrayData?.map((ddData) =>
        data.push({
          fullname: ddData.user.first_name + " " + ddData.user.last_name,
          revenue: "$" + ddData.revenue,
          earnings: ddData.earning,
          topProduct: ddData.top_product,
        })
      );
      return data;
    }
  };
  const getCSVHeader = () => {
    if (type === "host-detail") {
      const header = [
        { label: "Host", key: "fullname" },
        { label: "Revenue", key: "revenue" },
        { label: "Earnings", key: "earnings" },
        { label: "Total showings", key: "totalShowings" },
        { label: "Top Product", key: "topProduct" },
      ];
      return header;
    } else if (type === "company-detail") {
      const header = [
        { label: "Company", key: "company" },
        { label: "Hosts", key: "host" },
        { label: "Total showings", key: "totalShowings" },
        { label: "Revenue", key: "revenue" },
        { label: "Top Product", key: "topProduct" },
      ];
      return header;
    } else if (type === "store-host-detail") {
      const header = [
        { label: "Host", key: "fullname" },
        { label: "Revenue", key: "revenue" },
        { label: "Earnings", key: "earnings" },
        { label: "Top Product", key: "topProduct" },
      ];
      return header;
    } else {
      const header = [{ label: "First Name", key: "first" }];
      return header;
    }
  };

  const handleClick = () => {
    if (type === "host-detail") {
      csvDataArray(
        `?order=true&category=host&start_date=${formatDate(
          startDateA
        )}&end_date=${formatDate(endDateA)}`
      );
    } else if (type === "company-detail") {
      csvDataArray(
        `?order=true&category=company&start_date=${formatDate(
          startDateA
        )}&end_date=${formatDate(endDateA)}`
      );
    } else if (type === "store-host-detail") {
      csvDataArray(
        `?order=true&start_date=${formatDate(startDateA)}&end_date=${formatDate(
          endDateA
        )}`
      );
    }
  };

  return (
    <div className="csv-datepicker">
      <DatePicker
        placeholderText="Select Date"
        //   selected={startDate}
        className="csv-calendar mb-3"
        dateFormat="dd-MM-yyyy"
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
      />
      <button className="mb-3" onClick={handleClick}>
        Get Data
      </button>
      <div>
        {CSVArrayData.length < 1 ? (
          <>
            <p>{message}</p>
          </>
        ) : (
          <CSVLink data={getCSVData()} headers={getCSVHeader()}>
            Download CSV
          </CSVLink>
        )}
      </div>
    </div>
  );
};

export default CsvView;
