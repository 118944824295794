import { Navigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "context/AuthContext";

const UserRoute = ({ children, ...rest }) => {
  let { user, loggedIn } = useContext(AuthContext);
  if ((!user && !loggedIn) || !(user.role === 3)) {
    return <Navigate to="/user/welcome" />;
  }
  return children;
};

export default UserRoute;
