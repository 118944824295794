import { createContext, useEffect, useState } from "react";
import { getHostLogbook, createHostLogbook } from "Services/logServices";

const LogContext = createContext();
export default LogContext;

export const LogProvider = ({ children }) => {
  const [allLogbook, setAllLogbook] = useState(null);
  const [allLogbookNext, setAllLogbookNext] = useState(null);
  const [allLogbookPrevious, setAllLogbookPrevious] = useState(null);
  const [allLogbookCount, setAllLogbookCount] = useState(null);

  let getAllHostLogbook = async (param) => {
    try {
      const response = await getHostLogbook(param);
      setAllLogbook(response?.data?.data?.results);
      setAllLogbookNext(response?.data?.data?.next);
      setAllLogbookPrevious(response?.data?.data?.previous);
      setAllLogbookCount(response?.data?.data?.count);
      return response?.data?.data;
    } catch (error) {
      throw error;
    }
  };

  let addHostLogbook = async (data) => {
    try {
      const response = await createHostLogbook(data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  let contextData = {
    getAllHostLogbook: getAllHostLogbook,
    allLogbookNext: allLogbookNext,
    allLogbookPrevious: allLogbookPrevious,
    addHostLogbook: addHostLogbook,
    allLogbook: allLogbook,
    allLogbookCount: allLogbookCount,
  };
  return (
    <LogContext.Provider value={contextData}>{children}</LogContext.Provider>
  );
};
