import React, { useContext, useState } from 'react';
import { Modal, Button, Form, message, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import './HostConnectModal.css';
import { MESSAGES } from './constants/messages';
import { connectToDots } from 'Services/dotsService';
import { GENERIC_ERROR_MESSAGE } from 'utils/constants';
import PhoneInput from 'react-phone-input-2';
import DotsContext from 'context/DotsContext';

/**
 * This component renders a modal for the host connect to dots
 *
 * @return {ReactNode} A React element that renders a modal for the host connect to dots
 */

const HostConnectModal = ({
  hostConnectModalOpen,
  handleModalClose,
  currentStore,
  handleConnectSuccess,
}) => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const { setStoreList, storeList } = useContext(DotsContext);

  const [phoneNumber, setPhoneNumber] = useState('');
  const [dialCode, setDialCode] = useState('1');
  const [countryCode, setCountryCode] = useState('');

  const [dotsConnectLoading, setDotsConnectLoading] = useState(false);

  const onFinish = async () => {
    const payload = {
      phone_number: phoneNumber,
      dial_code: dialCode,
      country_code: countryCode,
      store_id: currentStore?.product?.store?.identifier,
    };

    setDotsConnectLoading(true);
    let win;

    try {
      win = window.open('about:blank');
      const res = await connectToDots(payload);
      if (res && res.data) {
        win.location = res.data.data.flow_link;
        if (currentStore) {
          const resultIndex = storeList.findIndex(
            (store) =>
              store?.product?.store?.identifier ===
              currentStore?.product?.store?.identifier
          );

          if (resultIndex !== -1) {
            const newStoreList = [...storeList];
            newStoreList[resultIndex].store_payment_type =
              res?.data?.data?.dots_user;
            setStoreList(newStoreList);
            handleModalClose();
          }
        }

        setDotsConnectLoading(false);
      } else {
        win.close();
      }
    } catch (e) {
      if (win) {
        win.close();
      }

      if (
        e?.response?.data?.message &&
        typeof e?.response?.data?.message === 'object'
      ) {
        messageApi.open({
          type: 'error',
          content:
            e?.response?.data?.message?.phone_number ||
            e?.message ||
            GENERIC_ERROR_MESSAGE,
          duration: 5,
        });
      } else {
        messageApi.open({
          type: 'error',
          content:
            e?.response?.data?.message || e?.message || GENERIC_ERROR_MESSAGE,
          duration: 5,
        });
      }

      setDotsConnectLoading(false);
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={hostConnectModalOpen}
        className="host-connect-modal"
        onCancel={() => {
          handleModalClose();
        }}
        footer={null}
      >
        <Form layout={'vertical'} form={form} onFinish={onFinish}>
          <Form.Item
            label={
              <>
                <span className="phone-label"> Phone Number </span>

                <Tooltip
                  title={
                    'If you already have a verified phone number on Dots for any other store on Purebrand, please use the same phone number to connect.'
                  }
                >
                  <InfoCircleOutlined />
                </Tooltip>
              </>
            }
            name="phone_number"
            rules={[
              { required: true, message: MESSAGES.FUNDS_AMOUNT_REQUIRED },
            ]}
            value={`${dialCode}${phoneNumber}`}
          >
            <PhoneInput
              country={'us'}
              value={`${dialCode}${phoneNumber}`}
              disableDropdown
              countryCodeEditable={false}
              onChange={(e, data) => {
                setPhoneNumber(e.slice(data.dialCode.length));
                setDialCode(data.dialCode);
                setCountryCode(data.countryCode);
              }}
            />
          </Form.Item>
          <Form.Item>
            <div>
              <Button
                className="btn btn-primary"
                id="dots-connect-btn"
                type="submit"
                loading={dotsConnectLoading}
                onClick={() => {
                  onFinish();
                }}
              >
                Connect to Dots
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default HostConnectModal;
