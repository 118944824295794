import {
  customerDataShopify,
  geoCoords,
  hostGuestStars,
  getShopifyShopDetail,
  getShopifyShopLogo,
  updateShopifyShopLogo,
  getShopifyShopVideo,
  updateShopifyShopVideo,
  getStoreCommissions,
} from "Services/externalApiService";
import { getOtpProfile, putUserOtpProfile } from "Services/userService";
const { createContext } = require("react");

const ApiContext = createContext();
export default ApiContext;

export const ApiProvider = ({ children }) => {

  const getGeoCoords = async (values) => {
    try {
      const response = await geoCoords(values);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const getUserOtp = async (data) => {
    try {
      const response = await getOtpProfile(data);
      return response;
    } catch (error) {
      throw error;
    }
  };
  const putUserOtp = async (data, id, param) => {
    try {
      const response = await putUserOtpProfile(data, id, param);
      return response;
    } catch (error) {
      throw error;
    }
  };
  const userDataShopify = async (param) => {
    try {
      const res = customerDataShopify(param);
      return res;
    } catch (error) {
      throw error;
    }
  };
  const getDataShopifyShop = async (param) => {
    try {
      const response = await getShopifyShopDetail(param);
      return response;
    } catch (error) {
      throw error;
    }
  };
  const getShopifyLogo = async (id) => {
    try {
      const res = await getShopifyShopLogo(id);
      return res;
    } catch (error) {
      throw error;
    }
  };

  const updateShopifyLogo = async (id) => {
    try {
      const res = await updateShopifyShopLogo(id);
      return res;
    } catch (error) {
      // throw error;
      console.log({ error });
    }
  };

  const getShopifyVideo = async (id) => {
    try {
      const res = await getShopifyShopVideo(id);
      return res;
    } catch (error) {
      throw error;
    }
  };

  const updateShopifyVideo = async (id) => {
    try {
      const res = await updateShopifyShopVideo(id);
      return res;
    } catch (error) {
      // throw error;
      console.log({ error });
    }
  };

  const hostGuestRating = async (uid, body) => {
    try {
      const res = await hostGuestStars(uid, body);
      return res;
    } catch (error) {
      throw error;
    }
  };

  const storeCommissions = async (params) => {
    try {
      const res = await getStoreCommissions(params);
      return res;
    } catch (error) {
      throw error;
    }
  };

  let contextData = {
    getGeoCoords: getGeoCoords,
    getUserOtp: getUserOtp,
    putUserOtp: putUserOtp,
    userDataShopify: userDataShopify,
    hostGuestRating: hostGuestRating,
    getDataShopifyShop: getDataShopifyShop,
    getShopifyLogo: getShopifyLogo,
    updateShopifyLogo: updateShopifyLogo,
    getShopifyVideo: getShopifyVideo,
    updateShopifyVideo: updateShopifyVideo,
    storeCommissions: storeCommissions,
  };

  return (
    <ApiContext.Provider value={contextData}>{children}</ApiContext.Provider>
  );
};
