import { Card1, Card2, Card3, dots } from "assets/images";
import React from "react";

const Cardcomponent = () => {
  return (
    <div>
      <div className="container">
        <div className="row text-center card-components-wrap">
          <div className="col-xl-4 col-sm-6 mb-5 card-comp">
            <div className="bg-white shadow-sm py-5 px-4">
              <img
                src={Card1}
                alt="card-1"
                width="100"
                className="img-fluid mb-3"
              />
              <h5 className="mb-0">
                Others make informed decisions from your reviews of products you
                actually own.
              </h5>
            </div>
          </div>
          <div className="col-xl-4 col-sm-6 mb-5 card-comp">
            <div className="bg-white shadow-sm py-5 px-4">
              <img
                src={Card2}
                alt="card-2"
                width="100"
                className="img-fluid  mb-3 "
              />
              <h5 className="mb-0">
                Brands in turn can truly hear their customers likes, dislikes
                and sell their products through you.
              </h5>
            </div>
          </div>

          <div className="col-xl-4 col-sm-6 mb-5 card-comp">
            <div className="bg-white shadow-sm py-5 px-4">
              <img
                src={Card3}
                alt="card-3"
                width="100"
                className="img-fluid mb-3"
              />
              <h5 className="mb-0 justify-content-center">
                You get paid via
                <img
                  src={dots}
                  height="25"
                  width="auto"
                  alt="Dots"
                  className="stripelogo-landing-page"
                  style={{ marginLeft: '10px' }}
                />
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cardcomponent;
