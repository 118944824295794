import Dashelement from "components/Dashboard/Dashelement";
import React from "react";
import { Button } from "react-bootstrap";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

const ApprovalWait = () => {
  //approval status is also handled by the request we take from backend
  const location = useLocation();
  const navigate = useNavigate();
  if (location?.state?.redirected === true) {
    return (
      <>
        <Dashelement className="features approval-check-wrapper">
          <div className="container text-center">
            <div className="text-approval">
              <h3>Please wait for approval</h3>
              <p>
                We will email you in 2-3 business days once you have been
                approved.{" "}
              </p>
            </div>
            <div className="button-wrapper">
              <Button
                variant="primary"
                onClick={() => navigate("/user/showrooms")}
              >
                Upload Another product
              </Button>
            </div>
          </div>
        </Dashelement>
      </>
    );
  } else {
    <Navigate to={"/user/showroom"} />;
  }
};

export default ApprovalWait;
