import Dashelement from "components/Dashboard/Dashelement";
import NavComponent from "components/Navigation/NavComponent";
import PaymentContext from "context/PaymentContext";
import { useEffectOnce } from "hooks/useEffectOnce";
import React, { useContext, useState } from "react";
import { Spinner, Table, Tabs } from "react-bootstrap";
import PaidListUser from "./PaidListUser";
import PaymentListUser from "./PaymentListUser";
import "./paymentadmin.css";
import PurebrandLoader from "components/Loader/PurebrandLoader";

const PaymentPurebrand = () => {
  const {
    payToHostlist,
    adminPaymentList,
    prevStep,
    nextStep,
    nextStepPaid,
    prevStepPaid,
    payToHostlistPaid,
    adminPaymentListPaid,
  } = useContext(PaymentContext);
  const [loading, setLoading] = useState(true);
  const [loadingPros, setLoadingPros] = useState(true);

  const getlist = (type) => {
    if (loadingPros) {
      return (
        <tr>
          <div className="d-flex justify-content-center">
            <Spinner animation="grow" />
            <Spinner animation="grow" />
            <Spinner animation="grow" />
          </div>
        </tr>
      );
    } else {
      if (type === "demo") {
        if (adminPaymentList.length < 1) {
          return (
            <tr>
              <td className="no-data-wrap">No data available yet!</td>
            </tr>
          );
        } else {
          return adminPaymentList?.map((adminPaymentList, index) => {
            return (
              <PaymentListUser key={index} hostDetails={adminPaymentList} />
            );
          });
        }
      } else if (type === "paid") {
        if (adminPaymentListPaid.length < 1) {
          return (
            <tr>
              <td className="no-data-wrap">No data available yet!</td>
            </tr>
          );
        } else {
          return adminPaymentListPaid?.map((adminPaymentListPaid, index) => {
            return (
              <PaidListUser key={index} hostDetails={adminPaymentListPaid} />
            );
          });
        }
      }
    }
  };

  useEffectOnce(() => {
    let first = payToHostlist("?order=true&paid=false");
    let second = payToHostlistPaid("?order=true&paid=true");
    const promise = Promise.all([first, second]);
    promise.then((data) => {
      setLoading(false);
      setLoadingPros(false);
    });
  }, []);
  if (loading) {
    return <PurebrandLoader />;
  } else {
    return (
      <>
        <p>My Payouts</p>
        <div>
          <Tabs
            defaultActiveKey="unpaid"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <NavComponent
              eventKey="unpaid"
              title="Unpaid Revenues"
              className=" payout-list-wrapper"
            >
              <div className="features inner-tab-payout">
                <Table responsive className="table-payout">
                  <thead>
                    <tr>
                      <th className="storename-head">Full name</th>
                      <th className="payment-head">Store name</th>
                      <th className="receive-head">Product</th>
                      <th className="receive-head">Commission type</th>
                      <th className="receive-head price-head">Amount</th>
                      {/* <th className="receive-head">Status</th> */}
                      <th className="receive-head">Guest Name</th>
                      <th className="receive-head ">Demo Date</th>
                    </tr>
                  </thead>
                  <tbody>{getlist("demo")}</tbody>
                </Table>
                <div className="payout-page-title-wrapper my-5 ">
                  <div className="text-center">
                    <button
                      onClick={() => {
                        setLoadingPros(true);
                        let res = payToHostlist(new URL(prevStep).search);
                        res.then(() => setLoadingPros(false));
                      }}
                      disabled={prevStep === null}
                      className=" previous-button-product"
                    >
                      Prev
                    </button>

                    <button
                      onClick={() => {
                        setLoadingPros(true);
                        let res = payToHostlist(new URL(nextStep).search);
                        res.then(() => setLoadingPros(false));
                      }}
                      disabled={!nextStep === null}
                      className=" next-button-product"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </NavComponent>
            <NavComponent
              eventKey="paid"
              title="Paid Revenues"
              className=" payout-list-wrapper"
            >
              <Dashelement className="features payout-tables">
                <div className="features inner-tab-payout">
                  <Table responsive className="table-payout">
                    <thead>
                      <tr>
                        <th className="storename-head">Full name</th>
                        <th className="payment-head">Store name</th>
                        <th className="receive-head">Product</th>
                        <th className="receive-head">Commission type</th>
                        <th className="receive-head price-head">Amount</th>
                        {/* <th className="receive-head">Status</th> */}
                        <th className="receive-head">Guest Name</th>
                        <th className="receive-head">Demo Date</th>
                      </tr>
                    </thead>
                    <tbody>{getlist("paid")}</tbody>
                  </Table>
                  <div className="payout-page-title-wrapper my-5 ">
                    <div className="text-center">
                      <button
                        onClick={() => {
                          setLoadingPros(true);
                          let res = payToHostlistPaid(
                            new URL(prevStepPaid).search
                          );
                          res.then(() => setLoadingPros(false));
                        }}
                        disabled={prevStepPaid === null}
                        className=" previous-button-product"
                      >
                        Prev
                      </button>
                      <button
                        onClick={() => {
                          setLoadingPros(true);
                          let res = payToHostlistPaid(
                            new URL(nextStepPaid).search
                          );
                          res.then(() => setLoadingPros(false));
                        }}
                        disabled={nextStepPaid === null}
                        className=" next-button-product"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </Dashelement>{" "}
            </NavComponent>
          </Tabs>
        </div>
      </>
    );
  }
};

export default PaymentPurebrand;
