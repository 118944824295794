import { BlankImg, Icon, VenmoImage } from "assets/images";
import AuthContext from "context/AuthContext";
import ProfileContext from "context/ProfileContext";
import React, { useContext, useEffect, useState } from "react";
import { Alert, Spinner } from "react-bootstrap";
import DemoContext from "context/DemoContext";
import Logbook from "../Logbook/Logbook";

const HostUserMoreDetail = ({ details }) => {
  const { user, userProfile } = useContext(AuthContext);
  const { storeGetUserData, hostMoreDetail, userSeeRequest } =
    useContext(ProfileContext);
  const [loading, setLoading] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [error, setError] = useState(null);
  let { getShopifyProductStore } = useContext(DemoContext);
  const [productStoreList, setProductStoreList] = useState([]);
  let hostEmail = {
    customer_emails: [hostMoreDetail?.email],
    storeid: userProfile?.store?.store_id,
  };
  const [productHosted, setProductHosted] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    let response = userSeeRequest("?order=true", { signal });
    response
      .then(function (res) {
        setProductHosted(res?.data?.data?.results);
        return res?.data?.data?.results;
      })
      .catch(function (err) {
        return null;
      });

    return () => {
      controller.abort();
    };
  }, [details]);

  useEffect(() => {
    if (user?.role === 2) {
      setLoading(true);
      const res = storeGetUserData(details);
      res
        .then(function (response) {
          setLoading(false);
          setError(null);
        })
        .catch(function (error) {
          setLoading(false);
          setError(true);
        });
    }
  }, []);

  useEffect(() => {
    let body = hostEmail;
    setLoadingProducts(true);
    if (body && body?.customer_emails[0]) {
      let response = getShopifyProductStore(body);
      response
        .then((res) => {
          setProductStoreList(res?.data?.data);
          setLoadingProducts(false);
        })
        .catch((error) => {
          setLoadingProducts(false);
        });
    }
  }, [details, hostEmail.customer_emails[0]]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="grow" />
        <Spinner animation="grow" />
        <Spinner animation="grow" />
      </div>
    );
  } else if (error) {
    return (
      <div>
        <Alert variant="danger">Some error occured!</Alert>
      </div>
    );
  } else if (hostMoreDetail) {
    return (
      <div>
        <div className="user-profile-wrapper">
          <div className="container-wrap ">
            <div className="main-body">
              <div className="row gutters-sm">
                <div className="col-md-5 mb-3">
                  <div className="card cardc">
                    <div className="card-body">
                      <div className="d-flex flex-column align-items-center text-center">
                        <div className="image-circular-edit">
                          <div className="image-view">
                            <img
                              src={
                                (hostMoreDetail?.user_profile?.profile_picture
                                  ?.cloudinary_file !== null
                                  ? hostMoreDetail?.user_profile
                                      ?.profile_picture?.cloudinary_file
                                  : hostMoreDetail?.user_profile
                                      ?.profile_picture?.file) ||
                                (hostMoreDetail?.user_profile
                                  ?.profile_picture === null
                                  ? BlankImg
                                  : "")
                              }
                              alt="User"
                              className="rounded-circle"
                              width="150"
                            />
                          </div>
                        </div>

                        <div className="mt-3">
                          <h4>
                            {hostMoreDetail?.first_name}{" "}
                            {hostMoreDetail?.last_name.charAt(0)}.
                          </h4>
                          {hostMoreDetail?.user_profile?.user_geo_location ? (
                            <>
                              <p className="text-secondary mb-1">
                                {
                                  hostMoreDetail?.user_profile
                                    ?.user_geo_location.address
                                }
                              </p>
                              <p className="text-muted font-size-sm">
                                {
                                  hostMoreDetail?.user_profile
                                    ?.user_geo_location.city
                                }
                                ,{" "}
                                {
                                  hostMoreDetail?.user_profile
                                    ?.user_geo_location.state
                                }
                                ,{" "}
                                {
                                  hostMoreDetail?.user_profile
                                    ?.user_geo_location.zip_code
                                }
                              </p>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {hostMoreDetail?.user_profile?.user_social && (
                    <div className="card cardc mt-3">
                      <ul className="list-group list-group-flush">
                        {hostMoreDetail?.user_profile?.user_social
                          .tiktok_handle === "" ? (
                          <></>
                        ) : (
                          <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                            <h6 className="mb-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-facebook mr-2 icon-inline text-primary"
                              >
                                <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                              </svg>{" "}
                              Tiktok
                            </h6>
                            <span className="text-secondary">
                              {" "}
                              @
                              {
                                hostMoreDetail?.user_profile?.user_social
                                  .tiktok_handle
                              }
                            </span>
                          </li>
                        )}
                        {hostMoreDetail?.user_profile?.user_social
                          .twitter_handle === "" ? (
                          <></>
                        ) : (
                          <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                            <h6 className="mb-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-twitter mr-2 icon-inline text-info"
                              >
                                <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                              </svg>{" "}
                              Twitter
                            </h6>
                            <span className="text-secondary">
                              @
                              {
                                hostMoreDetail?.user_profile?.user_social
                                  .twitter_handle
                              }
                            </span>
                          </li>
                        )}
                        {hostMoreDetail?.user_profile?.user_social
                          .instagram_handle === "" ? (
                          <></>
                        ) : (
                          <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                            <h6 className="mb-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-instagram mr-2 icon-inline text-danger"
                              >
                                <rect
                                  x="2"
                                  y="2"
                                  width="20"
                                  height="20"
                                  rx="5"
                                  ry="5"
                                ></rect>
                                <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                                <line
                                  x1="17.5"
                                  y1="6.5"
                                  x2="17.51"
                                  y2="6.5"
                                ></line>
                              </svg>{" "}
                              Instagram
                            </h6>
                            <span className="text-secondary">
                              @
                              {
                                hostMoreDetail?.user_profile?.user_social
                                  .instagram_handle
                              }
                            </span>
                          </li>
                        )}

                        {hostMoreDetail?.user_profile?.user_social
                          .facebook_handle === "" ? (
                          <></>
                        ) : (
                          <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                            <h6 className="mb-0">
                              {/* <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-facebook mr-2 icon-inline text-primary"
                                >
                                  <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                                </svg>{" "} */}
                              Facebook
                            </h6>
                            <span className="text-secondary">
                              @
                              {
                                hostMoreDetail?.user_profile?.user_social
                                  .facebook_handle
                              }
                            </span>
                          </li>
                        )}
                      </ul>
                    </div>
                  )}

                  <div className="card cardc mt-3">
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item justify-content-between align-items-center">
                        <h6 className="mb-0">Shopping History Emails:</h6>
                      </li>
                    </ul>

                    <div className="normal_view_emails">
                      <div className="email_list_wrap">
                        <ol>
                          {hostMoreDetail?.user_profile?.user_shopify.map(
                            (store, index) => (
                              <li key={index}>{store.store_email}</li>
                            )
                          )}
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="card cardc">
                    <div className="card-body">
                      <h5>Personal information</h5>
                      <hr />
                      <div className="row">
                        <div className="col-sm-3">
                          <h6 className="mb-0">Full Name</h6>
                        </div>
                        <div className="col-sm-9 text-secondary">
                          {hostMoreDetail?.first_name}{" "}
                          {hostMoreDetail?.last_name}
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-sm-3">
                          <h6 className="mb-0">Email</h6>
                        </div>
                        <div className="col-sm-9 text-secondary">
                          {hostMoreDetail?.email}
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-sm-3">
                          <h6 className="mb-0">Phone</h6>
                        </div>

                        {hostMoreDetail?.user_profile?.phone_number === "" ? (
                          <div className="col-sm-9 text-secondary red_pending">
                            Pending
                          </div>
                        ) : (
                          <div className="col-sm-9 text-secondary">
                            {hostMoreDetail?.user_profile?.phone_number}
                          </div>
                        )}
                      </div>

                      <hr />
                      <div className="row">
                        <div className="col-sm-3">
                          <h6 className="mb-0">Address</h6>
                        </div>
                        {hostMoreDetail?.user_profile?.user_geo_location ? (
                          <div className="col-sm-9 text-secondary">
                            {
                              hostMoreDetail?.user_profile?.user_geo_location
                                .address
                            }
                            ,{" "}
                            {
                              hostMoreDetail?.user_profile?.user_geo_location
                                .city
                            }
                            ,{" "}
                            {
                              hostMoreDetail?.user_profile?.user_geo_location
                                .state
                            }
                            ,{" "}
                            {
                              hostMoreDetail?.user_profile?.user_geo_location
                                .zip_code
                            }
                          </div>
                        ) : (
                          <div className="col-sm-9 text-secondary red_pending">
                            Pending
                          </div>
                        )}
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-sm-3">
                          <h6>About user</h6>
                        </div>

                        {hostMoreDetail?.user_profile?.about_me === "" ? (
                          <div className="col-sm-9 text-secondary red_pending">
                            Pending
                          </div>
                        ) : (
                          <div className="col-sm-9 text-secondary">
                            {hostMoreDetail?.user_profile?.about_me}
                          </div>
                        )}
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-sm-3">
                          <h6 className="mb-0">Purchases</h6>
                        </div>
                        {hostMoreDetail?.shopify_product_count ? (
                          <>
                            {hostMoreDetail?.shopify_product_count === 0 ? (
                              <div className="col-sm-9 text-secondary red_pending">
                                0
                              </div>
                            ) : (
                              <div className="col-sm-9 text-secondary ">
                                {hostMoreDetail?.shopify_product_count}
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="col-sm-9 text-secondary red_pending">
                            0
                          </div>
                        )}
                      </div>

                      <hr />
                    </div>
                  </div>
                  {/* <div className="card cardc mt-3">
                    <div className="normal_view_emails">
                      <div className="venmo_image_wrapper d-flex justify-content-center">
                        <img
                          style={{ height: "50px", width: "auto" }}
                          src={VenmoImage}
                          alt="venmo"
                        />
                      </div>
                      <div className="email_list_wrap">
                        <div className="card cardc">
                          {!hostMoreDetail?.user_venmo?.is_active ? (
                            <div className="d-flex justify-content-center">
                              <div className="col-sm-9 text-secondary red_pending">
                                Pending
                              </div>
                            </div>
                          ) : (
                            <div className="card-body">
                              <div className="row">
                                <div className="col-sm-3">
                                  <h6 className="mb-0">Name: </h6>
                                </div>
                                <div className="col-sm-9 text-secondary">
                                  {hostMoreDetail?.user_venmo.first_name}{" "}
                                  {hostMoreDetail?.user_venmo.last_name.charAt(
                                    0
                                  )}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-3">
                                  <h6 className="mb-0">Email: </h6>
                                </div>
                                <div className="col-sm-9 text-secondary">
                                  {hostMoreDetail?.user_venmo.email}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-3">
                                  <h6 className="mb-0">Phone: </h6>
                                </div>
                                <div className="col-sm-9 text-secondary">
                                  +{hostMoreDetail?.user_venmo.phone}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-3">
                                  <h6 className="mb-0">Username: </h6>
                                </div>
                                <div className="col-sm-9 text-secondary">
                                  @{hostMoreDetail?.user_venmo.username}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>

                <div className="col-md-12 mt-4">
                  <div className="card cardc">
                    <div className="card-body">
                      <h5>Products</h5>
                      <hr />
                      <ul
                        className="list-group "
                        style={{ maxHeight: "300px", overflow: "scroll" }}
                      >
                        {productStoreList.length < 1 ? (
                          <h5>No products available to review</h5>
                        ) : (
                          <>
                            {productStoreList &&
                              productStoreList.map((p, i) => {
                                let productId = p.product_id;
                                let customerId = p.customer_id;
                                let exists = productHosted.some(function (
                                  item
                                ) {
                                  return (
                                    item.product.identifier === productId &&
                                    item.customer.customer_identifier ===
                                      customerId
                                  );
                                });
                                return (
                                  <>
                                    <li
                                      className={`list-group-item `}
                                      key={p.product_id}
                                    >
                                      {loadingProducts ? (
                                        <Spinner animation="border" />
                                      ) : (
                                        <div className="row d-flex product-item-content">
                                          <div className="col col-md-3">
                                            {exists && (
                                              <div
                                                className="ribbon bg-info"
                                                style={{
                                                  position: "absolute",
                                                  padding: "5px",
                                                  borderRadius: "5px",
                                                }}
                                              >
                                                <span>Hosted</span>
                                              </div>
                                            )}
                                            <img
                                              className="small-image-list"
                                              src={
                                                p?.product_image !== ""
                                                  ? p?.product_image
                                                  : Icon
                                              }
                                              alt="product"
                                            />
                                          </div>
                                          <div className="col col-md-7">
                                            <p className="product-title">
                                              {p.product_name}
                                            </p>
                                            <p className="company">
                                              {p.shop_name}
                                            </p>
                                          </div>

                                          <div className="col col-md-2 product---price">
                                            <p className="price">
                                              ${p.product_price}
                                            </p>
                                          </div>
                                        </div>
                                      )}
                                    </li>
                                  </>
                                );
                              })}
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-4">
                  <Logbook details={details} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <div>No details on user</div>;
  }
};

export default HostUserMoreDetail;
