import AuthContext from "context/AuthContext";
import DataContext from "context/DataContext";
import React, { useContext, useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import ProductTable from "./ProductTable";

const ViewList = () => {
  const {
    productListAllPrev,
    productListAllNext,
    productListAll,
    productListAllTag,
    storeAdminProductList,
  } = useContext(DataContext);
  const { user } = useContext(AuthContext);
  const [loadingPros, setLoadingPros] = useState(false);
  const [active, setActive] = useState("all");

  const getListAll = async () => {
    setLoadingPros(true);
    try {
      await storeAdminProductList(`?order=true`);
      setLoadingPros(false);
    } catch (error) {
      setLoadingPros(true);
    }
  };

  useEffect(() => {
    getListAll();
  }, []);

  const changeProduct = (param) => {
    setLoadingPros(true);
    const res = storeAdminProductList(`${param}`);
    res
      .then((resp) => {
        setLoadingPros(false);
      })
      .catch((err) => {
        setLoadingPros(true);
      });
  };

  const getList = () => {
    if (loadingPros) {
      return (
        <tr>
          <td>
            <div className="d-flex justify-content-center">
              <Spinner animation="grow" />
              <Spinner animation="grow" />
              <Spinner animation="grow" />
            </div>
          </td>
        </tr>
      );
    } else {
      if (productListAll) {
        if (productListAll?.length < 1) {
          return (
            <tr>
              <td>There aren't any products yet!</td>
            </tr>
          );
        } else {
          return productListAll?.map((productListAll, index) => {
            return <ProductTable key={index} products={productListAll} />;
          });
        }
      } else {
        return <Spinner animation="grow" />;
      }
    }
  };

  return (
    <div className="card cardc">
      <div className="m-3">
        <div className="search-and-add row d-flex">
          <div className="navigation-items col-md-5 col-sm-5">
            <nav className="nav d-flex">
              <ul className="product-nav d-flex">
                <li
                  className={`nav-link ${
                    active === "all" ? "active-selection" : ""
                  }`}
                  onClick={() => {
                    changeProduct("?order=true");
                    setActive("all");
                  }}
                >
                  All
                </li>
                {productListAllTag?.map((tag) => {
                  return (
                    <li
                      key={tag?.id}
                      className={`nav-link ${
                        active === tag?.name ? "active-selection" : ""
                      }`}
                      onClick={() => {
                        changeProduct(`?order=true&tag__id=${tag?.id}`);
                        setActive(`${tag?.name}`);
                      }}
                    >
                      {tag?.name}
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>
        </div>

        <Table className="table-store" responsive>
          <thead>
            <tr>
              <th scope="col" colSpan={2}>
                Product
              </th>
              <th scope="col">Category</th>
              {user.role !== 5 && <th scope="col">Edit </th>}
            </tr>
          </thead>
          <tbody>{getList()}</tbody>
        </Table>
      </div>
    </div>
  );
};

export default ViewList;
