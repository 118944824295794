import { CsvLogo } from "assets/images";
import CustomModal from "components/Modal/CustomModal";
import DemoContext from "context/DemoContext";
import { useEffectOnce } from "hooks/useEffectOnce";
import CsvView from "Pages/CSV/CsvView";
import React, { useContext, useState } from "react";
import { Table } from "react-bootstrap";
import { CaretDown, CaretUp } from "react-bootstrap-icons";
import { CustomListAdminPage } from "../HomePage/CustomListAdmin";
import "../HomePage/admin.css";
import PurebrandLoader from "components/Loader/PurebrandLoader";

const AdminHostPage = () => {
  const {
    adminHostDataDashboard,
    adminDashboardHostData,
    nextStepAdminHostData,
    prevStepAdminHostData,
  } = useContext(DemoContext);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(true);
  const getHostListsAdmin = () => {
    if (adminDashboardHostData?.length < 1) {
      return (
        <tr>
          <td>No Hosts!</td>
        </tr>
      );
    } else {
      return adminDashboardHostData?.map((dashData, index) => {
        return <CustomListAdminPage key={index} hostDetails={dashData} />;
      });
    }
  };
  const dataChange = (params) => {
    setLoading(true);
    let res = adminHostDataDashboard(params);
    res.then(() => setLoading(false));
  };
  useEffectOnce(() => {
    let res = adminHostDataDashboard("?order=true");
    res.then(() => setLoading(false));
  }, []);
  if (loading) {
    return <PurebrandLoader />;
  } else {
    return (
      <>
        <div className="csv-wrapper text-end">
          <img
            className="csv-download"
            onClick={handleShow}
            src={CsvLogo}
            alt="csv"
          />
          <CustomModal
            showStatus={show}
            clickStatus={handleClose}
            buttonMessage="Close"
            closeStatus={handleClose}
            title="Photo"
            message={<CsvView type="host-detail" />}
            secondMessage="none"
            secondButton={false}
          />
        </div>
        <div className="admin-dashboard-list">
          <Table>
            <thead>
              <tr>
                <th>Host</th>
                <th scope="col">
                  <div className="arrows">
                    Revenue{" "}
                    <div className="arrow-grid">
                      <CaretUp
                        onClick={() => dataChange("?order=true&revenue=true")}
                      />
                      <CaretDown
                        onClick={() => dataChange("?order=true&revenue=false")}
                      />
                    </div>
                  </div>
                </th>
                <th scope="col">
                  <div className="arrows">
                    Earnings{" "}
                    <div className="arrow-grid">
                      <CaretUp
                        onClick={() => dataChange("?order=true&earning=true")}
                      />
                      <CaretDown
                        onClick={() => dataChange("?order=true&earning=false")}
                      />
                    </div>
                  </div>
                </th>
                <th scope="col">
                  <div className="arrows">
                    Total showings{" "}
                    <div className="arrow-grid">
                      <CaretUp
                        onClick={() => dataChange("?order=true&shows=true")}
                      />
                      <CaretDown
                        onClick={() => dataChange("?order=true&shows=false")}
                      />
                    </div>
                  </div>
                </th>
                <th>Top Product</th>
              </tr>
            </thead>
            <tbody>{getHostListsAdmin()}</tbody>
          </Table>
        </div>
        {(nextStepAdminHostData !== null || prevStepAdminHostData !== null) && (
          <div className="button-wrapper-product text-center my-4">
            <button
              onClick={() => {
                dataChange(new URL(prevStepAdminHostData).search);
              }}
              className="btn btn-outline-primary previous-button"
              disabled={prevStepAdminHostData === null}
            >
              Prev
            </button>
            <button
              onClick={() => {
                dataChange(new URL(nextStepAdminHostData).search);
              }}
              className="btn btn-outline-primary previous-button"
              disabled={nextStepAdminHostData === null}
            >
              Next
            </button>
          </div>
        )}
      </>
    );
  }
};

export default AdminHostPage;
