import React from 'react';
import '../Forms/Multistep/multistep.css';

const PrivacyPolShopify = () => {
  return (
    <div className='shopify-privacy-policy'>
      <div className='privacy-notice-box'>
        <p className='privacy-notice-header'>PRIVACY NOTICE</p>
        <div>
          <span className='privacy-last-updated'>Last updated </span>
          <span>April 09, 2024</span>
        </div>
      </div>
      <div className="app-header privacy-info privacy-margin">
      Purebrand App provides Simplify the demo request process and watch your conversion rates soar. A frictionless demo request journey fosters customer satisfaction and loyalty. Create opportunity to Showcase complicated products to customer. This Privacy Policy describes how personal information is collected, used, and shared when you install or use the App in connection with your Shopify-supported store.

      </div>
      
      <div className="app-installation-data">
        <h5>Personal Information the App Collects</h5>
        
        <div className="privacy-info privacy-margin">
        When you install the app, we are able to automatically access certain types of information from your Shopify account: such as past 60 days orders records and all product records
        </div>
      </div>
      
      <div className="privacy-info privacy-margin">
      The Over App provides a meeting-wise calendar.In addition, once you install the App, we collect the following types of personal information from you and/or your customers: you email and phone and store address information about you and others who may access the Application on behalf of your store, such as your name, address, email address, phone number and billing information.
      </div>
      
      <div className="personal-information">
        
        <h5>How Do We Use Your Personal Information?</h5>
        
        <div className="privacy-info privacy-margin">
        We use the personal information we collect from you and your customers in order to provide the Service and to operate the App. Additionally, we use this personal information to: Communicate with you; Optimize or improve the App; and Provide you with information or advertising relating to our products or services.
        </div>

      </div>
      
      <div className="sharing-personal-information">
        <h5>Sharing Your Personal Information</h5>
        
        <div className="privacy-info privacy-margin">We will not share your personal information.</div>
        
        <div className="privacy-info privacy-margin">
        Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.
        </div>

        
        
        <div className="privacy-info privacy-margin">
        If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.
        </div>
        
        <div className="privacy-info privacy-margin">
        Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.
        </div>

      </div>
      
      <div className="EU-U S-privacy privacy-info">
        <div className="privacy-margin">
        Changes We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.
        </div>
        <div className="privacy-margin ">
        Contact Us For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at dev@purebrand.io or by mail using the details provided below:
        </div>
        
        2380 S 1900 W, West Haven, UT, 84401, US
      </div>
    </div>
  );
};

export default PrivacyPolShopify;
