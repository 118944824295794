import Dashelement from "components/Dashboard/Dashelement";
import { Tab } from "react-bootstrap";

const NavComponent = (props) => {
  return (
    <Tab eventKey={props.eventKey} title={props.title}>
      <Dashelement className={props.className}>{props.children}</Dashelement>
    </Tab>
  );
};

export default NavComponent;

// export const Homenavigation = (props) => {
//   console.log(props.showrooms);
//   // const getShowroomsDashboard = (tag) => {
//   //   return props.showrooms.map((showrooms, index) => {
//   //     if (showrooms.accept_status === "ACTIVE") {
//   //     }
//   //     // return <></>;
//   //   });
//   // };
//   return (
//     <Tab eventKey={props.eventKey} title={props.title}>
//       {/* {getShowroomsDashboard(props.tag)} */}
//       {props.children}
//     </Tab>
//   );
// };
