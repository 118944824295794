import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const VideoPlayerModal = ({
  handleClose,
  show,
  recordingUrl,
  recordingName,
}) => {
  return (
    <div>
      <Modal
        size={"xl"}
        show={show}
        onHide={handleClose}
        animation={false}
        centered={true}
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {recordingName ? recordingName : "Your recorded meeting"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            {recordingUrl ? (
              <video
                // preload="none"
                controls
                width="100%"
                height="530"
                // src={recordingUrl}

                // title="Purebrand - [DEMO]"
                // frameBorder="0"
                playsInline
                autoPlay
                loop
                // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                // allowFullScreen
              >
                <source src={recordingUrl} type="video/mp4" />
              </video>
            ) : (
              "Recording is corrupted, please contact the admin to get the recording"
            )}
          </div>
        </Modal.Body>
        {/*<Modal.Footer>*/}
        {/*    <Button variant="secondary" onClick={handleClose}>*/}
        {/*        Close*/}
        {/*    </Button>*/}
        {/*    <Button variant="primary" onClick={handleClose}>*/}
        {/*        Save Changes*/}
        {/*    </Button>*/}
        {/*</Modal.Footer>*/}
      </Modal>
    </div>
  );
};

export default VideoPlayerModal;
