import { Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import { useDropzone } from "react-dropzone";
import "./addproduct.css";
import { ExampleImageOne, ExampleImageTwo, FeatherImg } from "assets/images";
import CustomModal from "components/Modal/CustomModal";
import { Carousel, Spinner } from "react-bootstrap";
import ProfileContext from "context/ProfileContext";
import { TrashFill } from "react-bootstrap-icons";

const UploadMediaPage = (props) => {
  let { putToS3 } = useContext(ProfileContext);
  const [isEnabled, setIsEnabled] = useState(false);
  const [show, setShow] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [imagefiles, setImageFiles] = useState([]);
  const [videoFiles, setVideoFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = (values) => {
    if (isEnabled === true) {
      setIsEnabled(false);
      setLoading(true);
      const first = Promise.all(
        values?.files?.map(async (e) => {
          let res = await putToS3({
            tag: "product photo",
            type: "image",
            extension: "jpeg",
            file: [e],
          });
          return res.data.data.id;
        })
      );

      const second = Promise.all(
        values?.videos?.map(async (e) => {
          let res = await putToS3({
            tag: "product video",
            type: "video",
            extension: "mp4",
            file: [e],
          });
          return res.data.data.id;
        })
      );

      const promise = Promise.all([first, second]);
      promise
        .then((data) => {
          setIsEnabled(true);
          setLoading(false);
          values.productPictureId = data[0];
          values.productVideoId = data[1];
          props.next(values);
        })
        .catch((err) => {
          setIsEnabled(false);
        });
    } else {
      values.noMedia = true;
      props.next(values);
    }
  };

  const getImage = () => {
    if (imagefiles?.length > 0 || videoFiles?.length > 0) {
      setIsEnabled(true);
    } else {
      setIsEnabled(false);
    }
  };

  const getVideo = () => {
    if (imagefiles?.length > 0 || videoFiles?.length > 0) {
      setIsEnabled(true);
    } else {
      setIsEnabled(false);
    }
  };

  return (
    <Formik initialValues={props.data} onSubmit={handleSubmit}>
      {({ values, setFieldValue }) => (
        <Form>
          <div className="product-form-wrapper">
            <div className="product-information-wrapper">
              <h3 className="text-start">Media Upload</h3>
              <p className="text-start">
                Follow the instructions and upload your media to be verified.
                {/* (At least one required) */}
              </p>
            </div>
            <div className="product-uploader">
              <div className="row">
                <div className="col-md-6 col-sm-12 image-uploader mb-2">
                  <div className="form-group">
                    <UploadComponent
                      files={imagefiles}
                      class="uploader-part"
                      image={FeatherImg}
                      title="Upload a photo of your product (Limit of 5)"
                      // body="Take a picture of  your product in a well it area so that it can be verified. If possible, remove all unrelated items outside of the frame. Make sure the image is in focus and clear. "
                      footer="JPG, PNG, GIF file size less than 10MB"
                      accept={{ "image/*": [] }}
                      maxSize={10000000}
                      setIsEnabled={setIsEnabled}
                    />
                    {imagefiles?.length > 0 && getImage()}
                  </div>
                </div>
                <div className="col-md-6 col-sm-12 mb-2 video-uploader">
                  <div className="form-group">
                    <UploadComponent
                      files={videoFiles}
                      class="uploader-part-video"
                      image={FeatherImg}
                      title="Upload a short video of your product (Limit of 5)"
                      // body="Please upload a short demo of the product for our team to reveiw. Discuss the pros and cons of your customer experience. Try to keep the video between 1-3 minutes. "
                      footer="Video file length 1 to 3 minutes, 50MB"
                      accept={{ "video/*": [] }}
                      maxSize={50000000}
                      setIsEnabled={setIsEnabled}
                    />
                    {videoFiles?.length > 0 && getVideo()}
                  </div>
                </div>
                {loading ? (
                  <div className="d-flex justify-content-center">
                    Uploading{" "}
                    <div className="spinner-dots">
                      <Spinner className="custom-blink" animation="grow" />{" "}
                      <Spinner className="custom-blink" animation="grow" />{" "}
                      <Spinner className="custom-blink" animation="grow" />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="example-wrapper-photos mt-3">
                  <p className="example-text-blip" onClick={handleShow}>
                    See examples
                  </p>
                  <CustomModal
                    showStatus={show}
                    clickStatus={handleClose}
                    buttonMessage="Close"
                    closeStatus={handleClose}
                    title="Photo"
                    message={
                      <div className="good-bad-photo-viewer">
                        <Carousel>
                          <Carousel.Item interval={2000}>
                            <img
                              className="d-block w-100"
                              src={ExampleImageOne}
                              alt="First slide"
                            />
                          </Carousel.Item>
                          <Carousel.Item interval={2000}>
                            <img
                              className="d-block w-100"
                              src={ExampleImageTwo}
                              alt="Second slide"
                            />
                          </Carousel.Item>
                        </Carousel>
                      </div>
                    }
                    secondMessage="none"
                    secondButton={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="button-wrapper-product text-end">
            <div
              // disabled={!isEnabled}
              // type="submit"
              onClick={() => {
                setFieldValue(`files`, imagefiles);
                setFieldValue(`videos`, videoFiles);
                setShowConfirmation(true);
              }}
              className={`btn btn-outline-primary`}
            >
              {isEnabled === true ? "Next" : "Skip"}
            </div>
            <CustomModal
              showStatus={showConfirmation}
              clickStatus={() => {
                setShowConfirmation(false);
              }}
              buttonMessage="Close"
              closeStatus={() => {
                setShowConfirmation(false);
              }}
              title="Confirm media?"
              message={
                <div className="confirmation">
                  Are you sure about your uploaded media? You will have to re-do
                  the process if you change your mind after this step.
                </div>
              }
              secondMessage="ConfirmUpload"
              secondButton={true}
              loading={loading}
              setLoading={setLoading}
              submitActionProduct={() => {
                handleSubmit(values);
                setShowConfirmation(false);
              }}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

const UploadComponent = (props) => {
  const { files } = props;
  const [errors, setErrors] = useState("");
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: props.accept,
    maxFiles: 5,
    multiple: true,
    maxSize: props.maxSize,
    onDrop: (acceptedFiles, fileRejections) => {
      if (acceptedFiles) {
        props.setIsEnabled(true);
        acceptedFiles.forEach((file) => {
          if (files.length < 5) {
            files.push(file);
            setErrors("");
          } else {
            setErrors("Maximum upload reached");
          }
          // setFieldValue(`${props.value}`, acceptedFiles);
        });
      }
      if (fileRejections) {
        fileRejections.forEach((file) => {
          file.errors.forEach((err) => {
            if (err.code === "file-too-large") {
              setErrors(`Error: File is larger than acceptable`);
            }

            if (err.code === "file-invalid-type") {
              setErrors(`Error: ${err.message}`);
            }
          });
        });
      }
    },
  });
  return (
    <div>
      {}
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <div className={`${props.class} text-center`}>
            <div className="upload-text  justify-content-center">
              <h5 className="title">Drop the files here ...</h5>
              <div className="bottom-wrapper">
                <img className="upload-icon" src={FeatherImg} alt="upload" />
              </div>
            </div>
          </div>
        ) : (
          <div className={`${props.class} text-center`}>
            <div className="upload-text justify-content-center">
              <h5 className="title">{props.title}</h5>

              <ul className="text">
                {files.map((e, i) => (
                  <li key={i}>
                    {`Name : ${e.name} `}{" "}
                    <p
                      className="error"
                      onClick={() => {
                        files.splice(i, 1);
                        if (files.length < 5) {
                          setErrors("");
                        }
                      }}
                    >
                      <TrashFill
                        style={{ cursor: "pointer" }}
                        color="#f04b4b"
                      />
                    </p>{" "}
                  </li>
                ))}
              </ul>
              {errors === "" ? "" : <p style={{ color: "red" }}>{errors}</p>}
              <div className="bottom-wrapper">
                <img className="upload-icon" src={props.image} alt="upload" />
                <p>{props.footer}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadMediaPage;
