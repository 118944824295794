import PurebrandLoader from "components/Loader/PurebrandLoader";
import React, { useContext, useState } from "react";
import { XCircle, InfoCircleFill } from "react-bootstrap-icons";
import "./DemoInCallModal.css";
import { RescheduleForm } from "./RescheduleModal";
import DemoContext from "context/DemoContext";
import { convertToUTC } from "utils/dateFormatter";

const DemoInCallModal = ({
  showReScheduleModal,
  setShowRescheduleModal,
  demo_id,
  demoInfo,
  setRemainingTime,
}) => {
  const { requestInCallFallbackHost, rescheduleDemoByGuest } =
    useContext(DemoContext);
  const [showReschedulingModal, setShowReschedulingModal] = useState(false);
  const [showFallbackModal, setShowFallbackModal] = useState(false);
  const [showHoverText, setShowHoverText] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const displayReschedule = () => {
    setShowReschedulingModal(!showReschedulingModal);
  };

  const displayFallbackHostRequest = () => {
    setShowFallbackModal(!showFallbackModal);
  };

  const callFallbackHost = async () => {
    setLoading(true);
    try {
      let res = await requestInCallFallbackHost(demo_id);
      if (res.status === 200) {
        window.location.href = `/meeting-status-check?demo=${demo_id}`;
        setLoading(false);
      }
    } catch (err) {
      setLoading(true);
    }
  };

  const handleGuestReschedule = async (e) => {
    setLoading(true);
    setSubmitting(true);
    const [utcDate, utcTime] = convertToUTC(
      e.demo_scheduled_date + "T" + e.demo_scheduled_time
    );
    const data = {
      schedule: {
        scheduled_date: utcDate,
        scheduled_time: utcTime,
      },
    };
    try {
      const response = await rescheduleDemoByGuest(data, demo_id);
      if (response.status === 200) {
        window.location.href = `/meeting-status-check?demo=${demo_id}`;
        setSubmitting(false);
        setLoading(false);
      } else {
        setSubmitting(false);
        setLoading(false);
      }
    } catch (error) {
      setSubmitting(false);
      setLoading(false);
    }
  };

  if (loading) {
    return <PurebrandLoader />;
  } else {
    return (
      <div className="timer-popup">
        <div className="popup">
          <div className="popup-content">
            <div className=" text-end">
              {showHoverText && (
                <span
                  className="p-1"
                  style={{
                    background: "#ccc",
                    borderRadius: "10px",
                    boxShadow: " 5px 2px 2px #000",
                  }}
                >
                  <InfoCircleFill style={{ color: "info", fontSize: "20px" }} />{" "}
                  wait for a minute...
                </span>
              )}
              <button
                className="btn"
                onClick={() => {
                  setShowRescheduleModal(!showReScheduleModal);
                  setRemainingTime(60);
                }}
                style={{
                  background: "transparent",
                }}
                onMouseEnter={() => setShowHoverText(true)}
                onMouseLeave={() => setShowHoverText(false)}
              >
                <XCircle style={{ color: "red", fontSize: "30px" }} />
              </button>
            </div>
            <h4>
              Sorry for the delay. <br />
              Your host hasn't joined the call yet. <br />
              Would you like to invite other hosts to join or reschedule the
              call? <br />
            </h4>
            {!showFallbackModal && (
              <div className="reschedule">
                {!showReschedulingModal ? (
                  <button
                    onClick={() => {
                      displayReschedule();
                    }}
                    className="btn btn-primary m-1"
                    disabled={demoInfo?.rescheduled_at}
                  >
                    Reschedule Call
                  </button>
                ) : (
                  <>
                    <button
                      onClick={() => {
                        displayReschedule();
                      }}
                      className="btn btn-primary bg-danger m-1"
                    >
                      Cancel Reschedule Call
                    </button>
                    <div className="cardc card">
                      <div className="m-2">
                        <h5>Choose Reschedule Date and Time</h5>
                        <RescheduleForm
                          handleSubmit={handleGuestReschedule}
                          detail={demoInfo}
                          whatType="Reschedule"
                          submitting={submitting}
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="reschedule-info">
                  {!demoInfo?.rescheduled_at ? (
                    <p>This Demo call can be rescheduled only once.</p>
                  ) : (
                    <p className="text-danger">
                      Demo call is already rescheduled once, couldnot be
                      rescheduled.
                    </p>
                  )}
                </div>
              </div>
            )}
            {!showReschedulingModal && (
              <div className="hostfallback">
                {!showFallbackModal ? (
                  <button
                    onClick={() => {
                      displayFallbackHostRequest();
                    }}
                    className="btn btn-primary text-white"
                  >
                    Request Fallback Host
                  </button>
                ) : (
                  <>
                    <button
                      onClick={() => {
                        displayFallbackHostRequest();
                      }}
                      className="btn btn-primary m-1 bg-danger"
                    >
                      Cancel Fallback Host Request
                    </button>
                    <div className="cardc card mt-2">
                      <h5> Call for Fallback host</h5>
                      <button
                        onClick={() => callFallbackHost()}
                        className="btn btn-primary text-white mt-3"
                      >
                        Call Fallback Host
                      </button>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default DemoInCallModal;
