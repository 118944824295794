import { BlankImg, ImageLogo, VideoLogo } from "assets/images";
import CustomModal from "components/Modal/CustomModal";
import { useEffectOnce } from "hooks/useEffectOnce";
import React, { useState } from "react";
import { Facebook, Instagram, Tiktok, Twitter } from "react-bootstrap-icons";
import { formatSocialHandle } from "utils/removeHandle";
import HostUserMoreDetail from "./HostUserMoreDetail";
import { Carousel } from "react-bootstrap";
import ImageDownloader from "./ImageDownloader";
import DownloadMultipleFiles from "./MultipleDownload";

export const ProHostAlllist = (props) => {
  const [showUser, setShowUser] = useState(false);
  const handleCloseUser = () => setShowUser(false);
  const handleShowUser = () => setShowUser(true);
  const [hostProfile, setHostProfile] = useState();
  const [showViewer, setShowViewer] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const handleCloseViewer = () => setShowViewer(false);
  const handleShowViewer = () => setShowViewer(true);
  const handleCloseVideo = () => setShowVideoModal(false);
  const handleShowVideo = () => setShowVideoModal(true);

  const profilePicture = () => {
    let response = fetch(
      props?.hostDetails?.user?.profile_picture?.cloudinary_file
    );
    response
      .then(function (response) {
        if (response && response.status === 200) {
          setHostProfile(response?.url);
        } else {
          setHostProfile(props?.hostDetails?.user?.profile_picture?.file);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffectOnce(() => {
    profilePicture();
  }, []);

  const filteredData = props?.hostDetails?.media
    .filter((items) => items.picture !== null || items.video !== null)
    .map((item) => {
      if (item.picture !== null) {
        return item.picture.file;
        // return <ImageDownloader key={index} imageUrl={item.picture.file} />;
      } else if (item.video !== null) {
        return item.video.file;
        // return <ImageDownloader key={index} imageUrl={item.video.file} />;
      }
    });

  // console.log({ filteredData });

  // const filteredData = () => {
  //   return props?.hostDetails?.media
  //     .filter((item) => item.picture !== null || item.video !== null)
  //     .map((item, index) => (
  //       <div key={index}>
  //         {item.picture !== null && (
  //           <ImageDownloader imageUrl={item.picture.file} />
  //         )}
  //         {item.video !== null && (
  //           <ImageDownloader imageUrl={item.video.file} />
  //         )}
  //       </div>
  //     ));
  // };

  const getMedia = () => {
    return (
      <>
        {props?.hostDetails?.media_count?.picture_count >= 1 ? (
          <>
            <img
              src={ImageLogo}
              alt="uploaded"
              className="uploaded-media-icon"
              onClick={handleShowViewer}
            />

            {/* <ImageDownloader
              imageUrl={props?.hostDetails?.media[0]?.picture?.cloudinary_file}
            /> */}
            {/* <DownloadMultipleFiles
              fileUrls={filteredData}
              zipFileName="media.zip"
              buttonText="Download"
            /> */}

            <CustomModal
              showStatus={showViewer}
              clickStatus={handleCloseViewer}
              buttonMessage="Close"
              closeStatus={handleCloseViewer}
              title="Photo"
              message={
                <div className="good-bad-photo-viewer">
                  <Carousel variant="dark">
                    {(props?.hostDetails?.media).map((image, i) => {
                      if (image.picture) {
                        return (
                          <Carousel.Item
                            interval={20000}
                            key={i}
                            className="custom-carousel"
                          >
                            <img
                              key={i}
                              // style={{
                              //   height: "5rem",
                              //   width: "auto",
                              // }}
                              className="d-block w-100"
                              src={image.picture.file}
                              alt="slide"
                            />
                            <div className="mt-5 text-end">
                              <span>
                                {" "}
                                {i + 1}/
                                {props?.hostDetails?.media_count?.picture_count}{" "}
                              </span>
                              <ImageDownloader
                                imageUrl={image.picture.cloudinary_file}
                              />
                            </div>
                          </Carousel.Item>
                        );
                      }
                    })}
                  </Carousel>
                </div>
              }
              secondMessage="none"
              secondButton={false}
            />
          </>
        ) : null}{" "}
        {props?.hostDetails.media_count.video_count >= 1 ? (
          <>
            <img
              src={VideoLogo}
              alt="uploaded"
              className="uploaded-media-icon"
              onClick={handleShowVideo}
            />
            {/* <ImageDownloader
              imageUrl={props?.hostDetails?.media[0]?.video?.file}
            /> */}
            {/* <DownloadMultipleFiles
              fileUrls={filteredData}
              zipFileName="media.zip"
              buttonText="Download"
            /> */}
            <CustomModal
              showStatus={showVideoModal}
              clickStatus={handleCloseVideo}
              buttonMessage="Close"
              closeStatus={handleCloseVideo}
              title="Video"
              message={
                <div className="good-bad-photo-viewer">
                  <Carousel>
                    {props?.hostDetails?.media.map((media, i) => {
                      if (media.video) {
                        return (
                          <Carousel.Item
                            interval={200000}
                            key={i}
                            className="custom-carousel"
                          >
                            <video
                              key={i}
                              playsInline
                              autoPlay={false}
                              controls
                              // muted
                              loop
                              className="video-player_product"
                            >
                              <source
                                key={i}
                                src={media.video.file}
                                type="video/mp4"
                              />
                            </video>
                            <div className="mt-5 text-end">
                              <span>
                                {" "}
                                {i + 1}/
                                {props?.hostDetails?.media_count?.video_count}{" "}
                              </span>
                              <ImageDownloader imageUrl={media.video.file} />
                            </div>
                          </Carousel.Item>
                        );
                      }
                    })}
                  </Carousel>
                </div>
              }
              secondMessage="none"
              secondButton={false}
            />
          </>
        ) : null}
      </>
    );
  };

  return (
    <>
      {props?.hostDetails?.user?.is_soft_host === null && (
        <tr>
          <td className="image-title-wrapper" onClick={handleShowUser}>
            <img
              className="host-pp-small"
              src={
                props?.hostDetails?.user?.profile_picture?.file !== null
                  ? hostProfile
                  : BlankImg
              }
              alt="user"
            />{" "}
            <span>
              {props?.hostDetails?.user.first_name}{" "}
              {(props?.hostDetails?.user.last_name).charAt(0)}.
            </span>
          </td>
          <CustomModal
            showStatus={showUser}
            modalClass="profile_detail"
            clickStatus={handleCloseUser}
            buttonMessage="Close"
            closeStatus={handleCloseUser}
            title=" "
            message={
              <HostUserMoreDetail details={props?.hostDetails?.user.id} />
            }
            secondMessage="none"
            secondButton={false}
          />
          <td>{props?.hostDetails?.user.email}</td>
          <td>{getMedia()}</td>
          <td>{props?.hostDetails?.total_demos}</td>
          <td>$ {props?.hostDetails?.total_earning}</td>

          <td>
            {props?.hostDetails?.user?.user_social?.instagram_handle !== "" ? (
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`https://www.instagram.com/${formatSocialHandle(
                  props?.hostDetails?.user?.user_social?.instagram_handle
                )}`}
              >
                <Instagram />
              </a>
            ) : (
              <></>
            )}{" "}
            {props?.hostDetails?.user?.user_social?.tiktok_handle !== "" ? (
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`https://www.tiktok.com/@${formatSocialHandle(
                  props?.hostDetails?.user?.user_social?.tiktok_handle
                )}`}
              >
                <Tiktok />
              </a>
            ) : (
              <></>
            )}{" "}
            {props?.hostDetails?.user?.user_social?.twitter_handle !== "" ? (
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`https://twitter.com/${formatSocialHandle(
                  props?.hostDetails?.user?.user_social?.twitter_handle
                )}`}
              >
                <Twitter />
              </a>
            ) : (
              <></>
            )}{" "}
            {props?.hostDetails?.user?.user_social?.facebook_handle !== "" ? (
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`https://www.facebook.com/${formatSocialHandle(
                  props?.hostDetails?.user?.user_social?.facebook_handle
                )}`}
              >
                <Facebook />
              </a>
            ) : (
              <></>
            )}
          </td>
        </tr>
      )}
    </>
  );
};

export const SoftHostAlllist = (props) => {
  const [showUser, setShowUser] = useState(false);
  const handleCloseUser = () => setShowUser(false);
  const handleShowUser = () => setShowUser(true);
  const [hostProfile, setHostProfile] = useState();
  const [showViewer, setShowViewer] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const handleCloseViewer = () => setShowViewer(false);
  const handleShowViewer = () => setShowViewer(true);
  const handleCloseVideo = () => setShowVideoModal(false);
  const handleShowVideo = () => setShowVideoModal(true);

  const profilePicture = () => {
    let response = fetch(
      props?.hostDetails?.user?.profile_picture?.cloudinary_file
    );
    response
      .then(function (response) {
        if (response && response.status === 200) {
          setHostProfile(response?.url);
        } else {
          setHostProfile(props?.hostDetails?.user?.profile_picture?.file);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffectOnce(() => {
    profilePicture();
  }, []);

  const getMedia = () => {
    return (
      <>
        {props?.hostDetails?.media_count?.picture_count >= 1 ? (
          <>
            <img
              src={ImageLogo}
              alt="uploaded"
              className="uploaded-media-icon"
              onClick={handleShowViewer}
            />
            {/* <ImageDownloader
              imageUrl={props?.hostDetails?.media[0]?.picture?.cloudinary_file}
            /> */}

            <CustomModal
              showStatus={showViewer}
              clickStatus={handleCloseViewer}
              buttonMessage="Close"
              closeStatus={handleCloseViewer}
              title="Photo"
              message={
                <div className="good-bad-photo-viewer">
                  <Carousel variant="dark">
                    {(props?.hostDetails?.media).map((image, i) => {
                      if (image.picture) {
                        return (
                          <Carousel.Item
                            interval={20000}
                            key={i}
                            className="custom-carousel"
                          >
                            <img
                              key={i}
                              className="d-block w-100"
                              src={image.picture.file}
                              alt="slide"
                            />
                            <div className="mt-5 text-end">
                              <span>
                                {" "}
                                {i + 1}/
                                {props?.hostDetails?.media_count?.picture_count}{" "}
                              </span>
                              <ImageDownloader
                                imageUrl={image.picture.cloudinary_file}
                              />
                            </div>
                          </Carousel.Item>
                        );
                      }
                    })}
                  </Carousel>
                </div>
              }
              secondMessage="none"
              secondButton={false}
            />
          </>
        ) : null}{" "}
        {props?.hostDetails.media_count.video_count >= 1 ? (
          <>
            <img
              src={VideoLogo}
              alt="uploaded"
              className="uploaded-media-icon"
              onClick={handleShowVideo}
            />
            {/* <ImageDownloader
              imageUrl={props?.hostDetails?.media[0]?.video?.file}
            /> */}
            <CustomModal
              showStatus={showVideoModal}
              clickStatus={handleCloseVideo}
              buttonMessage="Close"
              closeStatus={handleCloseVideo}
              title="Video"
              message={
                <div className="good-bad-photo-viewer">
                  <Carousel>
                    {props?.hostDetails?.media.map((media, i) => {
                      if (media.video) {
                        return (
                          <Carousel.Item
                            interval={200000}
                            key={i}
                            className="custom-carousel"
                          >
                            <video
                              key={i}
                              playsInline
                              autoPlay={false}
                              controls
                              // muted
                              loop
                              className="video-player_product"
                            >
                              <source
                                key={i}
                                src={media.video.file}
                                type="video/mp4"
                              />
                            </video>
                            <div className="mt-5 text-end">
                              <span>
                                {" "}
                                {i + 1}/
                                {props?.hostDetails?.media_count?.video_count}{" "}
                              </span>
                              <ImageDownloader imageUrl={media.video.file} />
                            </div>
                          </Carousel.Item>
                        );
                      }
                    })}
                  </Carousel>
                </div>
              }
              secondMessage="none"
              secondButton={false}
            />
          </>
        ) : null}
      </>
    );
  };
  return (
    <>
      {props?.hostDetails?.user?.is_soft_host !== null && (
        <tr>
          <td className="image-title-wrapper" onClick={handleShowUser}>
            <img
              className="host-pp-small"
              src={
                props?.hostDetails?.user?.profile_picture?.file !== null
                  ? hostProfile
                  : BlankImg
              }
              alt="user"
            />{" "}
            <span>
              {props?.hostDetails?.user.first_name}{" "}
              {(props?.hostDetails?.user.last_name).charAt(0)}.
            </span>
          </td>
          <CustomModal
            showStatus={showUser}
            modalClass="profile_detail"
            clickStatus={handleCloseUser}
            buttonMessage="Close"
            closeStatus={handleCloseUser}
            title=" "
            message={
              <HostUserMoreDetail details={props?.hostDetails?.user.id} />
            }
            secondMessage="none"
            secondButton={false}
          />
          <td>{props?.hostDetails?.user.email}</td>
          <td>{getMedia()}</td>
          <td>{props?.hostDetails?.total_demos}</td>
          <td>$ {props?.hostDetails?.total_earning}</td>
          <td>
            {props?.hostDetails?.user?.user_social?.instagram_handle !== "" ? (
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`https://www.instagram.com/${formatSocialHandle(
                  props?.hostDetails?.user?.user_social?.instagram_handle
                )}`}
              >
                <Instagram />
              </a>
            ) : (
              <></>
            )}{" "}
            {props?.hostDetails?.user?.user_social?.tiktok_handle !== "" ? (
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`https://www.tiktok.com/@${formatSocialHandle(
                  props?.hostDetails?.user?.user_social?.tiktok_handle
                )}`}
              >
                <Tiktok />
              </a>
            ) : (
              <></>
            )}{" "}
            {props?.hostDetails?.user?.user_social?.twitter_handle !== "" ? (
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`https://twitter.com/${formatSocialHandle(
                  props?.hostDetails?.user?.user_social?.twitter_handle
                )}`}
              >
                <Twitter />
              </a>
            ) : (
              <></>
            )}{" "}
            {props?.hostDetails?.user?.user_social?.facebook_handle !== "" ? (
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`https://www.facebook.com/${formatSocialHandle(
                  props?.hostDetails?.user?.user_social?.facebook_handle
                )}`}
              >
                <Facebook />
              </a>
            ) : (
              <></>
            )}
          </td>
        </tr>
      )}
    </>
  );
};

export default SoftHostAlllist;
