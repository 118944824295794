import React from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import "./hostlisting.css";

const CustomLists = (props) => {
  let host = props.hostInfo;
  return (
    <>
      <ListGroup>
        <ListGroupItem className="list-item-custom">
          1. How was the quality of the product that you were expecting?
          <br /> Response: {host.questionnaire.quality_one}
          <br />{" "}
          {host.questionnaire.quality_one_optional
            ? "Optional: " + host.questionnaire.quality_one_optional
            : ""}
        </ListGroupItem>
        <ListGroupItem className="list-item-custom">
          2. Did Product functioned as expected? Rate the experience?
          <br /> Response: {host.questionnaire.quality_two}
          <br />{" "}
          {host.questionnaire.quality_two_optional
            ? "Optional: " + host.questionnaire.quality_two_optional
            : ""}
        </ListGroupItem>
        <ListGroupItem className="list-item-custom">
          3. Did you need customer service?
          <br /> Response: {host.questionnaire.customer_service}
          {host.questionnaire.customer_service === "yes" ? (
            <>
              <hr />
              If yes, rate how friendly they were
              <br /> Response: {host.questionnaire.customer_service_answer}
              <br />{" "}
              {host.questionnaire.customer_service_answer_optional
                ? "Optional: " +
                  host.questionnaire.customer_service_answer_optional
                : ""}
            </>
          ) : (
            " "
          )}
        </ListGroupItem>
        <ListGroupItem className="list-item-custom">
          4. Were you able to quickly navigate and find what what you were
          looking for when shopping?
          <br /> Response: {host.questionnaire.order_one}
          <br />{" "}
          {host.questionnaire.order_one_optional
            ? "Optional: " + host.questionnaire.order_one_optional
            : ""}
        </ListGroupItem>
        <ListGroupItem className="list-item-custom">
          5. Was the checkout process clear and easy?
          <br /> Response: {host.questionnaire.order_two}
          <br />{" "}
          {host.questionnaire.order_two_optional
            ? "Optional: " + host.questionnaire.order_two_optional
            : ""}
        </ListGroupItem>
        <ListGroupItem className="list-item-custom">
          6. How well did the assembly/installation meet expectations?
          <br /> Response: {host.questionnaire.install_setup}
          <br />{" "}
          {host.questionnaire.install_setup_optional
            ? "Optional: " + host.questionnaire.install_setup_optional
            : ""}
        </ListGroupItem>
        <ListGroupItem className="list-item-custom">
          7. Would you order again? Have you ordered again?
          <br /> Response: {host.questionnaire.order_again}
          <br />{" "}
          {host.questionnaire.order_again_optional
            ? "Optional: " + host.questionnaire.order_again_optional
            : ""}
        </ListGroupItem>
        <ListGroupItem className="list-item-custom">
          8. Did the product arrive on time?
          <br /> Response: {host.questionnaire.arrival_time}
          <br />{" "}
          {host.questionnaire.arrival_time_optional
            ? "Optional: " + host.questionnaire.arrival_time_optional
            : ""}
        </ListGroupItem>
        <ListGroupItem className="list-item-custom">
          9. Did product arrive undamaged?
          <br /> Response: {host.questionnaire.damage_rating}
          <br />{" "}
          {host.questionnaire.damage_rating_optional
            ? "Optional: " + host.questionnaire.damage_rating_optional
            : ""}
          {host.questionnaire.receive_product_optional === "" ? (
            " "
          ) : (
            <>
              <hr />
              <br /> Damage rating reason:{" "}
              {host.questionnaire.receive_product_optional}
            </>
          )}
        </ListGroupItem>
        <ListGroupItem className="list-item-custom">
          10. Would you change anything?
          <br /> Response: {host.feedback.feedback}
          {host.feedback.feedback === "yes" ? (
            <>
              <hr />
              What would you change?
              <br /> Response: {host.feedback.feedback_comment}
            </>
          ) : (
            " "
          )}
        </ListGroupItem>
      </ListGroup>
    </>
  );
};

export default CustomLists;
