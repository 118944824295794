import React from "react";
import { MicFill, MicMuteFill } from "react-bootstrap-icons";

export default function MuteAudioButton({ toggleAudio, hasAudio }) {
  return (
    <>
      <button onClick={toggleAudio} className="round-primary-btn">
        {!hasAudio ? (
          <MicMuteFill fontSize="inherit" />
        ) : (
          <MicFill fontSize="inherit" />
        )}
      </button>
    </>
  );
}
