import "./App.css";
import CustomRouter from "Routes/CustomRouter";
import "./context/firebase";

function App() {
  return (
    <div className="App">
      <CustomRouter />
    </div>
  );
}

export default App;
