import { PurebrandLineChart } from "components/Chart/LineChart";
import PurebrandLoader from "components/Loader/PurebrandLoader";
import DemoContext from "context/DemoContext";
import { useEffectOnce } from "hooks/useEffectOnce";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { getLineArray, getLineData } from "utils/chartData";

import "./storedashboard.css";

const StoreChart = () => {
  const {
    storeDashboardData,
    dashboardDataStore,
    storeDashboardLineData,
    dashboardLineDataStore,
  } = useContext(DemoContext);
  const [currentData, setCurrentData] = useState("rev");
  const [currentDataLine, setCurrentDataLine] = useState("day");
  const [labelLine, setLabelLine] = useState([]);
  const [finalLine, setFinalLine] = useState([]);
  var [preData, setPreData] = useState(0);
  var [postData, setPostData] = useState(0);
  const [dataArray, setDataArray] = useState([]);
  // const [dataIndex, setDataIndex] = useState({
  //   first: "Store Revenue",
  //   second: "Purebrand Revenue",
  // });
  const [loading, setLoading] = useState(false);
  // const [unitDefined, setUnitDefined] = useState("$");
  if (dashboardDataStore.length > 0 && currentData === "rev") {
    var pre = 0,
      post = 0;
    dashboardDataStore.map((dashboardData) => {
      pre = parseFloat(
        dashboardData?.product?.revenue?.store_revenue?.store_revenue
      );
      post = parseFloat(
        dashboardData?.product?.revenue?.store_revenue?.pure_brand_revenue
      );
    });
    preData = pre;
    postData = post;
  }

  const getLineChartData = () => {
    setDataArray([]);
    dashboardLineDataStore.map((lineData) => {
      dataArray.push(getLineArray(lineData));
    });
    let abc = getLineData(dataArray, currentDataLine);
    setLabelLine(abc.key);
    setFinalLine(abc.value);
  };
  var dataLine = {
    labels: labelLine,
    datasets: [
      {
        label: "",
        data: finalLine,
        fill: true,
        borderColor: "rgb(50, 157, 215)",
        backgroundColor: "rgba(50, 157, 215, 0.5)",
      },
    ],
  };
  // TODO: If we want circle graph in future , just uncomment the function
  // const getDoughnutdata = (type) => {
  //   if (type === "rev") {
  //     var pre = 0,
  //       post = 0;
  //     dashboardDataStore.map((dashboardData) => {
  //       pre = parseFloat(
  //         dashboardData?.product?.revenue?.store_revenue?.store_revenue
  //       );
  //       post = parseFloat(
  //         dashboardData?.product?.revenue?.store_revenue?.pure_brand_revenue
  //       );
  //     });
  //     setPreData(pre);
  //     setPostData(post);
  //     setUnitDefined("$");
  //     setDataIndex({
  //       first: "Store Revenue",
  //       second: "Purebrand Revenue",
  //     });
  //   } else if (type === "conv") {
  //     var pre = 0,
  //       post = 0;
  //     dashboardDataStore.map((dashboardData) => {
  //       pre = parseFloat(
  //         dashboardData?.product?.revenue?.store_revenue?.store_revenue
  //       );
  //       post = parseFloat(
  //         dashboardData?.product?.revenue?.store_revenue?.pure_brand_revenue
  //       );
  //     });
  //     setPreData((post / pre) * 100);
  //     setPostData(100 - (post / pre) * 100);
  //     setUnitDefined(" %");
  //     setDataIndex({
  //       first: "Purebrand Revenue",
  //       second: "Store Revenue",
  //     });
  //   }
  // };
  // const options = {
  //   responsive: true,

  //   plugins: {
  //     tooltip: {
  //       callbacks: {
  //         label: (item) =>
  //           `${item.label}: ${unitDefined === "$" ? unitDefined : ""}${
  //             item.formattedValue
  //           }${unitDefined === " %" ? unitDefined : ""}`,
  //       },
  //       backgroundColor: "#fff",
  //       bodyColor: "#359CD7",
  //     },
  //     legend: {
  //       display: false,
  //     },
  //     labels: {
  //       display: false,
  //     },
  //     title: {
  //       display: false,
  //       text: "Chart.js Line Chart",
  //     },
  //   },
  // };
  // const dataChart = {
  //   labels: [`${dataIndex.first}`, `${dataIndex.second}`],
  //   datasets: [
  //     {
  //       label: "Daily view",
  //       data: [preData, postData],
  //       backgroundColor: ["#359CD7", "#24BCB7"],
  //       hoverOffset: 4,
  //     },
  //   ],
  // };

  useEffectOnce(() => {
    setLoading(true);
    let first = storeDashboardData();
    let second = storeDashboardLineData("day");
    const promise = Promise.all([first, second]);
    promise
      .then((data) => {
        setLoading(false);
      })
      .catch(function (err) {
        setLoading(true);
      });
  }, []);

  useEffect(() => {
    getLineChartData();
  }, [dashboardLineDataStore]);

  if (loading) {
    return <PurebrandLoader />;
  } else {
    return (
      <div className="row charts-content-wrapper">
        {/* TODO: If circle graph needed in future then uncomment this code */}
        {/* <div className="col-sm-12 col-md-4 col-lg-4  monthly-data-wrapper">
          <div className="column-wrapper p-3">
            <div className="monthly-data-tab d-flex justify-content-between">
              <div className="tab-nav-list">
                <nav className="nav d-flex">
                  <p>Showroom Monthly Data</p>
                  <ul className="chart-nav">
                    <li
                      className={`nav-link ${
                        currentData === "rev" ? "active-selection" : ""
                      }`}
                      onClick={() => {
                        setCurrentData("rev");
                        getDoughnutdata("rev");
                      }}
                    >
                      Revenue
                    </li>
                    <li
                      className={`nav-link ${
                        currentData === "conv" ? "active-selection" : ""
                      }`}
                      onClick={() => {
                        setCurrentData("conv");
                        getDoughnutdata("conv");
                      }}
                    >
                      Conv. Rate
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="showroom-data">
              {dataIndex.first === 0 && dataIndex.second === 0 ? (
                <div>No data for the given criteria</div>
              ) : (
                <PurebrandChart
                  class="host-dashboard-chart"
                  options={options}
                  data={dataChart}
                />
              )}
            </div>
          </div>
        </div> */}
        <div className="col-sm-12 col-md-8 col-lg-8 showroom-wrapper schedule-demo-graph">
          <div className="column-wrapper p-3">
            <div className="showroom-data-tab d-flex justify-content-between">
              <div className="tab-nav-list">
                <nav className="nav d-flex">
                  <p>Number of Scheduled Demos</p>
                  <ul className="nav">
                    <li
                      className={`nav-link ${
                        currentDataLine === "day" ? "active-selection" : ""
                      }`}
                      onClick={() => {
                        setLoading(true);
                        setCurrentDataLine("day");
                        let res = storeDashboardLineData("day");
                        res
                          .then(function (response) {
                            setLoading(false);
                          })
                          .catch(function (err) {
                            setLoading(true);
                          });
                      }}
                    >
                      Day
                    </li>
                    <li
                      className={`nav-link ${
                        currentDataLine === "week" ? "active-selection" : ""
                      }`}
                      onClick={() => {
                        setLoading(true);
                        setCurrentDataLine("week");
                        let res = storeDashboardLineData("week");
                        res
                          .then(function (response) {
                            setLoading(false);
                          })
                          .catch(function (err) {
                            setLoading(true);
                          });
                      }}
                    >
                      Week
                    </li>
                    <li
                      className={`nav-link ${
                        currentDataLine === "month" ? "active-selection" : ""
                      }`}
                      onClick={() => {
                        setLoading(true);
                        setCurrentDataLine("month");
                        let res = storeDashboardLineData("month");
                        res
                          .then(function (response) {
                            setLoading(false);
                          })
                          .catch(function (err) {
                            setLoading(true);
                          });
                      }}
                    >
                      Mon
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="showroom-data">
              <PurebrandLineChart data={dataLine} />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default React.memo(StoreChart);
