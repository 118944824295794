import { MurphyProduct } from "assets/images";
import React from "react";

const Loginimage = () => {
  return (
    <div>
      <div className="col">
        <div className="login-wrapper">
          {/* <img className="login-img" src={MurphyProduct} alt="login-img" /> */}
          <div className="text-center mt-3">
            {/* <img
              style={{
                height: "200px",
                width: "250px",
              }}
              src={MurphyProduct}
              alt="murphy"
            /> */}
            <video
              playsInline
              autoPlay
              muted
              loop
              style={{
                height: "450px",
                width: "auto",
              }}
            >
              <source
                src="https://purebrand-storage.s3.us-east-2.amazonaws.com/PureBrandFacetime.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loginimage;
