import React, { useContext, useState } from "react";
import DisplayCard from "components/Cards/DisplayCard";
import * as Yup from "yup";
import "./addproduct.css";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import DemoContext from "context/DemoContext";
import ProfileContext from "context/ProfileContext";
import "./addproduct.css";
import { useEffectOnce } from "hooks/useEffectOnce";
import LoadingError from "Pages/Error/LoadingError";
import { useNavigate } from "react-router-dom";
import { blankRegExp } from "../ProfileCompletion/BasicInfo";
import PurebrandLoader from "components/Loader/PurebrandLoader";
import { DeliveredLogo, NotDeliveredLogo, Icon } from "assets/images";

const AddProduct = (props) => {
  let {
    productList,
    getShopifyProduct,
    softReviewHistory,
    softReviewHistoryData,
  } = useContext(DemoContext);

  let { showrooms, getUserReviews } = useContext(ProfileContext);
  const navigate = useNavigate();
  const [activeProduct, setActiveProduct] = useState({});
  const [isEnabled, setIsEnabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [loadingErr, setLoadingErr] = useState(null);
  const [prodDesc, setProdDesc] = useState("");
  const handleSubmit = (values) => {
    props.next(values);
  };

  const handleClick = async (i) => {
    let prodData = softReviewHistoryData?.filter(
      (x) => x?.soft_product?.identifier === productList[i]?.product_id
    );
    if (prodData?.length > 0) {
      setProdDesc(prodData[0]?.comment);
    } else {
      setProdDesc("");
    }
    let myData = showrooms?.filter(
      (x) => x?.product?.identifier === productList[i]?.product_id
    );
    if (myData?.length > 0) {
      setError(
        "Product already reviewed, please select another product for review now."
      );
      setActiveProduct(productList[i]);
      setIsEnabled(false);
    } else if (productList[i].status === 0) {
      setActiveProduct(productList[i]);
      setError("");
      setIsEnabled(false);
    } else {
      setActiveProduct(productList[i]);
      setError("");
      setIsEnabled(true);
    }
  };

  useEffectOnce(() => {
    let first = getUserReviews("?order=true&offset=0");
    let second = getShopifyProduct();
    let third = softReviewHistory("?order=true");
    const promise = Promise.all([first, second, third]);
    promise
      .then((data) => {
        setLoading(false);
        setLoadingErr(null);
      })
      .catch((error) => {
        setLoading(false);
        setLoadingErr(error);
      });
  }, []);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <div className="tooltip-top">
        <h5>Can't find a product</h5>
        Shopify brands must opt in to use Purebrand. If their products aren’t
        listed, it is most likely because they haven’t selected this product to
        be eligible for reviews.{" "}
      </div>
    </Tooltip>
  );

  if (loading) {
    return <PurebrandLoader />;
  } else if (loadingErr) {
    return (
      <LoadingError
        status={loadingErr?.response.status}
        message={loadingErr?.response.data.message}
        toUrl="/user/welcome"
      />
    );
  } else {
    return (
      <>
        {/* {!venmoData?.is_active ? (
          <div className="features justify-content-center">
            <div className="venmo-wrapper">
              <div className="venmo-form-title-wrapper">
                <h4 className="venmo-form-title">Enter your Venmo details</h4>
                <p className="venmo-form-desc">
                  Before you begin, you will need to link your Venmo account to
                  review and get paid.
                </p>
              </div>
              <VenmoForm />
            </div>
          </div>
        ) : ( */}
        <Formik
          initialValues={props.data}
          onSubmit={handleSubmit}
          validationSchema={Yup.object().shape({
            productDescription: Yup.string()
              // .required("This field is mandatory.")
              .max(144, "Please keep it below 144 characters.")
              .min(25, "Must be at least 25 characters long.")
              .matches(blankRegExp, "No white spaces only."),
          })}
        >
          {({ setFieldValue, touched, errors }) => (
            <Form>
              <div className="product-form-wrapper">
                <div className="product-information-wrapper">
                  <h3 className="text-start">
                    Select product from your recent purchases
                  </h3>
                </div>
                <div className="product-uploader">
                  <div className="row product-list-shopify-wrapper">
                    <div className="col-sm-12 col-md-6 product-lists mt-2">
                      <div className="list-shopify-products-wrapper">
                        <div className="card">
                          <ul className="list-group list-group-flush">
                            {productList.length < 1 ? (
                              <li>No products available to review</li>
                            ) : (
                              <></>
                            )}
                            {productList.map((p, i) => (
                              <li
                                className={`list-group-item ${
                                  activeProduct === productList[i]
                                    ? "selected-product-add"
                                    : ""
                                }`}
                                key={i}
                                onClick={() => handleClick(i)}
                              >
                                <div className="row d-flex product-item-content">
                                  <div className="col col-md-2">
                                    <img
                                      className="small-image-list"
                                      src={
                                        p?.product_image !== ""
                                          ? p?.product_image
                                          : Icon
                                      }
                                      alt="product"
                                    />
                                  </div>
                                  <div className="col col-md-5">
                                    <p className="product-title">
                                      {p.product_name}
                                    </p>
                                    <p className="company">{p.shop_name}</p>
                                  </div>
                                  <div className="col col-md-2 not_shipping_icon">
                                    <img
                                      className={`icon_delivery ${
                                        p.status === 0
                                          ? "not_delivered"
                                          : "delivered"
                                      }`}
                                      alt="delivered"
                                      src={
                                        p.status === 0
                                          ? NotDeliveredLogo
                                          : DeliveredLogo
                                      }
                                    />
                                  </div>
                                  <div className="col col-md-3 product---price">
                                    <p className="price">${p.product_price}</p>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 product-detail mt-2">
                      <DisplayCard product={activeProduct} />
                      <input type="hidden" value="shop_name" name="shop_name" />
                      <input
                        type="hidden"
                        value="product_name"
                        name="product_name"
                      />
                      <input type="hidden" value="shop_id" name="shop_id" />
                      <input
                        type="hidden"
                        value="product_id"
                        name="product_id"
                      />
                      <input
                        type="hidden"
                        value="product_image"
                        name="product_image"
                      />
                      <input
                        type="hidden"
                        value="product_price"
                        name="product_price"
                      />
                    </div>
                    <div className="col">
                      {error === "" ? (
                        <></>
                      ) : (
                        <p className="error-wrapper">{error}</p>
                      )}{" "}
                    </div>
                  </div>
                  <div className="product-desc-wrapper mt-3">
                    <Field
                      as="textarea"
                      row={2}
                      value={prodDesc}
                      onChange={(e) => {
                        setProdDesc(e.target.value);
                        setFieldValue("productDescription", e.target.value);
                      }}
                      placeholder="Please give a short description."
                      name="productDescription"
                      className={`form-control col-md-6 ${
                        touched.productDescription && errors.productDescription
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      component="div"
                      name="productDescription"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
                <div className="product-information-wrapper">
                  <p className="text-start">
                    Maybe you shopped with a different email address? Add more
                    emails to your profile so that we can find more shopping
                    history!{" "}
                    <span
                      onClick={() => navigate("/user/profile")}
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      Click here
                    </span>{" "}
                    to continue to add email.
                  </p>
                </div>
                <div className="tool-info">
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="23"
                      fill="currentColor"
                      className="bi bi-question-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
                    </svg>
                  </OverlayTrigger>
                </div>
              </div>

              <div className="button-wrapper-product text-end">
                <button
                  className={`${
                    isEnabled === false
                      ? "btn btn-secondary "
                      : "btn btn-outline-primary"
                  }`}
                  disabled={!isEnabled}
                  onClick={() => {
                    setFieldValue(
                      "customerIdentifier",
                      activeProduct.customer_id
                    );
                    setFieldValue("shopName", activeProduct.shop_name);
                    setFieldValue("shopId", activeProduct.shop_id);
                    setFieldValue("shopDomain", activeProduct.shop_domain);
                    setFieldValue("shopEmail", activeProduct.email);
                    setFieldValue("orderId", activeProduct.order_id);
                    setFieldValue("productId", activeProduct.product_id);
                    setFieldValue("productName", activeProduct.product_name);
                    setFieldValue("productDescription", prodDesc);
                    setFieldValue(
                      "productImage",
                      activeProduct.product_image === ""
                        ? "https://purebrand-storage.s3.us-east-2.amazonaws.com/logo512.png"
                        : activeProduct.product_image
                    );
                    setFieldValue("productPrice", activeProduct.product_price);
                    setFieldValue("productCurrency", activeProduct.currency);
                    setFieldValue(
                      "productHandle",
                      activeProduct.product_handle
                    );
                    setFieldValue(
                      "productTag",
                      activeProduct.product_type
                        ? activeProduct.product_type
                        : "misc"
                    );
                  }}
                  type="submit"
                >
                  Next
                </button>
              </div>
            </Form>
          )}
        </Formik>
        {/* )} */}
      </>
    );
  }
};

export default AddProduct;
