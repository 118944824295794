import { getDotsPaymentStatus } from 'Services/dotsService';

const { createContext, useState } = require('react');

const DotsContext = createContext();
export default DotsContext;

/**
 * This context component is used for managing the global states of all the dots components.
 *
 * @returns {ReactNode} A React element context provider
 */

export const DotsProvider = ({ children }) => {
  const [dotsLoading, setDotsLoading] = useState(true);
  const [dotsPhoneNumber, setDotsPhoneNumber] = useState('');
  const [dotsError, setDotsError] = useState('');
  const [storeList, setStoreList] = useState([]);

  const [dotsStoreLoading, setDotsStoreLoading] = useState(true);
  const [dotsStoreStatusError, setDotsStoreStatusError] = useState(true);
  const [dotsStoreInfo, setDotsStoreInfo] = useState();
  const [dotsStoreComplianceInfo, setDotsStoreComplianceInfo] = useState();
  const [dotsStoreAppInfo, setDotsStoreAppInfo] = useState();
  const [dotsStoreAccountInfo, setDotsStoreAccountInfo] = useState();

  /**
   * This function is used to call the app connection status api for store admin to manage the global dots states
   *
   * @returns {void}
   */

  const getAdminPaymentStatus = async (params) => {
    try {
      setDotsStoreStatusError('')
      setDotsStoreLoading(true);
      const res = await getDotsPaymentStatus(params);
      setDotsStoreInfo(res?.data?.data);
      setDotsStoreComplianceInfo(res?.data?.compliance_info);
      setDotsStoreAppInfo(res?.data?.app_information);
      setDotsStoreAccountInfo(res?.data?.account_info);
      setDotsStoreLoading(false);
    } catch (e) {
      setDotsStoreStatusError(e?.response?.data?.message || e?.message || 'Something went wrong')
      setDotsStoreLoading(false);
      setDotsError(e?.response?.data?.message || e?.message);
      throw e;
    }
  };

  const setPhone = (phone) => {
    setDotsPhoneNumber(phone);
  };

  const setLoading = (loading) => {
    setDotsLoading(loading);
  };

  const setError = (error) => {
    setDotsError(error);
  };


  let contextData = {
    dotsLoading: dotsLoading,
    setDotsPhoneNumber: setPhone,
    setDotsLoading: setLoading,
    setDotsError: setError,
    dotsPhoneNumber,
    storeList,
    getAdminPaymentStatus,
    setStoreList,
    dotsError,

    dotsStoreLoading,
    dotsStoreStatusError,
    dotsStoreInfo,
    dotsStoreComplianceInfo,
    dotsStoreAppInfo,
    dotsStoreAccountInfo
  };
  return (
    <DotsContext.Provider value={contextData}>{children}</DotsContext.Provider>
  );
};
