import AuthContext from "context/AuthContext";
import { SupportFormComponent } from "Pages/Support/ContactSupport";
import React, { useContext } from "react";
import { Accordion } from "react-bootstrap";
import { ArrowRight } from "react-bootstrap-icons";

const Gethelp = () => {
  const { user } = useContext(AuthContext);
  if (user.role === 3) {
    return (
      <div className="faq-wrapper">
        <div className="faq-title">
          <h3>FAQs:</h3>
        </div>
        <Accordion flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <strong>How do I sign up to become a PureBrand Host?</strong>
            </Accordion.Header>
            <Accordion.Body>
              <ArrowRight /> Simply click on the button in your invite email or
              signup at purebrand.io/signup.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <strong>How do I get paid?</strong>
            </Accordion.Header>
            <Accordion.Body>
              <ArrowRight /> You get paid your hosting payment through stripe on
              the 1st and the 15th following your meetings. You get paid your
              sales commissions through stripe on the 1st and the 15th following
              the delivery of the product to the customer.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <strong>Tips and tricks to optimize when hosting?</strong>
            </Accordion.Header>
            <Accordion.Body>
              <ArrowRight /> Quality pictures and quality videos go a long way!
              Have a list of your personal questions that you had before you
              bought.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              <strong>How do I know when I get a scheduled meeting?</strong>
            </Accordion.Header>
            <Accordion.Body>
              <ArrowRight /> You can manage meeting through your Purebrand
              dashboard and you will get emails and texts if you've opted in for
              text messaging.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              <strong>How long does a typical PureBrands meeting last?</strong>
            </Accordion.Header>
            <Accordion.Body>
              <ArrowRight /> Typical meetings last 15 minutes or less!
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>
              <strong>How do I host multiple products?</strong>
            </Accordion.Header>
            <Accordion.Body>
              <ArrowRight /> Simply submit any of the products from your
              favorite Purebrand verified stores.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header>
              <strong>How can I get other companies to use PureBrands?</strong>
            </Accordion.Header>
            <Accordion.Body>
              <ArrowRight /> We are always looking to add stores. Please contact
              admin@purebrand.io to let us know about a store you'd like to see!
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7">
            <Accordion.Header>
              <strong>
                What happens if I miss an appointment? How to reschedule?
              </strong>
            </Accordion.Header>
            <Accordion.Body>
              <ArrowRight /> We encourage you to try and reschedule meetings
              ahead time if you need to by using the Reschedule button on your
              calendar. If you miss your appointment, use the messaging link to
              your customer.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="8">
            <Accordion.Header>
              <strong>How is my Privacy handled?</strong>
            </Accordion.Header>
            <Accordion.Body>
              <ArrowRight /> Please refer to our privacy policy at
              https://purebrand.io/privacy-policy
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="9">
            <Accordion.Header>
              <strong>What are the benefits of being a Host?</strong>
            </Accordion.Header>
            <Accordion.Body>
              <ArrowRight /> Make money by sharing your experience with products
              you currently own and love!
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="10">
            <Accordion.Header>
              <strong>
                Can I speak with a different Host about their experience?
              </strong>
            </Accordion.Header>
            <Accordion.Body>
              <ArrowRight /> We have a Host Forum coming soon!
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    );
  } else if (
    user.role === 2 ||
    user.role === 4 ||
    user.role === 5 ||
    user.role === 6
  ) {
    return (
      <div className="row">
        <SupportFormComponent />
      </div>
    );
  }
  return <div>Get help</div>;
};

export default Gethelp;
