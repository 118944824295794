import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import RangeSlider from "react-bootstrap-range-slider";
import "./addproduct.css";

import * as Yup from "yup";
import OptionalQuestion from "./OptionalQuestion";

const Delivery = (props) => {
  const [sliderValue, setSliderValue] = useState(props.data.arrivalTime);
  const [sliderValue2, setSliderValue2] = useState(props.data.damageRating);
  const handleSubmit = (values) => {
    values.arrivalTime = parseInt(values.arrivalTime);
    values.damageRating = parseInt(values.damageRating);
    props.next(values);
  };

  return (
    <Formik
      initialValues={props.data}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape({
        arrivalTime: Yup.mixed().required(),
        damageRating: Yup.mixed().required(),
      })}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <div className="product-form-wrapper">
            <div className="product-information-wrapper">
              <h3 className="text-start">Delivery</h3>
              <p className="text-start">We appreciate your honest feedback</p>
            </div>
            <div className="product-uploader">
              <div className="delivery-question-two">
                <div id="my-radio-group">
                  <p className="question-product">
                    Did the product arrive on time? *
                  </p>
                </div>
                <div className="slider-wrapper d-flex">
                  <p className="low-indicator">(Low) </p>
                  <RangeSlider
                    value={sliderValue}
                    min={1}
                    max={10}
                    step={1}
                    onChange={(e) => {
                      setFieldValue("arrivalTime", e.target.value);
                      setSliderValue(e.target.value);
                    }}
                  />
                  <p className="low-indicator">(High) </p>
                  <p className="value-box slider-value">{sliderValue}</p>
                </div>
              </div>
              <div className="optional-question">
                {/* {!(sliderValue === "9" || sliderValue === "10") ? ( */}
                <OptionalQuestion
                  title="Tell us more"
                  name="arrivalTimeOptional"
                  message="Help us know more about your experience with product arrival."
                />
                {/* ) : (
                  <></>
                )} */}
              </div>
              <div className="delivery-question-three">
                <div id="my-radio-group">
                  <p className="question-product">
                    Did product arrive undamaged? *
                  </p>
                </div>
                <div className="slider-wrapper d-flex">
                  <p className="low-indicator">(Low) </p>

                  <RangeSlider
                    value={sliderValue2}
                    min={1}
                    max={10}
                    step={1}
                    onChange={(e) => {
                      setFieldValue("damageRating", e.target.value);
                      setSliderValue2(e.target.value);
                    }}
                  />
                  <p className="low-indicator">(High) </p>

                  <p className="value-box slider-value">{sliderValue2}</p>
                </div>
              </div>
              <div className="optional-question">
                {/* {!(
                  sliderValue2 === "9" ||
                  sliderValue2 === "10" ||
                  sliderValue2 === ""
                ) ? ( */}
                <OptionalQuestion
                  name="damageOptional"
                  message="Help us know more about your experience with damage of product."
                />
                {/* ) : (
                  <></>
                )} */}
              </div>
            </div>
            <div className="button-wrapper-product text-end">
              <button className="btn btn-outline-primary" type="submit">
                Next
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Delivery;
