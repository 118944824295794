import PurebrandLoader from "components/Loader/PurebrandLoader";
import NotificationContext from "context/NotificationContext";
import ProfileContext from "context/ProfileContext";
import { useEffectOnce } from "hooks/useEffectOnce";
import React, { useContext, useState } from "react";
import { Table } from "react-bootstrap";
import { CaretDown, CaretUp } from "react-bootstrap-icons";
import StoreDashboardListShows from "./Hosts/StoreDashboardListShows";

const StoreDashboardList = () => {
  let { updateHostRevenue, updatedRevenue } = useContext(ProfileContext);
  const { notificationPermission } = useContext(NotificationContext);
  const [loading, setLoading] = useState(true);
  const [sortStatus, setSortStatus] = useState(false);

  const getAcceptedHosts = () => {
    if (updatedRevenue?.length < 1) {
      return (
        <tr>
          <td>Not any revenue generated yet!</td>
        </tr>
      );
    } else {
      return updatedRevenue?.map((updatedRevenue, index) => {
        return (
          <StoreDashboardListShows key={index} hostDetails={updatedRevenue} />
        );
      });
    }
  };

  const sortTable = (param) => {
    setLoading(true);
    let res = updateHostRevenue(param);
    res
      .then(function (response) {
        setLoading(false);
      })
      .catch(function (err) {
        setLoading(true);
      });
  };

  useEffectOnce(() => {
    let res = updateHostRevenue("order=true");
    res
      .then(function (response) {
        setLoading(false);
        notificationPermission();
      })
      .catch(function (err) {
        setLoading(true);
      });
  }, []);

  if (loading) {
    return <PurebrandLoader />;
  } else {
    return (
      <>
        <div className="features store-admin-dashboard-wrap">
          <Table>
            <thead>
              <tr>
                <th scope="col">
                  <div className="arrows">
                    Host{" "}
                    <div
                      className="arrow-grid"
                      onClick={() => {
                        setSortStatus(!sortStatus);
                        sortTable(`order=true&host=${sortStatus}`);
                      }}
                    >
                      <CaretUp />
                      <CaretDown />
                    </div>
                  </div>
                </th>
                <th scope="col">
                  <div className="arrows">
                    Revenue{" "}
                    <div
                      className="arrow-grid"
                      onClick={() => {
                        setSortStatus(!sortStatus);
                        sortTable(`order=true&revenue=${sortStatus}`);
                      }}
                    >
                      <CaretUp />
                      <CaretDown />
                    </div>
                  </div>
                </th>
                <th scope="col">
                  <div className="arrows">
                    Earnings{" "}
                    <div
                      className="arrow-grid"
                      onClick={() => {
                        setSortStatus(!sortStatus);
                        sortTable(`order=true&earning=${sortStatus}`);
                      }}
                    >
                      <CaretUp />
                      <CaretDown />
                    </div>
                  </div>
                </th>
                <th scope="col">
                  <div className="arrows">
                    Total Shows{" "}
                    <div
                      className="arrow-grid"
                      onClick={() => {
                        setSortStatus(!sortStatus);
                        sortTable(`order=true&shows=${sortStatus}`);
                      }}
                    >
                      <CaretUp />
                      <CaretDown />
                    </div>
                  </div>
                </th>
                <th scope="col">
                  <div className="arrows">Top Product</div>
                </th>
              </tr>
            </thead>
            <tbody>{getAcceptedHosts()}</tbody>
          </Table>
        </div>
      </>
    );
  }
};

export default React.memo(StoreDashboardList);
