import CustomModal from 'components/Modal/CustomModal';
import React, { useState } from 'react';
import StorePayDetail from './StorePayDetail';
import { DEMO_HISTORY_STATUS } from 'utils/constants';
import { Icon, guestNoShow, success, backupSuccess } from 'assets/images';
import { calculateVideoDuration, convertUTCToLocal } from 'utils/dateFormatter';
import PropTypes from 'prop-types';
import './viewListData.css';

/**
 * This function is used to calculate the demo result status
 *
 * @param {string} guestAttended boolean value whether guest attended the meeting or not
 * @param {string} scheduledAt scheduled time of the demo
 * @param {string} startedAt start time of the demo
 * @param {string} endedAt end time of the demo
 * @return {ReactNode} element to display in the result status of the demo history table
 */

const getDemoResult = (
  guestAttended,
  scheduledAt,
  startedAt,
  endedAt,
  fallback_hosts_notified
) => {
  if (scheduledAt && endedAt) {
    let startedDate;
    const endedDate = new Date(endedAt);
    const scheduledDate = new Date(scheduledAt);

    if (!startedAt) {
      startedDate = new Date(scheduledAt);
    } else {
      startedDate = new Date(startedAt);
    }

    let timeDifference;

    if (startedDate < scheduledDate) {
      timeDifference = Math.abs(endedDate - scheduledDate);
    } else {
      timeDifference = Math.abs(endedDate - startedDate);
    }

    if (!guestAttended) {
      if (timeDifference > 600000) {
        return (
          <>
            <img src={guestNoShow} alt="Guest No Show" />{' '}
            {DEMO_HISTORY_STATUS.GUEST_NO_SHOW}
          </>
        );
      } else {
        return (
          <>
            <img src={guestNoShow} alt="Under Time" />{' '}
            {DEMO_HISTORY_STATUS.UNDER_TIME}
          </>
        );
      }
    }
    if (fallback_hosts_notified) {
      return (
        <>
          <img src={backupSuccess} alt="Back Up Success" />{' '}
          {DEMO_HISTORY_STATUS.BACKUP_SUCCESS}
        </>
      );
    }
    return (
      <>
        <img src={success} alt="Success" /> {DEMO_HISTORY_STATUS.SUCCESS}
      </>
    );
  } else {
    return '-';
  }
};

/**
 * This component renders demo information data for a single demo in the demo history table.
 *
 * @param {props} props information regarding the demo
 * @return {ReactNode} This component renders demo information data for a single demo in the demo history table.
 */

const ViewListData = ({ dataList }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <tr onClick={handleShow}>
        <td>
          <div className="d-flex align-items-center">
            <div className="product-name">
              <span>
                {
                  convertUTCToLocal(
                    `${dataList?.schedule.scheduled_date}T${dataList?.schedule.scheduled_time}`
                  )[0]
                }
              </span>
            </div>
          </div>
        </td>
        <td>
          <div className="d-flex align-items-center">
            <div className="">
              <img
                className="host-pp-small"
                src={dataList?.product.image ? dataList?.product.image : Icon}
                alt="product"
              />
            </div>{' '}
            <div className="product-name">
              <span>{dataList?.product.name}</span>
            </div>
          </div>
        </td>
        <td>
          {getDemoResult(
            dataList?.members[0].guest_detail?.attend_meeting,
            `${dataList?.schedule?.scheduled_date}T${dataList?.schedule?.scheduled_time}Z`,
            dataList?.started_at,
            dataList?.ended_at,
            dataList?.fallback_hosts_notified
          )}
        </td>
        <td>
          {calculateVideoDuration(
            dataList?.started_at,
            dataList?.ended_at,
            dataList?.members[0].guest_detail?.attend_meeting,
            dataList?.members[0].guest_detail?.left_meeting,
            `${dataList?.schedule?.scheduled_date}T${dataList?.schedule?.scheduled_time}`
          )}
        </td>
        <td>
          <div className="d-flex align-items-center">
            <div className=""></div>{' '}
            <div className="">
              <span>
                {dataList?.members[0].host_detail.first_name}{' '}
                {dataList?.members[0].host_detail.last_name.charAt(0)}.
              </span>
            </div>
          </div>
        </td>
        <td>{dataList?.members[0].guest_detail.full_name}</td>
      </tr>
      <CustomModal
        showStatus={show}
        clickStatus={handleClose}
        buttonMessage="Close"
        closeStatus={handleClose}
        title={
          <div className="demo-details-modal-header">
            <h2>Meeting Details</h2>
            <div className="modal-demo-result">
              Result: {'  '}
              {getDemoResult(
                dataList?.members[0].guest_detail?.attend_meeting,
                `${dataList?.schedule?.scheduled_date}T${dataList?.schedule?.scheduled_time}Z`,
                dataList?.started_at,
                dataList?.ended_at,
                dataList?.fallback_hosts_notified
              )}
            </div>
          </div>
        }
        message={
          <StorePayDetail
            earning={dataList?.earning}
            guest={dataList?.members[0].guest_detail}
            host={dataList?.members[0].host_detail}
            product={dataList?.product}
            recording={dataList?.recorded_video}
            startedAt={dataList?.started_at}
            endedAt={dataList?.ended_at}
            soonestAt={dataList?.soonest_at}
            stars={dataList?.stars}
            review={dataList?.guest_review_note}
            schedule={dataList?.schedule}
            coupon_code={dataList?.coupon}
          />
        }
        secondMessage="noClose"
        secondButton={false}
        modalClass="demo-details-modal"
      />
    </>
  );
};

ViewListData.propTypes = {
  dataList: PropTypes.shape({
    schedule: PropTypes.object,
    product: PropTypes.object,
    members: PropTypes.array,
    started_at: PropTypes.string,
    ended_at: PropTypes.string,
    soonest_at: PropTypes.string,
    earning: PropTypes.number,
    recorded_video: PropTypes.string,
    stars: PropTypes.number,
    guest_review_note: PropTypes.string,
    coupon_code: PropTypes.string,
  }).isRequired,
};

export default ViewListData;
