import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  Asset1,
  Ellipse,
  Feature1,
  Feature2,
  Jump1,
} from "../../assets/images";

const Jumping = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="row image-with-bullet">
        <div className="col-md-6  col-sm-12">
          <div className="leftimage-wrapper">
            <div className="left-image-wrap  d-flex justify-content-center">
              <img className="img-fluid jump-image" src={Jump1} alt="jumping" />
            </div>
            <div className="background-img-wrap">
              <img src={Asset1} alt="asset-1" />
            </div>
            <div className="esclipse-img-wrap">
              <img src={Ellipse} alt="ellipse" />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-sm-12 right-content-bullet">
          <div className="heading-text-wrap">Here’s how it works</div>
          <div className="text-container-wrap ">
            <div className="points-content-wrap d-flex">
              <div className="numbering">1</div>
              <div className="point-text-wrap">Sign up</div>
            </div>
            <div className="points-content-wrap d-flex">
              <div className="numbering">2</div>
              <div className="point-text-wrap">
                Upload a photo or quick video of your product review
              </div>
            </div>
            <div className="points-content-wrap d-flex">
              <div className="numbering">3</div>
              <div className="point-text-wrap">Wait for demo requests</div>
            </div>
            <div className="points-content-wrap d-flex">
              <div className="numbering">4</div>
              <div className="point-text-wrap">Do the demos, get your cash</div>
            </div>
          </div>
          <div className="start-text-wrap">Get started today</div>
          {/* <button className="btn btn-primary btn-signup">Sign up</button> */}
          <Button onClick={() => navigate("/signup")} variant="primary">
            Signup
          </Button>{" "}
        </div>
      </div>

      <div className="row image-with-feature">
        <div className="col-md-6  col-sm-12 left-content-wrap">
          <div className="text-description-wrapper">
            <div className="heading-wrap">We mean it, you get rewarded</div>
            <div className="feature-description-wrapper">
              Our purebrand reviewers have the chance to make hundreds of
              dollars through their 15 minute demo sessions. While payouts will
              vary based on the product we make sure each demo is worth it for
              the reviewer.
            </div>
          </div>
          <div className="start-text-wrap">Get started today</div>
          {/* <button className="btn btn-primary btn-signup">Sign up</button> */}
          <Button onClick={() => navigate("/signup")} variant="primary">
            Signup
          </Button>{" "}
        </div>
        <div className="col-md-6  col-sm-12 right-content-wrap">
          <div className="feature-content-wrap d-flex">
            <div className="feature-img">
              <img src={Feature1} className="feature1" alt="feature-1" />
            </div>
            <div className="feature-text">Flat payout for each demo</div>
          </div>
          <div className="feature-content-wrap d-flex">
            <div className="feature-img">
              <img src={Feature2} className="feature2" alt="feature-2" />
            </div>
            <div className="feature-text">
              Plus earn a percentage of the sale if they consumer buys the
              product
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Jumping;
