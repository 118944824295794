import { createContext, useState } from "react";
import { sendMessage } from "Services/messageServices";

const MessageContext = createContext();
export default MessageContext;

export const MessageProvider = ({ children }) => {
  let createNewMessage = async (body) => {
    try {
      const response = await sendMessage(body);
      return response;
    } catch (error) {
      throw error;
    }
  };

  let contextData = {
    createNewMessage: createNewMessage,
  };
  return (
    <MessageContext.Provider value={contextData}>
      {children}
    </MessageContext.Provider>
  );
};
