import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import MuteAudioButton from "./MuteAudioButton";
import MuteVideoButton from "./MuteVideoButton";
import EndCallButton from "./EndCallButton";
import { useParams } from "react-router";
import AuthContext from "../../context/AuthContext";
import VideoContext from "../../context/VideoContext";
import "./videoStyles.css";
import ChangeCameraButton from "./ChangeCameraButton";
import useDevices from "hooks/useDevices";
import { ChatTextFill } from "react-bootstrap-icons";
import DemoMessage from "components/Demos-Message/DemoMessage";
//screen share
import ScreenShareButton from "./ScreenShareButton";
import DemoContext from "context/DemoContext";
import { message } from "antd";

export default function ToolBar({
  room,
  connected,
  cameraPublishing,
  videoStatus,
  audioStatus,
  camera,
  demoIdentifier,
  demoInfo,
}) {
  const [messageApi, contextHolder] = message.useMessage();
  const { roomName } = useParams();
  const navigate = useNavigate();
  const [hasAudio, setHasAudio] = useState(audioStatus);
  const [hasVideo, setHasVideo] = useState(videoStatus);
  //screen share
  const [screenShare, setScreenShare] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user, loggedIn } = useContext(AuthContext);
  const { record, demoId, endCall } = useContext(VideoContext);
  const { setGuestPresence } = useContext(DemoContext);
  const [showRecordMessage, setShowRecordMessage] = useState(true);
  const [startedRecording, setStartedRecording] = useState(false);
  const [ending, setEnding] = useState(false);
  const { deviceInfo, getDevices } = useDevices();
  const [sendText, setSendText] = useState(false);

  useEffect(() => {
    getDevices();
  }, []);
  const toggleVideo = () => {
    if (room && room.camera) {
      const { camera } = room;
      const isVideoEnabled = camera.isVideoEnabled();
      if (isVideoEnabled) {
        camera.disableVideo();
        setHasVideo(false);
      } else {
        camera.enableVideo();
        setHasVideo(true);
      }
    }
  };
  const toggleAudio = () => {
    if (room && room.camera) {
      const camera = room.camera;
      const isAudioEnabled = camera.isAudioEnabled();
      if (isAudioEnabled) {
        camera.disableAudio();
        setHasAudio(false);
      } else {
        camera.enableAudio();
        setHasAudio(true);
      }
    }
  };
  //screen share
  const startShare = () => {
    if (room) {
      room
        .startScreensharing()
        .then(() => {
          setScreenShare(true);
        })
        .catch((err) => console.log("Error Occured While sharing Screen"));
    }
  };

  const stopShare = () => {
    if (room.screen.isScreenSharing) {
      room.stopScreensharing();
    }
    setScreenShare(false);
  };

  const toggleRecording = async () => {
    if (!loading) {
      setLoading(true);
      setStartedRecording(true);
      const demo_id = localStorage.getItem("demoId");
      try {
        let data = {
          record: !isRecording,
        };
        const res = await record(demo_id, data);
        if (res) {
          setIsRecording(true);
          setLoading(false);
        }
      } catch (err) {
        setIsRecording(false);
        setLoading(false);
      }
    }
    // setIsRecording(true);
  };

  const getVideoSource = async () => {
    if (room && room.camera) {
      let videoSource = await room.camera.getVideoDevice();
      return videoSource;
    }
    return null;
  };

  const changeVideoSource = (videoId) => {
    room.camera.setVideoDevice(videoId);
  };

  const getAudioSource = async () => {
    if (room && room.camera) {
      const audioDevice = await room.camera.getAudioDevice();
      return audioDevice.deviceId;
    }
    return null;
  };

  const changeAudioSource = (audioId) => {
    room.camera.setAudioDevice(audioId);
  };

  const endCurrentCall = async () => {
    if (demoInfo && demoInfo?.status === "UNDECIDED") {
      if (room) {
        if (user && loggedIn) {
          window.location.href = "/user/welcome";
        } else {
          window.location.href = `/meeting-status-check?demo=${demoIdentifier}`;
        }
      }
    } else {
      setEnding(true);
      const demo_id = localStorage.getItem("demoId");
      const payload = {
        status: "ENDED",
      };
      const res = endCall(demo_id, payload);
      res
        .then((res) => {
          localStorage.removeItem("videoToken");
          localStorage.removeItem("demoId");
          if (res.status === 200) {
            if (room) {
              if (user && loggedIn) {
                window.location.href = "/user/welcome";
              } else {
                window.location.href = `/meeting-status-check?demo=${demoIdentifier}`;
              }
            }
          }
        })
        .catch((err) => {
          if (room) {
            if (user && loggedIn) {
              window.location.href = '/user/welcome';
            } else {
              const res = setGuestPresence(demoIdentifier, {
                status: false,
              });
              res
                .then(() => {
                  window.location.href = `/meeting-status-check?demo=${demoIdentifier}`;
                })
                .catch(() => {
                  messageApi.open({
                    type: 'error',
                    content: 'Error while ending the call',
                  });
                });
            }
            // navigate("/user/welcome");
            // room.leave();
          }
        });
    }
  };

  const switchCamera = (id) => {
    // if (room && room.managedLayoutOptions.deviceLayoutMode === "mobile") {
    room.camera.setVideoDevice(id);
    // }
  };
  useEffect(() => {
    if (connected) {
      const isAudioEnabled = !!(
        room &&
        room.camera &&
        room.camera.isAudioEnabled()
      );
      const isVideoEnabled = !!(
        room &&
        room.camera &&
        room.camera.isVideoEnabled()
      );
      setHasAudio(isAudioEnabled);
      setHasVideo(isVideoEnabled);
    }
  }, [connected, room]);
  useEffect(() => {
    if (videoStatus === false) {
      room?.camera.disableVideo();
      setHasVideo(videoStatus);
    } else {
      room?.camera.enableVideo();
      setHasVideo(true);
    }
    if (audioStatus === false) {
      room?.camera.disableAudio();
      setHasAudio(audioStatus);
    } else {
      room?.camera.enableAudio();
      setHasAudio(true);
    }
  }, [room]);

  return (
    <>
      {contextHolder}
      {isRecording && showRecordMessage && (
        <div className="record-msg">
          This call is being recorded for future references{" "}
          <span
            style={{ float: "right", fontStyle: "normal", marginRight: "5px" }}
            onClick={() => setShowRecordMessage(false)}
          >
            &#x2716;
          </span>
        </div>
      )}
      {!ending ? (
        <div
          className="purebrand-toolbar"
          style={
            isRecording
              ? { borderTopRightRadius: 0, borderTopLeftRadius: 0 }
              : {}
          }
        >
          {/* <div className="w-100 d-flex justify-content-center gap-4">
            <ChangeCameraButton
              getVideoSource={getVideoSource}
              cameraPublishing={cameraPublishing}
              changeVideoSource={changeVideoSource}
              switchCamera={switchCamera}
              deviceInfo={deviceInfo}
            />
          </div> */}
          <div className="w-100 d-flex justify-content-center gap-4">
            <button className="round-primary-btn">
              <ChatTextFill onClick={() => setSendText(!sendText)} />
            </button>
            {sendText && (
              <DemoMessage sendText={sendText} setSendText={setSendText} />
            )}

            <MuteAudioButton
              toggleAudio={toggleAudio}
              hasAudio={hasAudio}
              changeAudioSource={changeAudioSource}
              getAudioSource={getAudioSource}
              cameraPublishing={cameraPublishing}
            />
            <MuteVideoButton
              toggleVideo={toggleVideo}
              hasVideo={hasVideo}
              getVideoSource={getVideoSource}
              cameraPublishing={cameraPublishing}
              changeVideoSource={changeVideoSource}
            />
            {loggedIn &&
              room?.managedLayoutOptions?.deviceLayoutMode !== "mobile" && (
                <ScreenShareButton
                  toggleShare={!screenShare ? startShare : stopShare}
                  screenShare={screenShare}
                />
              )}

            {room && room.managedLayoutOptions.deviceLayoutMode === "mobile" ? (
              <ChangeCameraButton switchCamera={switchCamera} />
            ) : null}
            {/* {startedRecording === false ? (
            <RecordButton
              toggleRecording={toggleRecording}
              isRecording={isRecording}
            />
          ) : (
            <></>
          )} */}

            <EndCallButton handleEndCall={endCurrentCall} demoInfo={demoInfo} />
          </div>
        </div>
      ) : null}
    </>
  );
}
