import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "./PaymentForm";

const stripePromise = loadStripe(`${process.env.REACT_APP_ADD_STRIPE_KEY}`);
console.log("jksdfkjsdfjkn", process.env.REACT_APP_ADD_STRIPE_KEY);

const BankForm = ({
  sureShow,
  setSureShow,
  showBankFormUpdate,
  setShowBankFormUpdate,
}) => {
  const clientSecret = "cus_OcjcV7drEfC6rm";

  return (
    <div className="App">
      {/* {clientSecret && ( */}
      <Elements stripe={stripePromise}>
        <PaymentForm
          sureShow={sureShow}
          setSureShow={setSureShow}
          showBankFormUpdate={showBankFormUpdate}
          setShowBankFormUpdate={setShowBankFormUpdate}
        />
      </Elements>
      {/* )} */}
    </div>
  );
};
export default BankForm;
