import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import RangeSlider from "react-bootstrap-range-slider";

import "./addproduct.css";

import * as Yup from "yup";
import OptionalQuestion from "./OptionalQuestion";

const InstallSetup = (props) => {
  const [sliderValue, setSliderValue] = useState(props.data.installSetup);

  const handleSubmit = (values) => {
    values.installSetup = parseInt(values.installSetup);
    props.next(values);
  };
  const handlePrevious = (values) => {
    const p1 = values.orderOne;
    const p2 = values.orderTwo;
    values.orderOne = p1.toString();
    values.orderTwo = p2.toString();
    props.prev(values);
  };
  return (
    <Formik
      initialValues={props.data}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape({
        installSetup: Yup.mixed().required(),
      })}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <div className="product-form-wrapper">
            <div className="product-information-wrapper">
              <h3 className="text-start">Assembly/Installation</h3>
              <p className="text-start">We appreciate your honest feedback</p>
            </div>
            <div className="product-uploader">
              <div className="install-question-one">
                <div id="my-radio-group">
                  <p className="question-product">
                    How well did the assembly/installation meet expectations? *
                  </p>
                </div>
                <div className="slider-wrapper d-flex">
                  <p className="low-indicator">(Low) </p>

                  <RangeSlider
                    value={sliderValue}
                    min={1}
                    max={10}
                    step={1}
                    onChange={(e) => {
                      setFieldValue("installSetup", e.target.value);
                      setSliderValue(e.target.value);
                    }}
                  />
                  <p className="low-indicator">(High) </p>

                  <p className="value-box slider-value">{sliderValue}</p>
                </div>
              </div>
              <div className="optional-question">
                {/* {!(
                  values.installSetup === "9" ||
                  values.installSetup === "10" ||
                  values.installSetup === ""
                ) ? ( */}
                <OptionalQuestion
                  name="installSetupOpt"
                  message="Help us know more about your experience."
                />
                {/* ) : (
                  <></>
                )} */}
              </div>
            </div>
            <div className="button-wrapper-product text-end">
              <div
                onClick={() => handlePrevious(values)}
                className="btn btn-outline-primary previous-button"
              >
                Prev
              </div>
              <button className="btn btn-outline-primary" type="submit">
                Next
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default InstallSetup;
