import React from "react";

import "./header.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navbar, Container } from "react-bootstrap";
import { HeaderCombined, Purebrand } from "assets/images";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Check from "Pages/landing/Check";
import Loginheader from "Pages/landing/Loginheader";
import moment from "moment";

const Headerss = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const currentYear = moment().format("YYYY");

  return (
    <React.Fragment>
      {location.pathname === "/meeting-status-check" ? null : (
        <header>
          <Navbar bg="transparent">
            <Container>
              <Navbar.Brand className="pb-logo-header">
                {location.pathname === "/signup" ||
                location.pathname === "/login" ||
                location.pathname === "/forgot-password" ||
                location.pathname.split("/")[1] === "reset-password" ||
                location.pathname === "/privacy-policy" ||
                location.pathname === "/terms-and-conditions" ||
                location.pathname === "/support" ||
                location.pathname.split("/")[1] === "verify-account" ? (
                  <>
                    <p>
                      <img
                        src={Purebrand}
                        className="Header-img murphy_door"
                        style={{ width: "300px" }}
                        alt="murphydoor"
                        onClick={() => navigate("/")}
                      />
                    </p>
                  </>
                ) : (
                  <img
                    src={Purebrand}
                    className="Header-img"
                    style={{ width: "300px" }}
                    alt="logo"
                    onClick={() => navigate("/")}
                  />
                )}{" "}
              </Navbar.Brand>
              {location.pathname === "/" && <Check />}
              {location.pathname === "/login" && <Loginheader />}
            </Container>
          </Navbar>
        </header>
      )}

      <Outlet />
      {/* {props.children} */}

      <footer id="footer">
        <Navbar bg="transparent">
          <Container>
            <Navbar.Brand>
              {" "}
              {location.pathname === "/meeting-status-check" ? null : (
                <img src={Purebrand} className="Header-img" alt="logo" />
              )}{" "}
              <p>© {currentYear} purebrand™, Inc. All rights reserved.</p>
            </Navbar.Brand>
          </Container>
        </Navbar>
      </footer>
    </React.Fragment>
  );
};

export default Headerss;
