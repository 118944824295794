import { createContext, useState } from 'react';
import {
  cancelSubscription,
  changeSubscriptionStatus,
  getSubscriptionDetails,
  startSubscription,
} from 'Services/subscriptionService';

const SubscriptionContext = createContext();

export default SubscriptionContext;

/**
 * Context Provider to manage the states of the subscription module
 */

export const SubscriptionProvider = ({ children }) => {
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [activePlan, setActivePlan] = useState({});

  /**
   * This function is used to get the subscription detials of a store
   *
   * @param {object} params query parameters to be passed in the api call
   * @returns {object} Response object with the data
   */

  const getSubscriptionData = async (params) => {
    try {
      return await getSubscriptionDetails(params);
    } catch (error) {
      throw error;
    }
  };

  /**
   * This function is used to select a subscription plan for a store
   *
   * @param {object} data body parameters to be passed in the api call
   * @returns {object} Response object with the data
   */

  const startSubscriptionForStore = async (data) => {
    try {
      return await startSubscription(data);
    } catch (error) {
      throw error;
    }
  };

  /**
   * This function is used to cancel a subscription plan for a store
   *
   * @param {object} data body parameters to be passed in the api call
   * @returns {object} Response object with the data
   */

  const cancelSubscriptionForStore = async (data) => {
    try {
      return await cancelSubscription(data);
    } catch (error) {
      throw error;
    }
  };

  /**
   * This function is used to change the subscription status for a store's plan
   *
   * @param {object} data body parameters to be passed in the api call
   * @returns {object} Response object with the data
   */

  const changeSubscriptionStatusForStore = async (data) => {
    try {
      return await changeSubscriptionStatus(data);
    } catch (error) {
      throw error;
    }
  };

  const contextData = {
    getSubscriptionData,
    startSubscriptionForStore,
    cancelSubscriptionForStore,
    changeSubscriptionStatusForStore,
    subscriptionPlans,
    setSubscriptionPlans,
    activePlan,
    setActivePlan,
  };

  return (
    <SubscriptionContext.Provider value={contextData}>
      {children}
    </SubscriptionContext.Provider>
  );
};
