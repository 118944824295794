import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { createContext } from "react";
import {
  deleteMediaOnceHost,
  editReviewByHostPut,
  hostSeeRequest,
  hostProductReviewEdit,
  hostSeeRequestAdmin,
  hostSeeRequestOnce,
  requestForReviewByHost,
  uploadNewEditedMedia,
} from "Services/demoService";
import { uploadFile } from "Services/uploadService";
import { storeRevenueData } from "Services/paymentService";
import {
  getHostDetailStore,
  getUserPayoutData,
  userProductReview,
} from "Services/userService";
import AuthContext from "./AuthContext";
import { getStores } from "../Services/storeService";

const ProfileContext = createContext();
export default ProfileContext;

export const ProfileProvider = ({ children }) => {
  const { updateUserProfile, updateStoreUserProfile, user, loggedIn } =
    useContext(AuthContext);

  const [updatedHosts, setUpdatedHosts] = useState(null);
  const [updatedHostsOnce, setUpdatedHostsOnce] = useState(null);
  const [countUpdatedHosts, setCountUpdatedHosts] = useState(0);
  const [nextAllHosts, setNextAllHosts] = useState(null);
  const [prevAllHosts, setPrevAllHosts] = useState(null);
  const [updatedRevenue, setUpdatedRevenue] = useState(null);
  const [updatedPayout, setUpdatedPayout] = useState(null);
  const [showrooms, setShowrooms] = useState([]);
  const [nextStep, setNextStep] = useState(null);
  const [prevStep, setPrevStep] = useState(null);
  const [hostMoreDetail, setHostMoreDetail] = useState(null);
  const [nextStephost, setNextStephost] = useState(null);
  const [prevStephost, setPrevStephost] = useState(null);
  const [prevStepHostRevenue, setPrevStepHostRevenue] = useState(null);
  const [nextStepHostRevenue, setNextStepHostRevenue] = useState(null);
  const [prevStepHostApproval, setPrevStepHostApproval] = useState(null);
  const [nextStepHostApproval, setNextStepHostApproval] = useState(null);
  const [prevUpdatedHosts, setPrevUpdatedHosts] = useState(null);
  const [nextUpdatedHosts, setNextUpdatedHosts] = useState(null);
  let getUserReviews = async (params) => {
    try {
      const response = await userProductReview(params);
      setShowrooms(response.data.data.results);
      setNextStep(response.data.data.next);
      setPrevStep(response.data.data.previous);
      return response;
    } catch (error) {
      throw error;
    }
  };

  let requestReview = async (data) => {
    var body = {
      accept_status: "PENDING",
      description: data.productDescription,
      decline_reason: "none",
      customer: {
        customer_identifier: data.customerIdentifier,
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
      },
      product: {
        order_identifier: data.orderId,
        identifier: data.productId,
        name: data.productName,
        handle: data.productHandle,
        image: data.productImage,
        price: data.productPrice,
        tag: null,
        // tag: data.productTag || null,
        currency_code: data.productCurrency,
        store: {
          identifier: data.shopId,
          name: data.shopName,
          domain: data.shopDomain,
          email: data.shopEmail,
        },
      },
      questionnaire: {
        quality_one: data.qualityOne,
        quality_one_optional: data.qualityOneOptional,
        quality_two: data.qualityTwo,
        quality_two_optional: data.qualityTwoOptional,
        customer_service: data.customerService,
        customer_service_answer: data.customerServiceAnswer,
        customer_service_answer_optional: data.customerServiceAnswerOpt,
        order_one: data.orderOne,
        order_one_optional: data.orderOneOpt,
        order_two: data.orderTwo,
        order_two_optional: data.orderTwoOpt,
        install_setup: data.installSetup,
        install_setup_optional: data.installSetupOpt,
        order_again: data.orderAgain,
        order_again_optional: data.orderAgainOptional,
        receive_product_optional: data.receiveProductOptional,
        arrival_time: data.arrivalTime,
        arrival_time_optional: data.arrivalTimeOptional,
        damage_rating: data.damageRating,
        damage_rating_optional: data.damageOptional,
      },
      product_media:
        data.noMedia === true
          ? {}
          : {
              picture_ids: data.productPictureId,
              video_ids: data.productVideoId,
            },
      product_feedback: {
        feedback: data.feedback,
        feedback_comment: data.feedbackValue,
      },
    };
    try {
      const response = await requestForReviewByHost(body);
      if (response.status === 200) {
        return response;
      }
    } catch (error) {
      // console.log(error);
      throw error;
    }
  };

  let editSubmittedReviewRequest = async (id, data) => {
    try {
      const res = await editReviewByHostPut(id, data);
      return res;
    } catch (error) {
      throw error;
    }
  };

  let putToS3 = async (data) => {
    var body = {
      tag: data.tag,
      type: data.type,
      extension: data.extension,
      file: data.file[0] || data.file,
    };
    try {
      const response = await uploadFile(body);
      return response;
    } catch (error) {
      // console.log(error);
      throw error;
    }
  };

  let uploader = async (values) => {
    // console.log("uploaderrrrr", values);
    var body = {
      tag: values.tag,
      type: values.type,
      extension: values.extension,
      file: values.imageFile[0],
    };
    try {
      const response = await uploadFile(body);
      values.profile_picture_id = response.data.data.id;
      const updateProfileCheck = await updateUserProfile(values);
      return updateProfileCheck;
    } catch (error) {
      // console.log(error);
      throw error;
    }
  };

  let setupStoreProfile = async (values) => {
    var body = {
      tag: values.tag,
      type: values.type,
      extension: values.extension,
      file: values.imageFile[0],
    };
    try {
      const response = await uploadFile(body);
      values.profile_picture_id = response.data.data.id;
      const updateProfileCheck = await updateStoreUserProfile(values);
      return updateProfileCheck;
    } catch (error) {
      throw error;
    }
  };

  let updateHostRequests = async (params) => {
    try {
      const response = await hostSeeRequest(params);
      setUpdatedHosts(response.data.data.results);
      setPrevStepHostApproval(response.data.data.previous);
      setNextStepHostApproval(response.data.data.next);
      return response;
    } catch (error) {
      throw error;
    }
  };

  let updateHostRequestsOnce = async (params) => {
    try {
      const response = await hostSeeRequestOnce(params);
      setNextAllHosts(response.data.data.next);
      setPrevAllHosts(response.data.data.previous);
      setCountUpdatedHosts(response.data.data.count);
      setUpdatedHostsOnce(response.data.data.results);
      return response;
    } catch (error) {
      throw error;
    }
  };

  let userSeeRequest = async (params) => {
    try {
      const res = await hostSeeRequest(params);
      return res;
    } catch (error) {
      throw error;
    }
  };

  let updateHostRequestsAdmin = async (params) => {
    try {
      const response = await hostSeeRequestAdmin(params);
      setUpdatedHosts(response.data.data.results);
      setPrevUpdatedHosts(response.data.data.previous);
      setNextUpdatedHosts(response.data.data.next);
      return response;
    } catch (error) {
      throw error;
    }
  };

  let userReviewEdit = async (id) => {
    try {
      const res = await hostProductReviewEdit(id);
      return res;
    } catch (error) {
      throw error;
    }
  };

  let updateHostRevenue = async (params) => {
    try {
      const res = await storeRevenueData(params);
      setUpdatedRevenue(res.data.data.results);
      setPrevStepHostRevenue(res.data.data.previous);
      setNextStepHostRevenue(res.data.data.next);
      return res;
    } catch (error) {
      throw error;
    }
  };

  let updatePayouts = async (param) => {
    try {
      const res = await getUserPayoutData(param);
      setUpdatedPayout(res.data.data.results);
      setPrevStephost(res.data.data.previous);
      setNextStephost(res.data.data.next);
      return res;
    } catch (error) {
      // console.log(error);
      throw error;
    }
  };

  let getStoreList = async () => {
    try {
      const res = await getStores();
      return res;
    } catch (e) {
      // console.log(e);
      throw e;
    }
  };

  let storeGetUserData = async (id) => {
    try {
      const res = await getHostDetailStore(id);
      setHostMoreDetail(res.data.data);
      return res;
    } catch (error) {
      throw error;
    }
  };

  let deleteMediaOnce = async (param) => {
    try {
      const res = await deleteMediaOnceHost(param);
      return res;
    } catch (error) {
      throw error;
    }
  };
  let uploadNewMediaOnEdit = async (data) => {
    try {
      const res = await uploadNewEditedMedia(data);
      return res;
    } catch (error) {
      throw error;
    }
  };

  let contextData = {
    requestReview: requestReview,
    uploader: uploader,
    updateHostRequests: updateHostRequests,
    updatedHosts: updatedHosts,
    getUserReviews: getUserReviews,
    showrooms: showrooms,
    putToS3: putToS3,
    setupStoreProfile: setupStoreProfile,
    updatePayouts: updatePayouts,
    updatedPayout: updatedPayout,
    updateHostRevenue: updateHostRevenue,
    updatedRevenue: updatedRevenue,
    setUpdatedRevenue: setUpdatedRevenue,
    setUpdatedHosts: setUpdatedHosts,
    nextStep: nextStep,
    prevStep: prevStep,
    getStoreList: getStoreList,
    updateHostRequestsAdmin: updateHostRequestsAdmin,
    storeGetUserData: storeGetUserData,
    hostMoreDetail: hostMoreDetail,
    userSeeRequest: userSeeRequest,
    editSubmittedReviewRequest: editSubmittedReviewRequest,
    deleteMediaOnce: deleteMediaOnce,
    uploadNewMediaOnEdit: uploadNewMediaOnEdit,
    updateHostRequestsOnce: updateHostRequestsOnce,
    updatedHostsOnce: updatedHostsOnce,
    countUpdatedHosts: countUpdatedHosts,
    nextAllHosts: nextAllHosts,
    prevAllHosts: prevAllHosts,
    nextStephost: nextStephost,
    prevStephost: prevStephost,
    nextStepHostRevenue: nextStepHostRevenue,
    prevStepHostRevenue: prevStepHostRevenue,
    nextStepHostApproval: nextStepHostApproval,
    prevStepHostApproval: prevStepHostApproval,
    nextUpdatedHosts: nextUpdatedHosts,
    prevUpdatedHosts: prevUpdatedHosts,
    userReviewEdit: userReviewEdit,
  };
  return (
    <ProfileContext.Provider value={contextData}>
      {children}
    </ProfileContext.Provider>
  );
};
