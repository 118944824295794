import PaymentContext from 'context/PaymentContext';
import { useEffectOnce } from 'hooks/useEffectOnce';
import React, { useContext, useState } from 'react';
import { Button, Spinner, Table } from 'react-bootstrap';
import { Tooltip } from 'antd';
import ViewListData from './ViewListData';
import './meetinghistory.css';
import FilterModal from 'components/Modal/FilterModal';
import { filter } from 'assets/images';
import DemoContext from 'context/DemoContext';
import { DATE_FORMATS, SORTING_STATUS } from 'utils/constants';
import { TEST_IDS } from './testIds';
import { CaretDown, CaretUp } from 'react-bootstrap-icons';

/**
 * This component renders all the demos history related information to the store admin.
 *
 * @return {ReactNode} A React element that renders all the demos history related information to the store admin.
 */

const MeetingStoreView = () => {
  const {
    storePayoutGet,
    demoHistoryStore,
    nextStorePayout,
    previousStorePayout,
  } = useContext(PaymentContext);

  const { fetchAllHosts, fetchAllProducts } = useContext(DemoContext);

  const [loading, setLoading] = useState(true);
  const [filteredDataLoading, setFilteredDataLoading] = useState(false);
  const [clearFiltersLoading, setClearFiltersLoading] = useState(false);

  const [filterShow, setFilterShow] = useState(false);

  const [dateFilter, setDateFilter] = useState('');
  const [originalDateFilter, setOriginalDateFilter] = useState('');
  const [startDateFilter, setStartDateFilter] = useState('');
  const [originalStartDateFilter, setOriginalStartDateFilter] = useState('');
  const [endDateFilter, setEndDateFilter] = useState('');
  const [originalEndDateFilter, setOriginalEndDateFilter] = useState('');
  const [hostFilter, setHostFilter] = useState('');
  const [originalHostFilter, setOriginalHostFilter] = useState('');
  const [productFilter, setProductFilter] = useState('');
  const [originalProductFilter, setOriginalProductFilter] = useState('');
  const [sorting, setSorting] = useState({
    date: SORTING_STATUS.NONE,
    productName: SORTING_STATUS.NONE,
    result: SORTING_STATUS.NONE,
    duration: SORTING_STATUS.NONE,
    guestName: SORTING_STATUS.NONE,
    hostName: SORTING_STATUS.NONE,
  });

  /**
   * This function is used to get the current sorting state
   *
   * @return {array} array of two values: columnName and sorting direction
   */

  const getCurrentSorting = () => {
    const keys = Object.keys(sorting);
    for (let index = 0; index < keys.length; index++) {
      if (
        sorting[keys[index]] === SORTING_STATUS.ASCENDING ||
        sorting[keys[index]] === SORTING_STATUS.DESCENDING
      ) {
        return [keys[index], sorting[keys[index]]];
      }
    }
    return [null, null];
  };

  /**
   * This function is the on close fuction which manages the states on close of the filter modal
   *
   * @param {Boolean} setAllStates boolean flag to reset all the states to its original state
   * @return {void}
   */

  const handleFilterClose = (setAllStates) => {
    if (setAllStates) {
      setDateFilter(originalDateFilter);
      setStartDateFilter(originalStartDateFilter);
      setEndDateFilter(originalEndDateFilter);
      setHostFilter(originalDateFilter);
      setProductFilter(originalProductFilter);
    }
    setFilterShow(false);
  };

  useEffectOnce(() => {
    let res = storePayoutGet(`?order=true`);
    res
      .then(() => setLoading(false))
      .catch((err) => {
        setLoading(true);
      });

    fetchAllHosts('?')
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });

    fetchAllProducts('?')
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  }, [demoHistoryStore]);

  /**
   * This function is used to handle the api call when next button is clicked
   *
   * @return {void}
   */

  const handleNext = async () => {
    setLoading(true);
    var nextPathname = new URL(nextStorePayout).search;
    try {
      let res = await storePayoutGet(nextPathname);
      setLoading(false);
    } catch (error) {
      setLoading(true);
    }
  };

  /**
   * This function is used to handle the api call when previous button is clicked
   *
   * @return {void}
   */

  const handlePrevious = async () => {
    setLoading(true);
    var prevPathname = new URL(previousStorePayout).search;
    try {
      let res = await storePayoutGet(prevPathname);
      setLoading(false);
    } catch (error) {
      setLoading(true);
    }
  };

  /**
   * This function is used to handle the api call with all the filters when filters are applied.
   *
   * @return {void}
   */

  const applyFilters = () => {
    setFilteredDataLoading(true);
    let startD = '';
    let endD = '';
    if (startDateFilter) {
      startD = startDateFilter.format(DATE_FORMATS.GENERIC_DATE_FORMAT);
    }
    if (endDateFilter) {
      endD = endDateFilter.format(DATE_FORMATS.GENERIC_DATE_FORMAT);
    }

    const currentSorting = getCurrentSorting();
    let res;

    if (currentSorting[0]) {
      res = storePayoutGet(
        `?order=true&startDate=${startD}&endDate=${endD}&host=${hostFilter}&product=${productFilter}&${currentSorting[0]}=${currentSorting[1]}`
      );
    } else {
      res = storePayoutGet(
        `?order=true&startDate=${startD}&endDate=${endD}&host=${hostFilter}&product=${productFilter}`
      );
    }

    res
      .then(() => {
        setFilteredDataLoading(false);
        setOriginalDateFilter(dateFilter);
        setOriginalStartDateFilter(startDateFilter);
        setOriginalEndDateFilter(endDateFilter);
        setOriginalHostFilter(hostFilter);
        setOriginalProductFilter(productFilter);
        handleFilterClose();
      })
      .catch((err) => {
        setFilteredDataLoading(true);
      });
  };

  /**
   * This function is used to handle the api call for clearing all the filters when filters are removed.
   *
   * @return {void}
   */

  const clearFilters = () => {
    setClearFiltersLoading(true);
    const res = storePayoutGet(`?order=true`);
    res
      .then(() => {
        setClearFiltersLoading(false);
        setOriginalDateFilter('');
        setOriginalStartDateFilter('');
        setOriginalEndDateFilter('');
        setOriginalHostFilter('');
        setOriginalProductFilter('');
        setDateFilter('');
        setStartDateFilter('');
        setEndDateFilter('');
        setHostFilter('');
        setProductFilter('');
        handleFilterClose();
      })
      .catch((err) => {
        setClearFiltersLoading(true);
      });
  };

  /**
   * This function is used to get the list of history of demos
   *
   * @return {ReactNode} A component returns a list of line items for each demo
   */

  const getlist = () => {
    if (loading) {
      return (
        <tr>
          <td>
            <div className="d-flex justify-content-center">
              <Spinner animation="grow" />
              <Spinner animation="grow" />
              <Spinner animation="grow" />
            </div>
          </td>
        </tr>
      );
    } else {
      if (demoHistoryStore.length < 1) {
        return (
          <tr>
            <td>No data available!</td>
          </tr>
        );
      } else {
        return demoHistoryStore?.map((dataList, index) => {
          return <ViewListData key={index} dataList={dataList} />;
        });
      }
    }
  };

  /**
   * This function is used to handle sorting on the table columns
   *
   * @return {void}
   */

  const handleSorting = (column) => {
    if (loading) {
      return;
    }
    let startD = '';
    let endD = '';
    let sortingDirection = '';
    if (startDateFilter) {
      startD = startDateFilter.format(DATE_FORMATS.GENERIC_DATE_FORMAT);
    }
    if (endDateFilter) {
      endD = endDateFilter.format(DATE_FORMATS.GENERIC_DATE_FORMAT);
    }

    if (sorting[column] === SORTING_STATUS.ASCENDING) {
      sortingDirection = SORTING_STATUS.DESCENDING;
    } else if (sorting[column] === SORTING_STATUS.DESCENDING) {
      sortingDirection = SORTING_STATUS.NONE;
    } else {
      sortingDirection = SORTING_STATUS.ASCENDING;
    }

    const res = storePayoutGet(
      `?order=true&startDate=${startD}&endDate=${endD}&host=${hostFilter}&product=${productFilter}&${column}=${sortingDirection}`
    );
    setLoading(true);
    res
      .then(() => {
        const newSorting = { ...sorting };
        Object.entries(newSorting).forEach(([key, value]) => {
          if (key === column && value === SORTING_STATUS.ASCENDING) {
            newSorting[key] = SORTING_STATUS.DESCENDING;
          } else if (
            key === column &&
            sorting[column] === SORTING_STATUS.DESCENDING
          ) {
            newSorting[key] = SORTING_STATUS.NONE;
          } else if (
            key === column &&
            sorting[column] === SORTING_STATUS.NONE
          ) {
            newSorting[key] = SORTING_STATUS.ASCENDING;
          } else {
            newSorting[key] = SORTING_STATUS.NONE;
          }
        });
        setLoading(false);
        setSorting(newSorting);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  /**
   * This function is used to get the tooltip message
   *
   * @return {string} message to be displayed in the tooltip
   */

  const getTooltipTitle = (columnName) => {
    if (sorting[columnName] === SORTING_STATUS.ASCENDING) {
      return 'Click here to sort descending';
    } else if (sorting[columnName] === SORTING_STATUS.DESCENDING) {
      return 'Click here to clear sorting';
    } else {
      return 'Click here to sort ascending';
    }
  };

  return (
    <>
      <div className="filter-btn">
        <Button
          data-testid={TEST_IDS.FILTERS_BTN}
          onClick={() => setFilterShow(true)}
        >
          <img src={filter} alt="" /> Filters
        </Button>
      </div>

      <div className="store-payout-history-wrapper table-responsive">
        <Table className="table-store-payout">
          <thead>
            <tr>
              <th className="date-head">
                <div className="arrows">
                  Date{' '}
                  <Tooltip title={getTooltipTitle('date')}>
                    <div
                      className="arrow-grid"
                      onClick={() => {
                        handleSorting('date');
                      }}
                    >
                      <CaretUp />
                      <CaretDown />
                    </div>
                  </Tooltip>
                </div>
              </th>
              <th className="product-head">
                <div className="arrows">
                  Product{' '}
                  <Tooltip title={getTooltipTitle('productName')}>
                    <div
                      className="arrow-grid"
                      onClick={() => {
                        handleSorting('productName');
                      }}
                    >
                      <CaretUp />
                      <CaretDown />
                    </div>
                  </Tooltip>
                </div>
              </th>
              <th className="result-head">
                <div className="arrows">
                  Result{' '}
                  <Tooltip title={getTooltipTitle('result')}>
                    <div
                      className="arrow-grid"
                      onClick={() => {
                        handleSorting('result');
                      }}
                    >
                      <CaretUp />
                      <CaretDown />
                    </div>
                  </Tooltip>
                </div>
              </th>
              <th className="duration-head">
                <div className="arrows">
                  Duration{' '}
                  <Tooltip title={getTooltipTitle('duration')}>
                    <div
                      className="arrow-grid"
                      onClick={() => {
                        handleSorting('duration');
                      }}
                    >
                      <CaretUp />
                      <CaretDown />
                    </div>
                  </Tooltip>
                </div>
              </th>
              <th className="host-head">
                <div className="arrows">
                  Host{' '}
                  <Tooltip title={getTooltipTitle('hostName')}>
                    <div
                      className="arrow-grid"
                      onClick={() => {
                        handleSorting('hostName');
                      }}
                    >
                      <CaretUp />
                      <CaretDown />
                    </div>
                  </Tooltip>
                </div>
              </th>
              <th className="guest-head">
                <div className="arrows">
                  Guest{' '}
                  <Tooltip title={getTooltipTitle('guestName')}>
                    <div
                      className="arrow-grid"
                      onClick={() => {
                        handleSorting('guestName');
                      }}
                    >
                      <CaretUp />
                      <CaretDown />
                    </div>
                  </Tooltip>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>{getlist()}</tbody>
        </Table>
      </div>
      {(previousStorePayout !== null || nextStorePayout !== null) && (
        <div className="button-wrapper-product text-center my-4">
          <button
            onClick={handlePrevious}
            className="btn btn-outline-primary previous-button"
            disabled={previousStorePayout === null}
          >
            Prev
          </button>
          <button
            onClick={handleNext}
            className="btn btn-outline-primary previous-button"
            disabled={nextStorePayout === null}
          >
            Next
          </button>
        </div>
      )}
      <FilterModal
        showStatus={filterShow}
        clickStatus={handleFilterClose}
        buttonMessage="Close"
        closeStatus={handleFilterClose}
        secondMessage="none"
        secondButton={false}
        dateFilter={dateFilter}
        setDateFilter={setDateFilter}
        setStartDateFilter={setStartDateFilter}
        setEndDateFilter={setEndDateFilter}
        setHostFilter={setHostFilter}
        setProductFilter={setProductFilter}
        startDateFilter={startDateFilter}
        endDateFilter={endDateFilter}
        hostFilter={hostFilter}
        productFilter={productFilter}
        applyFilters={applyFilters}
        originalDateFilter={originalDateFilter}
        originalStartDateFilter={originalStartDateFilter}
        originalEndDateFilter={originalEndDateFilter}
        originalHostFilter={originalHostFilter}
        originalProductFilter={originalProductFilter}
        clearFilters={clearFilters}
        loading={filteredDataLoading}
        clearFiltersLoading={clearFiltersLoading}
      />
    </>
  );
};

export default MeetingStoreView;
