import DataContext from "context/DataContext";
import React, { useContext, useEffect, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import ViewAdminList from "./ViewAdminList";

const ViewAllRole = () => {
  const [loadingPros, setLoadingPros] = useState(false);
  const { getAssignedRole, allAdminList, prevAdminList, nextAdminList } =
    useContext(DataContext);

  const getList = () => {
    setLoadingPros(true);
    let res = getAssignedRole(`?order=true`);
    res
      .then(() => {
        setLoadingPros(false);
      })
      .catch((err) => {
        setLoadingPros(true);
      });
  };

  useEffect(() => {
    getList();
  }, []);

  const getAdminList = () => {
    if (loadingPros) {
      return (
        <tr>
          <td>
            <div className="d-flex justify-content-center">
              <Spinner animation="grow" />
              <Spinner animation="grow" />
              <Spinner animation="grow" />
            </div>
          </td>
        </tr>
      );
    } else {
      if (allAdminList) {
        if (allAdminList?.length < 1) {
          return (
            <tr>
              <td>There aren't any admin assigned yet!</td>
            </tr>
          );
        } else {
          return allAdminList?.map((adminList, index) => {
            return <ViewAdminList key={index} adminList={adminList} />;
          });
        }
      } else {
        return <Spinner animation="grow" />;
      }
    }
  };

  return (
    <div className="card cardc">
      <div className="p-5">
        <Table responsive className="table-payout">
          <thead>
            <tr>
              <th className="product-head">User</th>
              <th className="product-head">Email</th>
              <th className="role-head">Role</th>
              <th className="action-head">Action</th>
            </tr>
          </thead>
          <tbody>{getAdminList()}</tbody>
        </Table>
        {(prevAdminList !== null || nextAdminList !== null) && (
          <div className="button-wrapper-product text-center my-4">
            <button
              onClick={() => {
                setLoadingPros(true);
                let res = getAssignedRole(new URL(prevAdminList).search);
                res.then(() => setLoadingPros(false));
              }}
              className="btn btn-outline-primary previous-button"
              disabled={prevAdminList === null}
            >
              Prev
            </button>
            <button
              onClick={() => {
                setLoadingPros(true);
                let res = getAssignedRole(new URL(nextAdminList).search);
                res.then(() => setLoadingPros(false));
              }}
              className="btn btn-outline-primary previous-button"
              disabled={nextAdminList === null}
            >
              Next
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewAllRole;
