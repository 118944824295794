import React, { useState } from "react";
import RangeSlider from "react-bootstrap-range-slider";
import OptionalQuestion from "../Multistep/AddProduct/OptionalQuestion";

const CustomSlider = ({
  question,
  formValue,
  setFieldValue,
  fieldName,
  disableStatus,
  optionalFieldName,
}) => {
  const [newValue, setNewValue] = useState(formValue);
  return (
    <>
      <div id="my-radio-group">
        <p className="question-product">{question}</p>
      </div>
      <div className="slider-wrapper d-flex">
        <p className="low-indicator">(Low) </p>

        <RangeSlider
          value={newValue}
          min={1}
          max={10}
          step={1}
          disabled={disableStatus}
          onChange={(e) => {
            setFieldValue(fieldName, parseInt(e.target.value));
            setNewValue(parseInt(e.target.value));
          }}
        />
        <p className="low-indicator">(High) </p>

        <p className="value-box slider-value">{newValue}</p>
      </div>
      <div className="optional-question">
        {!disableStatus && !(newValue === 9 || newValue === 10) ? (
          <OptionalQuestion
            name={optionalFieldName}
            message="Help us know more about your experience."
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default CustomSlider;
