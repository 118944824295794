import CustomModal from "components/Modal/CustomModal";
import React, { useContext, useState } from "react";
import { Card } from "react-bootstrap";
import "./productcard.css";
import { ProductImage } from "assets/images";
import DemoContext from "context/DemoContext";
import ProductModal from "components/Modal/ProductModal";
import ProfileContext from "context/ProfileContext";
import { useNavigate } from "react-router-dom";
const ProductCards = (props) => {
  let { userCancelReviewRequest } = useContext(DemoContext);
  let { getUserReviews } = useContext(ProfileContext);
  const [show, setShow] = useState(false);
  const [showA, setShowA] = useState(false);
  const [error, setError] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseA = () => setShowA(false);
  const handleShowA = () => setShowA(true);
  let navigate = useNavigate();
  const deleteReview = () => {
    const response = userCancelReviewRequest(props.requestId);
    response
      .then(function (response) {
        if (response.status === 200) {
          setShow(false);
          getUserReviews("?order=true&limit=12");
        }
      })
      .catch(function (error) {
        setShow(false);
        setError(error?.response?.data?.message);
      });
  };
  function acceptCheck() {
    if (props.acceptStatus === "PENDING") {
      return <div className="pending-wrapper">{props.acceptStatus}</div>;
    } else if (props.acceptStatus === "DECLINED") {
      return <div className="rejected-wrapper">PENDING</div>;
    } else if (props.acceptStatus === "ACTIVE") {
      return <div className="accepted-wrapper">{props.acceptStatus}</div>;
    } else if (props.acceptStatus === "ADMIN_REJECTED") {
      return <div className="rejected-wrapper">REJECTED</div>;
    }
  }
  function productAction() {
    if (props.acceptStatus === "PENDING") {
      return (
        <>
          <button onClick={handleShow} className="btn btn-outline-warning mb-2">
            🗑 Delete Product
          </button>
          <CustomModal
            showStatus={show}
            clickStatus={handleClose}
            buttonMessage="Delete"
            closeStatus={deleteReview}
            title="Confirm Delete"
            message="Are you sure you want to delete the product?"
            secondButton={false}
            secondMessage="none"
          />
        </>
      );
    } else if (props.acceptStatus === "DECLINED") {
      return (
        <>
          <button onClick={handleShow} className="btn btn-outline-danger mb-2">
            View Details
          </button>
          <CustomModal
            showStatus={show}
            clickStatus={handleClose}
            buttonMessage="Delete"
            closeStatus={deleteReview}
            title="Decline reason"
            message={props.declineReason}
            secondMessage="none"
            secondButton={false}
          />
        </>
      );
    } else if (props.acceptStatus === "ACTIVE") {
      return (
        <>
          <button onClick={handleShow} className="btn btn-outline-primary mb-2">
            Actions
          </button>
          <CustomModal
            showStatus={show}
            clickStatus={handleClose}
            buttonMessage="Cancel review"
            closeStatus={deleteReview}
            title="Review actions"
            message="Cancel the review request"
            secondMessage="none"
            secondButton={false}
          />
        </>
      );
    }
    // else if (props.acceptStatus === "ADMIN_REJECTED") {
    // return (
    //   <>
    //     <button onClick={handleShow} className="btn btn-outline-danger mb-2">
    //       View Details
    //     </button>
    //     <CustomModal
    //       showStatus={show}
    //       clickStatus={handleClose}
    //       buttonMessage="Delete"
    //       closeStatus={deleteReview}
    //       title="Decline reason"
    //       message={props.declineReason}
    //       secondMessage="none"
    //       secondButton={false}
    //     />
    //   </>
    // );
    // }
  }
  return (
    <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12 mb-4">
      <Card className="cardcustom">
        <Card.Img
          className={`image-listing image-fluid image-responsive ${
            props?.newClassCustom ? props.newClassCustom : ""
          }`}
          variant="top"
          src={props?.imageSrc === "" ? ProductImage : props.imageSrc}
          alt={props.imageAlt}
        />
        <div className="error-wrapper">
          {error === "" ? <></> : <p>{error}</p>}
        </div>
        <Card.Body>
          <div className="row product-detail-row">
            <div className="col product-col">
              <Card.Title>{props.productName}</Card.Title>
              <Card.Text>{props.companyName}</Card.Text>
            </div>
            <div className="col product-status">
              <div className="product-tag">{acceptCheck()}</div>
            </div>
          </div>
          <div className="pricing-wrapper">
            <Card.Title>${props.productPrice}</Card.Title>
          </div>
          <div className="product-detail-wrapper">
            <Card.Text onClick={handleShowA} className="detail-view-text">
              View Details
            </Card.Text>
            <ProductModal
              showStatus={showA}
              clickStatus={handleCloseA}
              title="Review Detail"
              hostInfo={props?.fullDetail}
              buttonOption="Accept"
              customClass="product-review-modal"
              buttonView={false}
            />
            <Card.Text
              onClick={() => navigate(`/user/editproduct/${props.requestId}/`)}
              className="detail-view-text"
            >
              Edit
            </Card.Text>
          </div>
          <div className="product-description-wrapper">
            <Card.Title>Description</Card.Title>
            <Card.Text>{props.productDescription}</Card.Text>
          </div>
        </Card.Body>
        <div className="footer-button-product text-center">
          {productAction()}
        </div>
      </Card>
    </div>
  );
};

export default ProductCards;
