import React, { useState } from "react";
import "./addproduct.css";
import CustomModal from "components/Modal/CustomModal";

const QuestionnaireConfirmation = (props) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleSkip = () => {
    props.data.noQuestions = true;
    props.data.feedback = "no";
    props.data.customerService = "no";
    props.data.orderAgain = "no";
    props.next(props.data, true);
    handleClose();
  };
  const handleSubmit = () => {
    props.next(props.data);
  };

  return (
    <div>
      <div className="product-form-wrapper">
        <div className="product-information-wrapper">
          <h3 className="text-start">Questionnaire</h3>
          <p className="text-start">
            Would you like to go through questionnaire or skip it?
          </p>
        </div>
      </div>
      <div className="button-wrapper-product text-end">
        <button className="btn btn-outline-primary" onClick={handleShow}>
          Skip
        </button>
        <CustomModal
          showStatus={show}
          clickStatus={handleClose}
          buttonMessage="Close"
          modalClass={"opt_validator_modal"}
          closeStatus={handleClose}
          title="Skip Questionnaire"
          message={
            <>
              <div>Sure want to skip ?</div> <br />
              <div
                className="btn btn-primary "
                style={{ marginRight: "10px" }}
                onClick={handleSkip}
              >
                Skip{" "}
              </div>
              <div className="btn btn-primary" onClick={handleClose}>
                Close{" "}
              </div>
            </>
          }
          secondMessage="noClose"
          secondButton={false}
        />
        <button className="btn btn-outline-primary" onClick={handleSubmit}>
          Answer Questionnaire
        </button>
      </div>
    </div>
  );
};

export default QuestionnaireConfirmation;
