import React from "react";
import "./addproduct.css";

import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import OptionalQuestion from "./OptionalQuestion";

const OrderAgain = (props) => {
  const handleSubmit = (values) => {
    props.next(values);
  };
  const handlePrevious = (values) => {
    const p1 = values.installSetup;
    values.installSetup = p1.toString();
    props.prev(values);
  };
  return (
    <Formik
      initialValues={props.data}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape({
        orderAgain: Yup.mixed().required(),
      })}
    >
      {({ values }) => (
        <Form>
          <div className="product-form-wrapper">
            <div className="product-information-wrapper">
              <h3 className="text-start">Order Again?</h3>
              <p className="text-start">We appreciate your honest feedback</p>
            </div>
            <div className="product-uploader">
              <div className="order-again-question">
                <div id="my-radio-group">
                  <p className="question-product">
                    Would you, or have you ordered again? *
                  </p>
                </div>
                <div
                  className="form-group d-flex"
                  role="group"
                  aria-labelledby="my-radio-group"
                >
                  <p> Yes </p>
                  <Field
                    className="radio-input"
                    type="radio"
                    name="orderAgain"
                    value="yes"
                  />
                  <p> No </p>
                  <Field
                    className="radio-input"
                    type="radio"
                    name="orderAgain"
                    value="no"
                  />
                </div>
                <div className="optional-question">
                  {/* {values.orderAgain === "no" ? ( */}
                  <OptionalQuestion
                    name="orderAgainOptional"
                    message="Help us know more."
                  />
                  {/* ) : (
                    <></>
                  )} */}
                </div>
              </div>
            </div>
            <div className="button-wrapper-product text-end">
              <div
                onClick={() => handlePrevious(values)}
                className="btn btn-outline-primary previous-button"
              >
                Prev
              </div>
              <button className="btn btn-outline-primary" type="submit">
                Next
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default OrderAgain;
