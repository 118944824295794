import Dashelement from "components/Dashboard/Dashelement";
import ApiContext from "context/ApiContext";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { useContext } from "react";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import states from "components/Data/states.json";
import "react-phone-input-2/lib/style.css";
import "../ProfileCompletion/profileform.css";
import { base64Decode } from "@firebase/util";
import AuthContext from "context/AuthContext";
import GetTimer from "utils/getTimer";

const zipRegExp = /^\d{5}(?:[-\s]\d{4})?$/;
const FormValidate = Yup.object().shape({
  about_me: Yup.string()
    .required("This field is mandatory")
    .min(5, "Must be at least 5 words"),
  phone_number: Yup.string().required("This field is mandatory"),
  address: Yup.string().required("This field is mandatory"),
  city: Yup.string().required("This field is mandatory"),
  state: Yup.string().required("This field is mandatory"),
  zip_code: Yup.string()
    .required("This field is mandatory")
    .matches(zipRegExp, "Valid zip= 10XXX or 10XXX-XXXX."),
});

const AdminInfo = (props) => {
  const { user } = useContext(AuthContext);
  let { getGeoCoords, getUserOtp, putUserOtp } = useContext(ApiContext);
  const [error, setError] = useState(false);
  const [otpShow, setOtpShow] = useState(false);
  const [verifiedOtp, setVerifiedOtp] = useState(false);
  const [resMessage, setResMessage] = useState(null);
  const [resOtp, setResOtp] = useState(null);
  const [sentOtp, setSentOtp] = useState(false);

  const handleSubmit = (values) => {
    const response = getGeoCoords(values);
    response
      .then(function (response) {
        if (response.status === 200 && response.data.results.length > 0) {
          values.latitude = response.data.results[0].geometry.location.lat;
          values.longitude = response.data.results[0].geometry.location.lng;
          props.next(values);
        } else {
          setError(true);
        }
      })
      .catch(function (error) {
        setError(true);
      });
  };

  const handleOtpTime = () => {
    setSentOtp(true);
    setTimeout(() => {
      setSentOtp(false);
    }, 3 * 60 * 1000);
  };

  const handleOTP = (e) => {
    // setOtpShow(true);
    const data = {
      number: e,
    };
    const res = getUserOtp(data);
    res
      .then(function (response) {
        if (response.status === 200) {
          handleOtpTime();
          setOtpShow(true);
          setResOtp(response.data.data);
          setResMessage(
            "Check your phone for a verification code and enter it below."
          );
        }
      })
      .catch(function (error) {
        setResMessage("Error sending OTP.");
        setTimeout(() => {
          setResMessage(null);
        }, 2500);
        console.log(error);
      });
  };

  const checkOTP = (e, num) => {
    const data = {
      number: num,
    };
    setResMessage("Checking...");
    var code = base64Decode(resOtp.code);
    if (e === code) {
      const res = putUserOtp(data, user.uid);
      res
        .then(function (response) {
          setResMessage("Successfully verified");
          setVerifiedOtp(true);
          setOtpShow(false);
        })
        .catch(function (error) {
          setResMessage("Verification failed");
          setTimeout(() => {
            setResMessage(null);
          }, 2000);
          console.log(error);
        });
    } else {
      setResMessage("Otp verification failed");
      setTimeout(() => {
        setResMessage(null);
      }, 2000);
    }
  };

  return (
    <Formik
      initialValues={props.data}
      onSubmit={handleSubmit}
      validationSchema={FormValidate}
    >
      {({ values, touched, errors, setFieldValue }) => (
        <Form>
          <Dashelement className="features basic-info-wrapper">
            <div className="container">
              <div className="information-text-wrapper">
                <h3>Personal Information</h3>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-6 ">
                  <label className="mb-3">
                    <strong>Address Information</strong>
                  </label>
                  <div className="basic-informations">
                    <div className="row">
                      <div className="col-sm-12 col-md-12  mb-3">
                        <Field
                          type="text"
                          placeholder="Address (15 West Drive St)"
                          name="address"
                          className={`form-control ${
                            touched.address && errors.address
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="address"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-md-12  mb-3">
                        <Field
                          type="text"
                          placeholder="City"
                          name="city"
                          className={`form-control ${
                            touched.city && errors.city ? "is-invalid" : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="city"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-sm-12 col-md-8  mb-3">
                        <Field
                          as="select"
                          name="state"
                          className={`form-select ${
                            touched.state && errors.state ? "is-invalid" : ""
                          }`}
                        >
                          <option value="" disabled>
                            State
                          </option>
                          {states.map((state, index) => (
                            <option key={index} value={state.name}>
                              {state.name}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          component="div"
                          name="state"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-sm-12 col-md-4  mb-3">
                        <Field
                          type="text"
                          placeholder="Zip Code"
                          name="zip_code"
                          className={`form-control ${
                            touched.zip_code && errors.zip_code
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="zip_code"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>
                  <label>
                    <strong>About me</strong>
                  </label>
                  <div className="about-me-wrapper">
                    <div className="row">
                      <div className="col-sm-12 col-md-12">
                        <Field
                          as="textarea"
                          rows={3}
                          name="about_me"
                          placeholder="Tell us more about yourself"
                          className={`form-control ${
                            touched.about_me && errors.about_me
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="about_me"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="opt-for-text-wrapper d-flex mt-3">
                    <div className="checkbox">
                      <Field id="checkbox" type="checkbox" name="optIn" />
                      <label htmlFor="checkbox">Opt-in for text sms</label>
                      <div className="information-text-sms">
                        <p>
                          By entering your number, you agree to receive text
                          messages at the phone number provided.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <label className="mb-3">
                    <strong>Social information</strong>
                  </label>
                  <div className="">
                    <label>Phone number</label>
                    <div className="form-outline d-flex">
                      <div className="col col-8">
                        <PhoneInput
                          country={"us"}
                          value={values.phone_number}
                          onChange={(e) => setFieldValue("phone_number", e)}
                          className={` ${
                            touched.phone_number && errors.phone_number
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="phone_number"
                          className="invalid-feedback"
                        />
                      </div>
                      {values.optIn ? (
                        <div className="col col-4">
                          {verifiedOtp ? (
                            <div> &#10004; Verified</div>
                          ) : (
                            <>
                              {sentOtp ? (
                                <div className="send-otp-wrap">
                                  Send again in <GetTimer />
                                </div>
                              ) : (
                                <div
                                  onClick={() => handleOTP(values.phone_number)}
                                  className="otp-btn-wrap btn btn-primary"
                                >
                                  Send OTP
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="response-otp-message">
                      <p>{resMessage ? resMessage : ""}</p>
                    </div>
                    {otpShow ? (
                      <div className="d-flex">
                        <div className="col col-md-4 mt-1 mb-3">
                          <Field
                            type="text"
                            placeholder="OTP"
                            name="otpCode"
                            className={`form-control `}
                          />
                        </div>
                        <div className="col col-4  mt-3">
                          <div
                            onClick={() =>
                              checkOTP(values.otpCode, values.phone_number)
                            }
                            className="btn send-otp-button"
                          >
                            Verify OTP
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="error-wrapper">
                {error ? (
                  <p>Some error occured, please check your address</p>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </Dashelement>
          <div className="button-place text-end">
            <button className="btn btn-primary" type="submit">
              Next
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AdminInfo;
