import React, { useContext, useEffect, useState } from 'react';
import './Subscription.css';
import SubscriptionContext from 'context/SubscriptionContext';
import AuthContext from 'context/AuthContext';
import PurebrandLoader from 'components/Loader/PurebrandLoader';
import { Button } from 'antd';
import {
  SUBSCRIPTION_MODAL_ACTIONS,
  SUBSCRIPTION_PLANS,
  SUBSCRIPTION_STATUS,
} from 'utils/constants';
import SubscriptionModal from 'components/Modal/SubscriptionModal';
import SubscriptionManager from 'components/SubscriptionManager/SubscriptionManager';
import { convertToAnyDateFormat } from 'utils/dateFormatter';

/**
 * This component is used to render the subscription details for the store admin.
 *
 * @returns {ReactComponent} A component that renders the subscription details for the store admin.
 */

const Subscription = () => {
  const {
    getSubscriptionData,
    setSubscriptionPlans,
    subscriptionPlans,
    activePlan,
    setActivePlan,
  } = useContext(SubscriptionContext);
  const { userProfile } = useContext(AuthContext);

  const [currentPlan, setCurrentPlan] = useState({});
  const [manageActivePlan, setManageActivePlan] = useState(false);
  const [operativePlan, setOperativePlan] = useState({});
  const [plansLoading, setPlansLoading] = useState(true);
  const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false);
  const [subscriptionModalAction, setSubscriptionModalAction] = useState('');

  /**
   * This function is used to get all the subscription plans along with the active plan.
   *
   * @returns {void}
   */

  const getSubscriptionInfo = async () => {
    try {
      setPlansLoading(true);
      const res = await getSubscriptionData(
        `?storeId=${userProfile.store.store_id}`
      );

      const subPlans = res?.data?.plans;

      const isActivePlanIncluded = res?.data?.plans?.some(
        (plan) => plan.id === res?.data?.active_plan?.subscription_type.id
      );

      // If the active plan is not included, push a new plan to the subscriptionPlans
      if (!isActivePlanIncluded) {
        const enterprisePlan = {
          id: 'Enterprise',
          name: 'Enterprise',
          monthly_fees: 'CALL',
          prosperity_partnership: '(CALL)',
          call_limit: '(CALL)',
          basic_dashboard: true,
          enhanced_dashboard: true,
          downloadable_data: true,
          video_transcriptions_ai_summary: true,
        };
        subPlans.push(enterprisePlan);
      } else if (
        Object.values(SUBSCRIPTION_PLANS).includes(
          res?.data?.active_plan?.subscription_type?.name
        )
      ) {
        const enterprisePlan = {
          id: 'Enterprise',
          name: 'Enterprise',
          monthly_fees: 'CALL',
          prosperity_partnership: '(CALL)',
          call_limit: '(CALL)',
          basic_dashboard: true,
          enhanced_dashboard: true,
          downloadable_data: true,
          video_transcriptions_ai_summary: true,
        };
        subPlans.push(enterprisePlan);
      } else {
        subPlans.push(res?.data?.active_plan);
      }
      setSubscriptionPlans(subPlans);
      setActivePlan(res?.data?.active_plan);
    } catch (err) {
      console.log(err);
    } finally {
      setPlansLoading(false);
    }
  };

  /**
   * This function is used to return the text of the plan button
   *
   * @param {object} plan subscription plan object
   * @returns {String} Text to be displayed on the plan button
   */

  const getPlanButtonText = (plan) => {
    if (!activePlan?.subscription_type?.id) {
      return 'Select Plan';
    } else if (plan?.id === activePlan?.subscription_type?.id) {
      if (activePlan?.subscription_status === SUBSCRIPTION_STATUS.PAUSED) {
        return 'Paused';
      }
      return 'Your Plan';
    } else if (
      activePlan?.subscription_type?.name === SUBSCRIPTION_PLANS.ENTERPRISE &&
      plan?.name !== SUBSCRIPTION_PLANS.ENTERPRISE
    ) {
      return 'Select Plan';
    } else if (
      plan?.call_limit > activePlan?.subscription_type?.call_limit ||
      (activePlan?.subscription_type?.name !== SUBSCRIPTION_PLANS.ENTERPRISE &&
        plan?.name === SUBSCRIPTION_PLANS.ENTERPRISE)
    ) {
      return 'Upgrade';
    } else {
      return 'Select Plan';
    }
  };

  /**
   * This function is used to handle the plan selection by the store admin
   *
   * @param {object} plan subscription plan object
   * @returns {void}
   */

  const handlePlanSelection = (plan) => {
    if (!activePlan?.subscription_type?.id) {
      setCurrentPlan(plan);
      setSubscriptionModalOpen(true);
      setSubscriptionModalAction(SUBSCRIPTION_MODAL_ACTIONS.SELECT);
    }
  };

  /**
   * This function is used to check whether the subscription plan is active or not
   *
   * @param {object} plan subscription plan object
   * @returns {Boolean} boolean value of true or false
   */

  const checkActivePlan = (plan) => {
    if (
      plan?.id === activePlan?.subscription_type?.id &&
      activePlan?.subscription_status !== SUBSCRIPTION_STATUS.BLOCKED
    ) {
      return true;
    }
    return false;
  };

  /**
   * This function is used to check whether the subscription plan is paused or not
   *
   * @param {object} plan subscription plan object
   * @returns {Boolean} boolean value of true or false
   */

  const checkPausedPlan = (plan) => {
    if (
      plan?.id === activePlan?.subscription_type?.id &&
      activePlan?.subscription_status === SUBSCRIPTION_STATUS.PAUSED
    ) {
      return true;
    }
    return false;
  };

  /**
   * This function is used to manage the active plan for a store
   *
   * @param {object} plan subscription plan object
   * @returns {void}
   */

  const handleManagePlan = (plan) => {
    setManageActivePlan(true);
    setOperativePlan(activePlan);
    setTimeout(() => {
      const targetDiv = document.getElementById('sub-manager-outer-container');
      if (targetDiv) {
        targetDiv.scrollIntoView({ behavior: 'smooth' });
      }
    }, 500);
  };

  /**
   * This function is used to manage the cancelling of plan for a store
   *
   * @returns {void}
   */

  const handleCancelPlan = () => {
    setSubscriptionModalOpen(true);
    setCurrentPlan(activePlan?.subscription_type);
    setSubscriptionModalAction(SUBSCRIPTION_MODAL_ACTIONS.CANCEL);
  };

  /**
   * This function is used to manage the pausing or resuming of a store's plan
   *
   * @returns {void}
   */

  const handlePauseOrResumePlan = () => {
    setSubscriptionModalOpen(true);
    setSubscriptionModalAction(SUBSCRIPTION_MODAL_ACTIONS.UPDATE);
    setCurrentPlan(activePlan?.subscription_type);
  };

  /**
   * This function is used to render the subscription plans for the store admin
   *
   * @returns {ReactComponent} A component that renders the subscription plans for the store admin
   */

  const renderPlans = () => {
    return (
      <>
        {activePlan?.subscription_status === SUBSCRIPTION_STATUS.BLOCKED && (
          <div className="paused-subscription-alert">
            <>
              Your subscription is currently blocked. Please reach out to the
              support team
            </>
          </div>
        )}
        {activePlan?.subscription_status === SUBSCRIPTION_STATUS.PAUSED && (
          <div className="paused-subscription-alert">
            <>
              Your subscription is currently paused as of{' '}
              {convertToAnyDateFormat(
                activePlan?.last_paused_at,
                'YYYY-M-DTHH:mmZ',
                'MM/DD/YYYY'
              )[0] || '-'}{' '}
              ,
              <button
                className="resume-subscription-link"
                onClick={handlePauseOrResumePlan}
              >
                click here to resume your subscription.
              </button>
            </>
          </div>
        )}

        {subscriptionPlans.map((plan) => {
          const isComingSoon = plan?.name !== 'Starter';
          return (
            <div>
              <div
                className={`individual-plan-container ${
                  checkActivePlan(plan) && 'active-plan-container'
                } ${isComingSoon && 'coming-soon'}`}
              >
                <div className="plan-content">
                  <div
                    className={`plan-name plan-text-styling ${
                      checkActivePlan(plan) &&
                      'active-plan-styling active-plan-name'
                    }`}
                  >
                    {plan?.name || '-'}
                  </div>
                  <div
                    className={`plan-monthly-fees plan-text-styling ${
                      checkActivePlan(plan) &&
                      'active-plan-styling active-plan-monthly-fees'
                    }`}
                  >
                    {isNaN(Number(plan?.monthly_fees))
                      ? plan?.monthly_fees
                      : `$${Number(plan?.monthly_fees)}`}
                  </div>
                  <div
                    className={`plan-duration plan-text-styling ${
                      checkActivePlan(plan) &&
                      'active-plan-styling active-plan-duration'
                    }`}
                  >
                    per month
                  </div>
                  <div
                    className={`partnership-fees plan-text-styling ${
                      checkActivePlan(plan) &&
                      'active-plan-styling active-partnership-fees'
                    }`}
                  >
                    + {plan?.prosperity_partnership} % of sales
                  </div>
                  <div
                    className={`plan-btn plan-text-styling ${
                      checkActivePlan(plan) &&
                      'active-plan-styling active-plan-btn'
                    }`}
                  >
                    <Button
                      className={`plan-button ${
                        checkActivePlan(plan) && 'active-plan-button'
                      } ${checkPausedPlan(plan) && 'paused-plan-button'}`}
                      onClick={() => {
                        handlePlanSelection(plan);
                      }}
                    >
                      {getPlanButtonText(plan)}
                    </Button>
                  </div>
                  <div
                    className={`plan-seperator ${
                      checkActivePlan(plan) && 'active-plan-seperator'
                    }`}
                  ></div>
                  <div
                    className={`plan-call-limit plan-text-styling ${
                      checkActivePlan(plan) &&
                      'active-plan-styling active-plan-call-limit'
                    }`}
                  >
                    Call Limit{' '}
                    <span
                      className={`call-limit-number plan-text-styling ${
                        checkActivePlan(plan) &&
                        'active-plan-styling active-call-limit-number'
                      }`}
                    >
                      {plan?.call_limit || '-'}
                    </span>
                  </div>
                  <div
                    className={`plan-basic-dashboard plan-text-styling ${
                      checkActivePlan(plan) && 'active-plan-styling'
                    }`}
                  >
                    {plan?.basic_dashboard ? (
                      'Basic Dashboard'
                    ) : (
                      <s
                        className={`plan-text-styling ${
                          checkActivePlan(plan) && 'active-plan-styling'
                        }`}
                      >
                        Basic Dashboard
                      </s>
                    )}
                  </div>
                  <div
                    className={`plan-enhanced-dashboard plan-text-styling ${
                      checkActivePlan(plan) && 'active-plan-styling'
                    }`}
                  >
                    {plan?.enhanced_dashboard ? (
                      'Enhanced Dashboard'
                    ) : (
                      <s
                        className={`plan-text-styling ${
                          checkActivePlan(plan) && 'active-plan-styling'
                        }`}
                      >
                        Enhanced Dashboard
                      </s>
                    )}
                  </div>
                  <div
                    className={`plan-downloadable-data plan-text-styling ${
                      checkActivePlan(plan) && 'active-plan-styling'
                    }`}
                  >
                    {plan?.downloadable_data ? (
                      'Downloadable Data'
                    ) : (
                      <s
                        className={`plan-text-styling ${
                          checkActivePlan(plan) && 'active-plan-styling'
                        }`}
                      >
                        Downloadable Data
                      </s>
                    )}
                  </div>
                  <div
                    className={`plan-video-transcriptions plan-text-styling ${
                      checkActivePlan(plan) && 'active-plan-styling'
                    }`}
                  >
                    {plan?.video_transcriptions_ai_summary ? (
                      'Video Transcriptions and AI Summary'
                    ) : (
                      <s
                        className={`plan-text-styling ${
                          checkActivePlan(plan) && 'active-plan-styling'
                        }`}
                      >
                        Video Transcriptions and AI Summary
                      </s>
                    )}
                  </div>
                </div>
                {isComingSoon && (
                  <div className="coming-soon-overlay">Coming Soon..</div>
                )}
              </div>
              {checkActivePlan(plan) && (
                <Button
                  className="manage-plan-btn"
                  onClick={() => {
                    handleManagePlan(plan);
                  }}
                >
                  Manage Plan
                </Button>
              )}
            </div>
          );
        })}
      </>
    );
  };

  useEffect(() => {
    getSubscriptionInfo();
  }, []);

  if (plansLoading) {
    return <PurebrandLoader />;
  }
  return (
    <>
      <div className="plans-container">{renderPlans()}</div>
      {manageActivePlan && (
        <div id="sub-manager-outer-container">
          <SubscriptionManager
            activePlan={activePlan}
            handleCloseManager={() => {
              setManageActivePlan(false);
              setOperativePlan({});
            }}
            handleCancelPlan={handleCancelPlan}
            handlePauseOrResumePlan={handlePauseOrResumePlan}
          />
        </div>
      )}

      <SubscriptionModal
        subscriptionModalOpen={subscriptionModalOpen}
        subscriptionModalAction={subscriptionModalAction}
        handleSubscriptionModalClose={() => {
          setSubscriptionModalOpen(false);
          setCurrentPlan({});
        }}
        setManageActivePlan={setManageActivePlan}
        getSubscriptionInfo={getSubscriptionInfo}
        activePlan={activePlan}
        plan={currentPlan}
      />
    </>
  );
};

export default Subscription;
