/* eslint-disable import/extensions, import/no-unresolved */
import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import useScrollingIntent from '@rainbow-modules/hooks/lib/useScrollingIntent';
import { InternalTooltip, ButtonIcon } from 'react-rainbow-components';
import './copyIcon.css';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const Text = styled.p`
  font-size: 14px;
  text-align: start;
  text-overflow: ellipsis;
  margin-bottom: 4px;
`;

const copyElement = async (elementText) => {
  try {
    await navigator.clipboard.writeText(elementText);
    console.log('Content copied to clipboard');
  } catch (err) {
    console.error('Failed to copy: ', err);
  }
};

function CopyIcon({ elementText }) {
  const [isCopied, setCopied] = useState(false);
  const triggerRef = useRef();
  const tooltipRef = useRef();
  const [isVisible, setVisible] = useState(false);
  const text = isCopied ? 'Copied' : 'Click to copy';

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setVisible(false);
        setCopied(false);
      }, 3000);
    }
  }, [isCopied]);

  useScrollingIntent({
    callback: () => setVisible(false),
    isListening: isVisible,
    triggerElementRef: () => triggerRef.current.htmlElementRef,
  });

  const onMouseEnter = () => {
    setVisible(true);
  };

  const onMouseLeave = () => {
    if (!isCopied) {
      setVisible(false);
    }
  };

  return (
    <Container>
      <ButtonIcon
        onClick={() => {
          setCopied(true);
          copyElement(elementText);
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={triggerRef}
        icon={<FontAwesomeIcon icon={faClipboard} />}
      />
      <InternalTooltip
        triggerElementRef={() => triggerRef.current.htmlElementRef}
        isVisible={isVisible}
        ref={tooltipRef}
      >
        <Text style={{ color: 'white' }}>{text}</Text>
      </InternalTooltip>
    </Container>
  );
}

export default CopyIcon;
