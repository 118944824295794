import Dashelement from "components/Dashboard/Dashelement";
import { Field, FieldArray, Form, Formik } from "formik";
import { removeDuplicates } from "Pages/dashboard/Profile/AddEmail";
import React from "react";

const ShopifyEmail = (props) => {
  const handleSubmit = (values) => {
    values.email = removeDuplicates(values.email);
    const newAccounts = [];
    values.email.map((email) => {
      let data = { store_email: email };
      return newAccounts.push(data);
    });
    values.accounts = newAccounts;
    props.next(values);
  };
  return (
    <Formik initialValues={props.data} onSubmit={handleSubmit}>
      {({ values }) => (
        <Form>
          <Dashelement className="features basic-info-wrapper">
            <div className="container">
              <div className="information-text-wrapper">
                <h3>Shopping History Emails</h3>
                <p>
                  Purebrand links your shopping history based on your email
                  address. Please be sure to include your various email
                  addresses so we can sync all potential products into your
                  account!
                </p>
              </div>
              <div>
                <FieldArray
                  name="email"
                  validateOnChange
                  render={(arrayHelpers) => (
                    <div>
                      {values.email && values.email.length > 0 ? (
                        values.email.map((email, index) => (
                          <div key={index}>
                            <div className="form-outline d-flex">
                              <Field
                                name={`email.${index}`}
                                type="email"
                                className="form-control additional-email"
                              />
                              <button
                                type="button"
                                className="remove_email_button"
                                onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                              >
                                -
                              </button>
                            </div>
                          </div>
                        ))
                      ) : (
                        <></>
                      )}
                      <button
                        type="button"
                        className="add_email_button"
                        onClick={() => arrayHelpers.push("")}
                      >
                        {/* show this when user has removed all email from the list */}
                        Add email
                      </button>
                      <div>
                        <button
                          type="submit"
                          className="submit_add_email_button"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>
          </Dashelement>
        </Form>
      )}
    </Formik>
  );
};

export default ShopifyEmail;
