import React from 'react';

const InfoIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{minWidth: 16}}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M8 16C3.58172 16 0 12.4182 0 8C0 3.58172 3.58172 0 8 0C12.4182 0 16 3.58172 16 8C16 12.4182 12.4182 16 8 16ZM8 14.4C11.5346 14.4 14.4 11.5346 14.4 8C14.4 4.46538 11.5346 1.6 8 1.6C4.46538 1.6 1.6 4.46538 1.6 8C1.6 11.5346 4.46538 14.4 8 14.4ZM8.8 6.8V10.4H9.6V12H6.4V10.4H7.2V8.4H6.4V6.8H8.8ZM9.2 4.8C9.2 5.46274 8.66272 6 8 6C7.33728 6 6.8 5.46274 6.8 4.8C6.8 4.13726 7.33728 3.6 8 3.6C8.66272 3.6 9.2 4.13726 9.2 4.8Z"
      fill="#C3C4C5"
    />
  </svg>
);

export default InfoIcon;
