import { Laptop, Shield } from "assets/images";
import Dashelement from "components/Dashboard/Dashelement";
import ImgComponent from "components/imgComponent";
import React from "react";

const InfoDashboard = () => {
  return (
    <Dashelement className="features dash-info-component">
      <div className="container-wrap">
        <div className="row d-flex justify-content-center">
          <div className="col">
            <div className="col-item">
              <div className="image-wrapper-dashboard">
                <ImgComponent
                  source={Shield}
                  alternate="shield"
                  className="img-icon"
                />
              </div>
              <div className="text-wrapper-dashboard">
                <h5>Safety First</h5>
                <p>
                  Our vetting process is designed to ensure that our viewer's
                  safety is our number one priority which is why all calls are
                  recorded and monitored{" "}
                </p>
              </div>
            </div>
            <div className="col-item">
              <div className="image-wrapper-dashboard">
                <ImgComponent
                  source={Shield}
                  alternate="shield"
                  className="img-icon"
                />
              </div>
              <div className="text-wrapper-dashboard">
                <h5>Fair and Equal Opportunity</h5>
                <p>
                  We welcome everyone to be a part of our amazing new platform.{" "}
                </p>
              </div>
            </div>
            <div className="col-item">
              <div className="image-wrapper-dashboard">
                <ImgComponent
                  source={Shield}
                  alternate="shield"
                  className="img-icon"
                />
              </div>
              <div className="text-wrapper-dashboard">
                <h5>We Want to Hear the Good, Bad and Ugly </h5>
                <p>
                  We pride ourselves in providing pure and honest feedback. We
                  never disapprove anyone for their honest feedback of the
                  product or service.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="image-wrapper-welcome">
              <ImgComponent
                source={Laptop}
                alternate="laptop"
                className="img-laptop"
              />
            </div>
          </div>
        </div>
      </div>
    </Dashelement>
  );
};

export default InfoDashboard;
