import Dashelement from "components/Dashboard/Dashelement";
import TermsCondition from "./TermsCondition";
import PrivacyPolShopify from "./PrivacyPolShopify";

export const TermsAndConditionsPB = () => {
  return (
    <Dashelement className="features">
      <TermsCondition />
    </Dashelement>
  );
};

export const PrivacyPolicyPB = () => {
  return (
    <Dashelement className="features">
      {/* <PrivacyPol /> */}
      <PrivacyPolShopify />
    </Dashelement>
  );
};
