import React from 'react';
import './SidebarMenuIcon.css';

const SidebarMenuIcon = ({ icon, ...props }) => {
  return (
    <div
      className={`sidebar-menu-icon-container ${
        props.activeItem && 'sidebar-menu-icon-container-active'
      }`}
    >
      {icon(props)}
    </div>
  );
};

export default SidebarMenuIcon;
