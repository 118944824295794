import { Field } from "formik";
import React from "react";

const OptionalQuestion = (props) => {
  return (
    <div className="form-outline mt-1">
      <div className="delivery-question-one">
        <p>
          <strong>Tell us more (Optional)</strong>
        </p>
        <Field
          as="textarea"
          rows={3}
          name={props.name}
          className="form-control"
        />
      </div>
    </div>
  );
};

export default OptionalQuestion;
