import { CsvLogo } from "assets/images";
import CustomModal from "components/Modal/CustomModal";
import ProfileContext from "context/ProfileContext";
import CsvView from "Pages/CSV/CsvView";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Spinner, Table, Tabs } from "react-bootstrap";
import { CaretDown, CaretUp, Search } from "react-bootstrap-icons";
import HostAcceptedList from "./HostAcceptedList";
import "./hostlisting.css";
import { Field, Form, Formik } from "formik";

const HostRevenueList = () => {
  let {
    updateHostRevenue,
    updatedRevenue,
    nextStepHostRevenue,
    prevStepHostRevenue,
  } = useContext(ProfileContext);
  const [loadingPros, setLoadingPros] = useState(false);
  const [searchTerminologyRev, setSearchTerminologyRev] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [sortStatus, setSortStatus] = useState(false);

  const getAcceptedHosts = () => {
    if (loadingPros) {
      return (
        <tr>
          <td>
            <div className="d-flex justify-content-center">
              <Spinner animation="grow" />
              <Spinner animation="grow" />
              <Spinner animation="grow" />
            </div>
          </td>
        </tr>
      );
    } else {
      if (updatedRevenue?.length < 1) {
        return (
          <tr>
            <td>Not any revenue generated yet!</td>
          </tr>
        );
      } else {
        return updatedRevenue?.map((updatedRevenue, index) => {
          return <HostAcceptedList key={index} hostDetails={updatedRevenue} />;
        });
      }
    }
  };

  return (
    <div>
      <div className="header_revenue_page d-flex justify-content-end">
        <div className="search-wrapper col-md-4 col-sm-5 mb-3">
          <Formik
            initialValues={{ searchterm: "" }}
            onSubmit={(value) => {
              setLoadingPros(true);
              let res = updateHostRevenue(
                `order=true&search=${value.searchterm}`
              );
              res.then(() => setLoadingPros(false));
            }}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <div className="row">
                  <div className="col-12 d-flex">
                    <Field
                      type="search"
                      placeholder="Search"
                      className="form-control"
                      onChange={(e) => {
                        setSearchTerminologyRev(e.target.value);
                        setFieldValue("searchterm", e.target.value);
                      }}
                      value={values.searchterm}
                      name="searchterm"
                    />{" "}
                    <button type="submit" className="search magnifier">
                      <Search />{" "}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <div className="csv-wrapper text-end">
          <img
            className="csv-download"
            onClick={handleShow}
            src={CsvLogo}
            alt="csv"
          />
          <CustomModal
            showStatus={show}
            clickStatus={handleClose}
            buttonMessage="Close"
            closeStatus={handleClose}
            title="Photo"
            message={<CsvView type="store-host-detail" />}
            secondMessage="none"
            secondButton={false}
          />
        </div>
      </div>
      <div>
        <Table className="table-store" responsive>
          <thead>
            <tr>
              <th scope="col">
                <div className="arrows">
                  Host{" "}
                  <div
                    className="arrow-grid"
                    onClick={() => {
                      setLoadingPros(true);
                      setSortStatus(!sortStatus);
                      let res = updateHostRevenue(
                        `order=true&host=${sortStatus}&search=${searchTerminologyRev}`
                      );
                      res.then(() => setLoadingPros(false));
                    }}
                  >
                    <CaretUp />
                    <CaretDown />
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="arrows">
                  Revenue{" "}
                  <div
                    className="arrow-grid"
                    onClick={() => {
                      setLoadingPros(true);
                      setSortStatus(!sortStatus);
                      let res = updateHostRevenue(
                        `order=true&revenue=${sortStatus}&search=${searchTerminologyRev}`
                      );
                      res.then(() => setLoadingPros(false));
                    }}
                  >
                    <CaretUp />
                    <CaretDown />
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="arrows">
                  Earnings{" "}
                  <div
                    className="arrow-grid"
                    onClick={() => {
                      setLoadingPros(true);
                      setSortStatus(!sortStatus);
                      let res = updateHostRevenue(
                        `order=true&earning=${sortStatus}&search=${searchTerminologyRev}`
                      );
                      res.then(() => setLoadingPros(false));
                    }}
                  >
                    <CaretUp />
                    <CaretDown />
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="arrows">Top Product</div>
              </th>
            </tr>
          </thead>
          <tbody>{getAcceptedHosts()}</tbody>
        </Table>
        {(nextStepHostRevenue !== null || prevStepHostRevenue !== null) && (
          <div className="button-wrapper-product text-center my-4">
            <button
              onClick={() => {
                setLoadingPros(true);
                let res = updateHostRevenue(
                  new URL(prevStepHostRevenue).search
                );
                res.then(() => setLoadingPros(false));
              }}
              className="btn btn-outline-primary previous-button"
              disabled={prevStepHostRevenue === null}
            >
              Prev
            </button>
            <button
              onClick={() => {
                setLoadingPros(true);
                let res = updateHostRevenue(
                  new URL(nextStepHostRevenue).search
                );
                res.then(() => setLoadingPros(false));
              }}
              className="btn btn-outline-primary previous-button"
              disabled={nextStepHostRevenue === null}
            >
              Next
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default HostRevenueList;
