import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Input, Select, message } from 'antd';
import './BankModal.css';
import { MESSAGES } from './constants/messages';
import { PLACEHOLDERS } from './constants/placeholders';
import { addPaymentInfo } from 'Services/dotsService';
import { GENERIC_ERROR_MESSAGE } from 'utils/constants';
const valid = require('us-bank-account-validator');
const { Option } = Select;

/**
 * This component renders a modal for entering the bank account details to the store admin
 *
 * @return {ReactNode} A React element that renders a modal for entering the bank account details to the store admin
 */

const BankModal = ({
  bankModalOpen,
  handleModalClose,
  getStoreAdminsStatus,
}) => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const [updatePaymentLoading, setUpdatePaymentLoading] = useState(false);

  const onFinish = async (values) => {

    const routingValidation = valid.routingNumber(values.routing_number);

    if (!routingValidation.isValid && !routingValidation.isPotentiallyValid) {
      messageApi.open({
        type: 'error',
        content: 'Invalid Routing Number',
      });
      return;
    }

    const accountValidation = valid.accountNumber(values.account_number);

    if (!accountValidation.isValid && !accountValidation.isPotentiallyValid) {
      messageApi.open({
        type: 'error',
        content: 'Invalid Account Number',
      });
      return;
    }

    const payload = { ...values };

    setUpdatePaymentLoading(true);

    try {
      await addPaymentInfo(payload);
      messageApi.open({
        type: 'success',
        content: 'Successfully submitted compliance information',
      });
      setUpdatePaymentLoading(false);
      handleModalClose();
      getStoreAdminsStatus();
    } catch (e) {
      console.log(e);
      setUpdatePaymentLoading(false);
      messageApi.open({
        type: 'error',
        content:
          e?.response?.data?.message || e?.message || GENERIC_ERROR_MESSAGE,
        duration: 5,
      });
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [bankModalOpen]);

  return (
    <>
      {contextHolder}
      <Modal
        open={bankModalOpen}
        title={
          <div className="bank-title">Enter your bank account information</div>
        }
        className="bank-modal"
        onCancel={() => {
          handleModalClose();
        }}
        footer={null}
      >
        <Form layout={'vertical'} form={form} onFinish={onFinish}>
          <Form.Item
            label="Account Number"
            name="account_number"
            rules={[
              { required: true, message: MESSAGES.ACCOUNT_NUMBER_REQUIRED },
            ]}
          >
            <Input
              placeholder={PLACEHOLDERS.ACCOUNT_NUMBER}
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item
            label="Routing Number"
            name="routing_number"
            rules={[
              { required: true, message: MESSAGES.ROUTING_NUMBER_REQUIRED },
            ]}
          >
            <Input
              placeholder={PLACEHOLDERS.ROUTING_NUMBER}
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item
            label="Account Type"
            name="account_type"
            rules={[
              { required: true, message: MESSAGES.ACCOUNT_TYPE_REQUIRED },
            ]}
          >
            <Select placeholder={PLACEHOLDERS.ACCOUNT_TYPE}>
              <Option value="checking">Checking</Option>
              <Option value="savings">Savings</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={updatePaymentLoading}
              loading={updatePaymentLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default BankModal;
