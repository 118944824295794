import AuthContext from "context/AuthContext";
import ProfileContext from "context/ProfileContext";
import React, { useContext, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AddProduct from "./AddProduct";
import CustomerService from "./CustomerService";
import Delivery from "./Delivery";
import Feedback from "./Feedback";
import InstallSetup from "./InstallSetup";
import OrderAgain from "./OrderAgain";
import Ordering from "./Ordering";
import ProductQuality from "./ProductQuality";
import QuestionnaireConfirmation from "./QuestionnaireConfirmation";
import UploadMediaPage from "./UploadMediaPage";

const AddProductController = () => {
  let { user } = useContext(AuthContext);
  let { requestReview, putToS3 } = useContext(ProfileContext);
  const navigate = useNavigate();

  const [data, setData] = useState({
    productDescription: "",
    //customer
    email: user.email,
    customerIdentifier: "", //notdone
    firstName: user.firstName,
    lastName: user.lastName,
    //store
    shopName: "",
    shopId: "",
    shopDomain: "", //notdone
    shopEmail: "", //notdone
    //product
    orderId: "",
    productId: "",
    productName: "",
    productImage: "",
    productPrice: "",
    productTag: "",
    productCurrency: "",
    //product media
    files: "",
    videos: "",
    // productPictureId: [],
    // productVideoId: [],
    //questionnaire
    qualityOne: "10",
    qualityOneOptional: "",
    qualityTwoOptional: "",
    qualityTwo: "10",
    customerService: "",
    customerServiceAnswer: "10",
    customerServiceAnswerOpt: "",
    orderOne: "10",
    orderOneOpt: "",
    orderTwo: "10",
    orderTwoOpt: "",
    installSetup: "10",
    installSetupOpt: "",
    orderAgain: "",
    orderAgainOptional: "",
    receiveProductOptional: "",
    arrivalTime: "10",
    arrivalTimeOptional: "",
    damageRating: "10",
    damageOptional: "",
    noMedia: false,
    noQuestions: false,
    //product feedback
    feedback: "",
    feedbackValue: "",
    productHandle: "",
  });
  const [currentStep, setCurrentStep] = useState(0);

  const makeRequest = (formData) => {
    const lastResponse = requestReview(formData);
    lastResponse
      .then(function (response) {
        if (response.status === 200) {
          navigate("/user/meeting-status", {
            state: { redirected: true },
          });
        }
      })
      .catch(function (err) {
        console.error("Error Occured");
      });
  };

  const handleNextStep = (newData, final = false) => {
    setData((prev) => ({ ...prev, ...newData }));

    if (final) {
      makeRequest(newData);
      return;
    }
    setCurrentStep((prev) => prev + 1);
  };

  const handlePrevStep = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setCurrentStep((prev) => prev - 1);
  };

  const steps = [
    <AddProduct next={handleNextStep} data={data} />,
    <UploadMediaPage next={handleNextStep} data={data} />,
    <QuestionnaireConfirmation next={handleNextStep} data={data} />,
    <Delivery next={handleNextStep} prev={handlePrevStep} data={data} />,
    <ProductQuality next={handleNextStep} prev={handlePrevStep} data={data} />,
    <CustomerService next={handleNextStep} prev={handlePrevStep} data={data} />,
    <Ordering next={handleNextStep} prev={handlePrevStep} data={data} />,
    <InstallSetup next={handleNextStep} prev={handlePrevStep} data={data} />,
    <OrderAgain next={handleNextStep} prev={handlePrevStep} data={data} />,
    // <Maintenance next={handleNextStep} data={data} />,
    <Feedback next={handleNextStep} prev={handlePrevStep} data={data} />,
  ];

  return (
    <div>
      <ProgressBar
        className="step-progress-bar"
        variant="info"
        now={currentStep + 1}
        min={0}
        max={3}
        label={`${currentStep + 1}`}
      />
      {steps[currentStep]}
    </div>
  );
};

export default AddProductController;
