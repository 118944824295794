import Dashelement from "components/Dashboard/Dashelement";
import React from "react";
import { useContext } from "react";
import { useState } from "react";
import MeetingReqView from "./MeetingReqView";
import { Calendar, globalizeLocalizer } from "react-big-calendar";
import moment from "moment";
import globalize from "globalize";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./mymeeting.css";
import DemoContext from "context/DemoContext";
import { OverlayTrigger, Popover, Toast } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import RescheduleModal from "components/Modal/RescheduleModal";
import { useRef } from "react";
import VideoContext from "context/VideoContext";
import AuthContext from "context/AuthContext";
import { useEffectOnce } from "hooks/useEffectOnce";
import {
  calculateDifferenceToday,
  convertUTCToLocal,
  getScheduleTimeDifference,
} from "utils/dateFormatter";
import { Google } from "react-bootstrap-icons";
import VideoPlayerModal from "components/Modal/VideoPlayerModal";
import RequestDot from "components/RequestDot/RequestDot";
import { Button, Modal, Checkbox } from "antd";
import DotsContext from "context/DotsContext";
import { getAllStoresForHost } from "Services/storeService";
const localizer = globalizeLocalizer(globalize);

const MyMeetings = ({
  demoRequests,
  calendarData,
  acceptedOtherData,
  handleNavigate,
}) => {
  let { user } = useContext(AuthContext);
  const { setStoreList } = useContext(DotsContext);
  const [storesLoading, setStoresLoading] = useState(true);

 /**
  * This function is used to get all the stores in order to connect with them for a host
  *
  * @return {void}
  */

 const getAllStores = async () => {
  try {
    const storesList = await getAllStoresForHost();
    setStoresLoading(false);
    setStoreList(storesList?.data?.data);
  } catch (err) {
    console.log(err);
  }
};

  useEffectOnce(() => {
    if (user.role === 3) {
      getAllStores();
    }
  }, []);

  const eventList = [];
  if (calendarData.length > 0) {
    calendarData.map((x, i) => {
      var [utcDate, utcTime] = convertUTCToLocal(
        x?.schedule.scheduled_date + "T" + x?.schedule?.scheduled_time
      );
      return eventList.push({
        start: new Date(utcDate + "T" + utcTime),
        end: new Date(moment(utcDate + "T" + utcTime).add(30, "minutes")),
        title: x.product && x.product.name,
        time: utcTime,
        myCust: x,
      });
    });
  }

  const getDemoRequest = () => {
    if (demoRequests.length < 1) {
      return <div>No demo request available</div>;
    } else {
      return demoRequests.map((demoRequest, index) => {
        return (
          <MeetingReqView
            key={index}
            demoDetail={demoRequest}
            event={eventList}
            storesLoading={storesLoading}
            customClass="my-meeting-page"
          />
        );
      });
    }
  };

  const getOtherAccepted = () => {
    if (acceptedOtherData.length >= 1) {
      return acceptedOtherData.map((datasAccepted, i) => {
        return (
          <div key={i} className="request-component my-meeting-page">
            <p className="detail_desc_name">
              <span className="letter_title_desc">Store: </span>
              {datasAccepted.product.store.name}
            </p>
            <p className="detail_desc_name">
              <span className="letter_title_desc">Guest:</span>{" "}
              {datasAccepted.members.guest_detail.full_name.toUpperCase()}
            </p>
            <p className="detail_desc_name">
              <span className="letter_title_desc">Meeting begins in:</span>{" "}
              {getScheduleTimeDifference(
                `${datasAccepted?.schedule?.scheduled_date}T${datasAccepted?.schedule?.scheduled_time}Z`
              )}
            </p>
          </div>
        );
      });
    }
  };

  return (
    <>
      <div className="row row-meeting-wrapper">
        <div className="col-lg-9 col-md-12 col-sm-12 meeting-wrapper">
          <Dashelement className="features meeting-wrapper">
            <Calendar
              className="custom"
              localizer={localizer}
              defaultDate={new Date()}
              defaultView="month"
              events={eventList}
              style={{ height: "80vh", width: "100%" }}
              components={{
                event: Event,
              }}
              onNavigate={handleNavigate}
            />
          </Dashelement>
        </div>
        <div className="col-lg-3 col-md-12 col-sm-12">
          <Dashelement className="features demo-request-wrapper">
            <h5 className="text-center mt-3">
              Demo Requests
              {demoRequests.length > 0 && <RequestDot />}
            </h5>
            <div className="demo_request_wrapper_main">
              <div className="request_wrapper_custom">{getDemoRequest()}</div>
            </div>
            {user.role === 3 && (
              <>
                <div className="request-title-wrapper">
                  <h5 className="text-center my-3 ">Scheduled</h5>
                </div>
                <hr />
                <div className="scheduled_wrapper">{getOtherAccepted()}</div>
              </>
            )}
          </Dashelement>
        </div>
      </div>
    </>
  );
};

export default MyMeetings;

const Event = ({ event }) => {
  let { user } = useContext(AuthContext);
  let { hostCancelAccepted, userDemos, userAcceptedDemos } =
    useContext(DemoContext);
  let { setDemoId } = useContext(VideoContext);
  const [showA, setShowA] = useState(false);
  const [show, setShow] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [watchMeeting, setWatchMeeting] = useState(null);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showStartedModal, setStartedShowModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

    /**
   * This function is a for checkbox 
   *
   * @param {e} event for checkbox
   * @return {void}
   */
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
    e.stopPropagation();
  };
  const handleShowRecording = () => {
    let modalToClose = document.getElementById("popover-trigger-click-close");
    modalToClose.hidden = true;
    setShowVideoModal(true);
  };
  const handleCloseRecording = () => setShowVideoModal(false);
  const handleClose = () => setShow(false);
  const navigate = useNavigate();
  let overlayRef = useRef(null);
  const handleShow = () => {
    overlayRef?.current?.click();
    setShow(true);
  };
  const [utcDate, utcTime] = convertUTCToLocal(
    event.myCust.schedule.scheduled_date +
      "T" +
      event.myCust.schedule.scheduled_time
  );
  const a = calculateDifferenceToday(utcDate + " " + utcTime, "minutes");
  const calendarDate = moment(utcDate + " " + utcTime).format(
    "YYYYMMDDTHHmmss"
  );
  const utcTimeAMPM = moment(calendarDate).format("YYYY-MM-DD, hh:mm A");
  const created_atAMPM = moment(event?.myCust?.created_at).format(
    "YYYY-MM-DD, hh:mm A"
  );
  const started_atAMPM = moment(event?.myCust?.started_at).format(
    "YYYY-MM-DD, hh:mm A"
  );
  const calendarDateend = moment(calendarDate)
    .add(30, "minutes")
    .format("YYYYMMDDTHHmmss");

  const viewMeetingRecordings = () => {
    let response = fetch(event?.myCust?.recorded_video?.cloudinary_file);
    response
      .then(function (res) {
        if (res && res.status === 200) {
          setWatchMeeting(res?.url);
        } else {
          setWatchMeeting(event?.myCust?.recorded_video?.recorded_video);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  /**
   * This function is a cancel function for closing the modal window
   *
   * @param {void}
   * @return {void}
   */
  const handleCancelJoin = () => {
    const modalToClose = document.getElementById("popover-trigger-click-close");
    modalToClose.hidden = true;
    setIsChecked(false);
    setShowModal(false);
  };

  /**
   * This function is a cancel function for closing the modal window
   *
   * @param {void}
   * @return {void}
   */

  const handleCancelStartedJoin = () => {
    const modalToClose = document.getElementById("popover-trigger-click-close");
    modalToClose.hidden = true;
    setIsChecked(false);
    setStartedShowModal(false);
  };

  /**
   * This function is used to navigate to the waiting room when the call is already being started by the host
   *
   * @return {void}
   */

  const handleStartedJoinCall = () => {
    setEnabled(false);
    setDemoId(event.myCust.id);
    localStorage.setItem("demoId", event.myCust.id);
    navigate(`/demo/waiting-room/${event.myCust.id}`, {
      state: {
        eventDetail: event.myCust,
        direct: "yes",
      },
    });
    setEnabled(true);
  };

  /**
   * This function is used to navigate to the waiting room when the host joins the call
   *
   * @return {void}
   */

  const handleJoinCall = () => {
    setEnabled(false);
    setDemoId(event.myCust.id);
    localStorage.setItem("demoId", event.myCust.id);
    navigate(`/demo/waiting-room/${event.myCust.id}`);
    setEnabled(true);
  };

  useEffectOnce(() => {
    viewMeetingRecordings();
  }, []);

  let popoverClickRootClose = (
    <Popover id="popover-trigger-click-close">
      <p className="user-name">
        Guest: {event.myCust.members.guest_detail.full_name}
      </p>
      {(user.role === 2 ||
        user.role === 4 ||
        user.role === 5 ||
        user.role === 6) && (
        <>
          <p className="user-name">
            Host &nbsp;: {event?.myCust?.members?.host_detail?.first_name}{" "}
            {(event?.myCust?.members?.host_detail?.last_name).charAt(0)}.
          </p>
          <h5>{event.myCust.product.name}</h5>
          <p>
            Demo Request (
            {event.myCust.soonest_at ? "Soonest At" : "Date Picked"}) :
            <br />
            <span>{created_atAMPM}</span>
          </p>
          <p>
            Accepted at: <br />
            <span>{utcTimeAMPM}</span>
          </p>
          {event.myCust.started_at && (
            <p>
              Started at: <br />
              <span>{started_atAMPM}</span>
            </p>
          )}
        </>
      )}

      {user.role === 3 && (
        <>
          <h5>{event.myCust.product && event.myCust.product.name}</h5>
          <p>
            Accepted at: <br />
            <span>{utcTimeAMPM}</span>
          </p>
        </>
      )}

      {user.role === 2 ||
      user.role === 3 ||
      user.role === 4 ||
      user.role === 5 ||
      user.role === 6 ? (
        <>
          {a <= 4 && a >= -9 ? (
            <>
              {user.role === 3 ? (
                <button
                  onClick={() => {
                    const modalToClose = document.getElementById(
                      "popover-trigger-click-close"
                    );
                    modalToClose.hidden = true;
                    setShowModal(true);
                  }}
                  className="btn btn-primary"
                >
                  Join Demo
                </button>
              ) : (
                <p>Meeting in Progress</p>
              )}
              <Modal
                title="Terms and Conditions"
                open={showModal}
                onOk={handleJoinCall}
                onCancel={handleCancelJoin}
                maskClosable={handleCancelJoin}
                footer={[
                  <Button key="cancel" onClick={handleCancelJoin}>
                    Cancel
                  </Button>,
                  <Button
                    key="join"
                    type="primary"
                    onClick={handleJoinCall}
                    disabled={!isChecked}
                  >
                    Join
                  </Button>,
                ]}
              >
                <Checkbox onChange={handleCheckboxChange}>
                  I accept the{" "}
                  <button
                    onClick={() => {
                      navigate("/user/settings?page=term");
                    }}
                    className="link-button"
                  >
                    Terms and Conditions
                  </button>
                  .
                </Checkbox>
              </Modal>
            </>
          ) : a >= 4 ? (
            <>
              {user.role === 3 ? (
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={`https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${calendarDate}%2F${calendarDateend}&text=${event.myCust.product.name}%20Demo`}
                  title="Save Event in my Calendar"
                  className="add_to_calendar_wrapper"
                >
                  Add to Calendar <Google />
                </a>
              ) : null}
            </>
          ) : a < -9 ? (
            <div className="expired_meeting_date">
              {user.role === 3 && event?.myCust.status === "ACCEPTED" ? (
                <>
                  Meeting Expired!<p>Reschedule or Cancel</p>
                </>
              ) : user.role === 3 && event?.myCust.status === "STARTED" ? (
                <>
                  <button
                    onClick={() => {
                      const modalToClose = document.getElementById(
                        "popover-trigger-click-close"
                      );
                      modalToClose.hidden = true;
                      setStartedShowModal(true);
                    }}
                    className="btn btn-primary"
                    disabled={utcDate < new Date().toISOString().split("T")[0]}
                  >
                    Join Demo
                  </button>
                  <Modal
                    title="Terms and Conditions"
                    open={showStartedModal}
                    onOk={handleStartedJoinCall}
                    onCancel={handleCancelStartedJoin}
                    maskClosable={handleCancelStartedJoin}
                    footer={[
                      <Button key="cancel" onClick={handleCancelStartedJoin}>
                        Cancel
                      </Button>,
                      <Button
                        key="join"
                        type="primary"
                        onClick={handleStartedJoinCall}
                        disabled={!isChecked}
                      >
                        Join
                      </Button>,
                    ]}
                  >
                    <Checkbox onChange={handleCheckboxChange}>
                      I accept the{" "}
                      <button
                        onClick={() => {
                          navigate("/user/settings?page=term");
                        }}
                        className="link-button"
                      >
                        Terms and Conditions
                      </button>
                      .
                    </Checkbox>
                  </Modal>
                </>
              ) : (user.role === 2 ||
                  user.role === 4 ||
                  user.role === 5 ||
                  user.role === 6) &&
                event?.myCust.status === "ACCEPTED" ? (
                <>Meeting Missed (Host)</>
              ) : (user.role === 2 ||
                  user.role === 4 ||
                  user.role === 5 ||
                  user.role === 6) &&
                event?.myCust.status === "ENDED" ? (
                <>
                  {event?.myCust.members.guest_detail.attend_meeting ? (
                    <>
                      <div className="meeting-link">
                        <button
                          className=" btn btn-primary btn-sm-primary btn-paid"
                          onClick={handleShowRecording}
                        >
                          Watch Recording
                        </button>
                        <VideoPlayerModal
                          show={showVideoModal}
                          handleClose={handleCloseRecording}
                          recordingUrl={
                            event?.myCust?.recorded_video?.cloudinary_file ===
                              null &&
                            event?.myCust?.recorded_video?.recorded_video ===
                              null
                              ? null
                              : watchMeeting
                          }
                        />
                      </div>
                      <p>Meeting Concluded</p>
                    </>
                  ) : (
                    <>Meeting Missed (Guest)</>
                  )}{" "}
                </>
              ) : (
                <p>Hasn't happened yet</p>
              )}
            </div>
          ) : null}
          {user.role === 3 && (
            <div className="button-wrapper d-flex">
              <button
                disabled={
                  !enabled || utcDate < new Date().toISOString().split("T")[0]
                }
                className="btn"
                onClick={() => {
                  const response = hostCancelAccepted(event.myCust.id);
                  response
                    .then(function (response) {
                      if (response.status === 200) {
                        userDemos();
                        userAcceptedDemos();
                      }
                    })
                    .catch(function (error) {
                      userDemos();
                      userAcceptedDemos();
                      setShowA(!showA);
                    });
                }}
              >
                Cancel
              </button>
              <button
                className="btn"
                disabled={utcDate < new Date().toISOString().split("T")[0]}
                onClick={handleShow}
              >
                Reschedule
              </button>
            </div>
          )}
          <Toast show={showA} onClose={() => setShowA(!showA)}>
            <Toast.Header>
              <strong className="me-auto">Failed!</strong>
            </Toast.Header>
            <Toast.Body>This demo couldn't be canceled.</Toast.Body>
          </Toast>
        </>
      ) : null}
    </Popover>
  );
  return (
    <div>
      <div>
        <OverlayTrigger
          id="help"
          trigger={"click"}
          rootClose
          placement="top"
          overlay={popoverClickRootClose}
        >
          <div ref={overlayRef}>{event.title}</div>
        </OverlayTrigger>
        <RescheduleModal
          showStatus={show}
          clickStatus={handleClose}
          title={event.myCust.members.guest_detail.first_name}
          eventDetail={event.myCust}
        />
      </div>
    </div>
  );
};
