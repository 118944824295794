import react, { useContext, useState } from "react";
import { useEffectOnce } from "../../hooks/useEffectOnce";
import ProfileContext from "../../context/ProfileContext";
import { formatDate } from "../../utils/dateFormatter";
import AuthContext from "../../context/AuthContext";

const RecordingsToolbar = ({ updateRecordListings }) => {
  const [disabledSelectOption, setDisabledSelectOption] = useState(true);
  const [storeList, setStoreList] = useState([]);
  const [filterSearch, setFilterSearch] = useState({});
  const [selectedDate, setSelectedDate] = useState(0);
  const [inputExactDate, setInputExactDate] = useState("");
  const [isRevenue, setIsRevenue] = useState("none");
  const [isMissed, setIsMissed] = useState(null);
  const [isRecent, setIsRecent] = useState(true);
  const [message, setMessage] = useState("");
  const { getStoreList } = useContext(ProfileContext);
  const { user } = useContext(AuthContext);

  const handleChange = (e) => {
    setIsRevenue(e.target.value);
    if (e.target.value !== "") {
      setDisabledSelectOption(false);
    }

    if (e.target.name === "revenue-filter") {
      setFilterSearch({
        ...filterSearch,
        revenue:
          e.target.value === "true"
            ? "true"
            : e.target.value === "none"
            ? null
            : e.target.value === "false"
            ? "false"
            : null,
      });
      updateRecordListings({
        ...filterSearch,
        revenue:
          e.target.value === "true"
            ? "true"
            : e.target.value === "none"
            ? null
            : e.target.value === "false"
            ? "false"
            : null,
      });
      // updateRecordListings({
      //     revenue: e.target.value === "true"
      // })
    }

    if (e.target.name === "company-filter") {
      setFilterSearch({
        ...filterSearch,
        store: e.target.value,
      });
      updateRecordListings(filterSearch);
    }
  };

  const handleSearch = () => {
    updateRecordListings(filterSearch);
  };

  const handleMissedMeetingsFilter = () => {
    updateRecordListings({
      ...filterSearch,
      is_missed: false,
    });
    setIsMissed(false);
    setFilterSearch({
      ...filterSearch,
      is_missed: false,
    });
  };

  const handleAttentedMeetingsFilter = () => {
    updateRecordListings({
      ...filterSearch,
      is_missed: null,
    });
    setIsMissed(null);
    setFilterSearch({
      ...filterSearch,
      is_missed: null,
    });
  };

  const handleRecentFilter = () => {
    updateRecordListings({
      ...filterSearch,
      order: !isRecent,
    });
    setIsRecent(!isRecent);
    setFilterSearch({
      ...filterSearch,
      order: isRecent,
    });
  };

  const handleClear = () => {
    setSelectedDate(0);
    setMessage("");
    setIsRevenue("none");
    setInputExactDate("");
    setIsMissed(null);
    setFilterSearch({
      ...filterSearch,
      order: true,
      limit: 20,
      search: null,
      is_missed: null,
      revenue: null,
      date: "",
      timeline: "",
    });
    updateRecordListings({
      ...filterSearch,
      order: true,
      limit: 20,
      search: null,
      is_missed: null,
      revenue: null,
      date: "",
      timeline: "",
    });
  };

  const handleSearchText = (event) => {
    setMessage(event.target.value);
    setFilterSearch({
      ...filterSearch,
      search: event.target.value,
    });
  };

  useEffectOnce(() => {
    if (user.role === 1) {
      const getStores = async () => {
        const store = await getStoreList();
        return store.data.data;
      };
      getStores()
        .then((data) => {
          setStoreList(data?.results);
        })
        .catch((e) => {
          console.log("error while getting store list", e);
        });
    }
  }, []);

  const removeTimelineFromFilter = (date, timeline, all) => {
    if (date) {
      let newFilter = { ...filterSearch };
      delete newFilter.date;
      setFilterSearch(newFilter);
    }
    if (timeline) {
      let newFilter = { ...filterSearch };
      delete newFilter.timeline;
      setFilterSearch(newFilter);
    }
    if (all) {
      setFilterSearch({ limit: 8, order: true });
    }
  };

  const handleDateSort = (sortDate) => {
    setSelectedDate(sortDate);
    let date, timeline;
    switch (sortDate) {
      case 0:
        removeTimelineFromFilter(false, false, true);
        updateRecordListings({
          ...filterSearch,
          date: "",
          timeline: "",
        });
        break;
      case 1:
        date = new Date();
        date = formatDate(date);
        removeTimelineFromFilter(false, true, false);
        updateRecordListings({
          ...filterSearch,
          date: date,
          timeline: "",
        });
        break;
      case -1:
        date = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24);
        date = formatDate(date);
        removeTimelineFromFilter(false, true, false);
        updateRecordListings({
          ...filterSearch,
          date: date,
          timeline: "",
        });
        break;
      case 7:
        setSelectedDate(7);
        removeTimelineFromFilter(true, false, false);
        updateRecordListings({
          ...filterSearch,
          timeline: "week",
          date: "",
        });
        break;
      case 30:
        setSelectedDate(30);
        removeTimelineFromFilter(true, false, false);
        updateRecordListings({ ...filterSearch, timeline: "month" });
        break;
      case 60:
        setSelectedDate(60);
        removeTimelineFromFilter(true, false, false);
        updateRecordListings({
          ...filterSearch,
          timeline: "prev_month",
          date: "",
        });
        break;
      default:
        setSelectedDate(-2);
        setInputExactDate(sortDate);
        date = new Date(new Date(sortDate));
        date = formatDate(date);
        removeTimelineFromFilter(false, true, false);
        updateRecordListings({ ...filterSearch, date: date });
        break;
    }
    if (sortDate < 90) {
      setInputExactDate("");
    }
  };

  const chooseDate = () => {
    const today = new Date();
    const maxDate = today.toISOString().split("T")[0];
    const minDate = new Date(today.getTime() - 90 * 24 * 60 * 60 * 1000)
      .toISOString()
      .split("T")[0];

    return (
      <input
        className={`btn input-label py-2 px-3 ${
          selectedDate === -2 ? "selected-tab" : ""
        }`}
        value={inputExactDate}
        type="date"
        id="recording-date"
        name="recording-date"
        min={minDate}
        max={maxDate}
        onChange={(e) => {
          handleDateSort(e.target.value);
        }}
      />
    );
  };

  return (
    <>
      <div className="duration-toolbar d-flex flex-wrap">
        <div
          className={`input-label py-2 px-3 ${
            selectedDate === 0 ? "selected-tab" : ""
          }`}
          onClick={() => {
            handleDateSort(0);
          }}
        >
          All
        </div>
        <div
          className={`input-label py-2 px-3 ${
            selectedDate === 1 ? "selected-tab" : ""
          }`}
          onClick={() => {
            handleDateSort(1);
          }}
        >
          Today
        </div>
        <div
          className={`input-label py-2 px-3 ${
            selectedDate === -1 ? "selected-tab" : ""
          }`}
          onClick={() => {
            handleDateSort(-1);
          }}
        >
          Yesterday
        </div>
        <div
          className={`input-label py-2 px-3 ${
            selectedDate === 7 ? "selected-tab" : ""
          }`}
          onClick={() => {
            handleDateSort(7);
          }}
        >
          Last 7 days
        </div>
        <div
          className={`input-label py-2 px-3 ${
            selectedDate === 30 ? "selected-tab" : ""
          }`}
          onClick={() => {
            handleDateSort(30);
          }}
        >
          Last 30 days
        </div>
        <div
          className={`input-label py-2 px-3 ${
            selectedDate === 60 ? "selected-tab" : ""
          }`}
          onClick={() => {
            handleDateSort(60);
          }}
        >
          Last month
        </div>
        <div>{chooseDate()}</div>
      </div>
      <div className="recording-toolbar-container d-flex justify-content-center">
        <div className="d-flex flex-wrap gap-2 m-0 my-3 w-100">
          <div className="col-12 ps-0 pe-2 col-md-6 col-lg-3 mt-md-2 mr-md-1 d-flex align-items-center search">
            {/* <label htmlFor="search-bar" className="input-label px-2">
              Search
            </label> */}
            <div>
              <input
                type="text"
                name="search-bar"
                placeholder="Search here..."
                className="search-input form-control"
                onChange={handleSearchText}
                value={message}
              />
            </div>
          </div>
          {(user.role === 2 ||
            user.role === 4 ||
            user.role === 5 ||
            user.role === 6) && (
            <>
              <div className="mt-md-2 mr-md-1 d-flex align-items-center recent-meeting-filter">
                <label
                  htmlFor="recent-meeting-filter"
                  className="input-label px-2 "
                  onClick={handleRecentFilter}
                >
                  {isRecent === true ? "Oldest to Newest" : "Newest to Oldest"}
                </label>
              </div>
              <div className="mt-md-2 mr-md-1 d-flex align-items-center missed-meeting-filter">
                <label
                  htmlFor="missed-meeting-filter"
                  className={
                    isMissed === null ? "input-label px-2 " : "p-1 selected-tab"
                  }
                  onClick={
                    isMissed === null
                      ? handleMissedMeetingsFilter
                      : handleAttentedMeetingsFilter
                  }
                >
                  Exclude Missed Meetings
                </label>
              </div>
            </>
          )}
          {user.role === 1 && (
            <div className=" mt-md-2 mr-md-1 d-flex align-items-center company-filter">
              <label htmlFor="company-filter" className="input-label px-2">
                Company
              </label>
              <select
                className="input-select"
                name="company-filter"
                id="company-filter"
                onChange={handleChange}
                style={
                  disabledSelectOption
                    ? { color: "#D9D9D9" }
                    : { color: "black" }
                }
                defaultValue=""
              >
                <option value="" disabled>
                  Filter Company
                </option>
                {/*<option key={i} value="test-company-2">Purebrand App Store 6</option>*/}
                {storeList.map((val, i) => (
                  <option key={i} value={val.name}>
                    {val.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className="mt-md-2 mr-md-1 d-flex align-items-center revenue-filter">
            <label htmlFor="revenue-filter" className="input-label px-2">
              Revenue Generated
            </label>
            <select
              className="input-select"
              name="revenue-filter"
              id="revenue-filter"
              onChange={handleChange}
              style={
                disabledSelectOption ? { color: "#D9D9D9" } : { color: "black" }
              }
              value={isRevenue}
            >
              <option value="none">None</option>
              <option value="true">High to Low</option>
              <option value="false">Low to High</option>
            </select>
          </div>
          <div className=" mt-md-2 mr-md-1 d-flex align-items-center cta-btn-container">
            <button className="clear-btn" onClick={handleClear}>
              Clear
            </button>
            <button className="search-btn ms-2" onClick={handleSearch}>
              Search
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecordingsToolbar;
