import axiosInstance from "./axios";

export const toggleRecording = async (id, data) => {
  return axiosInstance.put(`/api/v1/demo-rec/${id}/`, data);
};

export const startVideocreds = async (id, payload) => {
  return axiosInstance.put(`/api/v1/demo-start/${id}/`, payload);
};

export const endVideoCall = async (id, payload) => {
  return axiosInstance.put(`/api/v1/demo-end/${id}/`, payload);
};

export const getRecordings = async (filter) => {
  return axiosInstance.get("/api/v1/demo-recordings/", { params: filter });
};
