import React, { useContext, useState } from 'react';
import { Modal, Button, message } from 'antd';
import './CreateAppModal.css';
import DotsContext from 'context/DotsContext';
import { createDotsApp } from 'Services/dotsService';
import { GENERIC_ERROR_MESSAGE } from 'utils/constants';

/**
 * This component renders a modal for confirming the store admin to create its app on dots
 *
 * @return {ReactNode} A React element that renders a modal for confirming the store admin to create its app on dots
 */

const AppModal = ({ appModalOpen, handleModalClose, getStoreAdminsStatus }) => {
  const { dotsStoreInfo } = useContext(DotsContext);

  const [messageApi, contextHolder] = message.useMessage();

  const [createAppLoading, setCreateAppLoading] = useState(false);

  const createApp = async () => {
    setCreateAppLoading(true);
    try {
      const payload = {
        store_name: dotsStoreInfo?.store_name,
      };
      await createDotsApp(payload);
      messageApi.open({
        type: 'success',
        content: 'Dots App has been successfully created',
      });
      setCreateAppLoading(false);
      handleModalClose();
      getStoreAdminsStatus();
    } catch (e) {
      console.log(e);
      setCreateAppLoading(false);
      messageApi.open({
        type: 'error',
        content:
          e?.response?.data?.message || e?.message || GENERIC_ERROR_MESSAGE,
      });
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        centered
        open={appModalOpen}
        className="create-app-modal"
        onCancel={() => {
          handleModalClose();
        }}
        footer={null}
      >
        <div>
          <span className="store-header">Store: </span>{' '}
          <span className="store-name">{dotsStoreInfo?.store_name}</span>
        </div>

        <Button
          type="primary"
          className="create-acc-btn"
          onClick={createApp}
          loading={createAppLoading}
          disabled={createAppLoading}
        >
          {' '}
          Create My App on Dots{' '}
        </Button>
      </Modal>
    </>
  );
};

export default AppModal;
