import React, { useContext, useState } from "react";
import DataContext from "context/DataContext";
import {
  PencilSquare,
  TrashFill,
  CheckSquareFill,
  XCircleFill,
} from "react-bootstrap-icons";
import PurebrandLoader from "components/Loader/PurebrandLoader";
import PurebrandToast from "components/Toasts/Toast";
import AuthContext from "context/AuthContext";

const TagList = ({ tags }) => {
  const {
    storeAdminProductListTag,
    updateStoreAdminProductListTag,
    deleteStoreAdminProductListTag,
  } = useContext(DataContext);
  const { user } = useContext(AuthContext);
  const [showToast, setShowToast] = useState(false);
  const [showToastDelete, setShowToastDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [editValue, setEditValue] = useState("");
  const [tagId, setTagId] = useState("");

  const toastPopup = () => {
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  };

  const handleCloseToast = () => {
    setShowToast(false);
  };

  const handleEdit = async () => {
    if (editValue !== tags?.name) {
      setIsLoading(true);
      try {
        let res = await updateStoreAdminProductListTag(tagId, {
          name: editValue,
        });
        if (res.status === 200) {
          setShowEdit(false);
          toastPopup();
          setEditValue("");
          setTagId("");
          await storeAdminProductListTag();
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(true);
      }
    }
  };

  const handleDelete = async (id) => {
    setIsLoading(true);
    try {
      let res = await deleteStoreAdminProductListTag(id);
      if (res) {
        setShowToastDelete(true);
        toastPopup();
        await storeAdminProductListTag();
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(true);
    }
  };

  if (isLoading) {
    <PurebrandLoader />;
  }
  return (
    <>
      <PurebrandToast
        show={showToast}
        onClose={handleCloseToast}
        title="Success"
        message={
          showToastDelete
            ? "Successfully Deleted Category."
            : "Successfully Edited Category."
        }
      />
      <tr>
        <td>
          {!showEdit ? (
            <div className="h6">{tags?.name}</div>
          ) : (
            <div>
              <input
                className="form-control"
                defaultValue={tags?.name}
                onChange={(e) => {
                  setEditValue(e?.target?.value);
                  setTagId(tags?.id);
                }}
              />
            </div>
          )}
        </td>
        {user.role !== 5 && (
          <td>
            <div className="d-flex justify-content-start">
              {showEdit ? (
                <>
                  {editValue && (
                    <div
                      className="p-1"
                      style={{
                        border: "1px solid #808080",
                        borderRadius: "5px",
                        marginRight: "10px",
                      }}
                    >
                      <CheckSquareFill
                        size={"20px"}
                        onClick={() => {
                          handleEdit();
                        }}
                        color="green"
                      />
                    </div>
                  )}
                  <div
                    className="p-1"
                    style={{
                      border: "1px solid #808080",
                      borderRadius: "5px",
                    }}
                  >
                    <XCircleFill
                      size={"20px"}
                      color="red"
                      onClick={() => {
                        setShowEdit(false);
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="p-1"
                    style={{
                      border: "1px solid #808080",
                      borderRadius: "5px",
                      marginRight: "10px",
                    }}
                  >
                    <PencilSquare
                      size={"20px"}
                      onClick={() => {
                        setShowEdit(true);
                      }}
                    />
                  </div>
                  <div
                    className="p-1"
                    style={{
                      border: "1px solid #808080",
                      borderRadius: "5px",
                    }}
                  >
                    <TrashFill
                      size={"20px"}
                      color="red"
                      onClick={() => {
                        handleDelete(tags?.id);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </td>
        )}
      </tr>
    </>
  );
};

export default React.memo(TagList);
