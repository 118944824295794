import { Icon } from "assets/images";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState, useContext } from "react";
import "./forgotpassword.css";
import * as Yup from "yup";
import AuthContext from "context/AuthContext";
import { Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const { forgotPassword } = useContext(AuthContext);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const handlesubmit = (data) => {
    const response = forgotPassword(data);
    response
      .then(function (response) {
        setError(null);
        setSubmitted(true);
      })
      .catch(function (error) {
        setError(error.response.data);
      });
    // setSubmitted(true);
  };
  return (
    <>
      <div className=" container forgot-password-wrapper">
        <div className="container information-wrapper">
          <div className="icon-wrapper text-start">
            <img className="image-logo-pb" src={Icon} alt="purebrand" />
          </div>
          <div className="reset-password-title text-start">
            <h3>Reset Password</h3>
          </div>
          {submitted === false ? (
            <ForgotPasswordForm handlesubmit={handlesubmit} />
          ) : (
            <PostForgotPassword />
          )}
          <div className="col-lg-4 mb-3 mt-3">
            {error ? (
              <Alert className="" key="danger" variant="danger">
                {error?.message}
              </Alert>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;

const ForgotPasswordForm = (props) => {
  const { handlesubmit } = props;
  const navigate = useNavigate();
  return (
    <>
      <div className="reset-password-text">
        <p>
          Enter your email below and we will email you a temporary reset link.
        </p>
        <p>The reset link will expire after 24 hours.</p>
      </div>
      <div className="forgot-password-form-wrapper">
        <Formik
          initialValues={{
            email: "",
          }}
          onSubmit={handlesubmit}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Invalid email address format")
              .required("Email is required"),
          })}
        >
          {({ touched, errors }) => (
            <Form>
              <div className="form-outline col-md-12 col-lg-12">
                <Field
                  type="email"
                  name="email"
                  placeholder="Enter Email"
                  className={`form-control ${
                    touched.email && errors.email ? "is-invalid" : ""
                  }`}
                />
                <ErrorMessage
                  component="div"
                  name="email"
                  className="invalid-feedback"
                />
              </div>

              <div className="return-link-wrapper">
                <p className="link-color d-flex ">
                  Want to go back?{" "}
                  <span onClick={() => navigate("/login")} className="">
                    Click me!
                  </span>{" "}
                </p>
              </div>
              <div className="button-wrapper">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

const PostForgotPassword = () => {
  return (
    <>
      <div className="check-email-text">
        <p>
          You have been emailed a temporary link that you can use to update your
          password. <br /> Please check your email account and click the link to
          change your password.
        </p>
        <div className="button-wrapper">
          <button
            onClick={() => (window.location.href = "/login")}
            className="btn btn-primary"
          >
            Go to login
          </button>
        </div>
      </div>
    </>
  );
};
