import { CsvLogo } from "assets/images";
import PurebrandLoader from "components/Loader/PurebrandLoader";
import CustomModal from "components/Modal/CustomModal";
import DemoContext from "context/DemoContext";
import { useEffectOnce } from "hooks/useEffectOnce";
import CsvView from "Pages/CSV/CsvView";
import React, { useContext, useState } from "react";
import { Table } from "react-bootstrap";
import { CaretDown, CaretUp } from "react-bootstrap-icons";
import { CustomCompaniesListAdmin } from "../HomePage/CustomListAdmin";

const Companies = () => {
  const {
    adminCompanyDataDashboard,
    adminDashboardCompanyData,
    nextAdminCompanyData,
    prevAdminCompanyData,
  } = useContext(DemoContext);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(true);
  const getHostListsAdmin = () => {
    if (adminDashboardCompanyData?.length < 1) {
      return (
        <tr>
          <td>No active companies!</td>
        </tr>
      );
    } else {
      return adminDashboardCompanyData?.map((dashData, index) => {
        return <CustomCompaniesListAdmin key={index} hostDetails={dashData} />;
      });
    }
  };
  const changeList = (params) => {
    setLoading(true);
    let res = adminCompanyDataDashboard(params);
    res.then(() => setLoading(false));
  };
  useEffectOnce(() => {
    let res = adminCompanyDataDashboard("?order=true");
    res.then(() => setLoading(false));
  }, []);
  if (loading) {
    return <PurebrandLoader />;
  } else {
    return (
      <>
        <div className="csv-wrapper text-end">
          <img
            className="csv-download"
            onClick={handleShow}
            src={CsvLogo}
            alt="csv"
          />
          <CustomModal
            showStatus={show}
            clickStatus={handleClose}
            buttonMessage="Close"
            closeStatus={handleClose}
            title="Photo"
            message={<CsvView type="company-detail" />}
            secondMessage="none"
            secondButton={false}
          />
        </div>
        <div className="admin-dashboard-list">
          <Table>
            <thead>
              <tr>
                <th>Company</th>
                <th scope="col">
                  {" "}
                  <div className="arrows">
                    Hosts{" "}
                    <div className="arrow-grid">
                      <CaretUp
                        onClick={() => changeList("?order=true&hosts=true")}
                      />
                      <CaretDown
                        onClick={() => changeList("?order=true&hosts=false")}
                      />
                    </div>
                  </div>
                </th>
                <th scope="col">
                  <div className="arrows">
                    Total showings{" "}
                    <div className="arrow-grid">
                      <CaretUp
                        onClick={() => changeList("?order=true&shows=true")}
                      />
                      <CaretDown
                        onClick={() => changeList("?order=true&shows=false")}
                      />
                    </div>
                  </div>
                </th>
                <th scope="col">
                  <div className="arrows">
                    Revenue{" "}
                    <div className="arrow-grid">
                      <CaretUp
                        onClick={() => changeList("?order=true&revenue=true")}
                      />
                      <CaretDown
                        onClick={() => changeList("?order=true&revenue=false")}
                      />
                    </div>
                  </div>
                </th>
                <th>Top Product</th>
              </tr>
            </thead>
            <tbody>{getHostListsAdmin()}</tbody>
          </Table>
        </div>
        {(nextAdminCompanyData !== null || prevAdminCompanyData !== null) && (
          <div className="button-wrapper-product text-center my-4">
            <button
              onClick={() => {
                changeList(new URL(prevAdminCompanyData).search);
              }}
              className="btn btn-outline-primary previous-button"
              disabled={prevAdminCompanyData === null}
            >
              Prev
            </button>
            <button
              onClick={() => {
                changeList(new URL(nextAdminCompanyData).search);
              }}
              className="btn btn-outline-primary previous-button"
              disabled={nextAdminCompanyData === null}
            >
              Next
            </button>
          </div>
        )}
      </>
    );
  }
};

export default Companies;
