import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import RangeSlider from "react-bootstrap-range-slider";

import "./addproduct.css";

import * as Yup from "yup";
import OptionalQuestion from "./OptionalQuestion";

const Ordering = (props) => {
  const [sliderValue, setSliderValue] = useState(props.data.orderOne);
  const [sliderValue2, setSliderValue2] = useState(props.data.orderTwo);
  const handleSubmit = (values) => {
    values.orderOne = parseInt(values.orderOne);
    values.orderTwo = parseInt(values.orderTwo);
    props.next(values);
  };
  const handlePrevious = (values) => {
    const p1 = values.customerServiceAnswer;
    values.customerServiceAnswer = p1.toString();
    props.prev(values);
  };
  return (
    <Formik
      initialValues={props.data}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape({
        orderOne: Yup.mixed().required(),
        orderTwo: Yup.mixed().required(),
      })}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <div className="product-form-wrapper">
            <div className="product-information-wrapper">
              <h3 className="text-start">Ordering</h3>
              <p className="text-start">We appreciate your honest feedback</p>
            </div>
            <div className="product-uploader">
              <div className="order-question-one">
                <div id="my-radio-group">
                  <p className="question-product">
                    Were you able to quickly navigate and find what what you
                    were looking for when shopping? *
                  </p>
                </div>
                <div className="slider-wrapper d-flex">
                  <p className="low-indicator">(Low) </p>

                  <RangeSlider
                    value={sliderValue}
                    min={1}
                    max={10}
                    step={1}
                    onChange={(e) => {
                      setFieldValue("orderOne", e.target.value);
                      setSliderValue(e.target.value);
                    }}
                  />
                  <p className="low-indicator">(High) </p>

                  <p className="value-box slider-value">{sliderValue}</p>
                </div>
              </div>
              <div className="optional-question">
                {/* {!(
                  values.orderOne === "9" ||
                  values.orderOne === "10" ||
                  values.orderOne === ""
                ) ? ( */}
                <OptionalQuestion
                  name="orderOneOpt"
                  message="Help us know more about your experience."
                />
                {/* ) : (
                  <></>
                )} */}
              </div>
              <div className="order-question-two">
                <div id="my-radio-group">
                  <p className="question-product">
                    Was the shopping experience clear and easy? *
                  </p>
                </div>
                <div className="slider-wrapper d-flex">
                  <p className="low-indicator">(Low) </p>

                  <RangeSlider
                    value={sliderValue2}
                    min={1}
                    max={10}
                    step={1}
                    onChange={(e) => {
                      setFieldValue("orderTwo", e.target.value);
                      setSliderValue2(e.target.value);
                    }}
                  />
                  <p className="low-indicator">(High) </p>

                  <p className="value-box slider-value">{sliderValue2}</p>
                </div>
              </div>
              <div className="optional-question">
                {/* {!(
                  values.orderTwo === "9" ||
                  values.orderTwo === "10" ||
                  values.orderTwo === ""
                ) ? ( */}
                <OptionalQuestion
                  name="orderTwoOpt"
                  message="Help us know more about your experience."
                />
                {/* ) : (
                  <></>
                )} */}
              </div>
            </div>
            <div className="button-wrapper-product text-end">
              <div
                onClick={() => handlePrevious(values)}
                className="btn btn-outline-primary previous-button"
              >
                Prev
              </div>
              <button className="btn btn-outline-primary" type="submit">
                Next
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Ordering;
