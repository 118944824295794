import axios from "axios";
import axiosInstance from "./axios";
import * as LocalStorageService from "../utils/localStorageService";

export const login = async (loginForm) =>
  axiosInstance.post(`/api/v1/auth/login/`, loginForm);

export const signup = async (signupForm) =>
  axiosInstance.post(`/api/v1/auth/signup/`, signupForm);

export const logout = async (data) =>
  axiosInstance.post(`/api/v1/auth/logout/`, data);

export const refresh = async (data) =>
  axiosInstance.post(`/api/v1/auth/refresh/`, data);

export const updateLoggedInUserProfile = async () =>
  axiosInstance.get(`/api/v1/profile/`);

export const updateLoginUserProfile = async (data, id) =>
  axiosInstance.put(`/api/v1/profile/${id}/`, data);

export const userProductReview = async (params) =>
  axiosInstance.get(`/api/v1/product-review/${params}`);

export const resendEmailVerification = async () =>
  axiosInstance.get(`/api/v1/auth/verification-email/`);

export const verifyEmail = async (data, param) =>
  axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/auth/verify-account/${param}/`,
    data
  );

export const changeUserPassword = async (data) =>
  // axiosInstance.post(`/api/v1/auth/change-password/`, data);
  axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/auth/change-password/`,
    data,
    {
      headers: {
        Authorization: "Bearer " + LocalStorageService.getAccessToken(),
      },
    }
  );

export const forgotUserPassword = async (data) =>
  axiosInstance.post(`/api/v1/auth/forgot-password/`, data);

export const resetUserPassword = async (data, passuid, passtoken) =>
  // axiosInstance.post(
  //   `/api/v1/auth/reset-password/${passuid}/${passtoken}/`,
  //   data
  // );
  axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/auth/reset-password/${passuid}/${passtoken}/`,
    data
  );

export const getOtpProfile = async (data) =>
  axiosInstance.post(`/api/v1/user-otp/`, data);

export const putUserOtpProfile = async (data, id, params) =>
  axiosInstance.put(`/api/v1/user-otp/${id}/${params}`, data);

export const getUserPayoutData = async (params) =>
  axiosInstance.get(`/api/v1/payout-list/${params}`);

export const supportServiceMail = async (data) =>
  axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/support-mail/`, data);

export const getHostDetailStore = async (id) =>
  axiosInstance.get(
    `${process.env.REACT_APP_BASE_URL}/api/v1/product-user/${id}/`
  );

export const demoUserPublicProfile = async (id) =>
  axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/v1/demo-public-profile/${id}/`
  );

/**
 * This function is used to call an api that gets the public details of a host
 *
 * @param {string} id Id of the host
 * @returns {Object} Response object
 */

export const hostUserPublicProfile = async (id) =>
  axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/v1/host-public-profile/${id}/`
  );

export const createUserShortcut = async (body, param) =>
  axios.post(
    `${process.env.REACT_APP_BASE_URL}/api/v1/user-create/${param}`,
    body
  );

export const checkUserExists = async (body) =>
  axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/user-exists/`, body);

/**
 * This function is used to call an api which checks the email verification of the host.
 *
 * @returns {Object} Response object
 */

export const checkUserEmailVerificationStatus = async (body) =>
  axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/v1/user-email-verification-status/`, body);
