import VideoPlayerModal from "components/Modal/VideoPlayerModal";
import CustomModal from "components/Modal/CustomModal";
import PhotoHandler from "Pages/dashboard/Profile/PhotoHandler";
import React, { useState } from "react";
import { Card } from "react-bootstrap";

const PublicVideoListing = ({ medias }) => {
  const [showVideoModal, setShowVideoModal] = useState(false);
  // const [recordingDetails, setRecordingDetails] = useState({});
  const handleCloseVideo = () => setShowVideoModal(false);
  const handleShowVideo = () => setShowVideoModal(true);
  if (medias.video) {
    return (
      <div className="col-sm-6 col-md-6 col-xl-3 mt-3">
        <Card className="gallery-item " tabIndex="0" onClick={handleShowVideo}>
          <video
            playsInline
            autoPlay={false}
            // muted
            loop
            className="gallery-image"
          >
            <source src={medias.video.file} type="video/mp4" />
          </video>
        </Card>
        <VideoPlayerModal
          show={showVideoModal}
          handleClose={handleCloseVideo}
          recordingUrl={medias.video.file}
          recordingName={medias.video.file + " Video"}
        />
      </div>
    );
  } else {
    return <></>;
  }
};

export default PublicVideoListing;

export const PublicPhotoListing = ({ medias }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  if (medias.picture) {
    return (
      <div className="col-sm-6 col-md-6 col-xl-3 mt-3" tabIndex="0">
        <div className="gallery-item " onClick={handleShow}>
          <img
            src={medias?.picture?.file}
            className="gallery-image imageA"
            alt="gallery"
          />
        </div>
        <CustomModal
          showStatus={show}
          clickStatus={handleClose}
          buttonMessage="Close"
          closeStatus={handleClose}
          title="Photo"
          message={
            <div className="photo-viewer-full text-center">
              <img
                src={medias?.picture?.file}
                alt="profile"
                className="photo-full-view"
              />
            </div>
          }
          secondMessage="none"
          secondButton={false}
        />
      </div>
    );
  }
};
