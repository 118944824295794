import { Icon } from "assets/images";
import DataContext from "context/DataContext";

import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { Alert } from "react-bootstrap";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { passwordRegExp } from "../signup/Host/SignupForm";

const passwordValidator = Yup.object().shape({
  password: Yup.string()
    .matches(
      passwordRegExp,
      "Must have 8 characters at least with number and a special character"
    )
    .required("Password is required"),
  confirm_password: Yup.string().when("password", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("password")],
      "Both password need to be the same"
    ),
  }),
});

const AdminOnboard = () => {
  const { completeAssignNewRole } = useContext(DataContext);
  const [error, setError] = useState(null);
  const [password, setPassword] = useState(true);
  const [password2, setPassword2] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.pathname;
  var data = params.split("/");
  if (data[2] && data[3]) {
    return (
      <>
        <div className="container reset-password-wrapper">
          <div className="container instruction-wrapper">
            <div className="icon-wrapper text-start">
              <img className="image-logo-pb" src={Icon} alt="purebrand" />
            </div>
            <div className="reset-password-title text-start">
              <h3>Complete signup</h3>
              <p>
                Use 8 or more characters with a mix of letters, numbers and
                symbols
              </p>
            </div>
            <div className="reset-form-wrapper">
              <Formik
                initialValues={{
                  password: "",
                  confirm_password: "",
                  uid: data[2],
                  userToken: data[3],
                }}
                onSubmit={(value, { setSubmitting }) => {
                  const response = completeAssignNewRole(value);
                  response
                    .then(function (response) {
                      navigate("/login");
                      setError(null);
                    })
                    .catch(function (error) {
                      console.log(error);
                      setError(error?.response);
                    });
                  setSubmitting(false);
                }}
                validationSchema={passwordValidator}
              >
                {({ touched, errors, isSubmitting }) => (
                  <Form>
                    <div className="col-md-12 mb-3">
                      <div className="password-enter-wrapper d-flex">
                        <Field
                          type={`${password ? "password" : "text"}`}
                          name="password"
                          placeholder="Enter password"
                          className={`form-control ${
                            touched.password && errors.password
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <div
                          className="password-visibility"
                          onClick={() => setPassword(!password)}
                        >
                          {password ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="38"
                              height="38"
                              viewBox="0 0 38 38"
                            >
                              <path d="M2.03381935,11.8192361 C4.16808919,6.31506648 10.3602678,3.58322297 15.8644374,5.71749281 C18.6654288,6.80359152 20.8800819,9.01824467 21.9661807,11.8192361 C22.0112731,11.9355272 22.0112731,12.0644728 21.9661807,12.1807639 C19.8319108,17.6849335 13.6397322,20.416777 8.1355626,18.2825072 C5.3345712,17.1964085 3.11991805,14.9817553 2.03381935,12.1807639 C1.98872688,12.0644728 1.98872688,11.9355272 2.03381935,11.8192361 Z M8.49709046,17.3501459 C13.4256883,19.2612348 18.9628618,16.8680219 20.9616531,12.0000475 C19.9532865,9.54665483 17.9813179,7.6108696 15.5029095,6.64985412 C10.5743117,4.73876518 5.0371382,7.13197809 3.03834692,11.9999525 C4.04671349,14.4533452 6.01868212,16.3891304 8.49709046,17.3501459 Z M12,16 C9.790861,16 8,14.209139 8,12 C8,9.790861 9.790861,8 12,8 C14.209139,8 16,9.790861 16,12 C16,14.209139 14.209139,16 12,16 Z M12,15 C13.6568542,15 15,13.6568542 15,12 C15,10.3431458 13.6568542,9 12,9 C10.3431458,9 9,10.3431458 9,12 C9,13.6568542 10.3431458,15 12,15 Z" />
                            </svg>
                          ) : (
                            <svg
                              fill="none"
                              height="38"
                              stroke-width="1.5"
                              viewBox="0 0 38 38"
                              width="38"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3 3L21 21"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M10.5 10.6771C10.1888 11.0296 10 11.4928 10 12C10 13.1045 10.8954 14 12 14C12.5072 14 12.9703 13.8112 13.3229 13.5"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M7.36185 7.5611C5.68002 8.73968 4.27894 10.4188 3 12C4.88856 14.991 8.2817 18 12 18C13.5499 18 15.0434 17.4772 16.3949 16.6508"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M12 6C16.0084 6 18.7015 9.1582 21 12C20.6815 12.5043 20.3203 13.0092 19.922 13.5"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          )}
                        </div>
                      </div>
                      <ErrorMessage
                        component="div"
                        name="password"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="col-md-12 mb-3">
                      <div className="password-enter-wrapper d-flex">
                        <Field
                          type={`${password2 ? "password" : "text"}`}
                          name="confirm_password"
                          placeholder="Confirm password"
                          className={`form-control ${
                            touched.confirm_password && errors.confirm_password
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <div
                          className="password-visibility"
                          onClick={() => setPassword2(!password2)}
                        >
                          {password2 ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="38"
                              height="38"
                              viewBox="0 0 38 38"
                            >
                              <path d="M2.03381935,11.8192361 C4.16808919,6.31506648 10.3602678,3.58322297 15.8644374,5.71749281 C18.6654288,6.80359152 20.8800819,9.01824467 21.9661807,11.8192361 C22.0112731,11.9355272 22.0112731,12.0644728 21.9661807,12.1807639 C19.8319108,17.6849335 13.6397322,20.416777 8.1355626,18.2825072 C5.3345712,17.1964085 3.11991805,14.9817553 2.03381935,12.1807639 C1.98872688,12.0644728 1.98872688,11.9355272 2.03381935,11.8192361 Z M8.49709046,17.3501459 C13.4256883,19.2612348 18.9628618,16.8680219 20.9616531,12.0000475 C19.9532865,9.54665483 17.9813179,7.6108696 15.5029095,6.64985412 C10.5743117,4.73876518 5.0371382,7.13197809 3.03834692,11.9999525 C4.04671349,14.4533452 6.01868212,16.3891304 8.49709046,17.3501459 Z M12,16 C9.790861,16 8,14.209139 8,12 C8,9.790861 9.790861,8 12,8 C14.209139,8 16,9.790861 16,12 C16,14.209139 14.209139,16 12,16 Z M12,15 C13.6568542,15 15,13.6568542 15,12 C15,10.3431458 13.6568542,9 12,9 C10.3431458,9 9,10.3431458 9,12 C9,13.6568542 10.3431458,15 12,15 Z" />
                            </svg>
                          ) : (
                            <svg
                              fill="none"
                              height="38"
                              stroke-width="1.5"
                              viewBox="0 0 38 38"
                              width="38"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3 3L21 21"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M10.5 10.6771C10.1888 11.0296 10 11.4928 10 12C10 13.1045 10.8954 14 12 14C12.5072 14 12.9703 13.8112 13.3229 13.5"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M7.36185 7.5611C5.68002 8.73968 4.27894 10.4188 3 12C4.88856 14.991 8.2817 18 12 18C13.5499 18 15.0434 17.4772 16.3949 16.6508"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M12 6C16.0084 6 18.7015 9.1582 21 12C20.6815 12.5043 20.3203 13.0092 19.922 13.5"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          )}
                        </div>
                      </div>
                      <ErrorMessage
                        component="div"
                        name="confirm_password"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-md-3">
                      {error ? (
                        <Alert className="" key="danger" variant="danger">
                          {error?.data?.message
                            ? error?.data?.message[0]
                            : "Reset password error"}
                        </Alert>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="button-wrapper">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Please wait..." : "Add password"}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <Navigate to="/login" />;
  }
};

export default AdminOnboard;
