import axios from "axios";
import * as LocalStorageService from "../utils/localStorageService";

const deviceId = LocalStorageService.getItem("deviceId");

export const geoCoords = async (values) =>
  axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.REACT_APP_GOOGLE_MAP_KEY}&address=${values.address},${values.city},${values.state}`
  );

export const getShopifyProductList = async (body) => {
  const response = await axios({
    method: "post",
    url: `${process.env.REACT_APP_SHOPIFY_URL}/api/productlistbyhostemails`,
    data: body,
  });
  return response;
};

export const getShopifyProductListPublic = async (body) => {
  const response = await axios({
    method: "post",
    url: `${process.env.REACT_APP_SHOPIFYPUBLIC_URL}/api/productlistbyhostemails`,
    data: body,
  });
  return response;
};

export const getShopifyProductListStore = async (body) => {
  const response = await axios({
    method: "post",
    url: `${process.env.REACT_APP_SHOPIFY_URL}/api/productlistbyemails`,
    data: body,
  });
  return response;
};

export const getShopifyProductListStorePublic = async (body) => {
  const response = await axios({
    method: "post",
    url: `${process.env.REACT_APP_SHOPIFYPUBLIC_URL}/api/productlistbyemails`,
    data: body,
  });
  return response;
};

export const getShopifyEmailShopId = async (body) => {
  const response = await axios({
    method: "post",
    url: `${process.env.REACT_APP_SHOPIFY_URL}/api/getProductbyEmailStoreid`,
    data: body,
  });
  return response;
};

export const getShopifyEmailShopIdPublic = async (body) => {
  const response = await axios({
    method: "post",
    url: `${process.env.REACT_APP_SHOPIFYPUBLIC_URL}/api/getProductbyEmailStoreid`,
    data: body,
  });
  return response;
};

export const shopifyUpdateDiscount = async (body) => {
  const response = await axios.post(
    `${process.env.REACT_APP_SHOPIFY_URL}/api/updateDiscount`,
    body
  );
  return response;
};

export const shopifyUpdateDiscountPublic = async (body) => {
  const response = await axios.post(
    `${process.env.REACT_APP_SHOPIFYPUBLIC_URL}/api/updateDiscount`,
    body
  );
  return response;
};

export const sendFirebaseToken = async (payload, deviceId) => {
  const response = await axios({
    method: "post",
    url: `${process.env.REACT_APP_BASE_URL}/api/v1/notification/set-fcm-token/`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + LocalStorageService.getAccessToken(),
      deviceid: deviceId,
      devicetype: "web",
    },
    data: payload,
  });
  return response;
};

export const updateThreshold = async (data) => {
  const response = await axios({
    method: "post",
    url: `${process.env.REACT_APP_SHOPIFY_URL}/api/updatethreshold`,
    data: data,
  });
  return response;
};

export const updateThresholdPublic = async (data) => {
  const response = await axios({
    method: "post",
    url: `${process.env.REACT_APP_SHOPIFYPUBLIC_URL}/api/updatethreshold`,
    data: data,
  });
  return response;
};

export const emailListShopify = async (shopId) =>
  axios.get(
    `${process.env.REACT_APP_SHOPIFY_URL}/api/getCustomers?shop_id=${shopId}`
  );

export const emailListShopifyPublic = async (shopId) =>
  axios.get(
    `${process.env.REACT_APP_SHOPIFYPUBLIC_URL}/api/getCustomers?shop_id=${shopId}`
  );

export const customerDataShopify = async (params) =>
  axios.get(
    `${process.env.REACT_APP_SHOPIFY_URL}/api/customerOrderData${params}`
  );

export const customerDataShopifyPublic = async (params) =>
  axios.get(
    `${process.env.REACT_APP_SHOPIFYPUBLIC_URL}/api/customerOrderData${params}`
  );

export const updateUserShopping = async (param) =>
  await axios({
    method: "post",
    url: `${process.env.REACT_APP_SHOPIFY_URL}/api/customerSearch${param}`,
  });

export const updateUserShoppingPublic = async (param) =>
  await axios({
    method: "post",
    url: `${process.env.REACT_APP_SHOPIFYPUBLIC_URL}/api/customerSearch${param}`,
  });

export const hostGuestStars = async (uid, body) => {
  const response = await axios({
    method: "put",
    url: `${process.env.REACT_APP_BASE_URL}/api/v1/demo-rating/${uid}/`,
    data: body,
  });
  return response;
};

export const updateShopifyShopDetailPublic = async (body) => {
  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_SHOPIFYPUBLIC_URL}/api/updateShopDetail?storeid=${body.storeId}&logo=${body.logo}&shop_name=${body.nameShop}&ship_day=${body.days}&videoid=${body.videoid}`,
  });
  return response;
};

export const updateShopifyShopDetail = async (body) => {
  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_SHOPIFY_URL}/api/updateShopDetail?storeid=${body.storeId}&logo=${body.logo}&shop_name=${body.nameShop}&ship_day=${body.days}&videoid=${body.videoid}`,
  });
  return response;
};

export const getShopifyShopDetailPublic = async (param) => {
  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_SHOPIFYPUBLIC_URL}/api/getShopDetail?shop_id=${param}`,
  });
  return response;
};

export const getShopifyShopDetail = async (param) => {
  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_SHOPIFY_URL}/api/getShopDetail?shop_id=${param}`,
  });
  return response;
};

export const getShopifyShopLogo = async (id, payload, deviceId) => {
  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}/api/v1/upload/${id}/`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + LocalStorageService.getAccessToken(),
      deviceid: deviceId,
      devicetype: "web",
    },
    data: payload,
  });
  return response;
};

export const updateShopifyShopLogo = async (id) => {
  const response = await axios({
    method: "put",
    url: `${process.env.REACT_APP_BASE_URL}/api/v1/upload/${id.logo}/`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + LocalStorageService.getAccessToken(),
      deviceid: deviceId,
      devicetype: "web",
    },
    data: {
      tag: "Shop_Logo",
      type: id.files[0].type,
      extension: "jpeg",
    },
  });
  return response;
};

export const getShopifyShopVideo = async (id, payload, deviceId) => {
  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}/api/v1/upload/${id}/`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + LocalStorageService.getAccessToken(),
      deviceid: deviceId,
      devicetype: "web",
    },
    data: payload,
  });
  return response;
};

export const updateShopifyShopVideo = async (id) => {
  const response = await axios({
    method: "put",
    url: `${process.env.REACT_APP_BASE_URL}/api/v1/upload/${id}/`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + LocalStorageService.getAccessToken(),
      deviceid: deviceId,
      devicetype: "web",
    },
    data: {
      tag: "Shop_video",
      type: id.files[0].type,
      extension: "mp4",
    },
  });
  return response;
};

export const getStoreCommissions = async (params) =>
  axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/v1/store-commissions/${params}`
  );
