import React, { useContext, useState } from 'react';

import 'react-phone-input-2/lib/style.css';
import './Dots.css';

import DotsContext from 'context/DotsContext';
import { Spinner } from 'react-bootstrap';
import { dots } from 'assets/images';
import { getAllStoresForHost } from 'Services/storeService';
import { Button, Tooltip } from 'antd';
import HostConnectModal from 'components/Modal/HostDotsConnect';
import { Link } from 'react-router-dom';
import { useEffectOnce } from 'hooks/useEffectOnce';

/**
 * This component renders the UI for a host to connect with the dots platform.
 *
 * @returns {ReactNode} A React element that renders the UI for a host to connect with the dots platform.
 */

const Dots = () => {
  const [storesLoading, setStoresLoading] = useState(true);
  const [hostConnectModalOpen, setHostConnectModalOpen] = useState(false);

  const { setStoreList, storeList } = useContext(DotsContext);

  const [currentStore, setCurrentStore] = useState();

  const openHostConnectModal = (store) => {
    setCurrentStore(store);
    setHostConnectModalOpen(true);
  };

  const closeHostConnectModal = () => {
    setCurrentStore(undefined);
    setHostConnectModalOpen(false);
  };

  /**
   * This component renders the UI based on the store connection status
   *
   * @return {ReactNode} React node that renders the UI based on the store connection status
   */

  const getStoreStatus = (store) => {
    if (!Object.keys(store?.store_payment_type).length) {
      return (
        <Button
          type="primary"
          className="connect-store-btn"
          onClick={() => {
            openHostConnectModal(store);
          }}
        >
          Connect to Store
        </Button>
      );
    }

    if (store?.store_payment_type?.dots?.is_verified) {
      return (
        <div className="verify-text">
          Verified (
          {`${store?.store_payment_type?.dots?.dial_code}${store?.store_payment_type?.dots?.phone_number}`}
          )
        </div>
      );
    } else {
      return (
        <Link
          to={store?.store_payment_type?.dots?.connected_flow_id}
          target="_blank"
          className="unverify-text"
        >
          Unverified (
          {`${store?.store_payment_type?.dots?.dial_code}${store?.store_payment_type?.dots?.phone_number}`}
          )
        </Link>
      );
    }
  };

  /**
   * This function checks the disability of the dots connect button
   *
   * @return {Boolean}
   */

  const checkDotsButtonDisabled = () => {
    const filterIndex = storeList.findIndex(
      (store) => store?.store_payment_type?.dots?.is_verified === true
    );

    if (filterIndex >= 0) {
      return false;
    }
    return true;
  };

  /**
   * This function returns the tooltip text
   *
   * @return {string} text to be displayed in tooltip
   */

  const getDotsButtonTooltip = () => {
    const filterIndex = storeList.findIndex(
      (store) => store?.store_payment_type?.dots?.is_verified === true
    );

    if (filterIndex >= 0) {
      return '';
    }
    return 'You need to get verified in atleast one store to be able to login on dots';
  };

  /**
   * This component renders the stores list for the host
   *
   * @return {ReactNode} react node that renders the stores list for the host
   */

  const getStoreList = () => {
    if (!storeList.length) {
      return (
        <div>
          You currently have no active approved product from any store. Please
          continue to <Link to="/user/showrooms">add products</Link> in order to
          connect with a store
        </div>
      );
    }
    return (
      <>
        <div>
          {storeList?.map((store) => {
            return (
              <>
                <div className="store-card">
                  <div className="store-name">
                    {store?.store_details?.store_name}
                  </div>
                  <div className="seperator"></div>
                  <div className="store-status">{getStoreStatus(store)}</div>
                </div>
              </>
            );
          })}
        </div>
        <div className="payouts-text-container">
          <div className="payouts-text">
            To make edits to your payment methods, go to dots.
          </div>
          <div className="dots-image">
            <img height={32} width={100} src={dots} alt="" />
          </div>
          <div>
            <Tooltip title={getDotsButtonTooltip()}>
              <Button
                type="primary"
                className="go-dots-btn"
                disabled={checkDotsButtonDisabled()}
                onClick={() => {
                  window.open(process.env.REACT_APP_DOTS_URL, '_blank');
                }}
              >
                Go to Dots
              </Button>
            </Tooltip>
          </div>
        </div>
      </>
    );
  };

  /**
   * This function is used to get all the stores
   *
   * @return {void}
   */

  const getAllStores = async () => {
    try {
      const storesList = await getAllStoresForHost();
      setStoreList(storesList?.data?.data);
      setStoresLoading(false);
    } catch (err) {
      setStoresLoading(false);
      console.log(err);
    }
  };

  useEffectOnce(() => {
    getAllStores();
  }, []);

  if (storesLoading) {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-dots">
          <Spinner className="custom-blink" animation="grow" />{' '}
          <Spinner className="custom-blink" animation="grow" />{' '}
          <Spinner className="custom-blink" animation="grow" />
        </div>
      </div>
    );
  }

  return (
    <div>
      {getStoreList()}
      <HostConnectModal
        hostConnectModalOpen={hostConnectModalOpen}
        handleModalClose={closeHostConnectModal}
        currentStore={currentStore}
      />
    </div>
  );
};

export default Dots;
