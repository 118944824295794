import React from "react";
import { Toast } from "react-bootstrap";

const PurebrandToast = ({ show, onClose, title, message }) => {
  return (
    <Toast show={show} onClose={onClose} delay={3000} autohide>
      <Toast.Header closeButton={false}>
        <strong className="mr-auto">{title}</strong>
      </Toast.Header>
      <Toast.Body>{message}</Toast.Body>
    </Toast>
  );
};

export default PurebrandToast;
