import axios from "axios";
import * as LocalStorageService from "../utils/localStorageService";

const deviceId = LocalStorageService.getItem("deviceId");

export const getFallbackHost = async (params) => {
  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}/api/v1/store/fall-back-hosts/${params}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + LocalStorageService.getAccessToken(),
      deviceid: deviceId,
      devicetype: "web",
    },
  });
  return response;
};

export const addFallbackHost = async (body) => {
  const response = await axios({
    method: "post",
    url: `${process.env.REACT_APP_BASE_URL}/api/v1/store/fall-back-hosts/`,
    data: body,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + LocalStorageService.getAccessToken(),
      deviceid: deviceId,
      devicetype: "web",
    },
  });
  return response;
};

export const removeFallbackHost = async (id) => {
  const response = await axios({
    method: "delete",
    url: `${process.env.REACT_APP_BASE_URL}/api/v1/store/fall-back-hosts/${id}/`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + LocalStorageService.getAccessToken(),
      deviceid: deviceId,
      devicetype: "web",
    },
  });
  return response;
};

export const requestFallbackHost = async (uid) => {
  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}/api/v1/demos/${uid}/request-fallback-host/`,
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: "Bearer " + LocalStorageService.getAccessToken(),
    //     deviceid: deviceId,
    //     devicetype: "web",
    //   },
  });
  return response;
};
