import React from 'react';
import './SubscriptionManager.css';
import { Button, Progress } from 'antd';
import { convertUTCToLocal } from 'utils/dateFormatter';
import { SUBSCRIPTION_STATUS } from 'utils/constants';
import { manageBilling } from 'assets/images';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'utils/routes';

/**
 * This component renders the active subscription details and statistics
 *
 * @return {ReactNode} A React element that renders the active subscription details and statistics
 */

const SubscriptionManager = ({
  activePlan,
  handleCloseManager,
  handleCancelPlan,
  handlePauseOrResumePlan,
}) => {
  const navigate = useNavigate();

  /**
   * This function is used to cancel the subscription of a store
   *
   * @returns {void}
   */

  const handleCancelSubscription = () => {
    handleCancelPlan();
  };

  /**
   * This function is used to pause or resume the subscription of a store
   *
   * @returns {void}
   */

  const handlePauseSubscription = () => {
    handlePauseOrResumePlan();
  };

  /**
   * This function is used to get the text of the pause subscription button
   *
   * @returns {String} Text to be displayed on the button
   */

  const getSubscriptionStatusText = () => {
    if (activePlan?.subscription_status === SUBSCRIPTION_STATUS.PAUSED) {
      return 'Resume Subscription';
    }
    return 'Pause Subscription';
  };

  /**
   * This function is used to get the progress bar percentage for the subscription status
   *
   * @returns {Number} TSubscription status progress percentage
   */

  const getProgessBarPercentage = () => {
    return Math.round(
      (activePlan.calls_scheduled / activePlan?.call_limit) * 100
    );
  };

  /**
   * This function is used to navigate the store admin to the manage billing section
   *
   * @returns {void}
   */

  const handleManageBilling = () => {
    navigate(`${ROUTES.ADMIN_SETTING}?page=adminDots`);
  };

  return (
    <div className="plan-manager-container">
      <div className="sub-plan-header">
        <div className="sub-plan-titles">
          <div className="sub-plan-main-header">Plan & Billing</div>
          <div className="sub-plan-sub-header">
            Manage your plan and payments
          </div>
        </div>
        <div className="sub-plan-manage-buttons">
          <Button
            className="sub-plan-action-btn"
            onClick={handlePauseSubscription}
          >
            {getSubscriptionStatusText()}
          </Button>
          <Button
            className="sub-plan-billing-btn"
            onClick={handleManageBilling}
          >
            Manage Billing{' '}
            <img
              className="manage-billing-icon"
              src={manageBilling}
              alt="Manage Billing"
            />
          </Button>
        </div>
      </div>
      <div className="sub-plan-details">
        <div className="sub-plan-info">
          <div className="sub-plan-info-header">Current plan</div>
          <div className="sub-plan-info-details">
            Basic - ${activePlan?.subscription_type?.monthly_fees}/Month &{' '}
            {activePlan?.subscription_type?.prosperity_partnership}% of sales
          </div>
        </div>
        <div className="sub-plan-statistics">
          <div className="sub-plan-statistics-header">Renews</div>
          <div className="sub-plan-statistics-details">
            {convertUTCToLocal(activePlan?.expires_at)[0]}
          </div>
        </div>
      </div>
      <div className="sub-plan-usage">
        <div className="sub-plan-usage-header">Usage</div>
        <div className="sub-plan-usage-calls">
          Calls ({activePlan.calls_scheduled}/{activePlan?.call_limit || '-'})
        </div>
        <div className="progress-bar-container">
          <Progress
            percent={getProgessBarPercentage()}
            strokeColor={'#23265F'}
            size={['100%', 15]}
            showInfo={false}
          />
        </div>
      </div>
      <div className="cancel-sub-buttons">
        <Button className="cancel-manage-btn" onClick={handleCloseManager}>
          Cancel
        </Button>
        <Button
          className="cancel-subscription-btn"
          onClick={handleCancelSubscription}
        >
          Cancel Subscription
        </Button>
      </div>
    </div>
  );
};

export default SubscriptionManager;
