import React, { useState } from "react";
import RangeSlider from "react-bootstrap-range-slider";

import "./addproduct.css";

import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import OptionalQuestion from "./OptionalQuestion";

const CustomerService = (props) => {
  const [sliderValue, setSliderValue] = useState(
    props.data.customerServiceAnswer
  );
  const handleSubmit = (values) => {
    values.customerServiceAnswer = parseInt(values.customerServiceAnswer);
    props.next(values);
  };
  const handlePrevious = (values) => {
    const p1 = values.qualityOne;
    const p2 = values.qualityTwo;
    values.qualityOne = p1.toString();
    values.qualityTwo = p2.toString();
    props.prev(values);
  };

  return (
    <Formik
      initialValues={props.data}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape({
        customerService: Yup.mixed().required(),
      })}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <div className="product-form-wrapper">
            <div className="product-information-wrapper">
              <h3 className="text-start">Customer Service</h3>
              <p className="text-start">We appreciate your honest feedback</p>
            </div>
            <div className="product-uploader">
              <div className="customer-service-question">
                <div id="my-radio-group">
                  <p className="question-product">
                    Did you need customer service? *
                  </p>
                </div>
                <div
                  className="form-group d-flex"
                  role="group"
                  aria-labelledby="my-radio-group"
                >
                  <p> Yes </p>
                  <Field
                    className="radio-input"
                    type="radio"
                    name="customerService"
                    value="yes"
                  />
                  <p> No </p>
                  <Field
                    className="radio-input"
                    type="radio"
                    name="customerService"
                    value="no"
                  />
                </div>
              </div>
              <div className="optional-question">
                {values?.customerService === "yes" ? (
                  <div className="form-outline mb-3">
                    <div className="customerservice-question-one">
                      <div id="my-radio-group">
                        <p className="question-product">
                          Was the problem solved?
                        </p>
                      </div>
                      <div className="slider-wrapper d-flex">
                        <p className="low-indicator">(Low) </p>

                        <RangeSlider
                          value={sliderValue}
                          min={1}
                          max={10}
                          step={1}
                          onChange={(e) => {
                            setFieldValue(
                              "customerServiceAnswer",
                              e.target.value
                            );
                            setSliderValue(e.target.value);
                          }}
                        />
                        <p className="low-indicator">(High) </p>

                        <p className="value-box slider-value">{sliderValue}</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="optional-question">
                {/* {!(
                  values.customerServiceAnswer === "9" ||
                  values.customerServiceAnswer === "10" ||
                  values.customerServiceAnswer === ""
                ) && values?.customerService === "yes" ? ( */}
                <OptionalQuestion
                  name="customerServiceAnswerOpt"
                  title="Tell us more"
                  message="Help us know more about your experience."
                />
                {/* ) : (
                  <></>
                )} */}
              </div>
            </div>
            <div className="button-wrapper-product text-end">
              <div
                onClick={() => handlePrevious(values)}
                className="btn btn-outline-primary previous-button"
              >
                Prev
              </div>
              <button className="btn btn-outline-primary" type="submit">
                Next
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CustomerService;
