import IncompleteProfile from "Pages/dashboard/Profile/IncompleteProfile";
import LoadingError from "Pages/Error/LoadingError";
import PurebrandLoader from "components/Loader/PurebrandLoader";
import AuthContext from "context/AuthContext";
import DemoContext from "context/DemoContext";
import { useEffectOnce } from "hooks/useEffectOnce";
import React, { useContext, useEffect, useState } from "react";
import MyMeetings from "./MyMeetings";

const Meetings = () => {
  const { userProfile, isEmailVerified, emailVerificationLoading } = useContext(AuthContext);
  let {
    demoRequests,
    calendarData,
    userDemos,
    userAcceptedDemos,
    hostUserOtherAccepted,
    acceptedOtherData,
  } = useContext(DemoContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffectOnce(() => {
    const first = userAcceptedDemos();
    const second = userDemos();
    const third = hostUserOtherAccepted("?order=true&offset=0");
    const promise = Promise.all([first, second, third]);
    promise
      .then((data) => {
        setLoading(false);
        setError(null);
        // console.log(data);
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  }, []);

  if (loading || emailVerificationLoading) {
    return <PurebrandLoader />;
  } else if (error) {
    return (
      <LoadingError
        status={error?.response.status}
        message={error?.response.data.message}
        toUrl="/user/welcome"
      />
    );
  } else {
    if (!userProfile?.complete_profile) {
      return <IncompleteProfile />;
    }
    if (!isEmailVerified) {
      return <></>
    }
    return (
      <MyMeetings
        demoRequests={demoRequests}
        calendarData={calendarData}
        acceptedOtherData={acceptedOtherData}
      />
    );
  }
};

export default Meetings;

export const AdminMeetings = () => {
  let { userProfile } = useContext(AuthContext);
  let {
    calendarData,
    userAcceptedDemosStore,
    storeDemoPendingView,
    demoRequests,
  } = useContext(DemoContext);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const handleNavigate = (newDate, view) => {
    const year = newDate.getFullYear();
    const month = newDate.getMonth() + 1;
    setCurrentYear(year);
    setCurrentMonth(month);
  };
  useEffect(() => {
    const res = async () => {
      await userAcceptedDemosStore(
        `?order=true&year=${currentYear}&month=${currentMonth}`
      );
    };
    res();
  }, [currentYear, currentMonth]);

  useEffectOnce(() => {
    const first = storeDemoPendingView();
    const promise = Promise.all([first]);
    promise
      .then((data) => {
        setLoading(false);
        setError(null);
        // console.log(data);
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  }, []);

  if (loading) {
    return <PurebrandLoader />;
  } else if (error) {
    return (
      <LoadingError
        status={error?.response.status}
        message={error?.response.data.message}
        toUrl="/user/welcome"
      />
    );
  } else {
    if (!userProfile?.complete_profile) {
      return <IncompleteProfile />;
    }
    return (
      <MyMeetings
        calendarData={calendarData}
        demoRequests={demoRequests}
        handleNavigate={handleNavigate}
      />
    );
  }
};
