import axios from "axios";
import * as LocalStorageService from "../utils/localStorageService";

// LocalstorageService
// const localStorageService = LocalStorageService.getService();

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    if (
      !config.url.includes("/api/v1/auth/") &&
      config.url !== "/api/v1/upload/"
    ) {
      const token = LocalStorageService.getAccessToken();
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
        config.headers["Content-Type"] = "application/json";
      }
    } else if (config.url === "/api/v1/upload/") {
      const token = LocalStorageService.getAccessToken();
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
        config.headers["Content-Type"] = "multipart/form-data";
      }
    } else if (config.url === "/api/v1/auth/logout/") {
      const token = LocalStorageService.getAccessToken();
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
        config.headers["Content-Type"] = "application/json";
        config.headers["deviceid"] = localStorage.getItem("deviceId");
        config.headers["devicetype"] = "web";
      }
    } else if (config.url.includes("verification-email")) {
      const token = LocalStorageService.getAccessToken();
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
        config.headers["Content-Type"] = "application/json";
      }
    }
    return config;
  },
  (error) => {
    Promise.reject(error).then();
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      (originalRequest.url === "/api/v1/auth/login/" ||
        originalRequest.url === "/api/v1/auth/refresh/")
    ) {
      return Promise.reject(error);
    }

    if (error.response.status === 403) {
      LocalStorageService.clearToken();
      window.location.href = `/login?returnUrl=${window.location.pathname}`
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = LocalStorageService.getRefreshToken();
      return axiosInstance
        .post("/api/v1/auth/refresh/", { refresh: refreshToken })
        .then((res) => {
          if (res.status === 201 || res.status === 200) {
            LocalStorageService.setToken(
              "authTokens",
              JSON.stringify(res.data)
            );
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + LocalStorageService.getAccessToken();
            return axiosInstance(originalRequest);
          }
        })
        .catch((err) => {
          LocalStorageService.clearToken();
          window.location.href = `/login?returnUrl=${window.location.pathname}`;
        });
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
