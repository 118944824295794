import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import RangeSlider from "react-bootstrap-range-slider";

import "./addproduct.css";

import * as Yup from "yup";
import OptionalQuestion from "./OptionalQuestion";

const ProductQuality = (props) => {
  const [sliderValue, setSliderValue] = useState(props.data.qualityOne);
  const [sliderValue2, setSliderValue2] = useState(props.data.qualityTwo);
  const handleSubmit = (values) => {
    values.qualityOne = parseInt(values.qualityOne);
    values.qualityTwo = parseInt(values.qualityTwo);
    props.next(values);
  };
  const handlePrevious = (values) => {
    const p1 = values.arrivalTime;
    const p2 = values.damageRating;
    values.arrivalTime = p1.toString();
    values.damageRating = p2.toString();
    props.prev(values);
  };

  return (
    <Formik
      initialValues={props.data}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape({
        qualityOne: Yup.mixed().required(),
        qualityTwo: Yup.mixed().required(),
      })}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <div className="product-form-wrapper">
            <div className="product-information-wrapper">
              <h3 className="text-start">Product Quality</h3>
              <p className="text-start">We appreciate your honest feedback</p>
            </div>
            <div className="product-uploader">
              <div className="quality-question-one">
                <div id="my-radio-group">
                  <p className="question-product">
                    Was the quality of the product what you were expecting? *
                  </p>
                </div>
                <div className="slider-wrapper d-flex">
                  <p className="low-indicator">(Low) </p>

                  <RangeSlider
                    value={sliderValue}
                    min={1}
                    max={10}
                    step={1}
                    onChange={(e) => {
                      setFieldValue("qualityOne", e.target.value);
                      setSliderValue(e.target.value);
                    }}
                  />
                  <p className="low-indicator">(High) </p>

                  <p className="value-box slider-value">{sliderValue}</p>
                </div>
              </div>
              <div className="optional-question">
                {/* {!(
                  values.qualityOne === "9" ||
                  values.qualityOne === "10" ||
                  values.qualityOne === ""
                ) ? ( */}
                <OptionalQuestion
                  name="qualityOneOptional"
                  message="Help us know more about your experience quality of product."
                />
                {/* ) : (
                  <></>
                )} */}
              </div>
              <div className="quality-question-two">
                <div id="my-radio-group">
                  <p className="question-product">
                    Did product function as expected? Rate the experience. *{" "}
                  </p>{" "}
                </div>
                <div className="slider-wrapper d-flex">
                  <p className="low-indicator">(Low) </p>

                  <RangeSlider
                    value={sliderValue2}
                    min={1}
                    max={10}
                    step={1}
                    onChange={(e) => {
                      setFieldValue("qualityTwo", e.target.value);
                      setSliderValue2(e.target.value);
                    }}
                  />
                  <p className="low-indicator">(High) </p>

                  <p className="value-box slider-value">{sliderValue2}</p>
                </div>
              </div>
              <div className="optional-question">
                {/* {!(
                  values.qualityTwo === "9" ||
                  values.qualityTwo === "10" ||
                  values.qualityTwo === ""
                ) ? ( */}
                <OptionalQuestion
                  name="qualityTwoOptional"
                  message="Help us know more about your experience quality of product."
                />
                {/* ) : (
                  <></>
                )} */}
              </div>
            </div>
            <div className="button-wrapper-product text-end">
              <div
                onClick={() => handlePrevious(values)}
                className="btn btn-outline-primary previous-button"
              >
                Prev
              </div>
              <button className="btn btn-outline-primary" type="submit">
                Next
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ProductQuality;
