import React from "react";
import InfoDashboard from "./infoDashboard";
import TitleWelcome from "./titleWelcome";
import "./host.css";
import { useContext } from "react";
import AuthContext from "context/AuthContext";
import StoreDashboard from "../store/StoreDashboard";
import HomePageHost from "./Dashboard/HomePageHost";
import AdminHomepage from "../PureBrand/HomePage/AdminHomepage";

const Welcome = () => {
  const { user, userProfile, isEmailVerified } = useContext(AuthContext);

  const getHostHome = () => {
    if (!isEmailVerified) {
      return <></>
    }
    return <HomePageHost />
  }

  if (user.role === 3) {
    return (
      <>
        {!userProfile?.complete_profile ? (
          <>
            <TitleWelcome />
            <InfoDashboard />
          </>
        ) : (
          <>
            {
              getHostHome()
            }
          </>
        )}
      </>
    );
  } else if (
    user.role === 2 ||
    user.role === 4 ||
    user.role === 5 ||
    user.role === 6
  ) {
    return (
      <>
        {!userProfile?.complete_profile ? (
          <>
            <TitleWelcome />
            {/* <StoreDashboard /> */}
          </>
        ) : (
          <>
            <StoreDashboard />
          </>
        )}
      </>
    );
  } else if (user.role === 1) {
    return (
      <>
        {!userProfile?.complete_profile ? (
          <>
            <TitleWelcome />
            {/* <AdminHomepage /> */}
          </>
        ) : (
          <>
            <AdminHomepage />
          </>
        )}
      </>
    );
  }
};

export default Welcome;
