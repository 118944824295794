import React, { useContext, useEffect, useState } from "react";
import LogContext from "context/LogContext";
import { TrashFill, CheckSquareFill } from "react-bootstrap-icons";
import PurebrandToast from "components/Toasts/Toast";
import PurebrandLoader from "components/Loader/PurebrandLoader";

const Logbook = ({ details }) => {
  let { getAllHostLogbook, addHostLogbook } = useContext(LogContext);
  const [allLogbook, setAllLogbook] = useState();
  const [newLog, setNewLog] = useState();
  const [isAdding, setIsAdding] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toastPopup = () => {
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  };

  const handleCloseToast = () => {
    setShowToast(false);
  };

  const listLogbook = () => {
    const response = getAllHostLogbook("?order=true&limit=10000");
    response.then(function (res) {
      setAllLogbook(res?.results);
    });
  };
  const openAddLog = () => {
    setIsAdding(true);
  };
  const closeAddLog = () => {
    setIsAdding(false);
  };

  const addNewLog = () => {
    setIsLoading(true);
    let payload = {
      host_id: details,
      text: newLog,
    };
    const response = addHostLogbook(payload);
    response.then(function (res) {
      //   setAllLogbook(res?.data?.data);
      listLogbook();
      setNewLog("");
      toastPopup();
      setIsLoading(false);
    });
  };

  if (isLoading) {
    <PurebrandLoader />;
  }

  useEffect(() => {
    listLogbook();
  }, []);

  return (
    <>
      <PurebrandToast
        show={showToast}
        onClose={handleCloseToast}
        title="Success"
        message="Successfully added log."
      />
      {/* {allLogbook && ( */}
      <div className="card cardc">
        <div className="card-body">
          <div className="row">
            <div className="col-8">
              <h5>Logs</h5>
            </div>
            <div className="col-4 text-end">
              <button
                className=" btn btn-secondary"
                style={{ borderRadius: "15px" }}
                onClick={openAddLog}
              >
                Add New +
              </button>
            </div>
          </div>
          <hr />
          {isAdding && (
            <>
              <div className="row p-3">
                <div className="col-10 ">
                  <input
                    className="form-control-sm form-control "
                    type="text"
                    placeholder="Add Log...."
                    style={{ height: "16px", borderRadius: "2px" }}
                    value={newLog}
                    onChange={(e) => {
                      setNewLog(e.target.value);
                    }}
                  />
                </div>
                <div className="col-2 ">
                  <div className="row">
                    <div className="col-6 text-end">
                      <button
                        className="btn"
                        style={{ border: "1px solid #ccc" }}
                      >
                        <CheckSquareFill
                          onClick={addNewLog}
                          style={{ color: "green" }}
                        />
                      </button>
                    </div>
                    <div className="col-6 text-end">
                      <button
                        className="btn"
                        style={{ border: "1px solid #ccc" }}
                      >
                        <TrashFill
                          onClick={closeAddLog}
                          style={{ color: "red" }}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </>
          )}
          {allLogbook && (
            <ul
              className="list-group "
              style={{ maxHeight: "200px", overflow: "scroll" }}
            >
              <ul>
                {allLogbook.map((res) => {
                  const dateCreated = res?.created_at.split("T");
                  const date = dateCreated[0];
                  return (
                    <div key={res?.id}>
                      {details === res?.host?.id ? (
                        <>
                          <li>
                            <div className="row m-1">
                              <div className="col-10">
                                <p
                                  style={{
                                    marginBottom: 0,
                                    borderRight: "1px solid #ccc",
                                    paddingRight: "8px",
                                  }}
                                >
                                  {res?.text}
                                </p>
                              </div>
                              <div className="col-2 text-end">
                                <p
                                  style={{
                                    marginBottom: 0,
                                    fontSize: "14px",
                                  }}
                                >
                                  {date}
                                </p>
                              </div>
                            </div>
                          </li>
                          <hr />
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
              </ul>
            </ul>
          )}
        </div>
      </div>
    </>
  );
};

export default Logbook;
