import ProductCards from "components/Cards/ProductCards";
import PurebrandLoader from "components/Loader/PurebrandLoader";
import CustomModal from "components/Modal/CustomModal";
import AuthContext from "context/AuthContext";
import ProfileContext from "context/ProfileContext";
import { Field, Form, Formik } from "formik";
import { useEffectOnce } from "hooks/useEffectOnce";
import IncompleteProfile from "Pages/dashboard/Profile/IncompleteProfile";
import React, { useContext, useState } from "react";
import { Alert } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import { useLocation, useNavigate } from "react-router-dom";
import SuggestedProducts from "../Dashboard/SuggestedProducts";
import "./myshowrooms.css";
import DemoContext from "context/DemoContext";
import DataContext from "context/DataContext";

const MyShowrooms = () => {
  const { userProfile, isEmailVerified, emailVerificationLoading } = useContext(AuthContext);
  let { showrooms, getUserReviews, nextStep, prevStep } =
    useContext(ProfileContext);
  let { getShopifyProduct, productList } = useContext(DemoContext);
  const { productListAllTag, storeAdminProductListTag } =
    useContext(DataContext);
  const [loading, setLoading] = useState(true);
  const [active, setActive] = useState("all");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const location = useLocation();
  const navigate = useNavigate();
  const singleProductTags = [
    ...new Set(productListAllTag?.map(JSON.stringify)),
  ].map(JSON.parse);

  const changeProduct = (param) => {
    setLoading(true);
    const res = getUserReviews(`${param}`);
    res
      .then((resp) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(true);
      });
  };
  if (nextStep) {
    var nextPathname = new URL(nextStep).search;
  }
  if (prevStep) {
    var prevPathname = new URL(prevStep).search;
  }

  const getShowrooms = () => {
    if (showrooms.length < 1) {
      return (
        <div className="no-product-found">
          <p>No product found</p>
        </div>
      );
    }
    return showrooms.map((showrooms, index) => {
      return (
        <ProductCards
          key={index}
          newClassCustom="image-zoom-custom"
          imageSrc={showrooms.product.image}
          productName={showrooms.product.name}
          companyName={showrooms.product.store.name}
          productPrice={showrooms.product.price}
          productDescription={showrooms.description}
          imageAlt="product"
          acceptStatus={showrooms.accept_status}
          productRating={showrooms.rating}
          declineReason={showrooms.decline_reason}
          productId={showrooms.product.identifier}
          shopId={showrooms.product.store.identifier}
          orderId={showrooms.product.order_identifier}
          requestId={showrooms.id}
          fullDetail={showrooms}
        />
      );
    });
  };

  useEffectOnce(() => {
    const first = getUserReviews("?order=true&limit=12");
    const forth = getShopifyProduct();
    const fifth = storeAdminProductListTag("?order=true");
    const promise = Promise.all([first, forth, fifth]);
    promise
      .then((data) => {
        setLoading(false);
        // console.log(data);
      })
      .catch(() => {
        setLoading(false);
        console.log("error");
      });
  }, []);

  if (loading || emailVerificationLoading) {
    return (
      <>
        <PurebrandLoader />
      </>
    );
  } else {
    if (!userProfile?.complete_profile) {
      return <IncompleteProfile />;
    }
    if (!isEmailVerified) {
      return <></>
    }
    return (
      <>
        <div className="navigation-product-menu">
          <h5 className="text-start">Products</h5>
        </div>
        <SuggestedProducts productList={productList} showrooms={showrooms} />
        <div className="col-sm-6 col-md-3  add-product-button-wrapper ">
          <button onClick={handleShow} className="btn btn-primary add-product">
            + Add Product
          </button>
          <CustomModal
            showStatus={show}
            clickStatus={handleClose}
            buttonMessage="Add Product"
            closeStatus={() => navigate("/user/addproduct")}
            title="More emails?"
            message={
              <>
                Maybe you shopped with a different email address? Add more
                emails to your profile so that we can find more shopping
                history!
              </>
            }
            secondMessage="Profile"
            secondButton={true}
          />
        </div>
        <div className="search-and-add row d-flex">
          <div className="navigation-items col-md-5 col-sm-5">
            <nav className="nav d-flex">
              <ul className="product-nav d-flex">
                <li
                  className={`nav-link ${
                    active === "all" ? "active-selection" : ""
                  }`}
                  onClick={() => {
                    changeProduct("?order=true&limit=12");
                    setActive("all");
                  }}
                >
                  All
                </li>

                {singleProductTags?.map((tag) => {
                  return (
                    <>
                      <li
                        key={tag?.id}
                        className={`nav-link ${
                          active === tag?.name ? "active-selection" : ""
                        }`}
                        onClick={() => {
                          changeProduct(`?order=true&product__tag=${tag?.id}`);
                          setActive(`${tag?.name}`);
                        }}
                      >
                        {tag?.name}
                      </li>
                    </>
                  );
                })}
              </ul>
            </nav>
          </div>
        </div>
        <div className="search-wrapper col-md-4 col-sm-5 text-end">
          <Formik
            initialValues={{ searchterm: "" }}
            onSubmit={(value) =>
              changeProduct(`?order=true&limit=12&search=${value.searchterm}`)
            }
          >
            {({ touched }) => (
              <Form>
                <div className="row">
                  <div className="col-12 d-flex">
                    <Field
                      type="search"
                      placeholder="Search"
                      className="form-control"
                      name="searchterm"
                    />{" "}
                    <button hidden type="submit" className="search magnifier">
                      {" "}
                      <Search />{" "}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <Alert
          className="text-center"
          key={location?.state?.deletedMessage ? "success" : " "}
          variant={location?.state?.deletedMessage ? "success" : " "}
        >
          {location?.state?.deletedMessage
            ? location.state.deletedMessage
            : " "}
        </Alert>

        <div className=" showroom-container">
          <div className="col row">{getShowrooms()}</div>
          <div className="text-center">
            {prevStep ? (
              <button
                onClick={() => changeProduct(`${prevPathname}`)}
                // disabled={!prevStep}
                className=" previous-button-product"
              >
                Prev
              </button>
            ) : (
              <></>
            )}
            {nextStep ? (
              <button
                onClick={() => changeProduct(`${nextPathname}`)}
                // disabled={!nextStep}
                className=" next-button-product"
              >
                Next
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </>
    );
  }
};

export default MyShowrooms;
