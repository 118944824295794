import * as LocalStorageService from '../utils/localStorageService';
import axiosInstance from './axios';

/**
 * This function is used to make a api call to get all the subscription plans along with the active plan.
 *
 * @param {object} params query parameters to be passed in the api call
 * @returns {object} Response object with the data
 */

export const getSubscriptionDetails = async (params) => {
  try {
    return await axiosInstance({
      method: 'GET',
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/store/subscription/${params}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + LocalStorageService.getAccessToken(),
        devicetype: 'web',
      },
    });
  } catch (e) {
    throw e;
  }
};

/**
 * This function is used to make a api call to select a subscription plan for a store
 *
 * @param {object} data body parameters to be passed in the api call
 * @returns {object} Response object with the data
 */

export const startSubscription = async (data) => {
  try {
    return await axiosInstance({
      method: 'POST',
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/store/subscription/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + LocalStorageService.getAccessToken(),
        devicetype: 'web',
      },
      data,
    });
  } catch (e) {
    throw e;
  }
};

/**
 * This function is used to make a api call to cancel a subscription plan for a store
 *
 * @param {object} data body parameters to be passed in the api call
 * @returns {object} Response object with the data
 */

export const cancelSubscription = async (data) => {
  try {
    return await axiosInstance({
      method: 'PUT',
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/store/cancel-subscription/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + LocalStorageService.getAccessToken(),
        devicetype: 'web',
      },
      data,
    });
  } catch (e) {
    throw e;
  }
};

/**
 * This function is used to make a api call to change the status of a store's subscription plan
 *
 * @param {object} data body parameters to be passed in the api call
 * @returns {object} Response object with the data
 */

export const changeSubscriptionStatus = async (data) => {
  try {
    return await axiosInstance({
      method: 'PUT',
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/store/subscription/pause/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + LocalStorageService.getAccessToken(),
        devicetype: 'web',
      },
      data,
    });
  } catch (e) {
    throw e;
  }
};
