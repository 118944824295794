import Dashelement from "components/Dashboard/Dashelement";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import * as yup from "yup";
import "../ProfileCompletion/profileform.css";
import ProfileContext from "context/ProfileContext";
import { BlankImg } from "assets/images";
import { UploadImage } from "../ProfileCompletion/ProfileInfo";
import PurebrandLoader from "components/Loader/PurebrandLoader";
import ReactPlayer from "react-player";
import { FeatherImg } from "assets/images";

const schema = yup.object().shape({
  shopName: yup.mixed().required("required"),
  shipDate: yup.number().required("required"),
  files: yup.mixed().required("required"),
  videofile: yup.mixed().required("required"),
});

const ShopifyDetail = (props) => {
  const { putToS3 } = useContext(ProfileContext);
  const [loading, setLoading] = useState(false);
  const [avatar, setAvatar] = useState(BlankImg);
  const [videoFileUpload, setVideoFileUpload] = useState();

  const handleSubmit = async (values) => {
    setLoading(true);
    let data = {
      tag: "product_photo",
      type: "image",
      extension: "jpg",
      file: values.files,
    };
    let videobody = {
      tag: "Store_video",
      type: "video",
      extension: "mp4",
      file: values.videofile,
    };
    try {
      let res_video = await putToS3(videobody);
      let res = await putToS3(data);
      if (res_video.status === 200 && res.status === 200) {
        setLoading(false);
        values.files = res.data.data.id;
        values.videofile = res_video.data.data.id;
        props.next(values);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const getImage = (event) => {
    let reader = new FileReader();
    reader.readAsDataURL(event[0]);

    reader.onload = (e) => {
      let img = e.target.result;
      setAvatar(img);
    };
  };

  const handleVideoChange = (event) => {
    const file = event.currentTarget.files[0];
    // console.log({ event, file }, event.currentTarget.files);
    if (file) {
      setVideoFileUpload(URL.createObjectURL(file));
    }
  };

  return (
    <>
      {loading && (
        <PurebrandLoader
          textMessage={"Uploading"}
          colorCode={"rgba(248, 248, 248, 0.7)"}
        />
      )}
      <Formik
        initialValues={props.data}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ values, touched, errors, setFieldValue }) => (
          <Form>
            <Dashelement className="features basic-info-wrapper">
              <div className="container">
                <div className="row">
                  <div className="information-text-wrapper">
                    <h3>More info on your store</h3>
                    <p>Please fill in the following details for your store.</p>
                  </div>
                  <div className="col col-sm-12 col-md-4 col-lg-4">
                    <div className="row">
                      <div className="col-12 col-md-12">
                        <Field
                          placeholder="Store Name (Eg. MurphyDoor Inc)"
                          type="text"
                          name="shopName"
                          className={`form-control ${
                            touched.shopName && errors.shopName
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="shopName"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <p>
                      No. of days after an order is shipped to invite a customer to start hosting on the Purebrand platform.
                      </p>
                      <div className="col-12 col-md-12">
                        <Field
                          placeholder="Days (Eg. 5)"
                          type="number"
                          name="shipDate"
                          className={`form-control ${
                            touched.shipDate && errors.shipDate
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="shipDate"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col col-sm-12 col-md-4 col-lg-4">
                    <UploadImage
                      setFieldValue={setFieldValue}
                      value="files"
                      class="uploader-parts"
                      title="Upload Your Store Logo"
                      maxSize={10000000}
                      body={
                        values.files === "" ? (
                          <div className="mb-3">
                            Requirements:{" "}
                            <ul>
                              <li>.jpg, .png, or .gif file type</li>
                              <li>less than 10MB</li>
                              <li>file name is less than 100 characters</li>
                            </ul>
                          </div>
                        ) : (
                          <div className="">
                            <img
                              src={avatar}
                              className="img img_small_viewfinder"
                              alt=""
                            />
                          </div>
                        )
                      }
                      footer="Click here or drag image here."
                      accept={{ "image/*": [] }}
                    />
                    {values.files && getImage(values.files)}
                  </div>
                  <div className="col col-sm-12 col-md-4 col-lg-4">
                    <div className="uploader-parts" style={{ height: "auto" }}>
                      <h5 className="upload-text">Upload and Preview Video</h5>
                      <div className="video-uploader-container">
                        <input
                          type="file"
                          accept="video/*"
                          onChange={(event) => {
                            setFieldValue(
                              "videofile",
                              event.currentTarget.files
                            );
                            handleVideoChange(event);
                          }}
                          className="btn btn-secondary w-100 p-2"
                        />
                        {videoFileUpload ? (
                          <div>
                            <div className="upload-text">
                              <ReactPlayer
                                url={videoFileUpload}
                                controls
                                autoPlay={true}
                                loop={true}
                                width="100%"
                                height="auto"
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="uploader-part-video">
                            <div className="upload-text">
                              Click the button above to select a video.
                              <img
                                src={FeatherImg}
                                alt="Feather Image"
                                width="100px"
                                className="upload-text"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Dashelement>
            <div className="button-place text-end">
              <button className="btn btn-primary" type="submit">
                Next
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ShopifyDetail;
