import React from 'react';
import { Col, Space, DatePicker, Radio } from 'antd';
import './Dates.css';
import { DATES_FILTER } from 'utils/constants';
import moment from 'moment';
import PropTypes from 'prop-types';

const { RangePicker } = DatePicker;

/**
 * This component renders the section of Dates Filter tab in the filters modal
 *
 * @param {props} props containing the state information of the filter dates
 * @return {ReactNode} A React element that renders the section of Dates Filter tab in the filters modal
 */

const Dates = ({
  startDateFilter,
  dateFilter,
  setDateFilter,
  setStartDateFilter,
  setEndDateFilter,
  endDateFilter,
  originalDateFilter,
  originalStartDateFilter,
  originalEndDateFilter,
}) => {
  /**
   * This function is executed when the date filter options is changed
   *
   * @param {string} checkedValues current checked value
   * @return {void}
   */

  const onChange = (checkedValues) => {
    const todayUTC = moment().utc();

    setDateFilter(checkedValues?.target?.value);

    switch (checkedValues?.target?.value) {
      case DATES_FILTER[0].value:
        setStartDateFilter(todayUTC);
        setEndDateFilter(todayUTC);
        break;
      case DATES_FILTER[1].value:
        const weekStartDate = moment(todayUTC).subtract(6, 'days');
        setStartDateFilter(weekStartDate);
        setEndDateFilter(todayUTC);
        break;
      case DATES_FILTER[2].value:
        const biWeekStartDate = moment(todayUTC).subtract(13, 'days');
        setStartDateFilter(biWeekStartDate);
        setEndDateFilter(todayUTC);
        break;
      case DATES_FILTER[3].value:
        const monthStartDate = moment(todayUTC).subtract(29, 'days');
        setStartDateFilter(monthStartDate);
        setEndDateFilter(todayUTC);
        break;
      case DATES_FILTER[4].value:
        const lastFriday = todayUTC.clone().day(-2);
        setStartDateFilter(lastFriday);
        setEndDateFilter(todayUTC);
        break;
      case DATES_FILTER[5].value:
        if (originalDateFilter === DATES_FILTER[5].value) {
          setStartDateFilter(originalStartDateFilter);
        } else {
          setStartDateFilter('');
        }
        if (originalDateFilter === DATES_FILTER[5].value) {
          setEndDateFilter(originalEndDateFilter);
        } else {
          setEndDateFilter('');
        }
        break;
      default:
        break;
    }
  };

  /**
   * This function is to get the disability of the dates in the range picker
   *
   * @return {Array} disabled dates
   */

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };

  /**
   * This function is executed when the dates are changed
   *
   * @param {array} dates array containing start and end date
   * @return {void}
   */

  const handleDateChange = (dates) => {
    if (dates?.length > 0) {
      if (dates[0]) {
        setStartDateFilter(dates[0]);
      }
      if (dates[1]) {
        setEndDateFilter(dates[1]);
      }
    } else {
      setStartDateFilter('');
      setEndDateFilter('');
    }
  };

  return (
    <div className="dates-filter">
      <div className="predefined-dates">
        {
          <Radio.Group
            style={{
              width: '100%',
            }}
            value={dateFilter}
            onChange={onChange}
          >
            <Col>
              {DATES_FILTER.map((item) => {
                return (
                  <div className="col-sm-12 px-3 py-2">
                    <Radio key={item.label} value={item.value}>
                      {item.label}
                    </Radio>
                  </div>
                );
              })}
            </Col>
          </Radio.Group>
        }
      </div>
      {dateFilter === DATES_FILTER[5].value && (
        <div className="custom-dates">
          <Space direction="vertical" size={12}>
            <RangePicker
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentNode;
              }}
              disabledDate={disabledDate}
              onChange={handleDateChange}
              value={[
                dateFilter === DATES_FILTER[5].value && startDateFilter
                  ? startDateFilter
                  : null,
                dateFilter === DATES_FILTER[5].value && endDateFilter ? endDateFilter : null,
              ]}
            />
          </Space>
        </div>
      )}
    </div>
  );
};

Dates.propTypes = {
  startDateFilter: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]),
  dateFilter: PropTypes.string,
  setDateFilter: PropTypes.func,
  setStartDateFilter: PropTypes.func,
  setEndDateFilter: PropTypes.func,
  endDateFilter: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]),
  originalDateFilter: PropTypes.string,
  originalStartDateFilter: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]),
  originalEndDateFilter: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]),
};

export default Dates;
