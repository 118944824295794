import React from "react";
import { useNavigate } from "react-router-dom";
import "./loginheader.css";

const Loginheader = () => {
  const navigate = useNavigate();
  return (
    <div className="d-flex">
      <p onClick={() => navigate("/signup")} className="login-header">
        Register
      </p>
      <p onClick={() => navigate("/support")} className="login-header">
        Contact Support
      </p>
    </div>
  );
};

export default Loginheader;
