import TermsCondition from "components/Agreements/TermsCondition";
import Dashelement from "components/Dashboard/Dashelement";
import { Formik, Form } from "formik";
import { useState } from "react";
import "./multistep.css";

const AgreementTC = (props) => {
  const [enabled, setenabled] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = (values) => {
    setSubmitted(true);
    setenabled();
    props.next(values, true);
  };

  return (
    <Formik initialValues={props.data} onSubmit={handleSubmit}>
      {({ touched, errors, values }) => (
        <Form>
          <Dashelement className="features multi-step-form t-c-acceptor">
            <TermsCondition />
            <div className="tc-acceptor-label">
              <label>
                <input
                  type={"checkbox"}
                  onChange={() => setenabled(!enabled)}
                />{" "}
                I agree to the terms and conditions.
              </label>
            </div>
          </Dashelement>

          <div className="button-place text-end">
            <button
              disabled={!enabled}
              className="btn btn-primary button-form"
              type="submit"
            >
              {!submitted ? "Submit" : "loading..."}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AgreementTC;
