import { dots } from "assets/images";

const Imagecomponent = () => {
  return (
    <div>
      <div className="col">
        <div className="austin-wrapper">
          <div className="text-start mt-5">
            {/* <img
              style={{
                height: "200px",
                width: "250px",
              }}
              src={MurphyProduct}
              alt="murphy"
            /> */}
            <video
              playsInline
              autoPlay
              muted
              loop
              style={{
                height: "400px",
                width: "auto",
              }}
            >
              <source
                src="https://purebrand-storage.s3.us-east-2.amazonaws.com/PureBrandFacetime.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          <div className="heading-text-wrap">Here’s how it works</div>
          <div className="text-container-wrap ">
            <div className="points-content-wrap d-flex">
              <div className="numbering">1</div>
              <div className="point-text-wrap">Sign up</div>
            </div>
            <div className="points-content-wrap d-flex">
              <div className="numbering">2</div>
              <div className="point-text-wrap">
                Upload a photo or quick video of your product review
              </div>
            </div>
            <div className="points-content-wrap d-flex">
              <div className="numbering">3</div>
              <div className="point-text-wrap">Wait for demo requests</div>
            </div>
            <div className="points-content-wrap d-flex">
              <div className="numbering">4</div>
              <div className="point-text-wrap">
                Do the demos, get your cash{" "}
                <img
                  src={dots}
                  height="25"
                  width="auto"
                  alt="Dots"
                  className="stripelogo-landing-page"
                  style={{ marginLeft: '10px' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Imagecomponent;

export const StoreImageComponent = () => {
  return (
    <div>
      <div className="col">
        <div className="austin-wrapper">
          <div className="heading-text-wrap">Here’s how it works</div>
          <div className="text-container-wrap ">
            <div className="points-content-wrap d-flex">
              <div className="numbering">1</div>
              <div className="point-text-wrap">Sign up</div>
            </div>
            <div className="points-content-wrap d-flex">
              <div className="numbering">2</div>
              <div className="point-text-wrap">
                Set your commissions and coupon amounts
              </div>
            </div>
            <div className="points-content-wrap d-flex">
              <div className="numbering">3</div>
              <div className="point-text-wrap">
                Invite past customers to demo the products they love
              </div>
            </div>
            <div className="points-content-wrap d-flex">
              <div className="numbering">4</div>
              <div className="point-text-wrap">
                Watch sales and conversions increase
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
