import PurebrandLoader from "components/Loader/PurebrandLoader";
import NavComponent from "components/Navigation/NavComponent";
import AuthContext from "context/AuthContext";
import DataContext from "context/DataContext";
import ProfileContext from "context/ProfileContext";
import IncompleteProfile from "Pages/dashboard/Profile/IncompleteProfile";
import React, { useContext, useEffect, useState } from "react";
import { Tabs } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import "./hostlisting.css";
import LogContext from "context/LogContext";
import HostApprovals from "./HostApprovals";
import HostsProSoftList from "./HostsProSoftList";
import HostRevenueList from "./HostRevenueList";
import HostProspectiveList from "./HostProspectiveList";
import HostLogbookList from "./HostLogbookList";
import DemoContext from "context/DemoContext";
import FallbackHostList from "./FallbackHostList";

const ViewHost = () => {
  let { user, userProfile } = useContext(AuthContext);
  let {
    updatedHosts,
    updateHostRequests,
    updateHostRevenue,
    updatedRevenue,
    updateHostRequestsOnce,
    countUpdatedHosts,
  } = useContext(ProfileContext);
  const { fallbackHostCount, getAllFallbackHost, fetchAllHosts } = useContext(DemoContext);
  let { shopifyEmaillistAll, countProspective } = useContext(DataContext);
  const [loading, setLoading] = useState(true);
  let { getAllHostLogbook, allLogbookCount } = useContext(LogContext);

  useEffect(() => {
    setLoading(true);
    const controller = new AbortController();
    const signal = controller.signal;
    let first = updateHostRequests("?order=true&active=false", { signal });
    let second = updateHostRevenue("order=true", { signal });
    let third = shopifyEmaillistAll(
      userProfile?.store?.store_id,
      "?order=true",
      { signal }
    );
    let fourth = updateHostRequestsOnce("?order=true", {
      signal,
    });
    let fifth = getAllHostLogbook("?order=true", { signal });
    let sixth = getAllFallbackHost("?order=true", { signal });
    const promise = Promise.all([first, second, third, fourth, fifth, sixth]);
    promise
      .then((data) => {
        setLoading(false);
        // console.log(data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });

    fetchAllHosts('?')
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });

    return () => {
      controller.abort();
    };
  }, []);

  if (user.role === 3 || 4 || 5 || 6) {
    if (loading) {
      return <PurebrandLoader />;
    } else {
      if (!userProfile?.complete_profile) {
        return <IncompleteProfile />;
      }
      return (
        <>
          <div className="navigation-product-menu">
            <h5 className="text-start">Host Requests</h5>
          </div>
          <div className="requests-wrapper">
            <Tabs
              defaultActiveKey="request"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <NavComponent
                eventKey="request"
                title={`Review Approvals (${
                  updatedHosts ? updatedHosts.length : 0
                })`}
                className=" host-request-wrapper"
              >
                <HostApprovals />
              </NavComponent>
              <NavComponent
                eventKey="allHosts"
                title={`Hosts (${countUpdatedHosts ? countUpdatedHosts : 0})`}
                className=" host-request-wrapper"
              >
                <HostsProSoftList />
              </NavComponent>
              <NavComponent
                eventKey="hosts"
                title={`Host Revenues (${
                  updatedRevenue ? updatedRevenue.length : 0
                })`}
                className="host-list-wrapper"
              >
                <HostRevenueList />
              </NavComponent>
              {/* TODO: Need to uncomment this once prospective hosts feature is ready */}

              {/* <NavComponent
                eventKey="prospective"
                title={`Prospective (${
                  countProspective ? countProspective : 0
                })`}
                className="host-list-wrapper"
              >
                <HostProspectiveList />
              </NavComponent> */}
              <NavComponent
                eventKey="fallbackhost"
                title={`Fallback Host (${
                  fallbackHostCount ? fallbackHostCount : 0
                })`}
                className="host-list-wrapper"
              >
                <FallbackHostList />
              </NavComponent>
              <NavComponent
                eventKey="logbook"
                title={`Log Book (${allLogbookCount}) `}
                className="host-list-wrapper"
              >
                <HostLogbookList />
              </NavComponent>
            </Tabs>
          </div>
        </>
      );
    }
  } else {
    return <Navigate to="/user/welcome" />;
  }
};

export default ViewHost;
