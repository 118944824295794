import React from "react";

import { MicFill } from "react-bootstrap-icons";

const AudioSettings = ({ hasAudio, onAudioChange, className }) => {
  return (
    <div className="d-flex align-items-center gap-2 mb-2 justify-content-center">
      <label
        htmlFor="microphone"
        className={`${hasAudio ? "microphone-active" : ""}`}
      >
        <MicFill /> Microphone
      </label>
      <input
        className="form-check-input"
        id="microphone"
        type="checkbox"
        role="switch"
        name="AudioToggle"
        onChange={onAudioChange}
        checked={hasAudio}
      />
    </div>
  );
};

export default AudioSettings;
