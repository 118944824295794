import { useContext, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import "./CardElement.css";
import PaymentContext from "context/PaymentContext";
import CustomModal from "components/Modal/CustomModal";
import { useNavigate } from "react-router-dom";
import AuthContext from "context/AuthContext";

const PaymentForm = ({
  sureShow,
  setSureShow,
  showBankFormUpdate,
  setShowBankFormUpdate,
}) => {
  const { sendCardToken, sendCardTokenHost } = useContext(PaymentContext);
  let { user } = useContext(AuthContext);
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [resposneForm, setResponseForm] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [inputGiven, setInputGiven] = useState(false);
  const handleCloseResponse = () => setResponseForm(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const cardElement = elements.getElement(CardElement);
    try {
      if (user.role === 2) {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
        });
        let res = await sendCardToken(paymentMethod.id);
        if (res.status === 400) {
          setResponseForm(true);
          setResponseMessage(res?.data?.error);
        }
        if (res.status === 200) {
          setResponseForm(true);
          setResponseMessage(res?.data?.message);
        }
        if (error) {
          setMessage(error.message);
        }
      } else if (user.role === 3) {
        await stripe
          .createToken(cardElement, { currency: "USD" })
          .then(async function (result) {
            if (result.token) {
              let res = await sendCardTokenHost(result.token.id);
              if (res.status === 400) {
                setResponseForm(true);
                setResponseMessage(res?.data?.error);
              }
              if (res.status === 200) {
                setResponseForm(true);
                setResponseMessage(res?.data?.message);
              }
            }
            if (result.error) {
              setMessage(result.error.message);
            }
          });
      }
      setIsLoading(false);
    } catch (err) {
      console.log({ err });
      setIsLoading(false);
    }
  };

  const card_options = {
    style: {
      base: {
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="cardc">
          <div className="m-5 cardc">
            <CardElement
              options={card_options}
              onChange={() => {
                setInputGiven(true);
              }}
            />
          </div>
          <div className="text-center mb-3">
            <button
              disabled={isLoading || !stripe || !elements}
              hidden={!inputGiven}
              className="btn bg-primary text-white"
              id="submit"
            >
              <span id="button-text">
                {isLoading
                  ? "Adding Details"
                  : `Add Details ${
                      user.role === 3 ? "For Host" : "For Store Admin"
                    } `}
              </span>
            </button>
          </div>
        </div>
        {message && (
          <div className="payment-message text-danger">{message}</div>
        )}
      </form>
      <CustomModal
        showStatus={resposneForm}
        clickStatus={() => {
          handleCloseResponse();
          if (user.role === 2) {
            setSureShow(!sureShow);
            setShowBankFormUpdate(!showBankFormUpdate);
            navigate("/user/profile");
          }
          if (user.role === 3) {
            window.location.href = "/user/settings";
          }
        }}
        buttonMessage="Close"
        message={responseMessage}
        closeStatus={() => {
          handleCloseResponse();
          if (user.role === 2) {
            setSureShow(!sureShow);
            setShowBankFormUpdate(!showBankFormUpdate);
            navigate("/user/profile");
          }
          if (user.role === 3) {
            window.location.href = "/user/settings";
          }
        }}
      />
    </>
  );
};

export default PaymentForm;
