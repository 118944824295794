export const PLACEHOLDERS = {
  ACCOUNT_NUMBER: 'Account Number',
  ROUTING_NUMBER: 'Routing Number',
  ACCOUNT_TYPE: 'Select Account type',

  COMPANY_NAME: 'Enter full legal company name',
  COMPANY_WEBSITE: 'https://company.com',
  COMPANY_DBA: 'Enter DBA',
  TAX_ID: '12-3456789',
  INCORPORATION_TYPE: 'Select Incorporation Type',
  INCORPORATION_STATE: 'Select Incorporation State',
  INCORPORATION_DATE: 'Select Incorporation Date',

  FIRST_NAME: 'John',
  LAST_NAME: 'Doe',
  EMAIL: 'john.doe@example.com',
  TITLE: 'CEO',
  OWNERSHIP_PERCENTAGE: '100%',
  SSN: '*********',
  LINE1: 'Line 1',
  LINE2: 'Line 2',
  CITY: 'Boston',
  ZIP: '123456',
  STATE: 'Select State',
  COUNTRY: 'Select Country',

  USAGE: 'Describe your industry, product and business model here',
  REASONS:
    'Describe where you get your revenue, who you make payments to and how will you move money in and out of the platform',
};
