import { PureLoyaltyCard } from "assets/images";
import Dashelement from "components/Dashboard/Dashelement";
import PurebrandLoader from "components/Loader/PurebrandLoader";
import AuthContext from "context/AuthContext";
import IncompleteProfile from "Pages/dashboard/Profile/IncompleteProfile";
import React, { useContext, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "./LoyaltyCard.css";

const LoyaltyCard = () => {
  let { userProfile } = useContext(AuthContext);
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const fetchData = () => {
    fetch("https://restcountries.com/v2/all")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCountries(data);
      });
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);
  useEffect(() => {
    fetchData();
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    navigate("/user/welcome");
  };

  if (loading) {
    return (
      <>
        <PurebrandLoader />
      </>
    );
  } else {
    if (!userProfile?.complete_profile) {
      return <IncompleteProfile />;
    }
    return (
      <>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Dashelement className="features loyalty-card-form">
            <div className="container">
              <div className="row">
                <div className="col col-lg-5 left-form-wrapper">
                  <div className="text-wrapper-card">
                    <h3>Get Your Purebrand Card</h3>
                    <p className="text-start">
                      We offer cash rewards for PureBrand demos. Add you
                      information tco start getting payouts!
                    </p>
                  </div>
                  <div className="card-image-wrapper">
                    <img
                      className="loyalty-card"
                      src={PureLoyaltyCard}
                      alt="Purebrand loyalty card"
                    />
                  </div>
                  <div className="skip-button-wrapper">
                    <Button href="/user/settings" variant="outline-primary">
                      Skip & Request Payment Card later
                    </Button>
                  </div>
                </div>
                <div className="col col-lg-7">
                  <div className="form-outline mb-4">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      className="form-select-other"
                      placeholder="John"
                      type="text"
                      name="firstName"
                      {...register("firstName", {
                        required: true,
                      })}
                    />
                    {errors?.firstName?.type === "required" && (
                      <p className="missing-fields">Required field</p>
                    )}
                  </div>
                  <div className="form-outline mb-4">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      className="form-select-other"
                      placeholder="Doe"
                      type="text"
                      name="lastName"
                      {...register("lastName", {
                        required: true,
                      })}
                    />
                    {errors?.lastName?.type === "required" && (
                      <p className="missing-fields">Required field</p>
                    )}
                  </div>
                  <div className="form-outline mb-4">
                    <Form.Label>Street Address</Form.Label>
                    <Form.Control
                      className="form-select-other"
                      placeholder="1234 State Rd."
                      type="text"
                      name="streetAddress"
                      {...register("streetAddress", {
                        required: true,
                      })}
                    />
                    {errors?.streetAddress?.type === "required" && (
                      <p className="missing-fields">Required field</p>
                    )}
                  </div>
                  <div className="form-outline mb-4">
                    <Form.Label>Apt, Suite, Building (optional)</Form.Label>
                    <Form.Control
                      className="form-select-other"
                      placeholder=""
                      type="text"
                      name="optAddress"
                      {...register("optAddress")}
                    />
                  </div>
                  <div className="row">
                    <div className="form-outline col-md-6 mb-4">
                      <Form.Label>Zip Code</Form.Label>
                      <Form.Control
                        className="form-select-other"
                        placeholder="00000"
                        type="text"
                        name="zipCode"
                        {...register("zipCode", {
                          required: true,
                        })}
                      />
                      {errors?.zipCode?.type === "required" && (
                        <p className="missing-fields">Required field</p>
                      )}
                    </div>
                    <div className="form-outline col-md-6 mb-4">
                      <Form.Label>City, State</Form.Label>
                      <Form.Control
                        className="form-select-other"
                        placeholder=""
                        type="text"
                        name="cityState"
                        {...register("cityState")}
                      />
                    </div>
                  </div>
                  <div className="form-outline mb-4">
                    <Form.Label>Country/Region</Form.Label>
                    <Form.Select
                      className="form-select-country"
                      placeholder=""
                      type="text"
                      name="countryRegion"
                      {...register("countryRegion", { required: true })}
                    >
                      <option default>United States</option>
                      {countries.map((country, index) => (
                        <option key={index} value={country.name}>
                          {country.name}
                        </option>
                      ))}
                    </Form.Select>

                    {errors?.countryRegion?.type === "required" && (
                      <p className="missing-fields">Required field</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Dashelement>
          <div className="submit-button text-end">
            <Button variant="primary" type="submit">
              Request
            </Button>
          </div>
        </Form>
      </>
    );
  }
};

export default LoyaltyCard;
