import React, { useContext } from "react";
import AuthContext from "context/AuthContext";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const TitleWelcome = () => {
  let { user } = useContext(AuthContext);
  let navigate = useNavigate();
  if (user.role === 3) {
    return (
      <div className="row d-flex text-center">
        <div className="main-wrapper">
          <div className="name-wrapper">
            {user && <h4>Hi, {user.firstName} </h4>}
          </div>
          <div className="message-wrapper">
            <h2>Welcome to purebrand!</h2>
            <p>
              Let’s get you approved so that you can start hosting showrooms.
            </p>
          </div>
          <div className="button-wrapper">
            <Button
              onClick={() => navigate("/user/completeprofile")}
              variant="primary"
              className="apply-host-btn"
            >
              Complete profile
            </Button>{" "}
          </div>
        </div>
      </div>
    );
  } else if (
    user.role === 2 ||
    user.role === 4 ||
    user.role === 5 ||
    user.role === 6
  ) {
    return (
      <div className="row d-flex text-center">
        <div className="main-wrapper">
          <div className="name-wrapper">
            {user && <h4>Hi, {user.firstName} </h4>}
          </div>
          <div className="message-wrapper">
            <h2>Welcome to purebrand!</h2>
            <p>
              Complete your store profile so that you can continue with your
              progress
            </p>
          </div>
          <div className="button-wrapper">
            <Button
              onClick={() => navigate("/user/completeprofile")}
              variant="primary"
              className="apply-host-btn"
            >
              Complete profile
            </Button>{" "}
          </div>
        </div>
      </div>
    );
  }
};

export default TitleWelcome;
