import React from "react";
import { CameraVideoFill, CameraVideoOffFill } from "react-bootstrap-icons";

export default function MuteVideoButton({ toggleVideo, hasVideo }) {
  return (
    <>
      <button onClick={toggleVideo} className="round-primary-btn">
        {!hasVideo ? (
          <CameraVideoOffFill fontSize="inherit" />
        ) : (
          <CameraVideoFill fontSize="inherit" />
        )}
      </button>
    </>
  );
}
