import * as Sentry from "@sentry/react";

export function init() {
  Sentry.init({
    dsn: process.env.REACT_ADD_SENTRY_URL,
    tracesSampleRate: 1.0,
    integrations: [new Sentry.Replay()],
  });
}

export function catchErrorsWithScope(error, errorInfo) {
  Sentry.withScope((scope) => {
    scope.setExtras(errorInfo);
    Sentry.captureException(error);
  });
}
