import { useContext, useEffect, useRef, useState } from "react";
import useRoom from "../../hooks/useRoom";
import ToolBar from "../../components/VideoStream/ToolBar";
import AuthContext from "../../context/AuthContext";
import VideoContext from "../../context/VideoContext";
import { useEffectOnce } from "../../hooks/useEffectOnce";
import "../../components/VideoStream/videoStyles.css";
import RecordingModal from "components/Modal/RecordingModal";
import { Toast, ToastContainer } from "react-bootstrap";
import DemoInCallModal from "components/Modal/DemoInCallModal";
import DemoContext from "context/DemoContext";
import SocketContext from "context/SocketContext";

export default function VideoMeeting() {
  const { publicDemoStatus } = useContext(DemoContext);
  const { user, loggedIn } = useContext(AuthContext);
  const { record } = useContext(VideoContext);
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const [videoStatus, setVideoStatus] = useState(true);
  const [audioStatus, setAudioStatus] = useState(false);
  //screen share
  const [screenShareStatus, setScreenShareStatus] = useState(false);
  const [demoInfo, setDemoInfo] = useState(null);
  const [hostAttendMeeting, setHostAttendMeeting] = useState(null);
  const [showA, setShowA] = useState(true);
  const toggleShowA = () => setShowA(!showA);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showReScheduleModal, setShowRescheduleModal] = useState(false);
  const [remainingTime, setRemainingTime] = useState(60);

  const [stateDataForVideoMeeting, setStateDataForVideoMeeting] = useState(
    JSON.parse(localStorage.getItem("DETAILS"))
  );
  const demo_id = localStorage.getItem("demoId");

  const {
    createCall,
    room,
    participants,
    connected,
    cameraPublishing,
    localParticipant,
    camera,
    shareScreen,
  } = useRoom();

  const { sendMessage } = useContext(SocketContext);

  const join_room = {
    command: "join_room",
    room_id: demo_id,
  };
  const joinRoom = async () => {
    await sendMessage(join_room);
  };

  useEffect(() => {
    joinRoom();
  }, []);

  const roomContainer = useRef();
  const publisherContainer = useRef();
  const userName = localStorage.getItem("userName");
  useEffectOnce(() => {
    // setTestCreds(JSON.parse(localStorage.getItem("videoToken")));
    const test = JSON.parse(localStorage.getItem("videoToken"));
    if (localStorage.getItem("videoToken")) {
      createCall(test, roomContainer.current, userName, {
        ...user?.defaultSettings,
      });
    } else {
      setError({ message: "credentials to the meeting room not found" });
      console.log("you are not allowed here");
    }
  }, [createCall, user]);

  useEffect(() => {
    let res = publicDemoStatus(demo_id);
    res.then((data) => {
      setDemoInfo(data?.data?.data);
      setHostAttendMeeting(data?.data?.data?.members?.host_attend_meeting);
    });
  }, [participants]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (remainingTime > 0) {
        setRemainingTime(remainingTime - 1);
      } else {
        if (hostAttendMeeting === null) {
          if (!loggedIn) {
            setShowRescheduleModal(true);
          }
        }
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [remainingTime]);

  useEffect(() => {
    if (
      document
        .getElementById("layoutContainer")
        ?.getElementsByClassName(
          "OT_root OT_subscriber OT_fit-mode-cover ot-layout"
        )[0] &&
      document
        .getElementById("layoutContainer")
        .getElementsByClassName(
          "OT_root OT_subscriber OT_fit-mode-cover ot-layout"
        )[0].clientHeight < 350
    ) {
      document
        .getElementById("layoutContainer")
        .getElementsByClassName(
          "OT_root OT_subscriber OT_fit-mode-cover ot-layout"
        )[0].style.top = "50%";
    } else {
    }
  }, [stateDataForVideoMeeting]);

  useEffect(() => {
    if (stateDataForVideoMeeting?.videoState === false) {
      setVideoStatus(false);
      room?.camera?.disableVideo();
    } else {
      setVideoStatus(true);
      room?.camera?.enableVideo();
    }
    if (stateDataForVideoMeeting?.audioState === false) {
      setAudioStatus(false);
      room?.camera?.disableAudio();
    } else {
      setAudioStatus(true);
      room?.camera?.enableAudio();
    }
  }, [stateDataForVideoMeeting]);

  useEffect(() => {
    if (participants.length === 0) {
      setShowA(true);
    } else {
      setShowA(false);
    }
  }, [participants]);

  useEffect(() => {
    if (connected && stateDataForVideoMeeting) {
      (async () => {
        if (
          stateDataForVideoMeeting?.sessionDetail?.meeting_details?.archive_id
        ) {
          handleShow();
        } else {
          try {
            let data = {
              record: true,
            };
            const res = await record(
              demo_id || stateDataForVideoMeeting?.sessionDetail?.id,
              data
            );
            handleShow();
          } catch (error) {
            console.log("Issue Occured While Demo Recording");
          }
        }
      })();
    }
  }, [connected, stateDataForVideoMeeting]);

  if (error) {
    return (
      <div className="card cardc text-center h4 p-5">
        There was an error fetching the data from the server
      </div>
    );
  }

  const getParticipantContainerStyle = (participantsCount) => {
    if (participantsCount === 1) {
      return { width: "30%", height: "120px" };
    } else {
      return { width: "150px", height: "150px" };
    }
  };
  const publisherContainerStyle = getParticipantContainerStyle(
    participants.length
  );

  return (
    <div id="callContainer" className="room-container-custom">
      <div className="position-relative" style={{ height: "95%" }}>
        <div id="roomContainer" ref={roomContainer}></div>
        <ToastContainer className="p-3" position={"top-end"}>
          <Toast className="message_toast" show={showA} onClose={toggleShowA}>
            <Toast.Body>
              {user && loggedIn
                ? "Waiting for Guest to join..."
                : "Connecting to Host..."}
            </Toast.Body>
          </Toast>
        </ToastContainer>
        <div
          id="publisherContainer"
          style={publisherContainerStyle}
          className="position-absolute"
          ref={publisherContainer}
        >
          <RecordingModal show={show} handleClose={handleClose} />
          {showReScheduleModal && (
            <DemoInCallModal
              showReScheduleModal={showReScheduleModal}
              setShowRescheduleModal={setShowRescheduleModal}
              demo_id={demo_id}
              demoInfo={demoInfo}
              setRemainingTime={setRemainingTime}
              remainingTime={remainingTime}
            />
          )}
        </div>
      </div>
      {stateDataForVideoMeeting && (
        <ToolBar
          room={room}
          participants={participants}
          localParticipant={localParticipant}
          connected={connected}
          camera={camera}
          shareScreen={shareScreen}
          demoIdentifier={stateDataForVideoMeeting?.sessionDetail?.id}
          cameraPublishing={cameraPublishing}
          videoStatus={stateDataForVideoMeeting?.videoState}
          audioStatus={stateDataForVideoMeeting?.audioState}
          demoInfo={demoInfo}
        ></ToolBar>
      )}
    </div>
  );
}
