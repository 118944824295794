import axios from "axios";
import * as LocalStorageService from "../utils/localStorageService";

export const sendMessage = async (body) => {
  let token = LocalStorageService.getAccessToken();
  if (token) {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/demos/${body.id}/message/`,
      data: body,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + LocalStorageService.getAccessToken(),
        Devicetype: "web",
      },
    });
    return response;
  } else if (token === "") {
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/demos/${body.id}/message/`,
      data: body,
    });
    return response;
  }
};
