import axios from "axios";
import * as LocalStorageService from "../utils/localStorageService";

export const getHostLogbook = async (param, payload) => {
  const response = await axios({
    method: "get",
    url: `${process.env.REACT_APP_BASE_URL}/api/v1/logbook/${param}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + LocalStorageService.getAccessToken(),
      devicetype: "web",
    },
    data: payload,
  });
  return response;
};

export const createHostLogbook = async (data) => {
  const response = await axios({
    method: "post",
    url: `${process.env.REACT_APP_BASE_URL}/api/v1/logbook/`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + LocalStorageService.getAccessToken(),
      devicetype: "web",
    },
    data: data,
  });
  return response;
};
