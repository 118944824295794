import NavComponent from "components/Navigation/NavComponent";
import React from "react";
import { Tabs } from "react-bootstrap";
import TagList from "./TagList";
import ViewList from "./ViewList";

const ProductListView = () => {
  return (
    <>
      <div className="container">
        <Tabs
          defaultActiveKey="Listview"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <NavComponent
            eventKey="Listview"
            title={`All Products`}
            className=" host-request-wrapper"
          >
            <ViewList />
          </NavComponent>
          <NavComponent
            eventKey="tags"
            title={`All Categories`}
            className="host-request-wrapper"
          >
            <TagList />
          </NavComponent>
        </Tabs>
      </div>
    </>
  );
};

export default ProductListView;
