import { Purebrand } from "assets/images";
import React from "react";
import LoadingScreen from "react-loading-screen";
import "./loader.css";

const PurebrandLoader = (props) => {
  return (
    <LoadingScreen
      className="pb-loader"
      loading={true}
      bgColor={props.colorCode ? props.colorCode : "rgb(248, 248, 248)"}
      spinnerColor="#9ee5f8"
      logoSrc={Purebrand}
      text={props.textMessage}
      children={<></>}
    ></LoadingScreen>
  );
};

export default PurebrandLoader;
