import Dashelement from "components/Dashboard/Dashelement";
import PurebrandLoader from "components/Loader/PurebrandLoader";
import AuthContext from "context/AuthContext";
import ProfileContext from "context/ProfileContext";
import { useEffectOnce } from "hooks/useEffectOnce";
import IncompleteProfile from "Pages/dashboard/Profile/IncompleteProfile";
import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import PayoutAccepted from "./PayoutAccepted";
import "./payout.css";

const Payouts = () => {
  const { userProfile, isEmailVerified, emailVerificationLoading } = useContext(AuthContext);
  let { updatePayouts, updatedPayout, nextStephost, prevStephost } =
    useContext(ProfileContext);
  const [loading, setLoading] = useState(true);

  const getPayouts = () => {
    return updatedPayout?.map((updatedPayout, index) => {
      return <PayoutAccepted key={index} hostDetails={updatedPayout} />;
    });
  };

  useEffectOnce(() => {
    let res = updatePayouts(`?order=true`);
    res.then((response) => {
      setLoading(false);
      // console.log(data);
    });
  }, []);

  const handleNext = async () => {
    setLoading(true);
    let nextPathname = new URL(nextStephost).search;
    await updatePayouts(nextPathname);
    setLoading(false);
  };
  const handlePrevious = async () => {
    setLoading(true);
    let prevPathname = new URL(prevStephost).search;
    await updatePayouts(prevPathname);
    setLoading(false);
  };

  if (loading || emailVerificationLoading) {
    return <PurebrandLoader />;
  } else {
    if (!userProfile?.complete_profile) {
      return <IncompleteProfile />;
    }
    if (!isEmailVerified) {
      return <></>
    }
    return (
      <>
        <div className="payout-page-title-wrapper">
          <p>My Payouts</p>
        </div>
        <Dashelement className="features payout-tables">
          <div className="features inner-tab-payout">
            <Table responsive className="table-payout">
              <thead>
                <tr>
                  <th className="product-head">Demoed product</th>
                  <th className="payout-head">Payout Type</th>
                  <th className="amount-head">Payout Amount</th>
                  <th className="status-head">Status</th>
                </tr>
              </thead>
              <tbody>{getPayouts()}</tbody>
            </Table>
            <div className="button-wrapper-product text-center my-4">
              <button
                onClick={handlePrevious}
                className="btn btn-outline-primary previous-button"
                disabled={prevStephost === null}
              >
                Prev
              </button>
              <button
                onClick={handleNext}
                className="btn btn-outline-primary previous-button"
                disabled={nextStephost === null}
              >
                Next
              </button>
            </div>
          </div>
        </Dashelement>
      </>
    );
  }
};

export default Payouts;
