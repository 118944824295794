import { CameraFlip } from "assets/images";
import useDevices from "hooks/useDevices";
import React, { useEffect, useState } from "react";
import { Camera2 } from "react-bootstrap-icons";

export default function ChangeCameraButton({ switchCamera }) {
  const { deviceInfo, getDevices } = useDevices();
  const [currentDevice, setCurrentDevice] = useState(null);
  const [frontCamera, setFrontCamera] = useState(null);
  const [backCamera, setBackCamera] = useState(null);

  useEffect(() => {
    getDevices();
  }, []);

  function isIOS() {
    const browserInfo = navigator.userAgent.toLowerCase();
    // console.log("browser", browserInfo, "platform", navigator.platform);
    if (browserInfo.match("iphone") || browserInfo.match("ipad")) {
      return true;
    }
    if (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform)
    ) {
      return true;
    }
    return false;
  }
  useEffect(() => {
    if (deviceInfo) {
      setCurrentDevice(deviceInfo.videoInputDevices[0]);

      if (isIOS()) {
        for (let i = 0; i < deviceInfo.videoInputDevices.length; i++) {
          const device = deviceInfo.videoInputDevices[i];
          if (device.label.includes("Back Camera")) {
            setBackCamera(device);
            break;
          }
        }
        for (let i = 0; i < deviceInfo.videoInputDevices.length; i++) {
          const device = deviceInfo.videoInputDevices[i];
          if (device.label.includes("Front Camera")) {
            setFrontCamera(device);
            break;
          }
        }
      } else {
        for (let i = 0; i < deviceInfo.videoInputDevices.length; i++) {
          const device = deviceInfo.videoInputDevices[i];
          if (device.label.includes("facing back")) {
            setBackCamera(device);
            break;
          }
        }
        for (let i = 0; i < deviceInfo.videoInputDevices.length; i++) {
          const device = deviceInfo.videoInputDevices[i];
          if (device.label.includes("facing front")) {
            setFrontCamera(device);
            break;
          }
        }
      }
    }
  }, [deviceInfo]);

  // console.log(
  //   getVideoSource(),
  //   "====",
  //   cameraPublishing,
  //   "=========>",
  //   changeVideoSource deviceInfo.videoInputDevices[2].deviceId
  // );

  const handleClick = () => {
    if (isIOS()) {
      // console.log("ios", currentDevice);
      if (currentDevice.label.includes("Front Camera")) {
        setCurrentDevice(backCamera);
        switchCamera(backCamera.deviceId);
      } else if (currentDevice.label.includes("Back Camera")) {
        setCurrentDevice(frontCamera);
        switchCamera(frontCamera.deviceId);
      }
    } else {
      if (currentDevice.label.includes("facing front")) {
        setCurrentDevice(backCamera);
        switchCamera(backCamera.deviceId);
      } else if (currentDevice.label.includes("facing back")) {
        setCurrentDevice(frontCamera);
        switchCamera(frontCamera.deviceId);
      }
    }
  };
  // console.log("=========================", deviceInfo);
  return (
    <button onClick={handleClick} className="round-primary-btn">
      <img src={CameraFlip} alt="Flip" height={"40px"} />
    </button>
  );
}
