import Dashelement from "components/Dashboard/Dashelement";
import AddProductController from "components/Forms/Multistep/AddProduct/AddProductController";
import PurebrandLoader from "components/Loader/PurebrandLoader";
import AuthContext from "context/AuthContext";
import IncompleteProfile from "Pages/dashboard/Profile/IncompleteProfile";
import React, { useContext } from "react";

const ProductAddition = () => {
  const { userProfile, isEmailVerified, emailVerificationLoading } = useContext(AuthContext);

  if (emailVerificationLoading) {
    return <PurebrandLoader />;
  }
  if (!userProfile?.complete_profile) {
    return <IncompleteProfile />;
  }
  if (!isEmailVerified) {
    return <></>
  }
  return (
    <>
      <Dashelement className="features product-list-dynamic">
        <AddProductController />
      </Dashelement>
    </>
  );
};

export default ProductAddition;
