import InfoIcon from "assets/svgs/infoIcon";
import React from "react";

const InfoText = ({ text }) => {
  return (
    <div className="commission_info_div">
      <InfoIcon height={30} width={30} />
      <span className="commission_info_text">{text}</span>
    </div>
  );
};

export default InfoText;
