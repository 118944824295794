const getAccessToken = () => {
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  if (authTokens === null) return "";
  return authTokens.access;
};

const getService = () => {
  return this;
};

const clearToken = () => {
  localStorage.clear();
};

const setToken = (key = "authTokens", tokens) => {
  localStorage.setItem(key, tokens);
};

const getItem = (key = "authTokens") => {
  return localStorage.getItem(key);
};

const setProfile = (profile) => {
  localStorage.setItem("userProfile", profile);
};

const setAccessToken = (access) => {
  // let token = JSON.parse(localStorage.getItem("authTokens"));
  // token.access = accessToken
  // localStorage.setItem("authTokens", JSON.stringify(token))
  localStorage.setItem("authTokens", { ...access });
};

const getRefreshToken = () => {
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  return authTokens.refresh;
};

export {
  getAccessToken,
  getService,
  getItem,
  setToken,
  clearToken,
  getRefreshToken,
  setAccessToken,
  setProfile,
};
