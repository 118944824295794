import ProfileContext from "context/ProfileContext";
import React, { useContext, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import "./hostlisting.css";
import { Field, Form, Formik } from "formik";
import { ProHostAlllist } from "./HostAlllist";
// import AuthContext from "context/AuthContext";
// import DataContext from "context/DataContext";
import PurebrandToast from "components/Toasts/Toast";

const HostsProSoftList = () => {
  let { updateHostRequestsOnce, updatedHostsOnce, prevAllHosts, nextAllHosts } =
    useContext(ProfileContext);
  // let { user } = useContext(AuthContext);
  // let { getAllMediaDownload } = useContext(DataContext);
  const [loadingPros, setLoadingPros] = useState(false);
  const [searchTerminologyAll, setSearchTerminologyAll] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [failed, setFailed] = useState(false);
  const handleCloseToast = () => {
    setShowToast(false);
  };

  const gethostsAll = () => {
    if (loadingPros) {
      return (
        <tr>
          <td>
            <div className="d-flex justify-content-center">
              <Spinner animation="grow" />
              <Spinner animation="grow" />
              <Spinner animation="grow" />
            </div>
          </td>
        </tr>
      );
    } else {
      if (updatedHostsOnce && updatedHostsOnce.length < 1) {
        return (
          <tr>
            <td className="no-data-wrap">No data available yet!</td>
          </tr>
        );
      } else {
        return updatedHostsOnce?.map((updatedhost, index) => {
          return <ProHostAlllist key={index} hostDetails={updatedhost} />;
        });
      }
    }
  };
  // TODO: uncomment below function for getting soft host details
  // const getSoftHostsAll = () => {
  //   if (loadingPros) {
  //     return (
  //       <tr>
  //         <td>
  //           <div className="d-flex justify-content-center">
  //             <Spinner animation="grow" />
  //             <Spinner animation="grow" />
  //             <Spinner animation="grow" />
  //           </div>
  //         </td>
  //       </tr>
  //     );
  //   } else {
  //     if (updatedHostsOnce && updatedHostsOnce.length < 1) {
  //       return (
  //         <tr>
  //           <td className="no-data-wrap">No data available yet!</td>
  //         </tr>
  //       );
  //     } else {
  //       return updatedHostsOnce?.map((updatedhost, index) => {
  //         return <SoftHostAlllist key={index} hostDetails={updatedhost} />;
  //       });
  //     }
  //   }
  // };

  // const downloadMedia = async () => {
  //   try {
  //     await getAllMediaDownload();
  //     setFailed(false);
  //     setShowToast(true);
  //   } catch (err) {
  //     setFailed(true);
  //     setShowToast(true);
  //   }
  // };

  // TODO: for downloading media in future , just uncomment this function
  // const DownloadMedia = () => {
  //   return (
  //     <>
  //       <div style={{ marginRight: "10px" }}>
  //         <OverlayTrigger
  //           key={"top"}
  //           placement={"top"}
  //           overlay={
  //             <Tooltip id={`tooltip-${"top"}`}>
  //               All the media will be compressed and sent to your mail.
  //             </Tooltip>
  //           }
  //         >
  //           <button
  //             className="btn btn-primary text-white "
  //             style={{ padding: "0px 15px", minHeight: "40px" }}
  //             disabled={user.role === 5}
  //             onClick={() => {
  //               downloadMedia();
  //             }}
  //           >
  //             Email all Media
  //           </button>
  //         </OverlayTrigger>
  //       </div>
  //     </>
  //   );
  // };

  return (
    <>
      <PurebrandToast
        show={showToast}
        onClose={handleCloseToast}
        title={!failed ? "Success" : <p className="h4 text-danger"> Failed </p>}
        message={
          !failed
            ? "Media email will be sent in your email soon."
            : "Media email sending failed."
        }
      />
      {/* Soft Host is not needed now If we want in  future just uncomment the following code */}
      {/* <Tabs
        defaultActiveKey="prohost"
        id="uncontrolled-tab-example"
        className="mb-3"
      > */}
      {/* <NavComponent
          eventKey="prohost"
          title={`Pro Hosts`}
          className=" host-request-wrapper"
        > */}
      <div>
        <div className="d-flex justify-content-end">
          {/* {DownloadMedia()} */}
          <div className="search-wrapper col-md-4 col-sm-5 mb-3">
            <Formik
              initialValues={{ searchterm: "" }}
              onSubmit={(value) => {
                setLoadingPros(true);
                let res = updateHostRequestsOnce(
                  `?order=true&search=${value.searchterm}`
                );
                res.then(() => setLoadingPros(false));
              }}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="row">
                    <div className="col-12 d-flex">
                      <Field
                        type="search"
                        placeholder="Search"
                        className="form-control"
                        onChange={(e) => {
                          setSearchTerminologyAll(e.target.value);
                          setFieldValue("searchterm", e.target.value);
                        }}
                        value={values.searchterm}
                        name="searchterm"
                      />{" "}
                      <button type="submit" className="search magnifier">
                        <Search />{" "}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <Table className="table-store" responsive>
          <thead>
            <tr>
              <th scope="col">
                <div className="arrows">Host </div>
              </th>
              <th>Email</th>
              <th>Media</th>
              <th>Demos</th>
              <th>Earnings</th>
              <th>Social</th>
            </tr>
          </thead>
          <tbody>{gethostsAll()}</tbody>
        </Table>
        {(nextAllHosts !== null || prevAllHosts !== null) && (
          <div className="button-wrapper-product text-center my-4">
            <button
              onClick={() => {
                setLoadingPros(true);
                const res = updateHostRequestsOnce(new URL(prevAllHosts).search);
                res.then(() => setLoadingPros(false));
              }}
              className="btn btn-outline-primary previous-button"
              disabled={prevAllHosts === null}
            >
              Prev
            </button>
            <button
              onClick={() => {
                setLoadingPros(true);
                const res = updateHostRequestsOnce(new URL(nextAllHosts).search);
                res.then(() => setLoadingPros(false));
              }}
              className="btn btn-outline-primary previous-button"
              disabled={nextAllHosts === null}
            >
              Next
            </button>
          </div>
        )}
      </div>
      {/* </NavComponent> */}
      {/* <NavComponent
          eventKey="softhost"
          title={`Soft Hosts`}
          className=" host-request-wrapper"
        >
          <div>
            <div className="d-flex justify-content-end">
              {DownloadMedia()}
              <div className="search-wrapper col-md-4 col-sm-5 mb-3">
                <Formik
                  initialValues={{ searchterm: "" }}
                  onSubmit={(value) => {
                    setLoadingPros(true);
                    let res = updateHostRequestsOnce(
                      `?order=true&search=${value.searchterm}`
                    );
                    res.then(() => setLoadingPros(false));
                  }}
                >
                  {({ setFieldValue, values }) => (
                    <Form>
                      <div className="row">
                        <div className="col-12 d-flex">
                          <Field
                            type="search"
                            placeholder="Search"
                            className="form-control"
                            onChange={(e) => {
                              setSearchTerminologyAll(e.target.value);
                              setFieldValue("searchterm", e.target.value);
                            }}
                            value={values.searchterm}
                            name="searchterm"
                          />
                          <button type="submit" className="search magnifier">
                            <Search />{" "}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <Table className="table-store" responsive>
              <thead>
                <tr>
                  <th scope="col">
                    <div className="arrows">Host</div>
                  </th>
                  <th>Email</th>
                  <th>Media</th>
                  <th>Demos</th>
                  <th>Earnings</th>
                  <th>Social</th>
                </tr>
              </thead>
              <tbody>{getSoftHostsAll()}</tbody>
            </Table>
          </div>
          {(nextAllHosts !== null || prevAllHosts !== null) && (
            <div className="button-wrapper-product text-center my-4">
              <button
                onClick={() => {
                  setLoadingPros(true);
                  let res = updateHostRequestsOnce(
                    new URL(prevAllHosts).search
                  );
                  res.then(() => setLoadingPros(false));
                }}
                className="btn btn-outline-primary previous-button"
                disabled={prevAllHosts === null}
              >
                Prev
              </button>
              <button
                onClick={() => {
                  setLoadingPros(true);
                  let res = updateHostRequestsOnce(
                    new URL(nextAllHosts).search
                  );
                  res.then(() => setLoadingPros(false));
                }}
                className="btn btn-outline-primary previous-button"
                disabled={nextAllHosts === null}
              >
                Next
              </button>
            </div>
          )}
        </NavComponent> */}
      {/* </Tabs> */}
    </>
  );
};

export default HostsProSoftList;
