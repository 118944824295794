import {
  storePaymentPBGet,
  storePaymentPBPay,
  storePayoutDetail,
  userPaymentList,
  userPaymentUpdate,
  userVenmoDetail,
  userVenmoStatus,
  storeCardToken,
  storeCardTokenUpdate,
  getStoreCard,
  hostOnBoard,
  hostOnBoardComplete,
  checkPaymentDetail,
  storeCardTokenHost,
  storeCardTokenHostDefault,
  storeCardTokenHostDelete,
} from "Services/paymentService";

const { createContext, useState } = require("react");

const PaymentContext = createContext();
export default PaymentContext;

export const PaymentProvider = ({ children }) => {
  const [adminPaymentList, setAdminPaymentList] = useState([]);
  const [adminPaymentListPaid, setAdminPaymentListPaid] = useState([]);
  const [adminPaymentListFailed, setAdminPaymentListFailed] = useState([]);
  const [nextStep, setNextStep] = useState(null);
  const [nextStepPaid, setNextStepPaid] = useState(null);
  const [nextStepFailed, setNextStepFailed] = useState(null);
  const [prevStep, setPrevStep] = useState(null);
  const [prevStepPaid, setPrevStepPaid] = useState(null);
  const [prevStepFailed, setPrevStepFailed] = useState(null);
  const [venmoData, setVenmoData] = useState(null);
  const [demoHistoryStore, setDemoHistoryStore] = useState([]);
  const [previousStorePayout, setPreviousStorePayout] = useState(null);
  const [nextStorePayout, setNextStorePayout] = useState(null);
  const [cardDetails, setCardDetails] = useState();
  const [cardDetailsHost, setCardDetailsHost] = useState();

  let payToHostlist = async (params) => {
    try {
      const res = await userPaymentList(params);
      setAdminPaymentList(res.data.data.results);
      setNextStep(res.data.data.next);
      setPrevStep(res.data.data.previous);
      return res;
    } catch (error) {
      return error;
    }
  };

  let payToHostlistPaid = async (params) => {
    try {
      const res = await userPaymentList(params);
      setAdminPaymentListPaid(res.data.data.results);
      setNextStepPaid(res.data.data.next);
      setPrevStepPaid(res.data.data.previous);
      return res;
    } catch (error) {
      throw error;
    }
  };
  let payToHostlistFailed = async (params) => {
    try {
      const res = await userPaymentList(params);
      setAdminPaymentListFailed(res.data.data.results);
      setNextStepFailed(res.data.data.next);
      setPrevStepFailed(res.data.data.previous);
      return res;
    } catch (error) {
      throw error;
    }
  };

  let payToHostUpdate = async (data, id) => {
    try {
      const res = await userPaymentUpdate(data, id);
      return res;
    } catch (error) {
      throw error;
    }
  };

  let venmoUpdate = async (data) => {
    try {
      const res = await userVenmoDetail(data);
      return res;
    } catch (error) {
      throw error;
    }
  };

  let venmoStatus = async () => {
    try {
      const res = await userVenmoStatus();
      setVenmoData(res.data.data);
      return res;
    } catch (error) {
      throw error;
    }
  };

  let storePayoutGet = async (param) => {
    try {
      const res = await storePayoutDetail(param);
      setDemoHistoryStore(res.data.data.results);
      setPreviousStorePayout(res.data.data.previous);
      setNextStorePayout(res.data.data.next);
      return res;
    } catch (error) {
      throw error;
    }
  };

  let storeDueAmount = async () => {
    try {
      const res = await storePaymentPBGet();
      return res;
    } catch (error) {
      throw error;
    }
  };

  let storePayDueAmount = async (data, id) => {
    try {
      const res = await storePaymentPBPay(data, id);
      return res;
    } catch (error) {
      throw error;
    }
  };

  //after the store admin adds their payment details, sends banktoken to BE
  let sendCardToken = async (data) => {
    let body = { card_token: data };
    try {
      let res = await storeCardToken(body);
      return res;
    } catch (error) {
      // throw error;
      return error.response;
    }
  };

  let sendCardTokenUpdated = async (data) => {
    let body = { card_token: data };
    try {
      let res = await storeCardTokenUpdate(body);
      return res;
    } catch (error) {
      // throw error;
      return error.response;
    }
  };

  let getCardDetails = async () => {
    try {
      let res = await getStoreCard();
      setCardDetails(res.data);
      return res;
    } catch (error) {
      return error.response;
    }
  };

  //host card token store
  let sendCardTokenHost = async (data) => {
    let body = { token: data };
    try {
      let res = await storeCardTokenHost(body);
      return res;
    } catch (error) {
      // throw error;
      return error.response;
    }
  };

  let hostCardDefault = async (data) => {
    let body = { card_id: data };
    try {
      let res = await storeCardTokenHostDefault(body);
      return res;
    } catch (error) {
      // throw error;
      return error.response;
    }
  };

  let hostCardDelete = async (data) => {
    try {
      let res = await storeCardTokenHostDelete(data);
      return res;
    } catch (error) {
      // throw error;
      return error.response;
    }
  };

  //provides onboarding link to host and allow to connecting account to stripe
  let onBoardHost = async () => {
    try {
      let res = await hostOnBoard();
      return res;
    } catch (error) {
      throw error;
    }
  };

  let onBoardHostComplete = async (data) => {
    try {
      let res = await hostOnBoardComplete(data);
      return res;
    } catch (error) {
      throw error;
    }
  };

  //checks onboarding and payment method
  let userPaymentDetail = async () => {
    try {
      let res = await checkPaymentDetail();
      setCardDetailsHost(res?.data?.capabilities);
      return res;
    } catch (error) {
      throw error;
    }
  };

  let contextData = {
    payToHostlist: payToHostlist,
    adminPaymentList: adminPaymentList,
    payToHostUpdate: payToHostUpdate,
    nextStep: nextStep,
    prevStep: prevStep,
    nextStepPaid: nextStepPaid,
    nextStepFailed: nextStepFailed,
    prevStepPaid: prevStepPaid,
    prevStepFailed: prevStepFailed,
    venmoUpdate: venmoUpdate,
    venmoStatus: venmoStatus,
    venmoData: venmoData,
    adminPaymentListPaid: adminPaymentListPaid,
    payToHostlistPaid: payToHostlistPaid,
    payToHostlistFailed: payToHostlistFailed,
    adminPaymentListFailed: adminPaymentListFailed,
    storePayoutGet: storePayoutGet,
    demoHistoryStore: demoHistoryStore,
    storeDueAmount: storeDueAmount,
    storePayDueAmount: storePayDueAmount,
    nextStorePayout: nextStorePayout,
    previousStorePayout: previousStorePayout,
    sendCardToken: sendCardToken,
    sendCardTokenUpdated: sendCardTokenUpdated,
    onBoardHost: onBoardHost,
    onBoardHostComplete: onBoardHostComplete,
    userPaymentDetail: userPaymentDetail,
    getCardDetails: getCardDetails,
    cardDetails: cardDetails,
    sendCardTokenHost: sendCardTokenHost,
    hostCardDelete: hostCardDelete,
    hostCardDefault: hostCardDefault,
    cardDetailsHost: cardDetailsHost,
  };
  return (
    <PaymentContext.Provider value={contextData}>
      {children}
    </PaymentContext.Provider>
  );
};
