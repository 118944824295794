import React from "react";
import "./addproduct.css";

import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { useState } from "react";

const Feedback = (props) => {
  const [enabled, setEnabled] = useState(true);
  const handleSubmit = (values) => {
    setEnabled(false);
    props.next(values, true);
  };
  const handlePrevious = (values) => {
    props.prev(values);
  };
  const getFeedback = () => {
    return (
      <div className="form-outline mt-3 mb-3">
        <div className="feedback-question-one">
          <p>
            <strong>What would you change?</strong>
          </p>
          <Field
            as="textarea"
            rows={4}
            placeholder="What would you change?"
            name="feedbackValue"
            className="form-control"
          />
        </div>
      </div>
    );
  };

  return (
    <Formik
      initialValues={props.data}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape({
        feedback: Yup.mixed().required(),
      })}
    >
      {({ values }) => (
        <Form>
          <div className="product-form-wrapper">
            <div className="product-information-wrapper">
              <h3 className="text-start">Feedback</h3>
              <p className="text-start">We appreciate your honest feedback</p>
            </div>
            <div className="product-uploader">
              <div className="customer-service-question">
                <div id="my-radio-group">
                  <p className="question-product">
                    Would you change anything? *
                  </p>
                </div>
                <div
                  className="form-group d-flex"
                  role="group"
                  aria-labelledby="my-radio-group"
                >
                  <p> Yes </p>
                  <Field
                    className="radio-input"
                    type="radio"
                    name="feedback"
                    value="yes"
                  />
                  <p> No </p>
                  <Field
                    className="radio-input"
                    type="radio"
                    name="feedback"
                    value="no"
                  />
                </div>
              </div>
              <div className="optional-question">
                {/* {values?.feedback === "yes" ? 
                getFeedback() 
                : <></>} */}
                {getFeedback()}
              </div>
            </div>
            <div className="button-wrapper-product text-end">
              <div
                onClick={() => {
                  if (enabled) {
                    handlePrevious(values);
                  }
                }}
                className="btn btn-outline-primary previous-button"
              >
                Prev
              </div>
              <button
                className="btn btn-outline-primary"
                disabled={!enabled}
                type="submit"
              >
                {enabled ? "Submit" : "loading..."}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Feedback;
