import StoreChart from "./StoreChart";
import StoreDashboardList from "./StoreDashboardList";
import "./storedashboard.css";
import PaymentContext from "context/PaymentContext";
import { useContext, useEffect, useState } from "react";
import PurebrandLoader from "components/Loader/PurebrandLoader";
import BankForm from "components/Stripes/BankForm";
import CustomModal from "components/Modal/CustomModal";
import AuthContext from "context/AuthContext";

const StoreDashboard = () => {
  const { userPaymentDetail } = useContext(PaymentContext);
  let { user } = useContext(AuthContext);
  const [cardAdded, setIsCardAdded] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [showBankForm, setShowBankForm] = useState(false);
  const handleClose = () => setShowBankForm(false);

  const checkPaymentMethod = async () => {
    setIsLoading(true);
    try {
      let res = await userPaymentDetail();
      if (res?.data.payment_method) {
        setIsCardAdded(true);
        setIsLoading(false);
      } else {
        setIsCardAdded(false);
        setIsLoading(false);
      }
    } catch (error) {
      setIsCardAdded(false);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    checkPaymentMethod();
  }, [cardAdded]);

  if (loading) {
    return <PurebrandLoader />;
  }

  return (
    <>
      {!cardAdded && user.role === 3 ? (
        <div className="  text-center  ">
          <h4 className="p-5">To Get Started, Please Add Payment Method</h4>
          <div className=" text-center">
            <button
              className="btn btn-primary "
              onClick={() => {
                setShowBankForm(true);
              }}
            >
              + Add Payment Method
            </button>
            <CustomModal
              showStatus={showBankForm}
              clickStatus={handleClose}
              buttonMessage="Close"
              title="Add Card Details"
              message={<BankForm />}
              closeStatus={handleClose}
              secondButton={false}
              secondMessage="none"
            />
          </div>
        </div>
      ) : (
        <>
          <StoreChart />
          <StoreDashboardList />
        </>
      )}
    </>
  );
};

export default StoreDashboard;
