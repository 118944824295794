import React from "react";
import { BoxArrowDown } from "react-bootstrap-icons";

const ImageDownloader = ({ imageUrl }) => {
  const downloadImage = async () => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const urlParts = imageUrl.split("/");
      const fileName = urlParts[urlParts.length - 1];
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };
  return (
    <button
      onClick={downloadImage}
      className="btn m-1"
      style={{ border: "1px solid #ccc" }}
    >
      <BoxArrowDown size={"20px"} />
    </button>
  );
};

export default ImageDownloader;
