import PurebrandLoader from "components/Loader/PurebrandLoader";
import ProfileContext from "context/ProfileContext";
import HostList from "Pages/dashboard/store/Hosts/HostList";
import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { CaretDown, CaretUp } from "react-bootstrap-icons";

const ReviewPageAdmin = () => {
  let {
    updatedHosts,
    updateHostRequestsAdmin,
    nextUpdatedHosts,
    prevUpdatedHosts,
  } = useContext(ProfileContext);
  const [loading, setLoading] = useState(true);
  const gethosts = () => {
    if (updatedHosts?.length < 1) {
      return (
        <tr>
          <td className="no-data-wrap">No data available yet!</td>
        </tr>
      );
    } else {
      return updatedHosts?.map((updatedhost, index) => {
        return <HostList key={index} hostDetails={updatedhost} />;
      });
    }
  };
  const changeList = (params) => {
    setLoading(true);
    let res = updateHostRequestsAdmin(params);
    res.then(() => setLoading(false));
  };
  useEffect(() => {
    let res = updateHostRequestsAdmin("?order=true");
    res.then(() => setLoading(false));
  }, []);

  if (loading) {
    return <PurebrandLoader />;
  } else {
    return (
      <div>
        <div>
          <Table className="table-store" responsive>
            <thead>
              <tr>
                <th scope="col">
                  <div className="arrows">
                    Host
                    <div className="arrow-grid">
                      <CaretUp onClick={() => changeList("?host=true")} />
                      <CaretDown onClick={() => changeList("?host=false")} />
                    </div>
                  </div>
                </th>
                <th scope="col">
                  <div className="arrows">
                    Time waiting
                    <div className="arrow-grid">
                      <CaretUp onClick={() => changeList("?order=true")} />
                      <CaretDown onClick={() => changeList("?order=false")} />
                    </div>
                  </div>
                </th>
                <th>Responses</th>
                <th scope="col">
                  <div className="arrows">
                    Product
                    <div className="arrow-grid">
                      <CaretUp onClick={() => changeList("?product=true")} />
                      <CaretDown onClick={() => changeList("?product=false")} />
                    </div>
                  </div>
                </th>
                <th>Edited</th>
                <th>Media</th>
                <th>Social</th>
              </tr>
            </thead>
            <tbody>{gethosts()}</tbody>
          </Table>
        </div>
        {(nextUpdatedHosts !== null || prevUpdatedHosts !== null) && (
          <div className="button-wrapper-product text-center my-4">
            <button
              onClick={() => {
                changeList(new URL(prevUpdatedHosts).search);
              }}
              className="btn btn-outline-primary previous-button"
              disabled={prevUpdatedHosts === null}
            >
              Prev
            </button>
            <button
              onClick={() => {
                changeList(new URL(nextUpdatedHosts).search);
              }}
              className="btn btn-outline-primary previous-button"
              disabled={nextUpdatedHosts === null}
            >
              Next
            </button>
          </div>
        )}
      </div>
    );
  }
};

export default ReviewPageAdmin;
