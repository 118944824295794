import React from 'react';
import { Modal, Tabs, Button } from 'antd';
import Dates from 'components/Filters/Dates/Dates';
import { coloredFilters } from 'assets/images';
import './FilterModal.css';
import Hosts from 'components/Filters/Hosts/Hosts';
import Products from 'components/Filters/Products/Products';
import PropTypes from 'prop-types';
import moment from 'moment';
import { TEST_IDS } from 'Pages/dashboard/store/Payments/testIds';

/**
 * This component renders a modal for applying filters on demo history
 *
 * @param {props} props props containing all the information and states for the filter of the demos
 * @return {ReactNode} A React element that renders a modal for applying filters on demo history
 */

const FilterModal = ({
  showStatus,
  clickStatus,
  startDateFilter,
  endDateFilter,
  setStartDateFilter,
  setEndDateFilter,
  dateFilter,
  setDateFilter,
  applyFilters,
  clearFilters,
  clearFiltersLoading,
  originalDateFilter,
  originalStartDateFilter,
  originalEndDateFilter,
  originalHostFilter,
  originalProductFilter,
  setHostFilter,
  hostFilter,
  setProductFilter,
  productFilter,
  loading,
}) => {
  const items = [
    {
      key: '1',
      label: 'Date',
      children: (
        <Dates
          startDateFilter={startDateFilter}
          endDateFilter={endDateFilter}
          setStartDateFilter={setStartDateFilter}
          setEndDateFilter={setEndDateFilter}
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
          originalDateFilter={originalDateFilter}
          originalStartDateFilter={originalStartDateFilter}
          originalEndDateFilter={originalEndDateFilter}
        />
      ),
    },
    {
      key: '2',
      label: 'Host',
      children: <Hosts setHostFilter={setHostFilter} hostFilter={hostFilter} />,
    },
    {
      key: '3',
      label: 'Product',
      children: (
        <Products
          setProductFilter={setProductFilter}
          productFilter={productFilter}
        />
      ),
    },
  ];

  /**
   * This function is used to check the disability of the clear filter button
   * 
   * @return {Boolean} boolean value for clear filter button disability
   */

  const checkClearFilterDisable = () => {
    if (
      !originalDateFilter &&
      !originalHostFilter?.length &&
      !originalProductFilter?.length
    ) {
      return true;
    }
    return false;
  };

  return (
    <Modal
      open={showStatus}
      title={
        <div className="filters-title">
          <img src={coloredFilters} alt="" /> Filters
        </div>
      }
      className="filter-modal"
      onCancel={() => {
        clickStatus(true);
      }}
      footer={[
        <div className="filter-btns">
          <div className="left-btns">
            <Button
              className="clear-btn"
              loading={clearFiltersLoading}
              onClick={clearFilters}
              disabled={
                clearFiltersLoading || loading || checkClearFilterDisable()
              }
              data-testid={TEST_IDS.CLEAR_BTN}
            >
              Clear All Filters
            </Button>
          </div>

          <div className="right-btns">
            <Button
              key="back"
              onClick={() => {
                clickStatus(true);
              }}
              disabled={clearFiltersLoading || loading}
              data-testid={TEST_IDS.CANCEL_BTN}
            >
              Cancel
            </Button>
            <Button
              key="submit"
              type="primary"
              onClick={applyFilters}
              loading={loading}
              disabled={clearFiltersLoading || loading}
              data-testid={TEST_IDS.APPLY_BTN}
            >
              Apply
            </Button>
          </div>
        </div>,
      ]}
    >
      <Tabs defaultActiveKey="1" items={items} />
    </Modal>
  );
};

FilterModal.propTypes = {
  showStatus: PropTypes.bool,
  clickStatus: PropTypes.func,
  startDateFilter: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]),
  endDateFilter: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]),
  setStartDateFilter: PropTypes.func,
  setEndDateFilter: PropTypes.func,
  dateFilter: PropTypes.string,
  setDateFilter: PropTypes.func,
  applyFilters: PropTypes.func,
  clearFilters: PropTypes.func,
  clearFiltersLoading: PropTypes.bool,
  originalDateFilter: PropTypes.string,
  originalStartDateFilter: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]),
  originalEndDateFilter: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]),
  originalHostFilter: PropTypes.arrayOf(PropTypes.string),
  originalProductFilter: PropTypes.arrayOf(PropTypes.string),
  setHostFilter: PropTypes.func,
  hostFilter: PropTypes.arrayOf(PropTypes.string),
  setProductFilter: PropTypes.func,
  productFilter: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
};

export default FilterModal;
