import React from "react";

const ImgComponent = (props) => {
  return (
    <>
      <img src={props.source} alt={props.alternate} className={props.class} />
    </>
  );
};

export default ImgComponent;
