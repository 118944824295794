import React, { useRef, useState } from "react";
import { Overlay, Tooltip } from "react-bootstrap";
import { Clipboard } from "react-bootstrap-icons";

const UsefulLinks = () => {
  const BASE_URL = `https://purebrand.io`;
  return (
    <div className="d-flex justify-content-start">
      <div className="link_wrapper">
        <LinkView links={`${BASE_URL}/signup`} linkName="Host Signup" />
        <LinkView links={`${BASE_URL}/store-signup`} linkName="Store Signup" />
        <LinkView links={`${BASE_URL}/login`} linkName="Host Login" />
        <LinkView links={`${BASE_URL}/store-login`} linkName="Store Login" />
        <LinkView links={`${BASE_URL}/user/host`} linkName="Hosts" />
        <LinkView links={`${BASE_URL}/user/recordings`} linkName="Recordings" />
        <LinkView links={`${BASE_URL}/user/store-meetings`} linkName="Demos" />
        <LinkView links={`${BASE_URL}/user/accounting`} linkName="Bills" />
        <LinkView
          links={`${BASE_URL}/user/payment-store`}
          linkName="Ended Demos"
        />
        <LinkView
          links={`${BASE_URL}/user/soft-reviews`}
          linkName="View Soft Review"
        />
        <LinkView links={`${BASE_URL}/user/settings`} linkName="Settings" />
        <LinkView
          links={`${BASE_URL}/meeting-status-check`}
          linkName="Demo Status (Guest)"
        />
      </div>
    </div>
  );
};

export default UsefulLinks;

const LinkView = ({ linkName, links }) => {
  const target = useRef(null);
  const [show, setShow] = useState(false);

  function handleCopyClick() {
    navigator.clipboard.writeText(links);
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 2000);
  }
  return (
    <div className="d-flex link_list_view">
      <div className="text-start justify-content-start">
        <a
          className="link_text_custom"
          target="_blank"
          rel="noreferrer"
          href={`${links}`}
        >
          {linkName}
        </a>
      </div>
      <div className="text-end justify-content-end">
        <button
          className="copy-button-dark"
          ref={target}
          onClick={handleCopyClick}
        >
          <Clipboard size={20} color="#000" />
        </button>
        <Overlay target={target.current} show={show} placement="top">
          {(props) => (
            <Tooltip id="overlay-example" {...props}>
              Copied!
            </Tooltip>
          )}
        </Overlay>
      </div>
    </div>
  );
};
