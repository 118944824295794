import DemoContext from "context/DemoContext";
import { useEffectOnce } from "hooks/useEffectOnce";
import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { CaretDown, CaretUp } from "react-bootstrap-icons";
import CustomListAdmin from "./CustomListAdmin";
import "./admin.css";
import Dashelement from "components/Dashboard/Dashelement";
import { PurebrandChart } from "components/Chart/DoughnutChart";
import { PurebrandLineChart } from "components/Chart/LineChart";
import { getLineArray, getLineData } from "utils/chartData";
import PurebrandLoader from "components/Loader/PurebrandLoader";
import NotificationContext from "context/NotificationContext";

const AdminHomepage = () => {
  const {
    adminHostDataDashboard,
    adminDashboardHostData,
    getAdminDoughnutData,
    getAdminLineChartData,
    adminDoughnutData,
    adminDashboardLineData,
  } = useContext(DemoContext);
  const { notificationPermission } = useContext(NotificationContext);
  const [currentDataLine, setCurrentDataLine] = useState("day");
  const [activeData, setActiveData] = useState({});
  const [dataArray, setDataArray] = useState([]);
  const [labelLine, setLabelLine] = useState([]);
  const [finalLine, setFinalLine] = useState([]);
  const [currentDoughnu, setCurrentDoughnu] = useState("customer");
  const [loading, setLoading] = useState(true);

  const getHostListsAdmin = () => {
    if (adminDashboardHostData?.length < 1) {
      return (
        <tr>
          <td>No hosts!</td>
        </tr>
      );
    } else {
      return adminDashboardHostData?.map((updatedRevenue, index) => {
        return <CustomListAdmin key={index} hostDetails={updatedRevenue} />;
      });
    }
  };

  const getLineChartData = () => {
    setDataArray([]);
    adminDashboardLineData.map((lineData) => {
      dataArray.push(getLineArray(lineData));
    });
    let abc = getLineData(dataArray, currentDataLine);
    setLabelLine(abc.key);
    setFinalLine(abc.value);
  };

  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: (item) =>
            `${item.label}: ${currentDoughnu === "revenue" ? "$" : ""}${
              item.formattedValue
            } ${currentDoughnu === "companies" ? "Stores" : ""}${
              currentDoughnu === "customer" ? "Customers" : ""
            }`,
        },
        backgroundColor: "#fff",
        bodyColor: "#359CD7",
      },
      legend: {
        display: false,
      },
      labels: {
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
  };

  const dataChart = {
    labels: ["Previous Month", "Current month"],
    datasets: [
      {
        label: "Daily view",
        data: [activeData?.previous_month, activeData?.current_month],
        backgroundColor: ["#359CD7", "#24BCB7"],
        hoverOffset: 4,
      },
    ],
  };

  var dataLine = {
    labels: labelLine,
    datasets: [
      {
        label: "",
        data: finalLine,
        fill: true,
        borderColor: "rgb(50, 157, 215)",
        backgroundColor: "rgba(50, 157, 215, 0.5)",
      },
    ],
  };

  const changeData = (params) => {
    setLoading(true);
    let res = adminHostDataDashboard(params);
    res
      .then(function (response) {
        setLoading(false);
      })
      .catch(function (err) {
        setLoading(true);
      });
  };

  const changeLineData = (params) => {
    setLoading(true);
    let res = getAdminLineChartData(params);
    res
      .then(function (response) {
        setLoading(false);
      })
      .catch(function (err) {
        setLoading(true);
      });
  };

  useEffectOnce(() => {
    let first = adminHostDataDashboard("?order=true");
    let second = getAdminDoughnutData("?order=true&timeline=day");
    let third = getAdminLineChartData("?order=true&timeline=day");
    const promise = Promise.all([first, second, third]);
    promise
      .then((data) => {
        setLoading(false);
        notificationPermission();
      })
      .catch((err) => {
        setLoading(true);
      });
  }, []);

  useEffect(() => {
    if (adminDoughnutData) {
      setActiveData(
        adminDoughnutData?.results[0]?.customers
          ? adminDoughnutData?.results[0]?.customers
          : adminDoughnutData?.customers
      );
    }
  }, [adminDoughnutData]);

  useEffect(() => {
    getLineChartData();
  }, [adminDashboardLineData]);

  if (loading) {
    return <PurebrandLoader />;
  } else {
    return (
      <>
        <div className="row homepage-wrappers d-flex">
          <Dashelement className="chart-host-wrapper col-sm-12 col-md-4">
            <div className="features d-flex chart-wrap">
              <nav className="nav d-flex">
                <p>Showroom Monthly Data</p>
                <ul className="chart-nav">
                  <li
                    onClick={() => {
                      setActiveData(
                        adminDoughnutData?.results[0]?.companies
                          ? adminDoughnutData?.results[0]?.companies
                          : adminDoughnutData?.companies
                      );
                      setCurrentDoughnu("companies");
                    }}
                    className={`nav-link ${
                      currentDoughnu === "companies" ? "active-selection" : ""
                    }`}
                  >
                    Company
                  </li>
                  <li
                    onClick={() => {
                      setActiveData(
                        adminDoughnutData?.results[0]?.revenue
                          ? adminDoughnutData?.results[0]?.revenue
                          : { previous_month: 0, current_month: 0 }
                      );
                      setCurrentDoughnu("revenue");
                    }}
                    className={`nav-link ${
                      currentDoughnu === "revenue" ? "active-selection" : ""
                    }`}
                  >
                    Revenue
                  </li>
                  <li
                    onClick={() => {
                      setActiveData(
                        adminDoughnutData?.results[0]?.customers
                          ? adminDoughnutData?.results[0]?.customers
                          : adminDoughnutData?.customers
                      );
                      setCurrentDoughnu("customer");
                    }}
                    className={`nav-link ${
                      currentDoughnu === "customer" ? "active-selection" : ""
                    }`}
                  >
                    Customer
                  </li>
                </ul>
              </nav>

              {activeData?.previous_month === 0 &&
              activeData?.current_month === 0 ? (
                <div>No data for the given criteria</div>
              ) : (
                <PurebrandChart
                  class="host-dashboard-chart"
                  options={options}
                  data={dataChart}
                />
              )}
            </div>
          </Dashelement>
          <div className="col-sm-12 col-md-8 col-lg-8 showroom-wrapper">
            <div className="column-wrapper p-3">
              <div className="showroom-data-tab d-flex justify-content-between">
                <div className="tab-nav-list">
                  <nav className="nav d-flex">
                    <p>Number of Scheduled Demos</p>
                    <ul className="nav">
                      <li
                        onClick={() => {
                          setCurrentDataLine("day");
                          changeLineData("?order=true&timeline=day");
                        }}
                        className={`nav-link ${
                          currentDataLine === "day" ? "active-selection" : ""
                        }`}
                      >
                        Day
                      </li>
                      <li
                        onClick={() => {
                          setCurrentDataLine("week");
                          changeLineData("?order=true&timeline=week");
                        }}
                        className={`nav-link ${
                          currentDataLine === "week" ? "active-selection" : ""
                        }`}
                      >
                        Week
                      </li>
                      <li
                        onClick={() => {
                          setCurrentDataLine("month");
                          changeLineData("?order=true&timeline=month");
                        }}
                        className={`nav-link ${
                          currentDataLine === "month" ? "active-selection" : ""
                        }`}
                      >
                        Mon
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="showroom-data">
                <PurebrandLineChart data={dataLine} />
              </div>
            </div>
          </div>
        </div>
        <div className="admin-dashboard-list">
          <Table>
            <thead>
              <tr>
                <th>Host</th>
                <th scope="col">
                  <div className="arrows">
                    Revenue{" "}
                    <div className="arrow-grid">
                      <CaretUp
                        onClick={() => changeData("?order=true&revenue=true")}
                      />
                      <CaretDown
                        onClick={() => changeData("?order=true&revenue=false")}
                      />
                    </div>
                  </div>
                </th>
                <th scope="col">
                  <div className="arrows">
                    Earnings{" "}
                    <div className="arrow-grid">
                      <CaretUp
                        onClick={() => changeData("?order=true&earning=true")}
                      />
                      <CaretDown
                        onClick={() => changeData("?order=true&earning=false")}
                      />
                    </div>
                  </div>
                </th>
                <th scope="col">Company</th>
                <th>Top Product</th>
              </tr>
            </thead>
            <tbody>{getHostListsAdmin()}</tbody>
          </Table>
        </div>
      </>
    );
  }
};

export default AdminHomepage;
