import DemoContext from "context/DemoContext";
import { useEffectOnce } from "hooks/useEffectOnce";
import React, { useContext, useEffect, useState } from "react";
import * as moment from "moment";
import { Spinner } from "react-bootstrap";
import { capitalizeFirst } from "utils/stringFormatter";

const CompanyDetail = ({ identifier }) => {
  const { getCompanyFullData, companyDetailData } = useContext(DemoContext);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (identifier) {
      const res = getCompanyFullData(identifier.id);
      res
        .then(function (response) {
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          setErrorMessage("Error getting data");
        });
    }
  }, []);

  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="grow" />
        <Spinner animation="grow" />
        <Spinner animation="grow" />
      </div>
    );
  } else {
    if (companyDetailData) {
      return (
        <div className="company-details-wrapper">
          <div className="company-title">
            <div className="d-flex">
              <h5>Company Name: </h5>{" "}
              <p>{companyDetailData?.company.store_info.name}</p>
            </div>
            <div className="d-flex">
              <h5>Company Admin: </h5>{" "}
              <p>
                {companyDetailData?.company.admin.first_name}{" "}
                {companyDetailData?.company.admin.last_name.charAt(0)}.
              </p>
            </div>
          </div>
          <div className="company-details-wrapper">
            <div className="company-detail-title">
              <h4>Financial details:</h4>
            </div>
            <div className="company-detail-info">
              <p>
                Demo Commission:{" "}
                {companyDetailData?.company.demo_commission_type === "AMOUNT"
                  ? "$"
                  : ""}
                {companyDetailData?.company.demo_commission}{" "}
                {companyDetailData?.company.demo_commission_type ===
                "PERCENTAGE"
                  ? "%"
                  : ""}
                <br />
                Sales Commission:{" "}
                {companyDetailData?.company.demo_sales_commission_type ===
                "AMOUNT"
                  ? "$"
                  : ""}
                {companyDetailData?.company.demo_sales_commission}{" "}
                {companyDetailData?.company.demo_sales_commission_type ===
                "PERCENTAGE"
                  ? "%"
                  : ""}
                {companyDetailData?.company.no_show_demo_commission_type ===
                "AMOUNT"
                  ? "$"
                  : ""}
                {companyDetailData?.company.no_show_demo_commission}{" "}
                {companyDetailData?.company.no_show_demo_commission_type ===
                "PERCENTAGE"
                  ? "%"
                  : ""}
              </p>
              <p>
                Due Amount: ${companyDetailData?.company.due_amount}
                {companyDetailData?.company.last_paid_date ? (
                  <>
                    {" "}
                    <br />
                    Due Last Paid:{" "}
                    {moment(companyDetailData?.company.last_paid_date).format(
                      "MMMM Do YYYY"
                    )}
                  </>
                ) : (
                  ""
                )}{" "}
              </p>
              <p>Revenue Generated: {companyDetailData?.revenue}</p>
              <p>
                Commission From Sales: {companyDetailData?.commission_sales}
              </p>
              <p>
                Host Demo Commission: {companyDetailData?.host_demo_commission}
              </p>
              <p>
                Host Sales Commission: {companyDetailData?.host_sale_commission}
              </p>
              <p>
                Subscription Type:{" "}
                {capitalizeFirst(companyDetailData?.subscription_type)}
              </p>
            </div>
          </div>
        </div>
      );
    } else {
      return <div>{errorMessage ? errorMessage : "N/a"}</div>;
    }
  }
};

export default CompanyDetail;
