import { Checkbox, Input } from 'antd';
import React, { useContext, useState } from 'react';
import './Hosts.css';
import DemoContext from 'context/DemoContext';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

/**
 * This component renders the section of Hosts Filter tab in the filters modal
 *
 * @param {props} props containing the state information of the filter hosts
 * @return {ReactNode} A React element that renders the section of Hosts Filter tab in the filters modal
 */

const Hosts = ({ setHostFilter, hostFilter }) => {
  const { allhosts } = useContext(DemoContext);
  const [filteredHosts, setFilteredHosts] = useState(allhosts);

  /**
   * This function is a debounce function for filtering out the hosts based on search
   *
   * @param {string} searchTerm search value
   * @return {void}
   */

  const debouncedFilter = debounce((searchTerm) => {
    const filtered = allhosts.filter((host) =>
      `${host.user.first_name} ${host.user.last_name}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredHosts(filtered);
  }, 300);

  /**
   * This function is called on change of the search input
   *
   * @param {event} e input event
   * @return {void}
   */

  const onChangeSearch = (e) => {
    debouncedFilter(e.target.value);
  };

  /**
   * This function is called on change of the checkbox
   *
   * @param {event} e current checked value reference
   * @return {void}
   */


  const onChangeCheckbox = (e) => {
    if (!hostFilter) {
      setHostFilter([e.target.value]);
    } else {
      if (e.target.checked) {
        setHostFilter([...hostFilter, e.target.value]);
      } else {
        setHostFilter(hostFilter.filter((item) => item !== e.target.value));
      }
    }
  };

  return (
    <div className="hosts-container">
      <Input
        addonBefore={
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M12.6267 11.5129L16 14.8861L14.8861 16L11.5129 12.6267C10.3 13.5971 8.76177 14.1776 7.08881 14.1776C3.17579 14.1776 0 11.0018 0 7.08881C0 3.17579 3.17579 0 7.08881 0C11.0018 0 14.1776 3.17579 14.1776 7.08881C14.1776 8.76177 13.5971 10.3 12.6267 11.5129ZM11.0465 10.9284C12.0096 9.93584 12.6023 8.58187 12.6023 7.08881C12.6023 4.04259 10.135 1.57529 7.08881 1.57529C4.04259 1.57529 1.57529 4.04259 1.57529 7.08881C1.57529 10.135 4.04259 12.6023 7.08881 12.6023C8.58187 12.6023 9.93584 12.0096 10.9284 11.0465L11.0465 10.9284Z"
                fill="#8DC3B6"
              />
            </svg>
          </>
        }
        placeholder="Search hosts"
        onChange={onChangeSearch}
      />
      <hr />

      <Checkbox.Group
        style={{
          width: '100%',
        }}
        value={hostFilter}
      >
        {filteredHosts?.map((host) => {
          return (
            <div className="single-host-div">
              <Checkbox value={host?.user?.id} onChange={onChangeCheckbox}>
                {' '}
                {host?.user?.first_name} {host?.user?.last_name}{' '}
              </Checkbox>
            </div>
          );
        })}
      </Checkbox.Group>
    </div>
  );
};

Hosts.propTypes = {
  setHostFilter: PropTypes.func,
  hostFilter: PropTypes.array || PropTypes.string,
};

export default Hosts;
