import { Icon, VenmoImage } from "assets/images";
import React from "react";
import { Card } from "react-bootstrap";
import "./productcard.css";

const DisplayCard = ({ product }) => {
  return (
    <Card className={`${Object.keys(product).length === 0 ? "" : "active"}`}>
      {Object.keys(product).length === 0 ? (
        <div className="d-flex justify-content-center pt-5">
          No Product Selected
        </div>
      ) : null}
      {Object.keys(product).length !== 0 && (
        <>
          <Card.Img
            className="image-listing image-fluid image-responsive"
            variant="top"
            src={product?.product_image ? product?.product_image : Icon}
            alt={"product"}
          />
        </>
      )}
      {product?.status === 0 ? (
        <div className="not_shipped_label">Not Shipped!</div>
      ) : null}
      {product?.product_name ? (
        <Card.Body>
          <div className="row product-detail-row">
            <div className="col product-col">
              <Card.Title>
                {product?.product_name ? product.product_name : "Product Name"}
                <span className="error_message">
                  {product.status === 0 ? "(Not Delivered Yet)" : null}
                </span>
              </Card.Title>
              <Card.Text>
                {product?.shop_name ? product.shop_name : "Company"}
              </Card.Text>
            </div>
          </div>
          <div className="pricing-wrapper">
            <Card.Title>
              ${product?.product_price ? product.product_price : "Price"}
            </Card.Title>
          </div>
        </Card.Body>
      ) : (
        <></>
      )}
    </Card>
  );
};

export default DisplayCard;

export const CustomCard = ({ showImage, image, title, message, classname }) => {
  return (
    <div className="venmo-selector-wrapper">
      <Card className="custom-card-border">
        <div className={`text-center`}>
          {showImage && (
            <Card.Img
              className={classname}
              variant="top"
              src={image}
              alt={"product"}
            />
          )}
        </div>
        <Card.Body>
          <div className="row product-detail-row">
            <div className="col product-col">
              <Card.Title>{title}</Card.Title>
              <Card.Text>{message}</Card.Text>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};
