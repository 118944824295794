import React from "react";
const { getDateRequired } = require("./dateFormatter");

export const getLineArray = (lineData) => {
  let day = getDateRequired(lineData.date, "day");
  let week = getDateRequired(lineData.date, "week");
  let month = getDateRequired(lineData.date, "month");
  let newDates = {
    Day: day,
    Week: week,
    Month: month,
    Count: lineData.count,
  };
  return newDates;
};

export const getLineData = (datas, cate) => {
  var monthlyData = {
    January: 0,
    February: 0,
    March: 0,
    April: 0,
    May: 0,
    June: 0,
    July: 0,
    August: 0,
    September: 0,
    October: 0,
    November: 0,
    December: 0,
  };
  var weeklyData = {
    Monday: 0,
    Tuesday: 0,
    Wednesday: 0,
    Thursday: 0,
    Friday: 0,
    Saturday: 0,
    Sunday: 0,
  };
  if (cate === "month") {
    datas.map((data) => {
      for (let i = 0; i < 12; i++) {
        if (Object.keys(monthlyData)[i] === data.Month) {
          monthlyData[Object.keys(monthlyData)[i]] =
            Object.values(monthlyData)[i] + data.Count;
        }
      }
    });
    let abc = {
      key: Object.keys(monthlyData),
      value: Object.values(monthlyData),
      type: cate,
    };
    return abc;
  } else if (cate === "week") {
    datas.map((data) => {
      for (let i = 0; i < 12; i++) {
        if (Object.keys(weeklyData)[i] === data.Week) {
          weeklyData[Object.keys(weeklyData)[i]] =
            Object.values(weeklyData)[i] + data.Count;
        }
      }
    });
    let abc = {
      key: Object.keys(weeklyData),
      value: Object.values(weeklyData),
      type: cate,
    };
    return abc;
  } else if (cate === "day") {
    let day = [];
    let val = [];
    datas.map((data) => {
      day.push(data.Day + " " + data.Month);
      val.push(data.Count);
    });
    let abc = {
      key: day.reverse(),
      value: val.reverse(),
      type: cate,
    };
    return abc;
  }
};
