import React from "react";

const RequestDot = () => {
  return (
    <>
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        id="_24x24_On_Light_Notification-Alert"
        data-name="24x24/On Light/Notification-Alert"
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginTop: "15px" }}
      >
        <path
          id="Shape-2"
          data-name="Shape"
          d="M3.5,7A3.5,3.5,0,1,1,7,3.5,3.5,3.5,0,0,1,3.5,7Z"
          transform="translate(15 2)"
          fill="#ff6359"
        />
      </svg>
    </>
  );
};

export default RequestDot;
