import { BlankImg } from "assets/images";
import Dashelement from "components/Dashboard/Dashelement";
import PurebrandLoader from "components/Loader/PurebrandLoader";
import NotificationContext from "context/NotificationContext";
import { useEffectOnce } from "hooks/useEffectOnce";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./notification.css";

const NotificationPage = () => {
  const [loading, setLoading] = useState(true);
  const { getAllNotifications } = useContext(NotificationContext);
  const [notificationData, setNotificationData] = useState(null);
  const [next, setNext] = useState(null);
  const shownNotification = [];
  const navigate = useNavigate();
  const handleMore = () => {
    setLoading(true);
    const res = getAllNotifications(next);
    res.then(function (response) {
      // setNotificationData(response.data.data.results);
      console.log(response.data.data.results);
      setNotificationData((prevNotificationData) => [
        ...prevNotificationData,
        ...response.data.data.results,
      ]);
      if (response.data.data.next) {
        const nextPathname = new URL(response.data.data.next).search;
        setNext(nextPathname);
      } else {
        setNext(null);
      }
      setLoading(false);
    });
  };

  const handleDropNotification = (e) => {
    if (e === "Product Approved") {
      navigate("/user/showrooms");
    } else if (e === "Product Review") {
      navigate("/user/meetings");
    } else if (e === "Demo Requested") {
      navigate("/user/meetings");
    }
  };

  const showNotifications = () => {
    return shownNotification.map((list, index) => {
      return (
        <li
          key={index}
          className="notification-list-page list-group-item"
          onClick={() => handleDropNotification(list.title)}
        >
          <div className="notification-head">
            <h5>{list.title}</h5>
          </div>
          <div className="notification-body">
            <div className="d-flex">
              <div className="notification-image-wrapper ">
                <img
                  src={
                    (list?.sent_by?.profile_picture?.cloudinary_file !== null
                      ? list?.sent_by?.profile_picture?.cloudinary_file
                      : list.sent_by.profile_picture.file) ||
                    (list.sent_by.profile_picture.file !== null
                      ? list.sent_by.profile_picture.file
                      : BlankImg)
                  }
                  className="notification-user-photo"
                  alt="user"
                />
              </div>
              <div className="name-wrapper ">
                <p>
                  {list.sent_by.first_name} {list.sent_by.last_name.charAt(0)}.
                </p>
              </div>
            </div>
            <div className="main-message-notification">
              <p>
                <strong>Message:</strong> {list.body}
              </p>
            </div>
          </div>
        </li>
      );
    });
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  useEffectOnce(() => {
    const res = getAllNotifications("?order=true&limit=10&offset=0");
    res.then(function (response) {
      console.log(response.data.data.results);
      setNotificationData(response.data.data.results);
      if (response.data.data.next) {
        const nextPathname = new URL(response.data.data.next).search;
        setNext(nextPathname);
      } else {
        setNext(null);
      }
    });
  }, []);

  if (notificationData?.length > 0) {
    notificationData.map((x) => shownNotification.push(x));
  }

  if (loading) {
    return (
      <>
        <PurebrandLoader />
      </>
    );
  } else {
    return (
      <Dashelement className="features notification-whole-wrapper justify-content-center">
        <div className="notification-head">
          <h4 className="justify-content-center">Notifications</h4>
        </div>
        <div className="notification-items-wrapper">
          <div className="notification-item ">
            <ul className="list-group list-group-flush ">
              {shownNotification.length > 0 ? showNotifications() : <></>}
            </ul>
            {next ? (
              <div
                className="notification-list-page list-group-item d-flex justify-content-center"
                onClick={handleMore}
              >
                Load more
              </div>
            ) : (
              <div className="notification-list-page d-flex justify-content-center">
                All notifications loaded!
              </div>
            )}
          </div>
        </div>
      </Dashelement>
    );
  }
};

export default NotificationPage;
