import NavComponent from "components/Navigation/NavComponent";
import DemoContext from "context/DemoContext";
import { AdminMeetings } from "Pages/dashboard/host/Meetings/Meetings";
import React, { useContext } from "react";
import { Tabs } from "react-bootstrap";
import MeetingStoreView from "../Payments/MeetingStoreView";

const StoreDemos = () => {
  let { demoRequests } = useContext(DemoContext);
  return (
    <>
      <Tabs
        defaultActiveKey="demo-history"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <NavComponent
          eventKey="demo-history"
          title="Demo History"
          className=" payout-list-wrapper"
        >
          <MeetingStoreView />
        </NavComponent>
        <NavComponent
          eventKey="calender"
          title={`Demo Calendar 
              (${demoRequests.length > 0 ? demoRequests.length : "0"})
          `}
          className="payout-list-wrapper"
        >
          <AdminMeetings />
        </NavComponent>
      </Tabs>
    </>
  );
};

export default StoreDemos;
