import ProfileContext from "context/ProfileContext";
import { useEffectOnce } from "hooks/useEffectOnce";
import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PlusLg, TrashFill } from "react-bootstrap-icons";
import CustomSlider from "components/Forms/FormComponents/CustomSlider";
import { Field, Form, Formik } from "formik";
import OptionalQuestion from "components/Forms/Multistep/AddProduct/OptionalQuestion";
import CustomModal from "components/Modal/CustomModal";
import CustomUploader from "components/Forms/FormComponents/CustomUploader";
import PurebrandLoader from "components/Loader/PurebrandLoader";

const ShowroomEdit = () => {
  const {
    userReviewEdit,
    editSubmittedReviewRequest,
    deleteMediaOnce,
    putToS3,
    uploadNewMediaOnEdit,
  } = useContext(ProfileContext);
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState(null);
  const [disabledStatus, setDisabledStatus] = useState(true);
  const [activeProduct, setActiveProduct] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setActiveProduct(null);
  };
  const handleShow = () => setShow(true);
  const [showA, setShowA] = useState(false);
  const handleCloseA = () => {
    setShowA(false);
    setActiveProduct(null);
  };
  const handleShowA = () => setShowA(true);

  const [showUploader, setShowUploader] = useState(false);
  const handleCloseUploader = () => setShowUploader(false);
  const handleShowUploader = () => setShowUploader(true);

  const [showUploaderVideo, setShowUploaderVideo] = useState(false);
  const handleCloseUploaderVideo = () => setShowUploaderVideo(false);
  const handleShowUploaderVideo = () => setShowUploaderVideo(true);

  const [loadingImg, setLoadingImg] = useState(false);
  const [loadingVid, setLoadingVid] = useState(false);
  let location = useLocation();
  let navigate = useNavigate();

  const checkedits = async () => {
    setLoading(true);
    try {
      let res = await userReviewEdit(location.pathname.split("/")[3]);
      if (res) {
        setLoading(false);
        setProductData(res.data);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    let data = {
      accept_status: productData?.accept_status,
      description: productData?.description,
      decline_reason: productData?.decline_reason,
      customer: {
        customer_identifier: productData?.customer.customer_identifier,
        first_name: productData?.customer.first_name,
        last_name: productData?.customer.last_name,
        email: productData?.customer.email,
      },
      product: {
        order_identifier: productData?.product.order_identifier,
        identifier: productData?.product.identifier,
        name: productData?.product.name,
        handle: productData?.product.handle,
        image: productData?.product.image,
        price: productData?.product.price,
        currency_code: productData?.product.currency_code,
        tag: productData?.product.tag,
        store: {
          identifier: productData?.product.store.identifier,
          name: productData?.product.store.name,
          domain: productData?.product.store.domain,
          email: productData?.product.store.email,
        },
      },
      questionnaire: {
        quality_one: e.quality_one,
        quality_one_optional: e.quality_one_optional,
        quality_two: e.quality_two,
        quality_two_optional: e.quality_two_optional,
        customer_service: e.customer_service,
        customer_service_answer: e.customer_service_answer,
        customer_service_answer_optional: e.customer_service_answer_optional,
        order_one: e.order_one,
        order_one_optional: e.order_one_optional,
        order_two: e.order_two,
        order_two_optional: e.order_two_optional,
        install_setup: e.install_setup,
        install_setup_optional: e.install_setup_optional,
        order_again: e.order_again,
        order_again_optional: e.order_again_optional,
        receive_product: e.receive_product,
        receive_product_optional: e.receive_product_optional,
        arrival_time: e.arrival_time,
        arrival_time_optional: e.arrival_time_optional,
        damage_rating: e.damage_rating,
        damage_rating_optional: e.damage_rating_optional,
      },
      product_feedback: {
        feedback: productData?.feedback.feedback
          ? productData?.feedback.feedback
          : "no",
        feedback_comment: productData?.feedback.feedback_comment,
      },
    };
    try {
      const res = await editSubmittedReviewRequest(productData?.id, data);
      if (res.status === 200) {
        checkedits();
        setDisabledStatus(true);
        navigate("/user/showrooms");
      }
    } catch (error) {
      console.error("Error Occured");
    }
  };

  const deleteSingleMedia = async () => {
    if (activeProduct) {
      try {
        const res = await deleteMediaOnce(activeProduct);
        if (res.status === 200) {
          checkedits();
        }
      } catch (error) {
        console.error("Error Occured");
      }
    }
  };

  const handleSubmitUpload = async (e, dataType) => {
    setLoadingImg(true);
    let data = {
      tag: "product_photo",
      type: dataType,
      extension: "jpg",
      file: e.files,
    };
    var newData = {
      user_product_id: productData?.id,
      picture_id: null,
      video_id: null,
    };
    try {
      let res = await putToS3(data);
      if (res.status === 200) {
        if (dataType === "image") {
          newData.picture_id = res.data.data.id;
        } else {
          newData.video_id = res.data.data.id;
        }
        try {
          const response = await uploadNewMediaOnEdit(newData);
          if (response) {
            checkedits();
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }

    setLoadingImg(false);
  };

  useEffectOnce(() => {
    if (location.pathname.split("/")[3]) {
      checkedits();
    }
  });

  if (loading) {
    return <PurebrandLoader />;
  } else {
    return (
      <div className="row d-flex justify-content-center">
        <div className="col-12 justify-content-end">
          <button
            className="btn btn-primary"
            onClick={() => navigate("/user/showrooms")}
          >
            Go back
          </button>
        </div>
        <div className="col-md-3 col-sm-12">
          <div className="photo-view-product">
            {productData ? (
              <div className="photo-viewing">
                <div className="mt-1 mb-3">Photos:</div>
                {productData?.media.map((media) => (
                  <>
                    {media.picture ? (
                      <div
                        className="d-flex mt-1 mb-1 row"
                        key={media?.picture.id}
                      >
                        <div className="col-9 text-center">
                          <img
                            src={media?.picture?.file}
                            style={{
                              height: "100px",
                              width: "100%",
                              "object-fit": "cover",
                            }}
                            alt="product"
                          />
                        </div>
                        <div className="col-3 delete-wrap">
                          <TrashFill
                            color="#f04b4b"
                            onClick={() => {
                              handleShow();
                              setActiveProduct(`${media.id}/?type=true`);
                            }}
                          />
                        </div>
                        <CustomModal
                          key={media.id}
                          showStatus={show}
                          clickStatus={handleClose}
                          buttonMessage="Close"
                          closeStatus={handleClose}
                          title="Delete Photo"
                          message={
                            <div className="good-bad-photo-viewer">
                              Are you sure want to delete the photo?
                            </div>
                          }
                          // console.log(`${media.id}/?type=true`)
                          secondMessage="Delete"
                          submitActionDelete={deleteSingleMedia}
                          setActiveProduct={setActiveProduct}
                          secondButton={true}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                ))}
                <div className="add_media d-flex justify-content-center">
                  {loadingImg ? (
                    <>
                      <PurebrandLoader
                        textMessage={"Uploading"}
                        colorCode={"rgba(248, 248, 248, 0.7)"}
                      />
                    </>
                  ) : (
                    <>
                      {productData.media_count.picture_count > 4 ? (
                        <></>
                      ) : (
                        <>
                          <PlusLg size={30} onClick={handleShowUploader} />
                          <CustomModal
                            showStatus={showUploader}
                            clickStatus={handleCloseUploader}
                            buttonMessage="Close"
                            closeStatus={handleCloseUploader}
                            title="Add Photo"
                            message={
                              <div className="good-bad-photo-viewer">
                                <CustomUploader
                                  submitAction={handleSubmitUpload}
                                  closeAction={handleCloseUploader}
                                  type="image"
                                  maximumSize={10000000}
                                  acceptType={{ "image/*": [] }}
                                />
                              </div>
                            }
                            secondMessage="noClose"
                            secondButton={false}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            ) : (
              <div>No media yet</div>
            )}
          </div>
          <div className="photo-view-product">
            {productData ? (
              <div className="video-wrapper">
                <div className="photo-viewing">
                  <div className="mt-1 mb-3">Videos:</div>
                  {productData?.media.map((media) => (
                    <>
                      {media.video ? (
                        <div
                          className="d-flex mt-1 mb-1"
                          key={media?.picture?.id}
                        >
                          <div className="col-9 text-center">
                            <video
                              playsInline
                              autoPlay={false}
                              style={{
                                height: "100px",
                                "object-fit": "cover",
                                width: "auto",
                                margin: "5px",
                              }}
                              // muted
                              loop
                              className="video-player_product"
                            >
                              <source src={media.video.file} type="video/mp4" />
                            </video>
                          </div>
                          <div className="col-3 delete-wrap">
                            <TrashFill
                              color="#f04b4b"
                              onClick={() => {
                                handleShowA();
                                setActiveProduct(`${media.id}/?type=false`);
                              }}
                            />
                            <CustomModal
                              key={media.id}
                              showStatus={showA}
                              clickStatus={handleCloseA}
                              buttonMessage="Close"
                              closeStatus={handleCloseA}
                              title="Delete Video"
                              message={
                                <div className="good-bad-photo-viewer">
                                  Are you sure want to delete the Video?
                                </div>
                              }
                              secondMessage="Delete"
                              submitActionDelete={deleteSingleMedia}
                              setActiveProduct={setActiveProduct}
                              secondButton={true}
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  ))}
                  <div className="add_media  d-flex justify-content-center">
                    {productData.media_count.picture_count > 4 ? (
                      <></>
                    ) : (
                      <>
                        <PlusLg size={30} onClick={handleShowUploaderVideo} />
                        <CustomModal
                          showStatus={showUploaderVideo}
                          clickStatus={handleCloseUploaderVideo}
                          buttonMessage="Close"
                          closeStatus={handleCloseUploaderVideo}
                          title="Add Video"
                          message={
                            <div className="good-bad-photo-viewer">
                              <CustomUploader
                                submitAction={handleSubmitUpload}
                                closeAction={handleCloseUploaderVideo}
                                type="video"
                                maximumSize={50000000}
                                acceptType={{ "video/*": [] }}
                              />
                            </div>
                          }
                          // console.log(`${media.id}/?type=true`)
                          secondMessage="noClose"
                          secondButton={false}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div>No media yet</div>
            )}
          </div>
        </div>
        {/* {console.log("eeee", productData)} */}
        <div className="col-md-6 col-sm-12">
          <div className="questions-view-product">
            <Formik
              initialValues={productData?.questionnaire}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="button_wrapper d-flex justify-content-end">
                    {/* {disabledStatus ? (
                      <div
                        className="btn-secondary_custom"
                        onClick={() => setDisabledStatus(false)}
                      >
                        Edit
                      </div>
                    ) : (
                      <div
                        className="btn-secondary_custom"
                        onClick={() => setDisabledStatus(true)}
                      >
                        Cancel
                      </div>
                    )} */}
                  </div>
                  <div className="product_uploader_view">
                    <CustomSlider
                      question={" Did the product arrive on time? *"}
                      formValue={productData?.questionnaire.arrival_time}
                      optionalFieldName={"arrival_time_optional"}
                      // disableStatus={disabledStatus}
                      setFieldValue={setFieldValue}
                      fieldName={"arrival_time"}
                    />
                    <CustomSlider
                      question={"Did product arrive undamaged? *"}
                      formValue={productData?.questionnaire.damage_rating}
                      optionalFieldName={"damage_rating_optional"}
                      // disableStatus={disabledStatus}
                      setFieldValue={setFieldValue}
                      fieldName={"damage_rating"}
                    />
                    <CustomSlider
                      question={
                        "Was the quality of the product what you were expecting? *"
                      }
                      formValue={productData?.questionnaire.quality_one}
                      optionalFieldName={"quality_one_optional"}
                      // disableStatus={disabledStatus}
                      setFieldValue={setFieldValue}
                      fieldName={"quality_one"}
                    />
                    <CustomSlider
                      question={
                        "Did product function as expected? Rate the experience. *"
                      }
                      formValue={productData?.questionnaire.quality_two}
                      optionalFieldName={"quality_two_optional"}
                      // disableStatus={disabledStatus}
                      setFieldValue={setFieldValue}
                      fieldName={"quality_two"}
                    />
                    <div id="my-radio-group">
                      <p className="question-product">
                        Did you need customer service? *
                      </p>
                    </div>
                    <div
                      className="form-group d-flex"
                      role="group"
                      aria-labelledby="my-radio-group"
                    >
                      <p> Yes </p>
                      <Field
                        className="radio-input"
                        type="radio"
                        // disabled={disabledStatus}
                        name="customer_service"
                        value="yes"
                      />
                      <p> No </p>
                      <Field
                        className="radio-input"
                        type="radio"
                        // disabled={disabledStatus}
                        name="customer_service"
                        value="no"
                      />
                    </div>
                    <div className="optional-question">
                      {values?.customer_service === "yes" ? (
                        <CustomSlider
                          question={"Was the problem solved? *"}
                          formValue={
                            productData?.questionnaire.customer_service_answer
                          }
                          optionalFieldName={"customer_service_answer_optional"}
                          // disableStatus={disabledStatus}
                          setFieldValue={setFieldValue}
                          fieldName={"customer_service_answer"}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                    <CustomSlider
                      question={
                        "Were you able to quickly navigate and find what what you were looking for when shopping?  *"
                      }
                      formValue={productData?.questionnaire.order_one}
                      optionalFieldName={"order_one_optional"}
                      // disableStatus={disabledStatus}
                      setFieldValue={setFieldValue}
                      fieldName={"order_one"}
                    />
                    <CustomSlider
                      question={"Was the shopping experience clear and easy? *"}
                      formValue={productData?.questionnaire.order_two}
                      optionalFieldName={"order_two_optional"}
                      // disableStatus={disabledStatus}
                      setFieldValue={setFieldValue}
                      fieldName={"order_two"}
                    />
                    <CustomSlider
                      question={
                        "How well did the assembly/installation meet expectations? *"
                      }
                      formValue={productData?.questionnaire.install_setup}
                      optionalFieldName={"install_setup_optional"}
                      // disableStatus={disabledStatus}
                      setFieldValue={setFieldValue}
                      fieldName={"install_setup"}
                    />
                    <div id="my-radio-group">
                      <p className="question-product">
                        Would you, or have you ordered again? *
                      </p>
                    </div>
                    <div
                      className="form-group d-flex"
                      role="group"
                      aria-labelledby="my-radio-group"
                    >
                      <p> Yes </p>
                      <Field
                        className="radio-input"
                        type="radio"
                        // disabled={disabledStatus}
                        name="order_again"
                        value="yes"
                      />
                      <p> No </p>
                      <Field
                        className="radio-input"
                        type="radio"
                        // disabled={disabledStatus}
                        name="order_again"
                        value="no"
                      />
                    </div>
                    <div className="optional-question">
                      {values?.order_again === "no" ? (
                        <OptionalQuestion
                          name="order_again_optional"
                          message="Help us know more."
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  {/* {disabledStatus ? (
                    <></>
                  ) : ( */}
                  <div className="button_wrapper">
                    <button className="btn btn-primary" type="submit">
                      Submit
                    </button>
                  </div>
                  {/* )} */}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
};

export default ShowroomEdit;
