import NavComponent from "components/Navigation/NavComponent";
import AuthContext from "context/AuthContext";
import React, { useContext } from "react";
import { Tabs } from "react-bootstrap";
import AssignNewRole from "./AssignNewRole";
import ViewAllRole from "./ViewAllRole";

const AddRole = () => {
  return (
    <div className="card cardc p-4">
      <Tabs
        defaultActiveKey="assign"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <NavComponent
          eventKey="assign"
          title="Assign Role"
          className="payout-list-wrapper"
        >
          <AssignNewRole />
        </NavComponent>
        <NavComponent
          eventKey="assigned"
          title="Assigned Roles"
          className="payout-list-wrapper"
        >
          <ViewAllRole />
        </NavComponent>
      </Tabs>
    </div>
  );
};

export default AddRole;
