import { Purebrand } from "assets/images";
import React, { useState, useEffect } from "react";
import { Container, Navbar } from "react-bootstrap";
import * as Sentry from "../sentry";

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {
    const handleError = (event) => {
      event.preventDefault();
      if (event.reason.message.match("(messaging/unsupported-browser)")) {
        setHasError(false);
      } else {
        setHasError(true);
        setError(event.error);
      }
    };
    window.addEventListener("unhandledrejection", handleError);
    return () => {
      window.removeEventListener("unhandledrejection", handleError);
    };
  }, []);

  useEffect(() => {
    const handleErrors = (error, errorInfo) => {
      Sentry.catchErrorsWithScope(error, errorInfo);
      setError(error);
    };
    window.addEventListener("error", handleErrors);
  }, []);

  if (hasError) {
    return (
      <React.Fragment>
        <header>
          <Navbar bg="transparent">
            <Container>
              <Navbar.Brand className="pb-logo-header">
                <img
                  src={Purebrand}
                  className="Header-img"
                  alt="logo"
                  onClick={() => (window.location.href = "/")}
                />
              </Navbar.Brand>
            </Container>
          </Navbar>
        </header>
        <Container>
          <h1>Something went wrong.</h1>
          <p>We apologize for the inconvenience. Please try again later.</p>
        </Container>
      </React.Fragment>
    );
  }

  return children;
};

export default ErrorBoundary;
