import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useState } from "react";

const PaypalPay = ({ price, type, paymentDetails, paymentId }) => {
  const [message, setMessage] = useState(null);
  return (
    <div>
      <PayPalScriptProvider
        options={{
          "client-id": `${process.env.REACT_APP_PAYPAL_CLIENT_ID}`,
          components: "buttons",
          "enable-funding": "venmo",
          intent: "authorize",
        }}
      >
        <PayPalButtons
          fundingSource="paypal"
          style={{ layout: "vertical", shape: "pill" }}
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: "0.01",
                  },
                },
              ],
            });
          }}
          onApprove={(data, actions) => {
            return actions.order.authorize().then(function (authorization) {
              const authData = {
                access_token:
                  authorization.purchase_units[0].payments.authorizations[0].id,
                token_type: "Bearer",
                expires_in:
                  authorization.purchase_units[0].payments.authorizations[0]
                    .expiration_time,
              };
              console.log("Authorization:", authData);
              // Now you can send the authorization token to your server to complete the payment
            });
          }}
          onCancel={(data, actions) => {
            // console.log(actions);
            // console.log(data);
            setMessage("Order cancelled by user");
          }}
          onError={(err) => {
            console.log(err);
            setMessage("Error occured while processing payment");
          }}
          scopes={["openid"]}
          responseType="id_token"
          returnUrl="https://purebrand.io/user/accounting"
        />
      </PayPalScriptProvider>
    </div>
  );
};

export default PaypalPay;
