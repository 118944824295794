import React from "react";
import { ShareScreen, ShareScreenOff } from "../../assets/images/index";

export default function ScreenShareButton({ toggleShare, screenShare }) {
  return (
    <>
      <button onClick={toggleShare} className="round-primary-btn">
        {!screenShare ? (
          <img src={ShareScreen} alt="screenshare" height={"45px"} />
        ) : (
          <img src={ShareScreenOff} alt="stopscreenshare" height={"45px"} />
        )}
      </button>
    </>
  );
}
