import PurebrandLoader from "components/Loader/PurebrandLoader";
import DataContext from "context/DataContext";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffectOnce } from "hooks/useEffectOnce";
import React, { useContext, useState } from "react";
import { Table } from "react-bootstrap";

const PlansSetup = () => {
  const { adminCommission, commissionData, adminCommissionNew } =
    useContext(DataContext);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffectOnce(() => {
    let res = adminCommission();
    res.then(() => setLoading(false));
  }, []);
  if (loading) {
    return <PurebrandLoader />;
  } else {
    return (
      <div className="row">
        {" "}
        <div className="col col-4">
          <div className="Commission_Detail_list">
            <h5>Current Rates</h5>
            <div className="detail-wrapper-commission">
              <Table>
                <thead>
                  <tr>
                    <th>No. of users</th>
                    <th>Commission (%)</th>
                  </tr>
                </thead>
                {commissionData ? (
                  <tbody>
                    {commissionData.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <p>{data.max_host}</p>
                          </td>
                          <td>
                            <p>{data.percentage}%</p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <></>
                )}
              </Table>
            </div>
          </div>
        </div>
        <div className="col col-8">
          <div>
            <h5>Create Custom Commission</h5>
          </div>
          <Formik
            initialValues={{
              max_host: 0,
              percentage: 0.0,
            }}
            onSubmit={(e) => {
              e.max_host = parseFloat(e.max_host);
              const res = adminCommissionNew(e);
              res
                .then(function (response) {
                  if (response) {
                    setError(false);
                    adminCommission();
                  }
                })
                .catch(function (error) {
                  setError(true);
                });
            }}
          >
            {({ touched, values, setFieldValue, errors }) => (
              <Form>
                <div className="row mt-4">
                  <div className="col col-md-2">
                    <label>No of users</label>
                    <Field
                      type="number"
                      name="max_host"
                      className={`form-control ${
                        touched.max_host && errors.max_host ? "is-invalid" : ""
                      }`}
                    />
                    <ErrorMessage
                      component="div"
                      name="max_host"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="col col-md-1">
                    <label>Commission</label>
                    <Field
                      placeholder="Value of percentage"
                      type="number"
                      name="percentage"
                      className={`form-control ${
                        touched.percentage && errors.percentage
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      component="div"
                      name="percentage"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
                <div className="submit-button-wrapper mt-3">
                  <button className="btn btn-primary" type="submit">
                    Save
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          {error ? <p>Error</p> : <></>}
        </div>
      </div>
    );
  }
};

export default PlansSetup;
