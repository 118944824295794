import React from "react";
import { Navigate } from "react-router-dom";
import ProfileForm from "components/Forms/Multistep/ProfileCompletion/ProfileForm";
import { useContext } from "react";
import AuthContext from "context/AuthContext";
import StoreProfileForm from "components/Forms/Multistep/ProfileStore/StoreProfileForm";
import AdminProfileController from "components/Forms/Multistep/ProfileAdmin/AdminProfileController";
import AdminRoleProfileForm from "components/Forms/Multistep/ProfileStore/AdminRoleProfileForm";

const Host = () => {
  let { user, userProfile } = useContext(AuthContext);

  if (userProfile?.complete_profile) {
    return <Navigate to="/user/welcome" />;
  }
  if (user.role === 3) {
    return (
      <>
        <div className="host-homepage">
          <ProfileForm />
        </div>
      </>
    );
  } else if (user.role === 2) {
    return (
      <>
        <div className="host-homepage">
          <StoreProfileForm />
        </div>
      </>
    );
  } else if (user.role === 4 || 5 || 6) {
    return (
      <>
        <div className="host-homepage">
          <AdminRoleProfileForm />
        </div>
      </>
    );
  } else if (user.role === 1) {
    return (
      <>
        <div className="host-homepage">
          <AdminProfileController />
        </div>
      </>
    );
  }
};

export default Host;
