import ProfileContext from "context/ProfileContext";
import React, { useContext, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import { CaretDown, CaretUp, Search } from "react-bootstrap-icons";
import HostList from "./HostList";
import "./hostlisting.css";
import { Field, Form, Formik } from "formik";

const HostApprovals = () => {
  let {
    updatedHosts,
    updateHostRequests,
    nextStepHostApproval,
    prevStepHostApproval,
  } = useContext(ProfileContext);
  const [loadingPros, setLoadingPros] = useState(false);
  const [searchTerminology, setSearchTerminology] = useState("");
  const [sortStatus, setSortStatus] = useState(false);

  const gethosts = () => {
    if (loadingPros) {
      return (
        <tr>
          <td>
            <div className="d-flex justify-content-center">
              <Spinner animation="grow" />
              <Spinner animation="grow" />
              <Spinner animation="grow" />
            </div>
          </td>
        </tr>
      );
    } else {
      if (updatedHosts && updatedHosts.length < 1) {
        return (
          <tr>
            <td className="no-data-wrap">No data available yet!</td>
          </tr>
        );
      } else {
        return updatedHosts?.map((updatedhost, index) => {
          return <HostList key={index} hostDetails={updatedhost} />;
        });
      }
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-end">
        <div className="search-wrapper col-md-4 col-sm-5 mb-3">
          <Formik
            initialValues={{ searchterm: "" }}
            onSubmit={(value) => {
              setLoadingPros(true);
              let res = updateHostRequests(
                `?order=true&search=${value.searchterm}`
              );
              res.then(() => setLoadingPros(false));
            }}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <div className="row">
                  <div className="col-12 d-flex">
                    <Field
                      type="search"
                      placeholder="Search Product"
                      className="form-control"
                      onChange={(e) => {
                        setSearchTerminology(e.target.value);
                        setFieldValue("searchterm", e.target.value);
                      }}
                      value={values.searchterm}
                      name="searchterm"
                    />
                    <button type="submit" className="search magnifier">
                      <Search />
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <Table className="table-store" responsive>
        <thead>
          <tr>
            <th scope="col">
              <div className="arrows">
                Host
                <div
                  className="arrow-grid"
                  onClick={() => {
                    setLoadingPros(true);
                    setSortStatus(!sortStatus);
                    let res = updateHostRequests(
                      `?order=true&host=${sortStatus}&search=${searchTerminology}`
                    );
                    res.then(() => setLoadingPros(false));
                  }}
                >
                  <CaretUp />
                  <CaretDown />
                </div>
              </div>
            </th>
            <th scope="col">
              <div className="arrows">
                Time waiting{" "}
                <div
                  className="arrow-grid"
                  onClick={() => {
                    setLoadingPros(true);
                    setSortStatus(!sortStatus);
                    let res = updateHostRequests(
                      `?order=${sortStatus}&search=${searchTerminology}`
                    );
                    res.then(() => setLoadingPros(false));
                  }}
                >
                  <CaretUp />
                  <CaretDown />
                </div>
              </div>
            </th>
            <th scope="col">
              <div className="arrows">
                Responses{" "}
                <div
                  className="arrow-grid"
                  onClick={() => {
                    setLoadingPros(true);
                    setSortStatus(!sortStatus);
                    let res = updateHostRequests(
                      `?order=true&status=${sortStatus}&search=${searchTerminology}`
                    );
                    res.then(() => setLoadingPros(false));
                  }}
                >
                  <CaretUp />
                  <CaretDown />
                </div>
              </div>
            </th>
            <th scope="col">
              <div className="arrows">
                Product{" "}
                <div
                  className="arrow-grid"
                  onClick={() => {
                    setLoadingPros(true);
                    setSortStatus(!sortStatus);
                    let res = updateHostRequests(
                      `?order=true&product=${sortStatus}&search=${searchTerminology}`
                    );
                    res.then(() => setLoadingPros(false));
                  }}
                >
                  <CaretUp />
                  <CaretDown />
                </div>
              </div>
            </th>
            <th scope="col">
              <div className="arrows">
                Edited{" "}
                <div
                  className="arrow-grid"
                  onClick={() => {
                    setLoadingPros(true);
                    setSortStatus(!sortStatus);
                    let res = updateHostRequests(
                      `?order=true&edited=${sortStatus}&search=${searchTerminology}`
                    );
                    res.then(() => setLoadingPros(false));
                  }}
                >
                  <CaretUp />
                  <CaretDown />
                </div>
              </div>
            </th>
            <th>Media</th>
            <th>Social</th>
          </tr>
        </thead>
        <tbody>{gethosts()}</tbody>
      </Table>
      {(nextStepHostApproval !== null || prevStepHostApproval !== null) && (
        <div className="button-wrapper-product text-center my-4">
          <button
            onClick={() => {
              setLoadingPros(true);
              let res = updateHostRequests(
                new URL(prevStepHostApproval).search
              );
              res.then(() => setLoadingPros(false));
            }}
            className="btn btn-outline-primary previous-button"
            disabled={prevStepHostApproval === null}
          >
            Prev
          </button>
          <button
            onClick={() => {
              setLoadingPros(true);
              let res = updateHostRequests(
                new URL(nextStepHostApproval).search
              );
              res.then(() => setLoadingPros(false));
            }}
            className="btn btn-outline-primary previous-button"
            disabled={nextStepHostApproval === null}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default HostApprovals;
