import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, InputNumber, message } from 'antd';
import './AddFundsModal.css';
import { MESSAGES } from './constants/messages';
import { transferFunds } from 'Services/dotsService';
import { GENERIC_ERROR_MESSAGE } from 'utils/constants';

/**
 * This component renders a modal for transfering the funds
 *
 * @return {ReactNode} A React element that renders a modal for transfering the funds
 */

const FundsModal = ({ fundsModalOpen, handleModalClose, getStoreAdminsStatus }) => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const [addFundsLoading, setAddFundsLoading] = useState(false);

  const onFinish = async (values) => {

    const payload = { ...values };

    setAddFundsLoading(true);

    try {
      await transferFunds(payload);
      messageApi.open({
        type: 'success',
        content: 'Successfully submitted compliance information',
      });
      setAddFundsLoading(false);
      handleModalClose();
      getStoreAdminsStatus();
    } catch (e) {
      console.log(e);
      setAddFundsLoading(false);
      messageApi.open({
        type: 'error',
        content:
          e?.response?.data?.message || e?.message || GENERIC_ERROR_MESSAGE,
        duration: 5,
      });
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [fundsModalOpen]);

  return (
    <>
      {contextHolder}
      <Modal
        open={fundsModalOpen}
        title={<div className="funds-title">Enter the amount of funds</div>}
        className="funds-modal"
        onCancel={() => {
          handleModalClose();
        }}
        footer={null}
      >
        <Form layout={'vertical'} form={form} onFinish={onFinish}>
          <Form.Item
            label="Funds Amount"
            name="amount"
            rules={[
              { required: true, message: MESSAGES.FUNDS_AMOUNT_REQUIRED },
            ]}
          >
            <InputNumber
              min={1}
              max={1000}
              placeholder="50.00"
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={addFundsLoading} disabled={addFundsLoading}>
              Trasnfer
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default FundsModal;
