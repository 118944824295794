import React, { useContext, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import "./hostlisting.css";
import LogbookAllList from "../Logbook/LogbookAllList";
import LogContext from "context/LogContext";

const HostLogbookList = () => {
  const [loadingPros, setLoadingPros] = useState(false);
  let { getAllHostLogbook, allLogbookNext, allLogbookPrevious, allLogbook } =
    useContext(LogContext);

  const getAllLogbook = () => {
    if (loadingPros) {
      return (
        <tr>
          <td>
            <div className="d-flex justify-content-center">
              <Spinner animation="grow" />
              <Spinner animation="grow" />
              <Spinner animation="grow" />
            </div>
          </td>
        </tr>
      );
    } else {
      if (allLogbook?.length === 0) {
        return (
          <tr>
            <td>No Data to show !</td>
          </tr>
        );
      } else if (allLogbook) {
        return allLogbook?.map((logbook) => {
          return <LogbookAllList key={logbook.id} log={logbook} />;
        });
      } else {
        return <></>;
      }
    }
  };

  return (
    <div>
      <Table className="table-store" responsive>
        <thead>
          <tr>
            <th scope="col">
              <div className="arrows">Host</div>
            </th>
            <th scope="col">
              <div className="arrows">Notes</div>
            </th>
            <th scope="col">
              <div className="arrows">Created At</div>
            </th>
            <th scope="col">
              <div className="arrows"> Commentor </div>
            </th>
          </tr>
        </thead>
        <tbody>{getAllLogbook()}</tbody>
      </Table>
      {(allLogbookNext !== null || allLogbookPrevious !== null) && (
        <div className="button-wrapper-product text-center my-4">
          <button
            onClick={() => {
              setLoadingPros(true);
              let res = getAllHostLogbook(new URL(allLogbookPrevious).search);
              res.then(() => setLoadingPros(false));
            }}
            disabled={allLogbookPrevious === null}
            className="btn btn-outline-primary previous-button"
          >
            Prev
          </button>
          <button
            onClick={() => {
              setLoadingPros(true);
              let res = getAllHostLogbook(new URL(allLogbookNext).search);
              res.then(() => setLoadingPros(false));
            }}
            disabled={allLogbookNext === null}
            className="btn btn-outline-primary previous-button"
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default HostLogbookList;
