import PurebrandLoader from "components/Loader/PurebrandLoader";
import React, { useCallback, useContext, useEffect, useState } from "react";
import RecordingCard from "../../components/Recordings-listinng/RecordingCard";
import RecordingsToolbar from "../../components/Recordings-listinng/RecordingsToolbar";
import VideoContext from "../../context/VideoContext";

const RecordMeetings = () => {
  const { getVideoRecordings } = useContext(VideoContext);
  const [recordingsDets, setRecordingDets] = useState([]);
  const [filterCriteria, setFilterCriteria] = useState({
    order: true,
    limit: 20,
    offset: 0,
  });

  const [nextStep, setNextStep] = useState();
  const [prevStep, setPrevStep] = useState();
  const [loading, setLoading] = useState(true);
  const setRecordings = useCallback(
    async (filter) => {
      setLoading(true);
      Object.keys((key) => {
        if (filter[key] === "") delete filter[key];
      });
      try {
        const data = await getVideoRecordings(filter);
        setRecordingDets(data.data.results);
        setNextStep(data?.data?.next);
        setPrevStep(data?.data?.previous);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    },
    [getVideoRecordings]
  );

  useEffect(() => {
    setRecordings(filterCriteria).catch((e) => {});
  }, [filterCriteria]);

  const updateRecordListings = (newFilter) => {
    if (newFilter.timeline) {
      setFilterCriteria((prevState) => {
        delete prevState.date;
        return prevState;
      });
    }
    if (newFilter.date) {
      setFilterCriteria((prevState) => {
        delete prevState.timeline;
        return prevState;
      });
    }
    setFilterCriteria({ ...filterCriteria, ...newFilter });
  };

  const handlePrevious = () => {
    if (prevStep) {
      updateRecordListings({
        offset: filterCriteria.offset - 20,
      });
    }
  };
  const handleNext = () => {
    if (nextStep) {
      updateRecordListings({
        offset: filterCriteria.offset + 20,
      });
    }
  };

  return (
    <>
      <RecordingsToolbar updateRecordListings={updateRecordListings} />
      {loading ? (
        <PurebrandLoader />
      ) : (
        <>
          <div className="d-flex flex-wrap row product-recording-wrapper">
            {recordingsDets.map((recording, index) => (
              <RecordingCard key={index} recording={recording} />
            ))}
          </div>
          <div className="text-end">
            <button
              onClick={handlePrevious}
              disabled={!prevStep}
              className=" previous-button-product"
            >
              Prev
            </button>
            <button
              onClick={handleNext}
              disabled={!nextStep}
              className=" previous-button-product"
            >
              Next
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default RecordMeetings;
