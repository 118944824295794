export const SSN_LENGTH = 9;

export const MESSAGES = {
  ACCOUNT_NUMBER_REQUIRED: 'Please enter your account number',
  ROUTING_NUMBER_REQUIRED: 'Please enter your routing number',
  ACCOUNT_TYPE_REQUIRED: 'Please select account type',
  FUNDS_AMOUNT_REQUIRED: 'Please enter the amount of funds',

  COMPANY_NAME_REQUIRED: 'Please enter company name',
  COMPANY_WEBSITE_REQUIRED: 'Please enter company website',
  COMPANY_DBA_REQUIRED: 'Please enter company DBA',
  COMPANY_TAX_REQUIRED: 'Please enter tax ID',
  INCORPORATION_TYPE_REQUIRED: 'Please select incorporation type',
  INCORPORATION_STATE_REQUIRED: 'Please select incorporation state',
  INCORPORATION_DATE_REQUIRED: 'Please select incorporation date',

  UBO_COUNT_REQUIRED: 'Please select atleast one UBO',

  FIRST_NAME_REQUIRED: 'Please enter first name',
  LAST_NAME_REQUIRED: 'Please enter last name',
  EMAIL_REQUIRED: 'Please enter email',
  PHONE_REQUIRED: 'Please enter phone number',
  TITLE_REQUIRED: 'Please enter title',
  OWNERSHIP_PERCENTAGE_REQUIRED: 'Please enter ownership percentage',
  SSN_REQUIRED: 'Please enter social security number',
  SSN_LENGTH: 'SSN must be exactly 9 digits long',
  DOB_REQUIRED: 'Please select date of birth',
  LINE1_REQUIRED: 'Please enter Address Line 1',
  LINE2_REQUIRED: 'Please enter Address Line 2',
  CITY_REQUIRED: 'Please enter city',
  ZIP_REQUIRED: 'Please enter zip code',
  STATE_REQUIRED: 'Please select state',
  COUNTRY_REQUIRED: 'Please select country',

  USAGE_REQUIRED: 'Please enter the description of your business',
  REASONS_REQUIRED: 'Please describe the flow of your funds',

  ACCOUNT_NUMBER_LEN: 'Account number must be a 8 - 12 digit number',
  ROUTING_NUMBER_LEN: 'Routing number must be a 9 digit number.',
};
