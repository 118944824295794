import React, { useContext, useState } from 'react';
import { Modal, Button, message } from 'antd';
import './SubscriptionModal.css';
import {
  GENERIC_ERROR_MESSAGE,
  SUBSCRIPTION_ACTION_TYPE,
  SUBSCRIPTION_MODAL_ACTIONS,
  SUBSCRIPTION_STATUS,
} from 'utils/constants';
import AuthContext from 'context/AuthContext';
import SubscriptionContext from 'context/SubscriptionContext';

/**
 * This component renders a modal for different subscription actions
 *
 * @return {ReactNode} A React element that renders a modal for various subscriptions actions
 */

const SubscriptionModal = ({
  subscriptionModalOpen,
  subscriptionModalAction,
  handleSubscriptionModalClose,
  setManageActivePlan,
  getSubscriptionInfo,
  activePlan,
  plan,
}) => {
  const { userProfile } = useContext(AuthContext);
  const {
    startSubscriptionForStore,
    cancelSubscriptionForStore,
    changeSubscriptionStatusForStore,
  } = useContext(SubscriptionContext);
  const [messageApi, contextHolder] = message.useMessage();

  const [updateSubscriptionLoading, setUpdateSubscriptionLoading] =
    useState(false);

  /**
   * This function is used to update the active subscription of the store
   *
   * @returns {void}
   */

  const changeActiveSubscriptionStatus = async () => {
    const payload = {
      store_identifier: userProfile?.store?.store_id,
      type:
        activePlan?.subscription_status === SUBSCRIPTION_STATUS.ACTIVE
          ? SUBSCRIPTION_ACTION_TYPE.PAUSE
          : SUBSCRIPTION_ACTION_TYPE.RESUME,
    };
    setUpdateSubscriptionLoading(true);
    try {
      await changeSubscriptionStatusForStore(payload);
      setUpdateSubscriptionLoading(false);
      handleSubscriptionModalClose();
      getSubscriptionInfo();
      setManageActivePlan(false);
    } catch (e) {
      console.log(e);
      setUpdateSubscriptionLoading(false);
      messageApi.open({
        type: 'error',
        content:
          e?.response?.data?.message || e?.message || GENERIC_ERROR_MESSAGE,
        duration: 5,
      });
    }
  };

  /**
   * This function is used to cancel the active subscription of the store
   *
   * @returns {void}
   */

  const cancelActiveSubscription = async () => {
    const payload = {
      store_identifier: userProfile?.store?.store_id,
    };

    setUpdateSubscriptionLoading(true);

    try {
      await cancelSubscriptionForStore(payload);
      setUpdateSubscriptionLoading(false);
      handleSubscriptionModalClose();
      getSubscriptionInfo();
      setManageActivePlan(false);
    } catch (e) {
      console.log(e);
      setUpdateSubscriptionLoading(false);
      messageApi.open({
        type: 'error',
        content:
          e?.response?.data?.message || e?.message || GENERIC_ERROR_MESSAGE,
        duration: 5,
      });
    }
  };

  /**
   * This function is used to handle the subscription action selection for any store
   *
   * @returns {void}
   */

  const onSubscriptionSelection = async () => {
    if (subscriptionModalAction === SUBSCRIPTION_MODAL_ACTIONS.UPDATE) {
      changeActiveSubscriptionStatus();
      return;
    }

    if (subscriptionModalAction === SUBSCRIPTION_MODAL_ACTIONS.CANCEL) {
      cancelActiveSubscription();
      return;
    }

    const payload = {
      subscription_type: plan?.id,
      store_identifier: userProfile?.store?.store_id,
    };

    setUpdateSubscriptionLoading(true);

    try {
      await startSubscriptionForStore(payload);
      setUpdateSubscriptionLoading(false);
      handleSubscriptionModalClose();
      getSubscriptionInfo();
      setManageActivePlan(false);
    } catch (e) {
      console.log(e);
      setUpdateSubscriptionLoading(false);
      messageApi.open({
        type: 'error',
        content:
          e?.response?.data?.message || e?.message || GENERIC_ERROR_MESSAGE,
        duration: 5,
      });
    }
  };

  /**
   * This function is used to get the modal text according to the subscription update action
   *
   * @returns {String} Text to be displayed in the confirmation modal body
   */

  const getModalBody = () => {
    if (plan?.id === activePlan?.subscription_type?.id) {
      if (
        subscriptionModalAction === SUBSCRIPTION_MODAL_ACTIONS.UPDATE &&
        activePlan?.subscription_status === SUBSCRIPTION_STATUS.PAUSED
      ) {
        return 'Are you sure you want to resume?';
      } else if (
        subscriptionModalAction === SUBSCRIPTION_MODAL_ACTIONS.UPDATE &&
        activePlan?.subscription_status !== SUBSCRIPTION_STATUS.PAUSED
      ) {
        return (
          <div>
            Are you sure you want to pause? <br /> All current meetings will be
            canceled. <br />
            <div className="pause-sub-body">
              Pausing your account will prevent users from scheduling demos and
              will pause your SAAS fees. Any commissions due to your Hosts from
              past demos will still be pulled from your account and distributed.
            </div>
          </div>
        );
      }
      return (
        <div>
          Are you sure you want to cancel? <br /> All current meetings will be
          canceled. <br />
          <div className="pause-sub-body">
            Cancelling your account will immediately initiate your SAAS fees for
            sales partnership. Any commissions due to your Hosts from past demos
            will be immediately pulled from your account and distributed.
          </div>
        </div>
      );
    }
    return 'Are you sure you want to select this plan ?';
  };

  /**
   * This function is used to get the modal button text according to the subscription update action
   *
   * @returns {String} Text to be displayed in the confirmation modal button
   */

  const getActionButtonText = () => {
    if (plan?.id === activePlan?.subscription_type?.id) {
      if (
        subscriptionModalAction === SUBSCRIPTION_MODAL_ACTIONS.UPDATE &&
        activePlan?.subscription_status === SUBSCRIPTION_STATUS.PAUSED
      ) {
        return 'Yes, Resume';
      } else if (
        subscriptionModalAction === SUBSCRIPTION_MODAL_ACTIONS.UPDATE &&
        activePlan?.subscription_status !== SUBSCRIPTION_STATUS.PAUSED
      ) {
        return 'Yes, Pause';
      }
      return 'Yes, Cancel';
    }
    return 'Yes, Select';
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={subscriptionModalOpen}
        centered
        className="subscription-modal"
        onCancel={() => {
          handleSubscriptionModalClose();
        }}
        footer={null}
      >
        <div className="subscription-modal-body">{getModalBody()}</div>
        <div className="subscription-modal-buttons">
          <Button
            className="cancel-sub-modal-button"
            onClick={handleSubscriptionModalClose}
          >
            Cancel
          </Button>
          <Button
            className="sub-selection-button"
            loading={updateSubscriptionLoading}
            onClick={onSubscriptionSelection}
          >
            {getActionButtonText()}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default SubscriptionModal;
