import React, { useCallback, useContext, useState } from "react";
import DemoContext from "context/DemoContext";
import "./hostdashboard.css";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from "hooks/useEffectOnce";
import ProfileContext from "context/ProfileContext";
import { Spinner } from "react-bootstrap";
import { Icon } from "assets/images";

const SuggestedProducts = ({ showrooms, productList }) => {
  const navigate = useNavigate();

  const getSuggestions = useCallback(() => {
    if (productList && productList.length > 0) {
      for (let i = 0; i < productList?.length; i++) {
        for (let j = 0; j < showrooms?.length; j++) {
          if (productList[i]?.product_id === showrooms[j]?.product.identifier) {
            productList?.splice(i, 1);
          }
        }
      }
      if (productList?.length > 5) {
        productList = productList.slice(0, 4);
      }

      return productList.map((p, index) => {
        return (
          <div
            className="col-sm-6 col-md-3 suggested-product-lists cardc"
            onClick={() => navigate("/user/addproduct")}
            key={index}
          >
            <div className="list-group-item">
              <div className="row d-flex product-item-content">
                <div className="col col-md-5 sugg-product-image p-3">
                  <img
                    className="small-image-list"
                    src={p.product_image ? p.product_image : Icon}
                    alt="product"
                  />
                </div>
                <div className="col col-md-7 sugg-product-details">
                  <p className="product-title">{p.product_name}</p>
                  <p className="company">{p.shop_name}</p>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
  }, [productList]);
  if (productList?.length < 1) {
    return <></>;
  } else {
    return (
      <div className="suggested-product-listing">
        <div className="suggested-title">
          <p className="suggested-product-title">Suggested Products:</p>
        </div>
        <div className="d-flex suggested-wrap">
          {getSuggestions()}
          <div className="suggested-product-lists suggest-add-product-btn col-sm-3 col-md-3 cardc">
            <button
              className="btn btn-primary add-product"
              onClick={() => navigate("/user/addproduct")}
            >
              + Add a Product
            </button>
          </div>
        </div>
      </div>
    );
  }
};

export default React.memo(SuggestedProducts);
