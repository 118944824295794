import React from "react";

import { CameraVideoFill } from "react-bootstrap-icons";

const VideoSetting = ({ hasVideo, onVideoChange, className }) => {
  return (
    <div className="d-flex align-items-center gap-2 justify-content-center">
      <label htmlFor="video" className={`${hasVideo ? "camera-active" : ""}`}>
        <CameraVideoFill /> Video
      </label>
      <input
        className="form-check-input"
        id="video"
        type="checkbox"
        role="switch"
        name="AudioToggle"
        onChange={onVideoChange}
        checked={hasVideo}
      />
    </div>
  );
};

export default VideoSetting;
