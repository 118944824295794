import React, { useEffect, useState } from "react";
import AuthContext from "context/AuthContext";
import { Form, Formik } from "formik";
import { useContext } from "react";
import ApiContext from "context/ApiContext";
import { UploadImage } from "components/Forms/Multistep/ProfileCompletion/ProfileInfo";
import { FeatherImg, IphoneLayout } from "assets/images";
import ReactPlayer from "react-player";
import PurebrandLoader from "components/Loader/PurebrandLoader";
import "./shopifyDetails.css";
import { Button } from "antd";

const ShopifyDetails = () => {
  const {
    getDataShopifyShop,
    getShopifyLogo,
    updateShopifyLogo,
    getShopifyVideo,
    updateShopifyVideo,
  } = useContext(ApiContext);
  const {
    user,
    userProfile,
    updateShopifyStoreDetail,
    uploader,
    storeVideoUploader,
  } = useContext(AuthContext);
  const [shopData, setShopData] = useState();
  const [videoData, setVideoData] = useState();
  const [avatar, setAvatar] = useState(null);
  const [videoFile, setVideoFile] = useState();
  const [loading, setLoading] = useState(false);
  const [logoUpdateLoading, setlogoUpdateLoading] = useState(false);
  const [videoUpdateLoading, setvideoUpdateLoading] = useState(false);
  const [videoFileUpload, setVideoFileUpload] = useState();
  const [isError, setIsError] = useState(false);

  /**
   * This function is triggered when a user uploads a logo
   *
   * @param {object} values Values for file data to upload
   * @return {void}
   */

  const handleLogoUpload = (values) => {
    setlogoUpdateLoading(true);
    const res = uploader(values)
      .then(() => {
        updateShopifyLogo(values).then((res) => {
          updateLogoId(res.data.data.id);
          setlogoUpdateLoading(false);
        });
      })
      .catch((error) => {
        setlogoUpdateLoading(false);
        throw error;
      });
  };

  /**
   * This function is a for handling video upload
   *
   * @param {void}
   * @return {void}
   */

  const handleVideoUpload = async () => {
    setvideoUpdateLoading(true);
    if (videoData) {
      let payload = {
        storeid: shopData?.shop_id,
        logo: shopData?.logo,
        nameShop: shopData?.shop_name,
        days: shopData?.ship_day,
        video: videoData,
      };
      try {
        const res = await storeVideoUploader(payload);
        updateVideoId(res.data.data.id);
        if (res) {
          await updateShopifyVideo(videoData[0]);
        }
        setvideoUpdateLoading(false);
        setVideoData();
      } catch (error) {}
    } else {
      setvideoUpdateLoading(false);
    }
  };

  /**
   * This function is for updating the logo id in shop data state
   *
   * @param {void}
   * @return {void}
   */
  const updateLogoId = (newLogo) => {
    setShopData((prevState) => ({
      ...prevState,
      logo: newLogo,
    }));
  };

  /**
   * This function is for updating the video id in shop data state
   *
   * @param {void}
   * @return {void}
   */
  const updateVideoId = (newVideoId) => {
    setShopData((prevState) => ({
      ...prevState,
      videoid: newVideoId,
    }));
  };

  /**
   * This function is triggered when a user uploads a video and restricts the user if the file size exceeds the limit.
   *
   * @param {object} values Values for file data to upload
   * @return {void}
   */

  const handleVideoChange = (event) => {
    setvideoUpdateLoading(true);
    try {
      const file = event.target.files[0];
      setVideoData(file);
      if (file) {
        // Check file size
        if (file.size > 100 * 1024 * 1024) {
          // 100 MB limit
          setIsError(true);
          event.target.value = null;
          setvideoUpdateLoading(false);
          return;
        }

        const upload_video = URL.createObjectURL(file);
        setVideoFileUpload(upload_video);
        setvideoUpdateLoading(false);
        setIsError(false);
      } else {
        setvideoUpdateLoading(false);
      }
    } catch (err) {
      console.log("err", err);
      setvideoUpdateLoading(false);
    }
  };

  /**
   * This function is for handling the logo submit
   *
   * @param {object} LogoObject that handle logo data
   * @return {void}
   */

  const handleSubmit = async (values) => {
    try {
      const response = await updateShopifyStoreDetail(values);
      if (response && Boolean(values.files)) {
        handleLogoUpload(values);
      }
    } catch (error) {
      setlogoUpdateLoading(false);
    }
  };

  const getImage = (event) => {
    let reader = new FileReader();
    reader.readAsDataURL(event[0]);

    reader.onload = (e) => {
      let img = e.target.result;
      setAvatar(img);
    };
  };

  useEffect(() => {
    const response = async () => {
      setLoading(true);
      try {
        let res = await getDataShopifyShop(userProfile?.store?.store_id);
        setShopData(res?.data?.data[0]);
        if (res) {
          const logoId = res?.data?.data[0]?.logo;
          const videoId = res?.data?.data[0]?.videoid;

          if (!logoId || logoId === "undefined" || logoId === "null") {
            setAvatar(FeatherImg);
          } else {
            let logo;
            try {
              logo = await getShopifyLogo(logoId);
            } catch (err) {
              console.log(err);
            }
            setAvatar(logo?.data?.file);
          }
          if (!videoId || videoId === "undefined" || videoId === "null") {
            setVideoFile();
          } else {
            try {
              const video = await getShopifyVideo(videoId);
              setVideoFile(video?.data?.file);
            } catch (err) {
              console.log(err);
            }
          }
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        throw err;
      }
    };
    response();
  }, []);

  if (loading) {
    return <PurebrandLoader />;
  }

  const playVideo = videoFileUpload ? videoFileUpload : videoFile;

  return (
    <>
      <div
        className="row"
        style={{
          padding: "52px 20px 64px 20px",
          height: "auto",
          background: "#FFFFFF",
          borderRadius: "18px",
        }}
      >
        <div className="col-lg-4 col-md-4 col-sm-12 col-12 card cardc store_commission_card mt-3">
          <div className="card-body">
            <h5>Shopify Shop Details</h5>
            <div className="requirements-section mb-3">
              <h6 className="requirements-header">
                Requirements for Logo and Video
              </h6>
              <ul className="requirements-list">
                <li>
                  <span className="label">Logo file formats:</span>{" "}
                  <span>.jpg, .png, .gif</span>
                </li>
                <li>
                  <span className="label">Video file formats:</span>{" "}
                  <span>.mp4, .mov, .avi</span>
                </li>
                <li>
                  <span className="label">Maximum file size:</span>{" "}
                  <span>10MB for logo, 100MB for video</span>
                </li>
                <li>
                  <span className="label">File name length:</span>{" "}
                  <span>less than 100 characters</span>
                </li>
              </ul>
            </div>
            <hr />
            {!!shopData && (
              <Formik
                initialValues={{
                  delivery: shopData?.ship_day,
                  name: shopData?.shop_name,
                  logo: shopData?.logo,
                  videoid: shopData?.videoid,
                }}
                onSubmit={handleSubmit}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    <div className="form-group ">
                      {/* <h6 className="m-2">Text</h6>
                      <div className="col">
                        <input className="form-control " defaultValue={shopData?.text ? shopData?.text : `Schedule an Online Demo for 10 % Off!`} /> 
                         </div> */}
                      <h6 className="m-2">Shop Logo</h6>
                      <div className="col">
                        <UploadImage
                          setFieldValue={setFieldValue}
                          value="files"
                          name="logo"
                          class="uploader-parts"
                          title="Your Store Logo"
                          maxSize={10000000}
                          disabled={user.role === 5}
                          body={
                            !avatar ? (
                              <div className="mb-3">
                                <ul>
                                  <li>
                                    Accepted file formats: .jpg, .png, .gif
                                  </li>
                                  <li>Maximum file size: 10MB</li>
                                  <li>
                                    File name length: less than 100 characters
                                  </li>
                                </ul>
                              </div>
                            ) : (
                              <div className="">
                                <img
                                  src={avatar}
                                  className="img img_small_viewfinder"
                                  alt=""
                                />
                              </div>
                            )
                          }
                          footer="Click here or drag image here."
                          accept={{ "image/*": [] }}
                        />
                        {values.files && getImage(values.files)}
                      </div>
                      <Button
                        htmlType="submit"
                        className="mt-3 update-logo-button"
                        loading={logoUpdateLoading}
                        hidden={user.role === 5}
                      >
                        Update Logo
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
            <div className="video col">
              <h6 className="m-2">Upload and Preview Video</h6>
              <div className="uploader-parts mt-2" style={{ height: "auto" }}>
                <div className="video-uploader-container p-1">
                  <div className="input">
                    <input
                      type="file"
                      accept="video/*"
                      onChange={handleVideoChange}
                      className="btn btn-secondary w-100"
                      hidden={user.role === 5}
                    />
                    {/* Display error message if isError is true */}
                    {isError && (
                      <p className="error-message">
                        File size exceeds 100 MB limit. Please choose a smaller
                        file.
                      </p>
                    )}
                  </div>
                  {playVideo ? (
                    <div>
                      <div className="mt-4 upload-text">
                        <ReactPlayer
                          url={playVideo}
                          controls
                          playing={true}
                          loop={true}
                          width="100%"
                          height="auto"
                          muted={true}
                        />
                      </div>
                      <Button
                        className=" update-logo-button"
                        hidden={!videoFileUpload}
                        onClick={() => handleVideoUpload()}
                        loading={videoUpdateLoading}
                      >
                        Update video
                      </Button>
                    </div>
                  ) : (
                    <div className="uploader-part-video ">
                      <div className="upload-text">
                        Click above choose button to select a video.
                        <img
                          src={FeatherImg}
                          alt="feather"
                          width="20%"
                          className="upload-text"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-8 col-lg-8 card cardc mt-3">
          <div className="card-body">
            <h4 className="text-center">Shopify Contact Page Preview</h4>
            <div className="row">
              <div
                className="col-sm-12 col-md-5 col-lg-5"
                style={{ padding: "30px" }}
              >
                <div className="block text-center">
                  <h3 className="mt-4 pb-3">
                    Schedule an Online <br />
                    Demo for{" "}
                    {userProfile?.store?.discount_type === "PERCENTAGE" ? (
                      <> {userProfile?.store?.discount_given} %</>
                    ) : (
                      <>$ {userProfile?.store?.discount_given}</>
                    )}{" "}
                    Off!
                  </h3>
                  <div className="logo  img_small_viewfinder p-3">
                    <img
                      src={avatar}
                      alt="store_logo"
                      className="img img_small_viewfinder"
                      style={{ border: 0 }}
                    />
                  </div>
                  <div className="video p-4">
                    <div className="d-flex justify-content-center">
                      <div className="videocall__wrapper">
                        <div
                          className="iphone-wrapper"
                          style={{ position: "relative" }}
                        >
                          <div className="iphone-layout">
                            <img
                              src={IphoneLayout}
                              alt="iphone layout"
                              style={{
                                zIndex: 2,
                                display: "block",
                                width: "150px",
                                height: "300px",
                                position: "relative",
                              }}
                            />
                            <div
                              className="main_video"
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                width: "100%",
                                height: "auto",
                                zIndex: 0,
                                transform: "translate(-50%, -50%)",
                              }}
                            >
                              <ReactPlayer
                                url={playVideo}
                                playing={true}
                                loop={true}
                                muted={true}
                                height="293px"
                                width="144px"
                              />
                            </div>
                            <div className="secondary-video-wrap">
                              <ReactPlayer
                                url={
                                  "https://cdn.shopify.com/videos/c/o/v/5d524bf539fa466589613c635bcb91d6.mp4"
                                }
                                playing={true}
                                loop={true}
                                style={{
                                  maxWidth: "50px",
                                  position: "absolute",
                                  bottom: "20px",
                                  right: "20px",
                                }}
                                width="100%"
                                height="auto"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-7 col-lg-7">
                <p className="mt-4 h3">Zip Code</p>
                <input
                  placeholder="Postal Code"
                  disabled
                  style={{
                    padding: " 12px",
                    border: "0",
                    borderRadius: "10px",
                    width: "100%",
                    background: "#ddd",
                  }}
                />
                <br />
                <p className="mt-2 h3">Full Name</p>
                <input
                  placeholder="john"
                  disabled
                  style={{
                    padding: " 12px",
                    border: "0",
                    borderRadius: "10px",
                    width: "100%",
                    background: "#ddd",
                  }}
                />
                <br />
                <p className="mt-2 h3">Phone</p>
                <div className="d-flex">
                  <div className="w-25">
                    <div
                      className="d-flex"
                      style={{
                        padding: "12px",
                        border: "0",
                        borderRadius: "10px",
                        width: "80%",
                        background: "#ddd",
                      }}
                    >
                      🇺🇸
                      <span
                        style={{
                          fontSize: "20px",
                          fontWeight: "600",
                          marginTop: "-10px",
                          marginLeft: "3px",
                        }}
                      >
                        &#8964;
                      </span>
                    </div>
                  </div>
                  <div className="w-100">
                    <input
                      placeholder="201-555-0123"
                      disabled
                      style={{
                        padding: " 12px",
                        border: "0",
                        borderRadius: "10px",
                        width: "100%",
                        background: "#ddd",
                      }}
                    />
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col-sm-1">
                    <input type="checkbox" disabled />
                  </div>
                  <div className="col-sm-11">
                    <p className="h5">
                      I Agree To Receive A Meeting Link Via Text Message
                      <br />
                    </p>
                    <span
                      className="h6"
                      style={{
                        color: "#8a8a8a",
                      }}
                    >
                      By entering your number, you agree to receive text
                      messages at the phone number provided.
                    </span>
                  </div>
                </div>

                <p className="mt-2 h3">Email (Optional)</p>
                <input
                  placeholder="john.doe@mail.com"
                  disabled
                  style={{
                    padding: " 12px",
                    border: "0",
                    borderRadius: "10px",
                    width: "100%",
                    background: "#ddd",
                  }}
                />
                <br />
                <div className="text-end mt-2">
                  <button
                    className="btn btn-primary"
                    disabled
                    style={{ borderRadius: "10px", width: "180px" }}
                  >
                    Select A Time
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopifyDetails;
