import moment from 'moment';
import { DATE_FORMATS } from './constants';

export const formatDate = (date) => {
  const datea = new Date(date);
  const month = Number(datea.getMonth()) + 1;
  date = datea.getFullYear() + '-' + month + '-' + datea.getDate();
  return date;
};
export const formatDateNew = (date) => {
  const datea = new Date(date);
  const month = Number(datea.getMonth()) + 1;
  date = datea.getDate() + '-' + month + '-' + datea.getFullYear();
  return date;
};
export const getDateRequired = (data, type) => {
  const date = moment(data);
  if (type === 'month') {
    return date.format('MMMM');
  } else if (type === 'day') {
    return date.format('DD');
  } else if (type === 'week') {
    return date.format('dddd');
  }
};

export const getTimeArray = (data) => {
  var abc = [];
  data.map((date) => {
    if (date < 10) {
      var dates = {
        date: '0' + date.toString(),
        meridian: 'AM',
        showing: date,
      };
      abc.push(dates);
    } else if (date >= 10 && date < 12) {
      var morning = {
        date: date.toString(),
        meridian: 'AM',
        showing: date,
      };
      abc.push(morning);
    } else if (date === 12) {
      var midday = {
        date: date.toString(),
        meridian: 'PM',
        showing: 12,
      };
      abc.push(midday);
    } else {
      var datesPm = {
        date: date.toString(),
        meridian: 'PM',
        showing: date - 12,
      };
      abc.push(datesPm);
    }
  });
  return abc;
};

export const calculateDifferenceToday = (date, type) => {
  return moment(date).diff(moment(Date.now()), type);
};

const now = moment();
export const getTimedifference = (dateGiven) => {
  const creation = moment(dateGiven);
  const diff = now.diff(creation);
  const diffDuration = moment.duration(diff);
  var date = '';
  if (diffDuration.years() > 0) {
    date =
      diffDuration.years() + ' Year(s)' + diffDuration.months() + ' month(s)';
  } else if (diffDuration.months() > 0) {
    date =
      diffDuration.months() + ' month(s)' + diffDuration.days() + ' day(s)';
  } else {
    date =
      (diffDuration.days() > 0 ? diffDuration.days() + ' Day(s) ' : '') +
      (diffDuration.hours() > 0 ? diffDuration.hours() + ' Hour(s) ' : '') +
      (diffDuration.minutes() > 0 ? diffDuration.minutes() + ' min(s)' : '');
  }

  return <p>{date}</p>;
};
export function getTimeDifferencetimezone(timeString) {
  // convert input time string to a Date object in UTC timezone
  const utcDate = new Date(timeString);
  // calculate difference in milliseconds between UTC time and local time now
  const diffMillis = Date.now() - utcDate.getTime();
  // calculate days, hours, and minutes difference from milliseconds difference
  const diffDays = Math.floor(diffMillis / 86400000);
  const diffHours = Math.floor((diffMillis % 86400000) / 3600000);
  const diffMinutes = Math.floor((diffMillis % 3600000) / 60000);
  // return the result as a string
  return `${diffDays} days ${diffHours} hrs ${diffMinutes} mins`;
}

/**
 * This function is used to modify the timezone display for the host
 *
 * @param {string} timeString time in string for the scheduled demo
 * @return {String} difference of the current time and the scheduled demo
 */

export function getScheduleTimeDifference(timeString) {
  // convert input time string to a Date object in UTC timezone
  const utcDate = new Date(timeString);
  // calculate difference in milliseconds between UTC time and local time now
  const diffMillis = utcDate.getTime() - Date.now();
  // calculate days, hours, and minutes difference from milliseconds difference
  const diffDays = Math.floor(diffMillis / 86400000);
  const diffHours = Math.floor((diffMillis % 86400000) / 3600000);
  const diffMinutes = Math.floor((diffMillis % 3600000) / 60000);
  // return the result as a string
  return `${diffDays} days ${diffHours} hrs ${diffMinutes} mins`;
}

export function convertToUTC(localTimeString) {
  // parse the local time string using moment
  const m = moment(localTimeString, 'YYYY-M-DTHH:m');

  // convert to UTC time
  m.utc();

  // format the result as an array containing date and time
  const utcDate = m.format('YYYY-MM-DD');
  const utcTime = m.format('HH:mm');
  const result = [utcDate, utcTime];

  return result;
}

export function convertUTCToLocal(utcTime) {
  // parse the UTC time string using moment
  const m = moment.utc(utcTime, 'YYYY-M-DTHH:mm');
  // convert to local time zone
  m.local();

  // format the result as an array containing date and time
  const localDate = m.format('YYYY-MM-DD');
  const localTime = m.format('HH:mm');
  const result = [localDate, localTime];

  return result;
}
export function utcLocalTimeStringConversion(utcTime) {
  // parse the UTC time string using moment
  const m = moment.utc(utcTime, 'YYYY-M-DTHH:mm');
  // convert to local time zone
  m.local();

  // format the result as an array containing date and time
  const localDate = m.format('MM-DD-YYYY');
  const localTime = m.format('HH:mm');
  const result = localDate;
  return result;
}

export const calculateDifferenceTodayPublic = (date, type) => {
  let [dateLocal, timeLocal] = convertUTCToLocal(date);
  const result = calculateDifferenceToday(dateLocal + ' ' + timeLocal, type);
  return result;
};

/**
 * This function is used to extract the time options to display in the local format to the host
 *
 * @return {Array} Array of time options
 */

export const extractTimeOptions = (dateTimeRange, defaultDate, reschedule) => {
  let allTimeOptions = [];
  for (let i = 0; i < dateTimeRange.length; i++) {
    const timeOptions = dateTimeRange[i].time_options;
    if (reschedule) {
      const modifiedTimeOptions = timeOptions
        .filter(
          (timeOption) =>
            calculateDifferenceToday(timeOption, 'minutes') >= 0 ||
            moment(timeOption).format('YYYY-MM-DD , hh:mm A') === defaultDate
        )
        .map((timeOption) => moment(timeOption).format('YYYY-MM-DD , hh:mm A'));
      allTimeOptions = allTimeOptions.concat(modifiedTimeOptions);
    } else {
      const modifiedTimeOptions = timeOptions
        .filter(
          (timeOption) => calculateDifferenceToday(timeOption, 'minutes') >= 0
        )
        .map((timeOption) => moment(timeOption).format('YYYY-MM-DD , hh:mm A'));
      allTimeOptions = allTimeOptions.concat(modifiedTimeOptions);
    }
  }
  return allTimeOptions;
};

/**
 * This function is used to modify the timezone display for the host
 *
 * @return {String} Timezone string representation
 */
export const convertTimeZoneFormat = (timeZoneString) => {
  const [region, city] = timeZoneString.split('/');
  const cityName = city.replace(/_/g, ' ');
  return `${cityName}, ${region}`;
};

/**
 * This function is used to convert utc time to local time
 *
 * @param {string} utcTime time in utc format
 * @return {Array} array containing local date and time
 */

export function convertUTCToLocalAmPmFormat(utcTime) {
  // parse the UTC time string using moment
  const m = moment.utc(utcTime, DATE_FORMATS.GENERIC_UTC_DATE_FORMAT);
  // convert to local time zone
  m.local();

  // format the result as an array containing date and time
  const localDate = m.format(DATE_FORMATS.GENERIC_DATE_FORMAT);
  const localTime = m.format(DATE_FORMATS.GENERIC_UTC_TIME_FORMAT);
  return [localDate, localTime];
}

/**
 * This function is used to convert utc time to local time
 *
 * @param {string} utcTime time in utc format
 * @return {Array} array containing local date and time
 */

export function convertUTCToLocalMyPayoutsFormat(utcTime) {
  // parse the UTC time string using moment
  const m = moment.utc(utcTime, DATE_FORMATS.GENERIC_UTC_DATE_FORMAT);
  // convert to local time zone
  m.local();

  // format the result as an array containing date and time
  const localDate = m.format(DATE_FORMATS.MY_PAYOUTS_DATE_FORMAT);
  const localTime = m.format(DATE_FORMATS.GENERIC_UTC_TIME_FORMAT);
  return [localDate, localTime];
}

/**
 * This function is used to convert utc time to local time
 *
 * @param {string} utcTime time in utc format
 * @return {Array} array containing local date and time
 */

export function convertUTCToLocalMeetingsFormat(utcTime) {
  // parse the UTC time string using moment
  const m = moment.utc(utcTime, DATE_FORMATS.GENERIC_UTC_DATE_FORMAT);
  // convert to local time zone
  m.local();

  // format the result as an array containing date and time
  const localDate = m.format(DATE_FORMATS.MEETING_STATUS_DATE_FORMAT);
  const localTime = m.format(DATE_FORMATS.GENERIC_UTC_TIME_FORMAT);
  return [localDate, localTime];
}

/**
 * This function is used to convert any input date string to required output format
 *
 * @param {string} utcTime time in utc format
 * @param {string} inputFormat input format of the date
 * @param {string} outputFormat output format of the date
 * @return {Array} array containing local date and time
 */

export function convertToAnyDateFormat(utcTime, inputFormat, outputFormat) {
  // parse the UTC time string using moment
  const m = moment.utc(utcTime, inputFormat);
  // convert to local time zone
  m.local();

  // format the result as an array containing date and time
  const localDate = m.format(outputFormat);
  const localTime = m.format(DATE_FORMATS.GENERIC_UTC_TIME_FORMAT);
  return [localDate, localTime];
}

/**
 * This function is used to convert utc time to local time
 *
 * @param {string} soonestAt time in utc format
 * @param {string} startUtcTime time in utc format
 * @param {string} scheduledUtcTime time in utc format
 * @return {string} time string in the required format
 */

export function convertNoShowQualificationTime(
  soonestAt,
  startUtcTime,
  scheduledUtcTime,
  getDateObject = false
) {
  let noShowQualificationTime;

  if (startUtcTime && scheduledUtcTime && !soonestAt) {
    const startTime = moment.utc(
      startUtcTime,
      DATE_FORMATS.GENERIC_FULL_DATE_FORMAT
    );
    startTime.local();

    const scheduledTime = moment.utc(
      scheduledUtcTime,
      DATE_FORMATS.GENERIC_FULL_DATE_FORMAT
    );

    scheduledTime.local();

    if (startTime < scheduledTime) {
      noShowQualificationTime = scheduledTime.add(10, 'minutes');
    } else {
      noShowQualificationTime = startTime.add(10, 'minutes');
    }

    if (getDateObject) {
      return noShowQualificationTime;
    }

    noShowQualificationTime = noShowQualificationTime.format(
      DATE_FORMATS.GENERIC_UTC_TIME_FORMAT
    );
    return noShowQualificationTime;
  } else if (soonestAt && startUtcTime) {
    const startTime = moment.utc(
      startUtcTime,
      DATE_FORMATS.GENERIC_FULL_DATE_FORMAT
    );
    startTime.local();

    noShowQualificationTime = startTime.add(10, 'minutes');

    if (getDateObject) {
      return noShowQualificationTime;
    }

    noShowQualificationTime = noShowQualificationTime.format(
      DATE_FORMATS.GENERIC_UTC_TIME_FORMAT
    );
    return noShowQualificationTime;
  } else {
    const scheduledTime = moment.utc(
      scheduledUtcTime,
      DATE_FORMATS.GENERIC_FULL_DATE_FORMAT
    );

    scheduledTime.local();
    noShowQualificationTime = scheduledTime.add(10, 'minutes');

    if (getDateObject) {
      return noShowQualificationTime;
    }

    noShowQualificationTime = noShowQualificationTime.format(
      DATE_FORMATS.GENERIC_UTC_TIME_FORMAT
    );
    return noShowQualificationTime;
  }
}

/**
 * This function is used to calculate the video duration of a meeting
 *
 * @param {string} hostJoinedUtcTime time in utc format
 * @param {string} hostLeftUtcTime time in utc format
 * @param {string} guestJoinedUtcTime time in utc format
 * @param {string} guestLeftUtcTime time in utc format
 * @param {string} scheduledUtcTime time in utc format
 * @param {boolean} isColumn flag to identify whether its for column or for modal
 * @return {string} time string in the required format
 */

export const calculateVideoDuration = (
  hostJoinedUtcTime,
  hostLeftUtcTime,
  guestJoinedUtcTime,
  guestLeftUtcTime,
  scheduledUtcTime
) => {
  if (!hostJoinedUtcTime) {
    hostJoinedUtcTime = scheduledUtcTime;
  }
  if (!guestLeftUtcTime) {
    guestLeftUtcTime = hostLeftUtcTime;
  }

  if (!guestJoinedUtcTime && hostJoinedUtcTime) {
    guestJoinedUtcTime = hostJoinedUtcTime;
  }

  if (!guestJoinedUtcTime && !hostJoinedUtcTime) {
    guestJoinedUtcTime = scheduledUtcTime;
  }

  const hostStartTime = moment.utc(
    hostJoinedUtcTime,
    DATE_FORMATS.GENERIC_FULL_DATE_FORMAT
  );

  const hostLeftTime = moment.utc(
    hostLeftUtcTime,
    DATE_FORMATS.GENERIC_FULL_DATE_FORMAT
  );

  const guestStartTime = moment.utc(
    guestJoinedUtcTime,
    DATE_FORMATS.GENERIC_FULL_DATE_FORMAT
  );

  const guestLeftTime = moment.utc(
    guestLeftUtcTime,
    DATE_FORMATS.GENERIC_FULL_DATE_FORMAT
  );

  hostStartTime.local();
  hostLeftTime.local();
  guestStartTime.local();
  guestLeftTime.local();

  // Calculate the earliest joining time and latest leaving time
  const earliestJoiningTime = moment.min(hostStartTime, guestStartTime);
  const latestLeavingTime = moment.max(hostLeftTime, guestLeftTime);

  const timeDifference = Math.abs(latestLeavingTime - earliestJoiningTime);

  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

  if (hours > 0) {
    return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${
      seconds < 10 ? '0' : ''
    }${seconds}`;
  } else {
    return `${minutes < 10 ? '0' : ''}${minutes}:${
      seconds < 10 ? '0' : ''
    }${seconds}`;
  }
};

/**
 * This function is used to calculate the facetime duration of a meeting
 *
 * @param {string} hostJoinedUtcTime time in utc format
 * @param {string} hostLeftUtcTime time in utc format
 * @param {string} guestJoinedUtcTime time in utc format
 * @param {string} guestLeftUtcTime time in utc format
 * @param {string} scheduledUtcTime time in utc format
 * @return {string} time string in the required format
 */

export const calculateFacetimeDuration = (
  hostJoinedUtcTime,
  hostLeftUtcTime,
  guestJoinedUtcTime,
  guestLeftUtcTime,
  scheduledUtcTime
) => {
  if (!guestJoinedUtcTime) {
    return '00:00';
  }
  if (!hostJoinedUtcTime) {
    hostJoinedUtcTime = scheduledUtcTime;
  }
  if (!guestLeftUtcTime) {
    guestLeftUtcTime = hostLeftUtcTime;
  }

  const hostStartTime = moment.utc(
    hostJoinedUtcTime,
    DATE_FORMATS.GENERIC_FULL_DATE_FORMAT
  );

  const hostLeftTime = moment.utc(
    hostLeftUtcTime,
    DATE_FORMATS.GENERIC_FULL_DATE_FORMAT
  );

  const guestStartTime = moment.utc(
    guestJoinedUtcTime,
    DATE_FORMATS.GENERIC_FULL_DATE_FORMAT
  );

  const guestLeftTime = moment.utc(
    guestLeftUtcTime,
    DATE_FORMATS.GENERIC_FULL_DATE_FORMAT
  );

  hostStartTime.local();
  hostLeftTime.local();
  guestStartTime.local();
  guestLeftTime.local();

  // Calculate the earliest joining time and latest leaving time
  const latestJoiningTime = moment.max(hostStartTime, guestStartTime);
  const earliestLeavingTime = moment.min(hostLeftTime, guestLeftTime);

  const timeDifference = Math.abs(earliestLeavingTime - latestJoiningTime);

  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

  if (hours > 0) {
    return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${
      seconds < 10 ? '0' : ''
    }${seconds}`;
  } else {
    return `${minutes < 10 ? '0' : ''}${minutes}:${
      seconds < 10 ? '0' : ''
    }${seconds}`;
  }
};
