import AuthContext from "context/AuthContext";
import { useEffectOnce } from "hooks/useEffectOnce";
import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Loginimage from "../login/Host/Loginimage";

const SignupVerify = () => {
  const { verificationEmail, setIsEmailVerified } = useContext(AuthContext);
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const params = location.pathname;
  const data = params.split("/");
  const userId = data[2];

  useEffect(() => {
    const data = {};
    const response = verificationEmail(data, userId);
    response
      .then(function (response) {
        setSubmitted(true);
        setIsEmailVerified(true);
      })
      .catch(function (error) {
        if (error.response.status === 500) {
          setErrorMessage(
            "The link is not working. Please try verification again"
          );
        } else if (error.response.status === 400) {
          setErrorMessage("The link maybe expired or wrong.");
        } else if (error.response.status === 409) {
          setErrorMessage("Account already verified!");
        }
      });
  }, []);
  useEffectOnce(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2500);
  }, []);

  return (
    <>
      <div className="container mt-5 login-wrapper">
        <div className="row login-column-wrap">
          <div className="col-md-8">
            <div className="right-text">
              <h1>Welcome to Purebrand.</h1>
              <p className="paragraph">Your virtual product showroom.</p>
            </div>

            <Loginimage />
          </div>
          <div className="col-md-4">
            {loading ? (
              <div className="text-center">
                <Spinner animation="grow" />
                <p>Requesting verification...</p>
              </div>
            ) : (
              <>
                <div className="mb-3">
                  {!submitted ? (
                    <>
                      <div className="error-message mt-3">{errorMessage}</div>
                    </>
                  ) : (
                    <PostEmailVerification />
                  )}
                </div>
                <div className="button-wrapper mt-3">
                  <button
                    onClick={() => navigate("/login")}
                    className="btn btn-primary"
                  >
                    Go to login
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SignupVerify;

const PostEmailVerification = () => {
  return (
    <>
      <div className="check-email-text">
        <h3>Your email has been verified. Log in to get started.</h3>
      </div>
    </>
  );
};
