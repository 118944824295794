import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import PaypalPay from "./PaypalPay";

const Checkout = (props) => {
  let location = useLocation();
  if (!location?.state?.redirected) {
    return <Navigate to="/user/welcome" />;
  } else {
    return (
      <div className="checkout-wrapper d-flex ">
        <div className="payout-detail-wrapper features">
          <div className="paypal-wrapper">
            <p>Proceed with the payment of ${location?.state.amount}</p>
            <p className="text-center">Available methods:</p>
            <PaypalPay
              price={location?.state.amount}
              type={location?.state.type}
              paymentDetails={location?.state.details}
              paymentId={location?.state.store_id}
            />

            <p style={{ fontSize: "xx-small" }}>
              Note: You will be greeted with the popup to confirm payment and be
              redirected back here upon completion
            </p>
          </div>
        </div>
      </div>
    );
  }
};

export default Checkout;
