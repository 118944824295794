import { ClientJS } from "clientjs";
import { getToken, isSupported } from "firebase/messaging";
import { useEffectOnce } from "hooks/useEffectOnce";
import { useContext, useEffect } from "react";
import { useState } from "react";
import { createContext } from "react";
import { sendFirebaseToken } from "Services/externalApiService";
import {
  allNotifications,
  bellNotification,
} from "Services/notificationService";
import { setToken, getItem } from "utils/localStorageService";
import AuthContext from "./AuthContext";
import { messaging, onMessageListener, register } from "./firebase";
// import * as serviceWorker from "../serviceWorker";

const NotificationContext = createContext();
export default NotificationContext;

export const NotificationProvider = ({ children }) => {
  const client = new ClientJS();
  const { loggedIn } = useContext(AuthContext);
  const [notification, setNotification] = useState({
    title: "",
    body: "",
    data: {},
  });
  const [newNotifications, setNewNotifications] = useState([]);
  function isSafariOnMacOrIOS() {
    var userAgent = window.navigator.userAgent;
    var isMac = /Macintosh/.test(userAgent);
    var isIOS = /iPad|iPhone|iPod/.test(userAgent);
    return (
      (isMac && /Safari/.test(userAgent) && !/Chrome/.test(userAgent)) ||
      (isIOS && /AppleWebKit/.test(userAgent))
    );
  }

  const notificationPermission = () => {
    if (isSupported()) {
      register();
      if (!isSafariOnMacOrIOS()) {
        requestPermission();
      }
      // console.log("dive given", isSafariOnMacOrIOS());
      // console.log("notification given");
      const fcmToken = getItem("FCMTOKEN");
      if (!fcmToken && !isSafariOnMacOrIOS()) {
        const fingerprint = client.getFingerprint();
        setToken("deviceId", fingerprint);
        isSupported().then(() =>
          getToken(messaging, {
            vapidKey: process.env.REACT_APP_PUREBRAND_FB_KEY,
          })
            .then((currentToken) => {
              if (currentToken) {
                sendTokenToServer(currentToken, fingerprint);
                setToken("FCMTOKEN", currentToken);
                // console.log("get token success === ", currentToken);
              } else {
              }
            })
            .catch((err) => {
              console.log("get token error === ", err);
            })
        );
      }
    }
  };

  const sendTokenToServer = async (token, fingerprint) => {
    const payload = {
      token,
    };
    try {
      await sendFirebaseToken(payload, fingerprint);
    } catch (error) {
      console.log("Error Occured while sending Token");
    }
  };

  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
        data: payload.data,
      });
    })
    .catch((err) => console.log("failed: ", err));

  function isIOS() {
    const browserInfo = navigator.userAgent.toLowerCase();
    // console.log("browser", browserInfo, "platform", navigator.platform);
    if (browserInfo.match("iphone") || browserInfo.match("ipad")) {
      return true;
    }
    if (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform)
    ) {
      return true;
    }
    return false;
  }
  function requestPermission() {
    // console.log("Requesting permission...");
    // if (isIOS()) {
    //   console.log("ios check");
    //   return false;
    // } else {
    //   console.log("iso not check");
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        return true;
      }
    });
    // }
    return false;
  }

  const getBellNotification = async (params) => {
    try {
      const response = await bellNotification(params);
      if (response.status === 200) {
        setNewNotifications(response.data.data.results);
      }
    } catch (error) {
      console.log("Error Occured");
    }
  };

  const getAllNotifications = async (params) => {
    try {
      const res = allNotifications(params);
      return res;
    } catch (error) {
      throw error;
    }
  };

  let contextData = {
    notification: notification,
    getBellNotification: getBellNotification,
    newNotifications: newNotifications,
    getAllNotifications: getAllNotifications,
    setNotification: setNotification,
    notificationPermission: notificationPermission,
  };
  return (
    <NotificationContext.Provider value={contextData}>
      {children}
    </NotificationContext.Provider>
  );
};
