import PurebrandLoader from "components/Loader/PurebrandLoader";
import PurebrandToast from "components/Toasts/Toast";
import DataContext from "context/DataContext";
import React, { useContext, useEffect, useState } from "react";

const AddTag = () => {
  let { setStoreAdminProductListTag, storeAdminProductListTag } =
    useContext(DataContext);
  const [allTagList, setAllTagList] = useState();
  const [newTag, setNewTag] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [failed, setFailed] = useState(false);

  const toastPopup = () => {
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  };
  const handleCloseToast = () => {
    setShowToast(false);
  };

  const listTags = () => {
    const response = storeAdminProductListTag("?order=true");
    response
      .then(function (res) {
        setAllTagList(res?.results);
      })
      .catch((err) => {
        toastPopup();
        setFailed(true);
      });
  };

  const addNewTag = () => {
    setIsLoading(true);
    let payload = {
      name: newTag,
    };
    const response = setStoreAdminProductListTag(payload);
    response
      .then(function (res) {
        setAllTagList(res?.data?.data);
        listTags();
        setNewTag("");
        if (newTag !== "") {
          toastPopup();
        }
        setIsLoading(false);
      })
      .catch((err) => {
        toastPopup();
        setFailed(true);
      });
  };

  useEffect(() => {
    listTags();
  }, [storeAdminProductListTag]);

  if (isLoading) {
    <PurebrandLoader />;
  }
  return (
    <div>
      <PurebrandToast
        show={showToast}
        onClose={handleCloseToast}
        title={!failed ? "Success" : "Failed"}
        message={
          !failed ? "Successfully added Category." : "Something went wrong"
        }
      />
      <div className="row p-3">
        <div className="col-10 col-md-10 col-sm-12 ">
          <input
            className="form-control"
            type="text"
            placeholder="Your Category Name"
            value={newTag}
            onChange={(e) => {
              setNewTag(e.target.value);
            }}
          />
        </div>
        <div className="col-2 col-md-2 col-sm-12 ">
          <div className="row">
            <div className="col-6 col-sm-6 text-end">
              <button className="btn bg-primary text-white" onClick={addNewTag}>
                Add
              </button>
            </div>
            <div className="col-6 col-sm-6 text-end">
              <button
                className="btn bg-danger text-white "
                style={{ border: "1px solid #ccc" }}
                onClick={() => {
                  setNewTag("");
                }}
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTag;
