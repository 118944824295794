import * as LocalStorageService from '../utils/localStorageService';
import axiosInstance from './axios';

/**
 * This function is used to make a api call that allows a user connect with the dots.
 *
 * @returns {object} Response object with the data
 */

export const connectToDots = async (body) => {
  try {
    return await axiosInstance({
      method: 'POST',
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/connect-dot/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + LocalStorageService.getAccessToken(),
        devicetype: 'web',
      },
      data: body,
    });
  } catch (e) {
    throw e;
  }
};

/**
 * This function is used to make a api call that checks the dots app connection status of the store admin.
 *
 * @returns {object} Response object with the data
 */

export const getDotsPaymentStatus = async (params) => {
  try {
    return await axiosInstance({
      method: 'GET',
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/dots-payment-status/${params}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + LocalStorageService.getAccessToken(),
        devicetype: 'web',
      },
    });
  } catch (e) {
    throw e;
  }
};

/**
 * This function is used to make a api call that creates a dots app for the store admin.
 *
 * @returns {object} Response object with the data
 */

export const createDotsApp = async (body) => {
  try {
    return await axiosInstance({
      method: 'POST',
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/dots-app-create/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + LocalStorageService.getAccessToken(),
        devicetype: 'web',
      },
      data: body,
    });
  } catch (e) {
    throw e;
  }
};

/**
 * This function is used to make a api call that adds complaince information for the store admin
 *
 * @returns {object} Response object with the data
 */

export const addComplianceInfo = async (body) => {
  try {
    return await axiosInstance({
      method: 'PUT',
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/dots-add-compliance/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + LocalStorageService.getAccessToken(),
        devicetype: 'web',
      },
      data: body,
    });
  } catch (e) {
    throw e;
  }
};

/**
 * This function is used to make a api call to update the admin's payment information
 *
 * @returns {object} Response object with the data
 */

export const addPaymentInfo = async (body) => {
  try {
    return await axiosInstance({
      method: 'PUT',
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/dots-add-account/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + LocalStorageService.getAccessToken(),
        devicetype: 'web',
      },
      data: body,
    });
  } catch (e) {
    throw e;
  }
};

/**
 * This function is used to make a api call to transfer funds into app's wallet
 *
 * @returns {object} Response object with the data
 */

export const transferFunds = async (body) => {
  try {
    return await axiosInstance({
      method: 'POST',
      url: `${process.env.REACT_APP_BASE_URL}/api/v1/dots-add-funds/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + LocalStorageService.getAccessToken(),
        devicetype: 'web',
      },
      data: body,
    });
  } catch (e) {
    throw e;
  }
};
