import { Form, Formik } from "formik";
import React from "react";
import { UploadImage } from "../Multistep/ProfileCompletion/ProfileInfo";

const CustomUploader = ({
  submitAction,
  closeAction,
  type,
  maximumSize,
  acceptType,
}) => {
  return (
    <Formik
      initialValues={{
        files: null,
      }}
      onSubmit={(e) => {
        submitAction(e, type);
        closeAction();
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <UploadImage
            setFieldValue={setFieldValue}
            value="files"
            class="uploader-parts"
            title="Upload Your Media"
            maxSize={maximumSize}
            body={
              values.files ? (
                <p className="mb-3">{values.files[0].name}</p>
              ) : (
                <div className="mb-3">
                  Requirements:{" "}
                  <ul>
                    <li>.jpg, .png, or .gif file type</li>
                    <li>less than 10MB</li>
                    <li>file name is less than 100 characters</li>
                  </ul>
                </div>
              )
            }
            footer="Click here or drag image here."
            accept={acceptType}
          />
          <button className="btn btn-primary mt-3" type="submit">
            Submit
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default CustomUploader;
