export const capitalizeFirst = (oldString) => {
  let newString =
    oldString.charAt(0).toUpperCase() + oldString.slice(1).toLowerCase();
  return newString;
};

export const getOffsetFromURL = (url) => {
  const params = new URLSearchParams(url);
  return params.get("offset");
};
