import axiosInstance from "./axios";

export const storeRevenueData = async (params) =>
  axiosInstance.get(`/api/v1/store-payout-list/?${params}`);

export const getDashboardData = async () =>
  axiosInstance.get(`/api/v1/host-dashboard-payment/?order=true`);

export const getDashboardDataStore = async () =>
  axiosInstance.get(`/api/v1/store-dashboard-payment/?order=true&timeline=day`);

export const getDashboardLineDataStore = async (param) =>
  axiosInstance.get(
    `/api/v1/store-dashboard-payment/?order=true&timeline=${param}`
  );

export const getDashboardDataAdmin = async (params) =>
  axiosInstance.get(`/api/v1/purebrand-dashboard-payment/${params}`);

export const userVenmoDetail = async (data) =>
  axiosInstance.put(`/api/v1/user-venmo/`, data);

export const userVenmoStatus = async () =>
  axiosInstance.get(`/api/v1/user-venmo/`);

export const userPaymentList = async (params) =>
  axiosInstance.get(`/api/v1/purebrand-admin-payment/${params}`);

export const userPaymentUpdate = async (data, id) =>
  axiosInstance.put(`/api/v1/purebrand-admin-payment/${id}/`, data);

export const storePayoutDetail = async (params) =>
  axiosInstance.get(`/api/v1/store-demos-payout-list/${params}`);

export const storePaymentPBGet = async () =>
  axiosInstance.get(`/api/v1/pay-purebrand/?order=true`);

export const storePaymentPBPay = async (data, id) =>
  axiosInstance.put(`/api/v1/pay-purebrand/${id}/`, data);

//stripe card token
export const storeCardToken = async (data) => {
  const response = axiosInstance.post(`/api/v1/add-payment-method/`, data);
  return response;
};

export const storeCardTokenUpdate = async (data) => {
  const response = axiosInstance.post(`/api/v1/update-payment-method/`, data);
  return response;
};

export const getStoreCard = async () => {
  const response = axiosInstance.get(`/api/v1/get-payment-method/`);
  return response;
};

//stripe card token for host
export const storeCardTokenHost = async (data) => {
  const response = axiosInstance.post(
    `/api/v1/accounts/external-account/`,
    data
  );
  return response;
};

export const storeCardTokenHostDefault = async (data) => {
  const response = axiosInstance.post(
    `/api/v1/accounts/external-account/default/`,
    data
  );
  return response;
};

export const storeCardTokenHostDelete = async (token) => {
  const response = axiosInstance.delete(`/api/v1/accounts/card-id/${token}/`);
  return response;
};

//onboarding host to stripe connect account
export const hostOnBoard = async () => {
  const response = await axiosInstance.get(`/api/v1/get-onboarding-link/`);
  return response.data;
};

export const hostOnBoardComplete = async (data) => {
  const response = await axiosInstance.post(
    `/api/v1/complete-stripe-onboarding/`,
    data
  );
  return response;
};

//checks onboarding for host and payment method for storeadmin
export const checkPaymentDetail = async () => {
  const response = await axiosInstance.get(`/api/v1/user-profile/`);
  return response;
};
