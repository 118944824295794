import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import "./chart.css";

ChartJS.register(ArcElement, Tooltip, Legend);

export const PurebrandChart = (props) => {
  return (
    <Doughnut
      className={props.class}
      data={props.data}
      options={props.options}
      plugins={props.plugins}
    />
  );
};
