import CustomModal from "components/Modal/CustomModal";
import React, { useContext, useState } from "react";
import { Send, Trash3, XCircle } from "react-bootstrap-icons";
import { useLocation } from "react-router-dom";
import MessageContext from "../../context/MessageContext";

const DemoMessage = ({ sendText, setSendText }) => {
  let { createNewMessage } = useContext(MessageContext);
  const loc = useLocation();
  const [newChat, setNewChat] = useState();
  const [sendMessageSucess, setSendMessageSucess] = useState(false);
  const [errorSendingMessage, setErrorSendingMessage] = useState(false);

  const handleClose = () => {
    setSendMessageSucess(false);
  };

  const sendMessage = () => {
    let demoId = loc?.state?.sessionDetail?.id;
    let payload = {
      id: demoId,
      text: newChat,
    };
    const response = createNewMessage(payload);
    response
      .then(function (res) {
        setNewChat("");
        setSendMessageSucess(true);
      })
      .catch(function (err) {
        setErrorSendingMessage(true);
        setSendMessageSucess(true);
      });
  };

  return (
    <>
      <CustomModal
        showStatus={sendMessageSucess}
        clickStatus={handleClose}
        buttonMessage="Close"
        closeStatus={handleClose}
        title={
          !errorSendingMessage
            ? "Message Sent Sucessfully"
            : "Message Sending Failed"
        }
        message={
          !errorSendingMessage
            ? "Your message has been sent successfully."
            : "Message Sending Failed, Try agian"
        }
        secondButton={false}
        secondMessage="none"
      />
      <div
        className="row"
        style={{
          position: "fixed",
          left: "5%",
          bottom: "100px",
          width: "auto",
          background: "#fff",
          padding: " 35px",
          borderRadius: "20px",
        }}
      >
        <div className="col-7 ">
          <textarea
            className="form-control form-control "
            type="text"
            placeholder="Type Message..."
            rows="5"
            style={{ borderRadius: "2px", width: "100%" }}
            value={newChat}
            onChange={(e) => {
              setNewChat(e.target.value);
            }}
          />
        </div>
        <div className="col-5 p-0">
          <div className="row">
            <div className="col-4 text-end ">
              <button
                className="btn"
                style={{
                  border: "1px solid #ccc",
                }}
                onClick={sendMessage}
              >
                <Send style={{ color: "green", fontSize: "25px" }} />
              </button>
            </div>
            <div className="col-4 text-end">
              <button
                className="btn"
                onClick={() => setNewChat("")}
                style={{
                  border: "1px solid #ccc",
                }}
              >
                <Trash3 style={{ color: "red", fontSize: "25px" }} />
              </button>
            </div>
            <div className="col-4 text-end">
              <button
                className="btn"
                onClick={() => setSendText(!sendText)}
                style={{
                  position: "absolute",
                  bottom: "70%",
                  right: "1%",
                }}
              >
                <XCircle style={{ color: "red", fontSize: "20px" }} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DemoMessage;
