import { Purebrand, PureLoyaltyCard } from "assets/images";
import React from "react";
import { Carousel, Container } from "react-bootstrap";

const SeeReview = () => {
  return (
    <Container>
      <div className="review-listing">
        <Carousel>
          <Carousel.Item interval={2000} className="custom-carousel">
            <img src={Purebrand} alt="purebrand" />
          </Carousel.Item>
          <Carousel.Item interval={2000} className="custom-carousel">
            <img src={PureLoyaltyCard} alt="purebrand" />
          </Carousel.Item>
        </Carousel>
      </div>
    </Container>
  );
};

export default SeeReview;
