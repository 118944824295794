import React from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Check = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Button
        onClick={() => navigate("/signup")}
        className="header-signup"
        variant="primary"
      >
        Sign up
      </Button>{" "}
      <Button
        onClick={() => navigate("/login")}
        className="header-login"
        variant="outline-primary"
      >
        Log in
      </Button>{" "}
    </div>
  );
};

export default Check;
