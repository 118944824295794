import { ProfilePicture } from "assets/images";
import AuthContext from "context/AuthContext";
import PhotoHandler from "Pages/dashboard/Profile/PhotoHandler";
import React, { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const CustomModal = (props) => {
  let { userProfile } = useContext(AuthContext);

  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/user/apply-loyalty");
  };
  const getSecondButton = () => {
    if (props.secondButton) {
      if (props.secondMessage === "card") {
        return (
          <Button variant="outline-primary" onClick={handleClick}>
            Setup card
          </Button>
        );
      } else if (props.secondMessage === "edit") {
        return (
          <Button variant="outline-primary" onClick={handleClick}>
            Save
          </Button>
        );
      } else if (props.secondMessage === "Profile") {
        return (
          <Button
            variant="outline-primary"
            onClick={() => navigate("/user/profile?edit=true")}
          >
            Continue
          </Button>
        );
      } else if (props.secondMessage === "ConfirmUpload") {
        return (
          <Button
            variant="outline-primary"
            type="submit"
            disabled={props.enabled === false || props.loading === true}
            onClick={props.submitActionProduct}
          >
            Yes
          </Button>
        );
      } else if (props.secondMessage === "Delete") {
        return (
          <Button
            variant="outline-primary"
            type="submit"
            onClick={() => {
              props.submitActionDelete();
              props.setActiveProduct(null);
              props.closeStatus();
            }}
          >
            Yes
          </Button>
        );
      } else if (props.secondMessage) {
        return (
          <Button variant="outline-primary" onClick={() => navigate("/login")}>
            Go to login
          </Button>
        );
      }
    } else {
      return <></>;
    }
  };
  return (
    <Modal
      show={props.showStatus}
      onHide={props.clickStatus}
      className={props.modalClass ? props.modalClass : ""}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.message === "editing" ? (
          <PhotoHandler
            status="edit"
            photo={
              userProfile?.profile_picture?.file
                ? userProfile?.profile_picture?.file
                : ProfilePicture
            }
            closing={props.closeStatus}
          />
        ) : (
          props.message
        )}
      </Modal.Body>
      <Modal.Footer>
        {props.secondMessage === "noClose" ? null : (
          <Button
            variant="outline-secondary"
            disabled={props?.loading ? props.loading : false}
            onClick={props.closeStatus}
          >
            {props.buttonMessage}
          </Button>
        )}

        {getSecondButton()}
      </Modal.Footer>
    </Modal>
  );
};

export default CustomModal;
