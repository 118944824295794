import AuthContext from "context/AuthContext";
import ProfileContext from "context/ProfileContext";
import React, { useContext, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AgreementTC from "../AgreementTC";
import BasicInfo from "../ProfileCompletion/BasicInfo";
import ProfileInfo from "../ProfileCompletion/ProfileInfo";
import ShopifyDetail from "./ShopifyDetail";
import StoreDetail from "./StoreDetail";

const StoreProfileForm = () => {
  let { user } = useContext(AuthContext);
  let { setupStoreProfile } = useContext(ProfileContext);
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState({
    phone_number: "",
    about_me: "",
    instagram_handle: "",
    twitter_handle: "",
    tiktok_handle: "",
    facebook_handle: "",
    latitude: "",
    longitude: "",
    address: "",
    street_number: "",
    city: "",
    state: "",
    zip_code: "",
    profile_picture_id: "",
    imageFile: "",
    files: "",
    videofile: "",
    tag: "profile_picture",
    type: "image",
    extension: "jpg",
    store_email: user.email,
    id: user.uid,
    social_selected: false,
    demoCommission: "",
    commissionType: "AMOUNT",
    salesCommission: "",
    salesCommissionType: "AMOUNT",
    discountType: "AMOUNT",
    discountGiven: "",
    paymentFrequency: 1,
    no_show_demo_commission_type: "AMOUNT",
    no_show_demo_commission: "",
  });
  const [currentStep, setCurrentStep] = useState(0);

  const makeRequest = (formData) => {
    const response = setupStoreProfile(formData);
    response
      .then(function (response) {
        if (response.status === 200) {
          navigate("/user/welcome");
        }
      })
      .catch(function (err) {
        console.error("Error Occured");
      });
  };

  const handleNextStep = (newData, final = false) => {
    setProfileData((prev) => ({ ...prev, ...newData }));

    if (final) {
      makeRequest(newData);
      return;
    }
    setCurrentStep((prev) => prev + 1);
  };

  const steps = [
    <BasicInfo next={handleNextStep} data={profileData} />,
    <ProfileInfo next={handleNextStep} data={profileData} />,
    <StoreDetail next={handleNextStep} data={profileData} />,
    <ShopifyDetail next={handleNextStep} data={profileData} />,
    <AgreementTC next={handleNextStep} data={profileData} />,
  ];

  return (
    <div>
      <ProgressBar
        className="step-progress-bar"
        variant="info"
        now={currentStep + 1}
        min={0}
        max={5}
        label={`${currentStep + 1}/5`}
      />
      {steps[currentStep]}
    </div>
  );
};

export default StoreProfileForm;
