import React, { createContext, useContext, useEffect, useState } from "react";
import { socket } from "Services/socket";

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [messageLogs, setMessageLogs] = useState("");
  const [allMessage, setAllMessage] = useState([]);

  socket.onopen = function (e) {
    console.log("[open] Connection established");
    console.log("Sending to server");
    setIsConnected(true);
  };

  socket.onmessage = function (event) {
    console.log(`[message] Data received from server: ${event.data}`);
    setMessageLogs(event.data);
  };

  socket.onclose = function (event) {
    if (event.wasClean) {
      console.log(
        `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`
      );
    } else {
      console.log("[close] Connection died");
    }
  };

  socket.onerror = function (error) {
    console.log(`[error]`);
  };

  // const demo_id = localStorage.getItem("demoId");

  // const sendMessage = (data) => {
  //   if (socket && socket.readyState === WebSocket.OPEN) {
  //     socket.send(JSON.stringify(data));
  //   }
  // };

  // useEffect(() => {
  //   if (demo_id) {
  //     sendMessage({
  //       command: "join_room",
  //       room_id: demo_id,
  //     });
  //   }
  // }, [demo_id]);

  // console.log({ demo_id });

  const storeAllMessage = (message) => {
    if (message !== "") {
      const parsedData = JSON.parse(message);
      setAllMessage((prev) => {
        if (Array.isArray(prev)) {
          return [...prev, parsedData];
        } else {
          return [prev, parsedData];
        }
      });
    }
  };

  useEffect(() => {
    storeAllMessage(messageLogs);
  }, [messageLogs]);

  const contextData = {
    isConnected,
    messageLogs,
    allMessage,
    sendMessage: (data) => {
      if (socket) {
        if (socket.readyState === WebSocket.OPEN) {
          socket.send(JSON.stringify(data));
        } else {
          socket.addEventListener("open", function () {
            socket.send(JSON.stringify(data));
          });
        }
      } else {
        console.error("WebSocket is not initialized");
      }
    },
  };

  return (
    <SocketContext.Provider value={contextData}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketContext;
